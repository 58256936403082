import { Pipe, PipeTransform } from '@angular/core';
import { ProfileQuery } from 'src/app/profile/state/profile.query';

@Pipe({
  standalone: true,
  name: 'agentIdToNamePipe',
})
export class AgentIdToNamePipe implements PipeTransform {

    constructor(
        private profileQuery: ProfileQuery
    ) {}

	transform(id: string): string {
    if (!id) return '-';
      const agent = this.profileQuery.getAgentById(id);
      return agent ? agent.name : id;
	}
}