import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';

import { HtmlDecodePipe } from 'src/app/shared/pipes/html-decode.pipe';
import { TableCustomView } from 'src/app/api/models/custom-views/custom-views.interface';

import { produce } from 'immer';

export interface CustomViewsState extends EntityState<TableCustomView, string> { }

@Injectable({
	providedIn: 'root'
})
@StoreConfig({
	name: 'customViews',
	idKey: 'id',
	producerFn: produce
})
export class CustomViewsStore extends EntityStore<CustomViewsState> {

	constructor(
		private htmlDecodePipe: HtmlDecodePipe
	) {
		super();
	}
  
  	akitaPreAddEntity(tableCustomView: TableCustomView): TableCustomView {
		return this.getDecodedTableCustomView(tableCustomView);
	}

	akitaPreUpdateEntity(tableCustomView: TableCustomView): TableCustomView {
		return this.getDecodedTableCustomView(tableCustomView);
	}

	private getDecodedTableCustomView(tableCustomView: TableCustomView): TableCustomView {
		return {
			...tableCustomView,
			name: this.htmlDecodePipe.transform(tableCustomView.name)
		};
	}
}