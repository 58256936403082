import { Injectable } from '@angular/core';
import { FeatureFlagsResponse, ProfileFeatureFlags } from 'src/app/api/models/profile/profile-feature-flags';
import { createInitialState, FeatureFlagsStore } from './feature-flag.store';
import { LoggerService } from 'src/app/logger/logger.service';
import { FeatureFlags, PublicFeatureFlags } from 'src/app/api/models/profile/profile-feature-flags.enum';
import { map, tap, Observable } from 'rxjs';
import { ApiClientFeaturesService } from 'src/app/api/api-client-features.service';
import { AnalyticsService } from 'src/app/analytics/analytics.service';
import { EventActionType } from 'src/app/analytics/event-action.enum';
import { AnalyticEventsLabels } from 'src/app/analytics/analytics-events-labels';

@Injectable({
	providedIn: 'root',
})
export class FeatureFlagService {
	private readonly loggerName: string = 'FeatureFlagService';

	constructor(
		private featureFlagStore: FeatureFlagsStore,
		private logger: LoggerService,
		private api: ApiClientFeaturesService,
		private analyticsService: AnalyticsService
	) {}

	updateFeatureFlags(featureFlags: ProfileFeatureFlags): void {
		this.logger.debug(this.loggerName, 'updateFeatureFlags()');
		this.featureFlagStore.update(featureFlags);
	}

	logout(): Observable<void> {
		return this.getPublicFeatureFlagsFromServer(); //after logout we are setting all flags to false, so we need to retreive again the public flags
	}

	getFeatureFlagsFromServer(): Observable<void> {
		//agent flags
		const flagsArr = [];
		for (const key in FeatureFlags) {
			flagsArr.push(key);
		}

		return this.api.getFeatureFlags(flagsArr).pipe(
			map((res) => this.handleFFResponse(res)),
			tap(ffs => this.sendAnalyticsOfFeatureFlags(ffs)),
			map((ffs) => this.updateFeatureFlags(ffs)),
			tap(() => this.featureFlagStore.setLoading(false))
		);
	}

	getPublicFeatureFlagsFromServer(): Observable<void> {
		//public flags
		const publicFlagsArr = [];
		for (const key in PublicFeatureFlags) {
			publicFlagsArr.push(key);
		}

		return this.api.getPublicFeatureFlags(publicFlagsArr).pipe(
			map((res) => this.handlePublicFFResponse(res)),
			map((ffs) => this.updateFeatureFlags({...createInitialState(), ...ffs})),
		);
	}

	private handleFFResponse(res: FeatureFlagsResponse): ProfileFeatureFlags {
		//have to transform FF keys to lowercase for the client
		let lowercaseFF: ProfileFeatureFlags = <any>{};
		for (let key in res.requestedFeatures) {
			const value = res.requestedFeatures[key];
			key = `${key[0].toLowerCase()}${key.slice(1)}`;
			lowercaseFF[key] = value;
		}
		return lowercaseFF;
	}

	private handlePublicFFResponse(res: { [key: string]: string }): ProfileFeatureFlags {
		let lowercaseFF = <any>{};
		for (let key in res) {
			const value = res[key];
			key = `${key[0].toLowerCase()}${key.slice(1)}`;
			lowercaseFF[key] = value;
		}
		return lowercaseFF;
	}

	private sendAnalyticsOfFeatureFlags(flags: ProfileFeatureFlags): void {
		// send search evidence on/off to analytics
		this.analyticsService.track({
			eventAction: EventActionType.Click,
			eventCategory: flags.ticketSearch ? AnalyticEventsLabels.SEARCH_EVIDENCE_flag_on : AnalyticEventsLabels.SEARCH_EVIDENCE_flag_off,
		});
	}
}