<canvas #confettiCanvasBar id="confettiCanvasBar" class="progress-bar__confetti-canvas"></canvas>

<ng-container *ngIf="progress$ | async as progress">
    <ng-container *ngIf="!shrink; else shrinkProgressBar">
        <div *ngIf="openedTasks; else finishedTasks" class="progress-bar__container" [@fadeOut]="fadeOutAfterCompletedTasks"
            (@fadeOut.done)="onboardingTasksFinished()">
            <p class="progress-bar__title">{{'menu.progressBarTitle' | content}}</p>
        
            <div class="progress-bar__bar-section">
                <div class="progress-bar__bar">
                    <div class="progress-bar__progress" [ngStyle]="{'width': progress}" [@glow]="startGlowing"
                        (@glow.done)="stopGlowing()" [@progressBar]>
                    </div>
                </div>
                <span class="progress-bar__text">{{doneTasks.length}}/{{numberOfTasks}}</span>
            </div>
        
            <svg class="progress-bar__icon">
                <use xlink:href="../../../assets/img/sprite.svg#icon-next-arrow"></use>
            </svg>
        </div>
    </ng-container>
</ng-container>

<ng-template #finishedTasks>
    <div [@enterFromBottom] (@enterFromBottom.done)="destroyComponent()" class="progress-bar__done-container">
        <svg class="progress-bar__done">
            <use xlink:href="../../../assets/img/sprite.svg#icon-success-check"></use>
        </svg>
        
        <span class="progress-bar__title">{{'menu.progressDone' | content}}</span>
    </div>
</ng-template>

<ng-template #shrinkProgressBar>
    <span class="progress-bar__shrink-text">{{doneTasks.length}}/{{numberOfTasks}}</span>
    <div class="progress-bar__shrink-container" [@fadeOut]="fadeOutAfterCompletedTasks"
        (@fadeOut.done)="onboardingTasksFinished()">
        <svg class="progress-bar__shrink-icon">
            <use xlink:href="../../../assets/img/sprite.svg#icon-Flag"></use>
        </svg>
    </div>
</ng-template>