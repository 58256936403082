<ng-container *ngFor="let item of items">
        <ng-container *ngTemplateOutlet="(item.route ? routerLinkButton : subMenuButton); context: {nav: item}">
        </ng-container>
</ng-container>

<ng-template #routerLinkButton let-item="nav">
    <ng-container *minePermissions="item.permittedRoles">
            <button class="nav-menu__item" 
            routerLink="/{{ item.route }}"
            routerLinkActive="nav-menu__item--active"
            (click)="onItemClick(item)"
            >
            <ng-container *ngTemplateOutlet="btnContent; context: {navItem:item}"></ng-container>
        </button>
        <div *ngIf="item.divider" class="nav-menu__item-divider"></div>
    </ng-container>
</ng-template>

<ng-template #subMenuButton let-item="nav">
    <mine-expansion-panel #subMenu *minePermissions="item?.permittedRoles">
        <p class="nav-menu__item" [class.nav-menu__item--active]="item.active && shrink" (click)="onItemClick(item)" header>
            <ng-container *ngTemplateOutlet="btnContent; context: {navItem:item}"></ng-container>
        <svg class="nav-menu__submenu-icon" [class.nav-menu__submenu-icon--open]="item.active">
            <use xlink:href="../../../assets/img/sprite.svg#icon-next-arrow"></use>
        </svg>
        </p>
        <div class="nav-menu__accordion" content>
            <ng-container *ngFor="let s of item.subMenu">
                <ng-container *ngTemplateOutlet="routerLinkButton; context: {nav:s}"></ng-container>
            </ng-container>
        </div>
    </mine-expansion-panel>
</ng-template>

<ng-template #btnContent let-item="navItem">
    <svg *ngIf="item.icon" [class]="'nav-menu__icon nav-menu__icon--'+ item.icon" [tp]="item.title"
        [tpIsEnabled]="shrink" [tpPlacement]="tpProps.placement">
        <use [attr.xlink:href]="'../../../assets/img/sprite.svg#'+item.icon"></use>
    </svg>
    <span class="nav-menu__title nav-menu__title--hide">{{item.title}}</span>
    <div *ngIf="pimpleMap?.get(item.id) | async as pimpleData" class="nav-menu__pimple" [@pimple] [ngStyle]="{ backgroundColor: item.pimple.bgColor }">{{pimpleData}}</div>
</ng-template>