import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isDateInPast',
  standalone: true
})
export class IsDateInPastPipe implements PipeTransform {

  transform(reviewDate: string): boolean {
    if (!reviewDate) return false;

    const inputDate = new Date(reviewDate);
    const today = new Date();

    // Remove time to compare only dates
    const inputDateWithoutTime = new Date(inputDate.getFullYear(), inputDate.getMonth(), inputDate.getDate());
    const todayWithoutTime = new Date(today.getFullYear(), today.getMonth(), today.getDate());

    return inputDateWithoutTime <= todayWithoutTime;
  }

}
