import { Directive, Input, OnChanges, SimpleChanges, TemplateRef, ViewContainerRef } from '@angular/core';
import { MineSkeletonComponent } from './mine-skeleton/mine-skeleton.component';

@Directive({
	standalone: true,
	selector: '[skeleton]'
})
export class SkeletonDirective implements OnChanges {

	@Input('skeleton') isLoading = false;
	@Input('skeletonType') type: 'rect' | 'circle' | 'graph' = 'rect';
	@Input('skeletonRepeat') repeat = 1;
	@Input('skeletonWidth') width: string;
	@Input('skeletonHeight') height: string;
	@Input('skeletonStyle') style: string;


	constructor(private tpl: TemplateRef<Element>,
				private vcr: ViewContainerRef) { }


	ngOnChanges(changes: SimpleChanges): void {
		if (changes.isLoading) {
			this.vcr.clear();

			if (changes.isLoading.currentValue) {
				this.loadSkeletons();
			}
			else {
				this.vcr.createEmbeddedView(this.tpl);
			}
		}
	}

	private loadSkeletons(): void {
		Array.from({ length: this.repeat }).forEach(() => {
			const ref = this.vcr.createComponent(MineSkeletonComponent);

			Object.assign(ref.instance, {
				type: this.type,
				width: this.width,
				height: this.height,
				style: this.style,
			});
		});
	}

}
