import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, HostBinding, HostListener, DestroyRef} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Observable, first, tap } from 'rxjs';
import { DialogRef } from '@ngneat/dialog';

import { AnalyticsEvent } from 'src/app/analytics/analytics-event';
import { AnalyticEventsLabels } from 'src/app/analytics/analytics-events-labels';
import { AnalyticsService } from 'src/app/analytics/analytics.service';
import { EventActionType } from 'src/app/analytics/event-action.enum';
import { Animations } from 'src/app/animations/animations';
import { Dpo } from 'src/app/api/models/onboarding/dpo';
import { ChatService } from 'src/app/chat/chat.service';
import { ProfileQuery } from 'src/app/profile/state/profile.query';
import { environment } from 'src/environments/environment';
import { RequestsService } from '../../state/requests.service';

@Component({
  selector: 'authorization-dialog',
  templateUrl: './authorization-dialog.component.html',
  styleUrls: ['./authorization-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [Animations.fadeInOut]
})
export class AuthorizationDialogComponent implements OnInit {
  assetsUrl = environment.assets.url;

  mailSent: boolean;
  dpo$: Observable<Dpo>;
  confirmSpinner: boolean;

  constructor(
    private chat: ChatService,
    private profileQuery: ProfileQuery,
    private requestsService: RequestsService,
    private analytics: AnalyticsService,
    private dialogRef: DialogRef,
    private cdr: ChangeDetectorRef,
    private destroyRef: DestroyRef,
  ) {}
    
  @HostBinding('@fadeInOut') get fade() {return true}
  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    this.closeDialog();
  }

  ngOnInit(): void {
    this.dpo$ = this.requestsService.getDpo();
    this.dialogRef.backdropClick$.pipe(
      tap(() => this.closeDialog()),
      takeUntilDestroyed(this.destroyRef)
    )
    .subscribe();
  }

  showChat(): void {
    this.chat.show();
  }

  closeDialog(): void {
    this.dialogRef.close(this.mailSent);
  }

  sendMail(resend: boolean): void {
    this.confirmSpinner = !resend;
    resend ? this.sendAnalytics(AnalyticEventsLabels.AUTHORIZATION_DIALOG_resend_mail_btn) : this.sendAnalytics(AnalyticEventsLabels.AUTHORIZATION_DIALOG_confirm_btn);
  
    const companyName = this.profileQuery.getValue().companyName;
    this.requestsService.sendMail(companyName).pipe(
      first(),
      tap(() => this.mailSent = true),
      tap(() => this.cdr.detectChanges()),
    ).subscribe();
  }

  private sendAnalytics(event: string): void {
		this.analytics.track({
			eventCategory: event,
			eventAction: EventActionType.Click,
		} as AnalyticsEvent);
  }
}
