import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  standalone: true,
  name: 'htmlDecode'
})
export class HtmlDecodePipe implements PipeTransform {

    // parse value to aviod SQL Injections & XSS attacks
    transform(value: string): string {
      if (!value) {
        return value;
      }
      
      const txt = document.createElement("textarea");
      txt.innerHTML = value;
      return txt.value;
    }
}
