import { Injectable } from '@angular/core';
import { first, map, Observable, tap } from 'rxjs';
import { ApiClientRegionsService } from 'src/app/api/api-client-regions.service';
import { RegionsResponse } from 'src/app/api/models/regions.interface';

@Injectable({
  providedIn: 'root'
})
export class RegionsService {
  
  private continentCountryMap: Map<string, string[]>;
  private statesMap: Map<string, string[]>;

  constructor(
    private apiClientRegionsService: ApiClientRegionsService,
  ) { }

  getRegions(): Observable<void> {
    return this.apiClientRegionsService.getRegions()
    .pipe(
      first(),
      tap(regions => this.mapRegionsData(regions)),
      map(() => void 0),
    );
  }

  private mapRegionsData(regions: RegionsResponse): void {
    // update continent
    this.continentCountryMap = new Map<string, string[]>(Object.keys(regions).map(key => [key, regions[key].map(s => s.code)]));

    // update states    
    const statesMap = new Map<string, string[]>();
    Object.entries(regions).forEach(([continent, countries]) => {
      countries.forEach(country => {
        if (country.states.length > 0) {
          statesMap.set(`${continent}:${country.code}`, country.states);
        }
      });
    });

    // update states
    this.statesMap = statesMap;
  }

  getContinentsMap(): Map<string, string[]> {
    return this.continentCountryMap;
  }

  getStatesMap(country: string): Map<string, string[]> {
    const statesByCountry = this.statesMap.get(country) ?? [];
    return new Map([[country, statesByCountry]]);
  }
}