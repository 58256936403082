import { SystemsService } from 'src/app/systems/state/systems.service';
import { UnverifiedSystem } from 'src/app/api/models/systems/systems.interface';
import { UnverifiedSystemsService } from 'src/app/radar/state/unverified-systems.service';
import { FeedbackStateEnum } from './../../api/models/feedback/feedback.enum';
import { FeedbackAnswer } from './../../api/models/feedback/feedback.interface';
import { tap, map, switchMap, first, BehaviorSubject, Observable } from 'rxjs';
import { ReviewerTask } from '../../api/models/feedback/feedback.interface';
import { ApiClientFeedbackService } from '../../api/api-client-feedback.service';
import { Injectable } from '@angular/core';
import { SystemInstance } from 'src/app/systems/models/systems.interface';

@Injectable({
  providedIn: 'root'
})
export class TasksService {

  private tasksSub = new BehaviorSubject<ReviewerTask[]>([]);
  tasksSub$ = this.tasksSub.asObservable();

  private openedTasksCount = new BehaviorSubject<number>(0);
  openedTasksCount$ = this.openedTasksCount.asObservable();

  private respondedTaskCount = new BehaviorSubject<number>(0);
  respondedTaskCount$ = this.respondedTaskCount.asObservable();

  constructor(private apiClientFeedback: ApiClientFeedbackService,
              private unverifiedSystemsService: UnverifiedSystemsService,
              private systemsService: SystemsService) { }

  getTasks(): Observable<ReviewerTask[]> {
    return this.apiClientFeedback.getTasks().pipe(
      map(tasks => tasks.feedbacks),
      tap(tasks => this.updateCount(tasks)),
      tap(tasks => this.tasksSub.next(tasks))
    );
  }

  selectFilteredTasks(state: FeedbackStateEnum): Observable<ReviewerTask[]> {
    return this.tasksSub$.pipe(
      map(tasks => tasks.filter(task => task.feedbackState === state)),
      );
  }

  sendResponse(answer: FeedbackAnswer, id: string): Observable<void> {
    return this.apiClientFeedback.reviewerRespondFeedback(answer, id).pipe(
      switchMap(res => this.updateTasks(res.feedbackData[0])),
    );
  }

  private updateTasks(updatedTask: ReviewerTask): Observable<void> {
    return this.tasksSub$.pipe(
      first(),
      map(tasks => tasks.filter(task => task.feedbackId !== updatedTask.feedbackId)),
      map(tasks => [...tasks, updatedTask]),
      tap(tasks => this.tasksSub.next(tasks)),
      tap(tasks => this.updateCount(tasks)),
      map(() => void 0)
    );
  }

  updateCount(tasks: ReviewerTask[]): void {
    this.openedTasksCount.next(tasks.filter(task => task.feedbackState === FeedbackStateEnum.Pending).length);
    this.respondedTaskCount.next(tasks.filter(task => task.feedbackState === FeedbackStateEnum.Responded).length);
  }

  getUnverifiedSystemData(systemId: string): Observable<UnverifiedSystem> {
    return this.unverifiedSystemsService.getUnverifiedSystem(systemId);
  }

  getInventorySystemData(systemId: string): Observable<SystemInstance> {
    return this.systemsService.getSystemInstanceById(systemId);
  }
}
