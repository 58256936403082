import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { Profile } from '../models/profile.interface';

export function createInitialState(): Profile {
	return {
		agents: undefined,
		companyName: undefined,
		companyIcon: undefined,
		completedOnboarding: undefined,
		domainsDetailsList: undefined,
		email: undefined,
		firstName: undefined,
		identityProvider: undefined,
		intercomUserHash: undefined,
		lastName: undefined,
		memberSince: undefined,
		pictureUrl: undefined,
		role: undefined,
		userId: undefined,
		verifiedToCompany: undefined,
		domainsInCompany: undefined,
		numOfMaxAgentsAllowedIfLimited: undefined,
		planDetails: {
			plan: undefined,
			lastModifiedAt: undefined,
		},
		companyId: undefined,
		forwardingEmail: undefined,
		connectedToMineStatus: undefined,
		emailForwardingConnected: false,
		onboardingProgress: {
			closeRequest: false,
			customizePrivacyCenter: false,
			setupIntegration: false,
			connectToMine: true,
			dataMapping: true,
			inviteTeamMember: true
		}
	};
}

@Injectable({
  	providedIn: 'root'
})
@StoreConfig({
	name: 'profile',
	idKey: 'userId'
})
export class ProfileStore extends Store<Profile> {

  constructor() {
    super(createInitialState());
	this.setLoading(true);
  }
}
