import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';

import { DataTypesState, DataTypesStore } from './data-types.store';
import { DataType } from '../models/data-types.interface';
import { Observable } from 'rxjs';

@Injectable({ 
	providedIn: 'root' 
})
export class DataTypesQuery extends QueryEntity<DataTypesState> {

	constructor(protected store: DataTypesStore) {
		super(store);
	}

	selectCustomDataTypes(): Observable<DataType[]> {
		return this.selectAll({
			filterBy: [
				entity => entity.isCustom,
			],
		});
	}

	getCustomDataTypes(): DataType[] {
		return this.getAll({
			filterBy: [
				entity => entity.isCustom,
			],
		});
	}

	selectReadonlyDataTypes(): Observable<DataType[]> {
		return this.selectAll({
			filterBy: [
				entity => !entity.isCustom || entity.isCustom === undefined,
			],
		});
	}

	getReadonlyDataTypes(): DataType[] {
		return this.getAll({
			filterBy: [
				entity => !entity.isCustom || entity.isCustom === undefined,
			],
		});
	}

	selectDataTypeById(id: string): Observable<DataType> {
		return this.selectEntity(id);
	}

	getDataTypeById(id: string): DataType {
		return this.getEntity(id);
	}

	selectDataTypesByName(dataTypeName: string): Observable<DataType[]> {
		return this.selectAll({
			filterBy: [
				entity => entity.isCustom,
				entity => entity.name.toLowerCase() === dataTypeName.toLowerCase(),
			],
		});
	}

	getDataTypesByName(dataTypeName: string): DataType[] {
		return this.getAll({
			filterBy: [
				entity => entity.isCustom,
				entity => entity.name.toLowerCase() === dataTypeName.toLowerCase(),
			],
		});	
	}

	updateFrameworksOfExistingDataType(dataTypeId: string, frameworks: { id: string }[]): void {
		this.store.update(dataTypeId, { frameworks });
	}

	getDataTypes(ids: string[]): DataType[] {
		const dataTypesSet = new Set<string>(ids);
		return this.getAll({
			filterBy: [
				entity => dataTypesSet.has(entity.id),
			],
		});	
	}
}
