<div class="explore__video" #video>
	
	<p class="explore__video-title">{{'home.videoTitle' | content}}</p>

	<div *ngIf="!showVideo; else youtube" class="explore__video-container">
		 <img class="explore__video-img" [style.height]="videoHeight" src="{{assetsUrl}}/Portal/home-video-cover.jpg">
		 
		 <div class="explore__video-play" (click)="startVideo()">
			  <svg class="explore__video-play-svg">
					<use xlink:href="../../../../assets/img/sprite.svg#play-sign-2"></use>
			  </svg>
		 </div>
	</div>

	<ng-template #youtube>
		 <iframe class="explore__video-player" [style.height]="videoHeight" src="https://www.youtube.com/embed/-zowITiWqCA?autoplay=1" frameborder="0"  allow="autoplay;" allowfullscreen></iframe> 
	</ng-template>

</div>

<div class="explore__item" *ngFor="let item of copy.items; index as i">

	<p class="explore__item-title">{{item.title}}</p>

	<p class="explore__item-text">{{item.text}}</p>

	<a mine-button color="secondary" class="explore__item-btn" 
		href="/{{item.cta.url}}">
		{{item.cta.text}}
	</a>

 	<img src="{{assetsUrl}}/Portal/{{item.image}}" alt="mine-home-explore-{{i}}" class="explore__item-img">

</div>