import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { EmployeeDataMapping } from 'src/app/api/models/data-mapping/data-mapping.interface';

import { produce } from 'immer';

export interface EmployeesState extends EntityState<EmployeeDataMapping, string> { }

@Injectable({
	providedIn: 'root'
})
@StoreConfig({
	name: 'employees',
	idKey: 'id',
	producerFn: produce
})
export class EmployeesStore extends EntityStore<EmployeesState> {
	constructor() {
		super();
	}
}