<ng-container *ngIf="profile$ | async as profile">
	<input type="checkbox" [(ngModel)]="checked" class="company-menu__checkbox" id="menu" />

	<label for="menu" class="company-menu__label">
		
		<mine-service-icon
			[attr.data-label]="'Portal Menu'"
			class="company-menu__service-icon"
			[ngClass]="{ 'company-menu__service-icon--checked': checked}"
			[icon]="profile.companyIcon"
			[width]="40"
			[height]="40">
		</mine-service-icon>
		
		<ng-container *ngIf="!shrink">

			<span class="company-menu__company-name" [tp]="profile.companyName"
				[tpOnlyTextOverflow]="true"> {{ profile.companyName }} </span>
			
			<span class="company-menu__agent-name"> {{ agentName }} </span>
			
		</ng-container>
	</label>

	<div *ngIf="checked" @fadeInOut 
		class="company-menu__menu" 
		[class.company-menu__menu--shrink]="shrink">
		
		<div class="company-menu__user">
			<h2 class="mine-title-xs"> {{profile.firstName}} {{profile.lastName}} </h2>

			<p class="company-menu__user-mail">{{ profile.email }}</p>
		</div>

		<div class="company-menu__company-section-container">
			
			<div class="company-menu__company-section" [class.company-menu__company-section-grid]="profile.connectedCompanies.length > 1">
				<span class="company-menu__current-company">{{profile.companyName}}</span>

				<ng-container *ngIf="profile.connectedCompanies.length > 1">
	
					<span class="company-menu__change-workspace">{{'menu.changeWorkspace' | content}}</span>
	
					<svg class="company-menu__change-company-svg">
						<use xlink:href="../../../assets/img/sprite.svg#icon-Arrow-right"></use>
					</svg>
	
				</ng-container>

			</div>
			
			<div *ngIf="profile.connectedCompanies.length > 1" class="company-menu__change-company-options">
				<p *ngFor="let company of connectedCompanies" (click)="changeCompany(company.companyId)" class="company-menu__company-option">{{company.companyName}}</p>
			</div>
			
		</div>

		<div *ngIf="featureFlags$ | async as featureFlags" class="company-menu__btn-container">
			<button
				class="company-menu__btn"
				[attr.data-label]="'menu.companySettings' | content"
				(click)="openCompanySettings()">
				{{ 'menu.companySettings' | content }}
			</button>

			<button *minePermissions="planPerrmissions"
				class="company-menu__btn"
				[attr.data-label]="'menu.plans' | content"
				(click)="openPlans()">
				{{ 'menu.plans' | content }}
			</button>

			<button
				class="company-menu__btn"
				[attr.data-label]="'menu.feedback' | content"
				(click)="onFeedbackClick()">
				{{ 'menu.feedback' | content }}
			</button>
		</div>

		<div class="company-menu__logout-container">

			<button [attr.data-label]="'Logout Portal'" class="company-menu__logout" (click)="navigate(routesManager.login)">
				<svg class="company-menu__logout-icon">
					<use xlink:href="../../../assets/img/sprite.svg#icon-Menu-log-out-icon"></use>
				</svg>
	
				<span class="company-menu__logout-text">{{ 'menu.logout' | content }}</span>
			</button>

		</div>
		
	</div>
	
</ng-container>
