import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { LoggerService } from 'src/app/logger/logger.service';
import { ContentState, ContentStore } from './content.store';

@Injectable({
	providedIn: 'root',
})
export class ContentQuery extends QueryEntity<ContentState> {
	
	private readonly loggerName: string = 'ContentQuery';

	constructor(
		protected store: ContentStore,
		private loggerService: LoggerService,
	) {
		super(store);
	}

	getContent(schemaName: string, fieldName: string, language?: string) {
		const schema = this.getEntity(language? `${language}${schemaName}`: schemaName);
		if (schema) {
			const field = schema[fieldName];
			if (field) {
				return field;
			}

			this.loggerService.error(this.loggerName, `schema: ${schemaName}, field not found: ${fieldName}`);
			return '';	
		}

		this.loggerService.error(this.loggerName, `schema not found: ${schemaName}`);
		return '';	
	}

	getSchema(name: string, language?: string): Object {
		return this.getEntity(language? `${language}${name}`: name);
	}
}
