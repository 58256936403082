<div class="preview__header">
    <p class="mine-title-m">
        {{'ticket.recordDialogTitle' | content}}
    </p>
    <svg class="preview__x-btn" (click)="closeDialog()">
		<use xlink:href="../../../assets/img/sprite.svg#icon-Dialog-x"></use>
	</svg>
</div>

<div class="preview__body">
	<div class="preview__company-header">
		<mine-service-icon *skeleton="!(loaded());
												width: '4.93rem';
												height: '4.93rem';
												style: 'grid-row: 1/-1';
												type: 'circle';"
			class="preview__company-icon" 
			[icon]="icon" [width]="49.3" [height]="49.3">
		</mine-service-icon>
		
		<p *skeleton="!(loaded());
							width: '6.6rem';
							height: '1rem';"
			class="preview__company-name" >{{integration.displayName}}
		</p>
		
		<p *skeleton="!(loaded());
							width: '9.7rem';
							height: '1rem';" 
			class="preview__company-records">{{records}} {{'ticket.recordsFound' | content}}
		</p>
	</div>

	<div class="preview__company-table">
		<ng-container *ngIf="integrationPreview$ | async as integrationPreview; else loading">
			<ng-container *ngIf="showSuccess(); else error">
					<div class="preview__table-row" *ngFor="let r of integrationPreview.records"> 
						<p class="preview__row-title">{{r.type | titlecase}}</p>
						<div *ngFor="let f of r.integrationPreviewFields">
							<p class="preview__key">{{f.name}}:</p>
							<p tp tpUseTextContent [tpOnlyTextOverflow]="true" class="preview__value">{{f.value ?? '--'}}</p>
						</div>      
					</div>
			</ng-container>
	
			<ng-template #error>
					<div  class="preview__error-container">
							<svg class="preview__icon-error">
							<use xlink:href="../../../assets/img/sprite.svg#icon-info-outline"></use>
						</svg> 
						<div>{{integrationPreview.errorType}}
							<p *ngIf="integrationPreview.message" class="preview__error-message">{{integrationPreview.message}}</p>
						</div>
					</div>
			</ng-template>

		</ng-container>

		<ng-template #loading>
			<div class="preview__table-row" *ngFor="let row of ['5rem', '9rem', '12.5rem', '9rem']">
				<ng-container *skeleton="true;
												 repeat: 4;
												 width: row;
												 height: '1.4rem';">
				</ng-container>
			</div>
			
		</ng-template>

	</div>

    
</div>