import { DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

import { DataTypeSampleFormatHelper } from './data-type-sample-format-helper';
import { DataTypeSmartSampleFormatHelper } from './data-type-smart-sample-format-helper';
import { ScanIntegrationType } from 'src/app/api/models/data-mapping/data-mapping-pii.enum';

@Pipe({
  standalone: true,
  name: 'dataTypeMatchesFormatPipe',
})
export class DataTypeMatchesFormatPipe implements PipeTransform {

  constructor(
    private decimalPipe: DecimalPipe
  ) {}

	transform(value: string | number, scanType: ScanIntegrationType): string {
    if (!this.isNumber(value) || value == 0) {
      return '-';
    }
    const num = Number(value);

    switch (scanType) {
      case ScanIntegrationType.Sample:
        return DataTypeSampleFormatHelper.transform(num);

      case ScanIntegrationType.SmartSample:
          return DataTypeSmartSampleFormatHelper.transform(num);
      
      default:
        return this.decimalPipe.transform(num, '1.0', 'en-US');
    }
	}

  private isNumber(value?: string | number): boolean {
    return ((value != null) && (value !== '') && !isNaN(Number(value.toString())));
  }
}