import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpConsts } from "../api/http-consts";

@Injectable({
	providedIn: 'root'
})
export class HttpClientHelperService {

	auth: string = HttpConsts.auth;
	
	constructor(private http: HttpClient) { }

	invokeGet<T>(url: string, qParams: HttpParams = null): Observable<T> {
		const fullUrl: string = environment.api.accessPointUrl + url;
		return this.http.get<T>(fullUrl, { params: qParams });
	}

	invokeGetAuth<T>(url: string, qParams: HttpParams = null): Observable<T> {
		const fullUrl: string = environment.api.accessPointUrl + url;
		let headers = new HttpHeaders();
		headers = headers.append(HttpConsts.auth, 'true');
		return this.http.get<T>(fullUrl, { headers: headers, params: qParams });
	}

	invokePost<T>(url: string, body: any = null): Observable<T> {
		const fullUrl: string = environment.api.accessPointUrl + url;
		return this.http.post<T>(fullUrl, body);
	}

	invokePostAuth<T>(url: string, body: any = null): Observable<T> {
		const fullUrl: string = environment.api.accessPointUrl + url;
		let headers = new HttpHeaders();
		headers = headers.append(HttpConsts.auth, 'true');
		return this.http.post<T>(fullUrl, body, { headers: headers });
	}

	invokePutAuth<T>(url: string, body: any = null): Observable<T> {
		const fullUrl: string = environment.api.accessPointUrl + url;
		let headers = new HttpHeaders();
		headers = headers.append(HttpConsts.auth, 'true');
		return this.http.put<T>(fullUrl, body, { headers: headers });
	}

	invokePatchAuth<T>(url: string, body: any = null): Observable<T> {
		const fullUrl: string = environment.api.accessPointUrl + url;
		let headers = new HttpHeaders();
		headers = headers.append(HttpConsts.auth, 'true');
		return this.http.patch<T>(fullUrl, body, { headers: headers });
	}
	
	invokePostPdfAuth<T>(url: string, body: any = null): Observable<T> {
		const fullUrl: string = environment.api.accessPointUrl + url;
		let headers = new HttpHeaders();
		headers = headers.append(HttpConsts.auth, 'true');
		headers = headers.append('Accept', 'application/pdf');
		return this.http.post<T>(fullUrl, body, {
			headers: headers,
			responseType: 'arraybuffer' as 'json'
		});
	}
	
	invokeDeleteAuth<T>(url: string, body: any = null, qParams: HttpParams = null): Observable<T> {
		const fullUrl: string = environment.api.accessPointUrl + url;
		let headers = new HttpHeaders();
		headers = headers.append(HttpConsts.auth, 'true');
		return this.http.delete<T>(fullUrl, { body,  headers: headers, params: qParams });
	}
}
