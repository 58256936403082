import { Pipe, PipeTransform } from '@angular/core';
import { ContentPipe } from 'src/app/services/content/content.pipe';

@Pipe({
  name: 'locationCodesToText',
  standalone: true
})
export class LocationCodesToTextPipe implements PipeTransform {
//pattern - continent-country-state / country-state / country
  constructor(private contentPipe: ContentPipe) {}

  transform(location: string, pattern?: string): string {
    if (!location) return '';

    const components = location.split(':');
    const continentCode = components[0];
    const countryCode = components[1];
    const stateCode = components[2];

    let result = '';

    switch (pattern) {
      case 'country':
        result = this.getCountryName(countryCode);
        break;
      case 'country-state':
        result = this.getCountryStateName(countryCode, stateCode);
        break;
      case 'continent-country-state':
        result = this.getContinentCountryStateName(continentCode, countryCode, stateCode);
        break;
      default:
        result = this.getDefaultName(continentCode, countryCode, stateCode);
        break;
    }

    return result;
  }

  private getCountryName(countryCode: string): string {
    return this.contentPipe.transform('location-codes.country-codes')[countryCode] || '';
  }

  private getStateName(countryCode: string, stateCode: string): string {
    return this.contentPipe.transform('location-codes.state-codes')[countryCode]?.[stateCode] || '';
  }

  private getContinentName(continentCode: string): string {
    return this.contentPipe.transform('location-codes.continent-codes')[continentCode] || '';
  }

  private getDefaultName(continentCode: string, countryCode: string, stateCode: string): string {
    if (stateCode) {
      return this.getStateName(countryCode, stateCode);
    } else if (countryCode) {
      return this.getCountryName(countryCode);
    } else if (continentCode) {
      return this.getContinentName(continentCode);
    }
    return '';
  }

  private getCountryStateName(countryCode: string, stateCode: string): string {
    const countryName = this.getCountryName(countryCode);
    const stateName = this.getStateName(countryCode, stateCode);
    return stateName ? `${countryName}: ${stateName}` : countryName;
  }

  private getContinentCountryStateName(continentCode: string, countryCode: string, stateCode: string): string {
    const continentName = this.getContinentName(continentCode);
    const countryStateName = this.getCountryStateName(countryCode, stateCode);
    return stateCode ? `${continentName}: ${countryStateName}` : countryStateName;
  }

}

// nnn:cc:ss