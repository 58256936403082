import { Component, Input, Output, EventEmitter, HostBinding } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AnalyticsEvent } from 'src/app/analytics/analytics-event';
import { AnalyticsService } from 'src/app/analytics/analytics.service';
import { EventActionType } from 'src/app/analytics/event-action.enum';
import { ButtonTheme } from '../models/button-theme.enum';
import { TippyModule } from '../tippy.module';

@Component({
	standalone: true,
	selector: 'mine-button-secondary',
	templateUrl: './mine-button-secondary.component.html',
	styleUrls: ['./mine-button-secondary.component.scss'],
	imports: [CommonModule, TippyModule]
})
export class MineButtonSecondaryComponent {
	constructor(private analytics: AnalyticsService) {}

	@Input() label: string; // eventCatagory in GA
	@Input() eventAction: string;
	@Input() btnTheme: ButtonTheme = ButtonTheme.Purple;

	@Input()
	disabled: boolean;

	@Input()
	tooltip: string = '';

	@HostBinding('class') get hostClass() {
		return this.btnTheme;
	}

	@Output()
	onClick = new EventEmitter<void>();

	onBtnClick() {
		if (!this.disabled) {
			if (this.label) {
				const e = {
					eventCategory: this.label, 
					eventAction: this.eventAction ? this.eventAction : EventActionType.Click
				} as AnalyticsEvent;
		
				this.analytics.track(e);
			}
	
			this.onClick.emit();
		}
	}
}
