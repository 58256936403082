export enum CloseTemplateEnum {
    // Delete
    RejectTemplate = 'rejectTemplate',
    CancelTemplate = 'cancelTemplate',
    CompleteTemplate = 'completeTemplate',
    
    // Copy
    CopyRejectTemplate = 'copyRejectTemplate',
    CopyCancelTemplate = 'copyCancelTemplate',
    CopyCompleteTemplate = 'copyCompleteTemplate',

    // Do not mail
    DoNotMailRejectTemplate = 'doNotMailRejectTemplate',
    DoNotMailCancelTemplate = 'doNotMailCancelTemplate',
    DoNotMailCompleteTemplate = 'doNotMailCompleteTemplate',
    
    // Right to edit
    RightToEditRejectTemplate = 'rightToEditRejectTemplate',
    RightToEditCancelTemplate = 'rightToEditCancelTemplate',
    RightToEditCompleteTemplate = 'rightToEditCompleteTemplate',

    //Do not sell
    DoNotSellRejectTemplate = 'doNotSellRejectTemplate',
    DoNotSellCancelTemplate = 'doNotSellCancelTemplate',
    DoNotSellCompleteTemplate = 'doNotSellCompleteTemplate',
}