import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';

import { FrameworksState, FrameworksStore } from './frameworks.store';
import { Observable } from 'rxjs';
import { Framework } from 'src/app/data-types/models/frameworks.interface';

@Injectable({ 
	providedIn: 'root' 
})
export class FrameworksQuery extends QueryEntity<FrameworksState> {

	constructor(protected store: FrameworksStore) {
		super(store);
	}

	selectCustomFrameworks(): Observable<Framework[]> {
		return this.selectAll({
			filterBy: [
				entity => entity.isCustom,
			],
		});
	}

	getCustomFrameworks(): Framework[] {
		return this.getAll({
			filterBy: [
				entity => entity.isCustom,
			],
		});
	}

	selectReadonlyFrameworks(): Observable<Framework[]> {
		return this.selectAll({
			filterBy: [
				entity => !entity.isCustom || entity.isCustom === undefined,
			],
		});
	}

	getReadonlyFrameworks(): Framework[] {
		return this.getAll({
			filterBy: [
				entity => !entity.isCustom || entity.isCustom === undefined,
			],
		});
	}

	getFrameworkByName(frameworkName: string): Framework {
		return this.getAll({
			filterBy: [
				entity => entity.displayName === frameworkName
			],
		})[0] ?? null;
	}
	
	selectFrameworkById(id: string): Observable<Framework> {
		return this.selectEntity(id);
	}
}
