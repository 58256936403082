import { Component, ChangeDetectionStrategy, Input, HostBinding, OnInit } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { BoxBgColor } from './mine-info-box.enum';

@Component({
  standalone: true,
  selector: 'mine-info-box',
  templateUrl: './mine-info-box.component.html',
  styleUrls: ['./mine-info-box.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MineInfoBoxComponent implements OnInit {
  @Input() bgColor: BoxBgColor; // when input is empty, possible to set the background color from outside

  @HostBinding('style.background-color')
	backgroundColor: SafeStyle;

  constructor(private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    if (this.bgColor) {
      this.backgroundColor = this.sanitizer.bypassSecurityTrustStyle(this.bgColor);
    }
  }
}
