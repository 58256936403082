import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PiiSystemFindingsHelperService {

    //get the page system id from routes in router-outlet
    private piiSystemId: BehaviorSubject<string> = new BehaviorSubject('');
	  piiSystemId$ = this.piiSystemId.asObservable();

    //update tabs about moving from data types to schema tab from "open in schema" button
    private moveToSchemaTab: Subject<void> = new Subject<void>();
    moveToSchemaTab$ = this.moveToSchemaTab.asObservable();

    updateSystemId(currId: string): void {
      this.piiSystemId.next(currId);
    }

    moveToSchemaTabClicked(): void {
      this.moveToSchemaTab.next();
    }

    resetHelperData(): void {
      this.piiSystemId.next(null);
      this.moveToSchemaTab.next(null);
    }

    get piiSystemIdValue(): string {
      return this.piiSystemId.getValue();
    }
}
