<img class="error__img" ngSrc="{{assetsUrl}}/Portal/error-404-new.png" width="236" height="196" priority>

<ng-container *ngTemplateOutlet="errorPageTpl"></ng-container>

<ng-template #error401>

    <h1 class="mine-title-l error__title">{{'common.errorPageNotAllowedTitle' | content}}</h1>
    
    <p class="error__text">{{'common.errorPageNotAllowed' | content}}</p>

</ng-template>

<ng-template #error404>
    
    <h1 class="mine-title-l error__title">{{'common.errorPageTitle' | content}}</h1>
    
    <p class="error__text">{{'common.errorPageNotFound' | content}}</p>
    
    <a class="error__navigate-text" [routerLink]="userHomePage">{{userHomePageText}}</a>

</ng-template>
