import { CustomViewsStore } from './custom-views.store';
import { Observable, filter, map, switchMap, tap } from 'rxjs';
import { ApiClientCustomViewsService } from '../../api/api-client-custom-views.service';
import { Injectable } from '@angular/core';
import { TableCustomView, TableCustomViewData } from 'src/app/api/models/custom-views/custom-views.interface';
import { CustomViewsQuery } from './custom-views.query';
import { LoggerService } from 'src/app/logger/logger.service';
import { TablesEnum } from 'src/app/shared/table-state-service/table-state.enum';

@Injectable({ 
	providedIn: 'root' 
})
export class CustomViewsService  {
	private readonly loggerName: string = 'CustomViewsService';

	constructor(private apiClientCustomViewsService: ApiClientCustomViewsService,
				private customViewsQuery: CustomViewsQuery,
				private logger: LoggerService,
				private customViewsStore: CustomViewsStore) { }

	init(): Observable<void> {
		return this.apiClientCustomViewsService.getCustomViews().pipe(
			tap(views => this.customViewsStore.add(views)),
			tap(() => this.customViewsStore.setLoading(false)),
			tap(() => this.logger.info(this.loggerName, 'Init custom views store')),
			map(() => void 0)
		);
	}

	selectViews(viewType: TablesEnum): Observable<TableCustomView[]> {
		return this.customViewsQuery.selectLoading().pipe(
			filter(loading => !loading),
			switchMap(() => this.customViewsQuery.selectViews(viewType)),
		);
	}

	addCustomView(customView: TableCustomViewData): Observable<TableCustomView> {
		return this.apiClientCustomViewsService.createCustomView(customView).pipe(
			tap(view => this.customViewsStore.add(view)),
			tap(view => this.logger.info(this.loggerName, `added view to store - ${view.id}`)),
		);
	}	

	updateCustomView(id: string, customView: Partial<TableCustomView>): Observable<TableCustomView> {
		return this.apiClientCustomViewsService.updateCustomView(id, customView).pipe(
			tap(view => this.customViewsStore.replace(id, {...view})),
			tap(() => this.logger.info(this.loggerName, `update view - ${id}`)),
		);
	}	

	deleteCustomView(id: string): Observable<void> {
		return this.apiClientCustomViewsService.deleteCustomView(id).pipe(
			tap(() => this.customViewsStore.remove(id)),
			tap(() => this.logger.info(this.loggerName, `deleted view - ${id}`)),
		);
	}	

	isNameInInventory(name: string): boolean {
		return !!this.customViewsQuery.getAll()?.find(entity => entity.name?.toLowerCase() === name?.toLowerCase());
    }
}