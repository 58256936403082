import { Component, ChangeDetectionStrategy, ElementRef, ViewChild, Input, Output, EventEmitter, OnInit, AfterViewInit, ChangeDetectorRef, HostBinding } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { AnimationSpeedEnum } from 'src/app/animations/animation-state.enum';
import { ContentPipe } from 'src/app/services/content/content.pipe';
import { MineCheckMarkComponent } from '../mine-check-mark/mine-check-mark.component';
import { ProgressStatesEnum } from './mine-evidence-loader.enum';
import { FetchingProgress } from './mine-evidence-loader.interface';
import { CommonModule } from '@angular/common';

@Component({
  standalone: true,
  selector: 'mine-evidence-loader',
  templateUrl: './mine-evidence-loader.component.html',
  styleUrls: ['./mine-evidence-loader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, MineCheckMarkComponent],
  animations: [
    trigger('loadProgress', [
      state(ProgressStatesEnum.Void, style({ width: '0px', })),
      state(ProgressStatesEnum.Chunk1, style({ width: '{{progressWidth}}px' }), {params: {progressWidth: 0}}),
      state(ProgressStatesEnum.Chunk2, style({ width: '{{progressWidth}}px' }), {params: {progressWidth: 0}}),
      state(ProgressStatesEnum.Chunk3, style({ width: '{{progressWidth}}px' }), {params: {progressWidth: 0}}),
      state(ProgressStatesEnum.Chunk4, style({ width: '{{progressWidth}}px' }), {params: {progressWidth: 0}}),
      transition(`${ProgressStatesEnum.Void} => ${ProgressStatesEnum.Chunk1}`, [animate(`${AnimationSpeedEnum.VerySlow} 500ms ease-out`)]),
      transition('* => *', [animate(`${AnimationSpeedEnum.VerySlow} 0ms ease-out`)]),
    ])
  ],
})
export class MineEvidenceLoaderComponent implements OnInit, AfterViewInit {

  private readonly loaderDoneDelay = 500;

  fetchingProgress: Map<ProgressStatesEnum, FetchingProgress> = new Map<ProgressStatesEnum, FetchingProgress>();

  @Input() showInsideStep = false;

  @Input() showEvidence: boolean;

  @Input() startFetching: boolean;

  @Output() loaderFinished: EventEmitter<void> = new EventEmitter<void>();
   
  @ViewChild('progressBar') progressBar: ElementRef;

  progressState = ProgressStatesEnum.Void;

  progressCurrentValue = 0;

  animationText = '';

  constructor(
    private contentPipe: ContentPipe,
    private cdr: ChangeDetectorRef,
  ) { }

  ngOnInit(): void {
    this.animationText = this.contentPipe.transform('ticket.fetchingEvidence');
  }

  ngAfterViewInit(): void {
    this.initProgressValues();
    this.progressCurrentValue = 0;
    this.progressState = ProgressStatesEnum.Chunk1;
    this.cdr.detectChanges();
  }

  @HostBinding('class.full-width') get loaderFullWidth() {
    return !this.showInsideStep;
  }

  @HostBinding('class.not-started') get loaderNotStarted() {
    return !this.startFetching;
  }

  //get random values for progress bar 
  private initProgressValues(): void {
    const totlaWidth = this.progressBar.nativeElement.clientWidth;
    const randomChunk1 = this.getRandomValueInChunc(10, 25);
    this.fetchingProgress.set(ProgressStatesEnum.Chunk1, { total: randomChunk1, width: `${(randomChunk1 * totlaWidth) / 100}` });
    const randomChunk2 = this.getRandomValueInChunc(26, 50);
    this.fetchingProgress.set(ProgressStatesEnum.Chunk2, { total: randomChunk2, width: `${(randomChunk2 * totlaWidth) / 100}` });
    const randomChunk3 = this.getRandomValueInChunc(51, 75);
    this.fetchingProgress.set(ProgressStatesEnum.Chunk3, { total: randomChunk3, width: `${(randomChunk3 * totlaWidth) / 100}` });
    this.fetchingProgress.set(ProgressStatesEnum.Chunk4, { total: 100, width: `${totlaWidth}` });
  }

  animationDone(): void {
    this.progressCurrentValue = this.fetchingProgress.get(this.progressState)?.total;
    if (this.progressState === ProgressStatesEnum.Chunk1) {
      this.progressState = ProgressStatesEnum.Chunk2;
    }
    else if (this.progressState === ProgressStatesEnum.Chunk2) {
      this.progressState = ProgressStatesEnum.Chunk3;
    }
    else if (this.progressState === ProgressStatesEnum.Chunk3) {
      this.progressState = ProgressStatesEnum.Chunk4;
    }
    else if (this.progressState === ProgressStatesEnum.Chunk4 && !this.showEvidence) {
      this.animationText = this.contentPipe.transform('ticket.doneFetching');
    }
  }

  checkmarkDone(): void {
    setTimeout(() => this.loaderFinished.emit(), this.loaderDoneDelay);
  }

  private getRandomValueInChunc(min: number, max: number): number {
    return Math.floor(Math.random() * (max - min) + min);
  }
}