import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClientHelperService } from './http-client-helper.service';
import { TableCustomView, TableCustomViewData } from './models/custom-views/custom-views.interface';

@Injectable({
  	providedIn: 'root'
})
export class ApiClientCustomViewsService {

	private readonly controller = 'Views';

  	constructor(private httpClientHelper: HttpClientHelperService) { }

	getCustomViews(): Observable<TableCustomView[]> {
		return this.httpClientHelper.invokeGetAuth<TableCustomView[]>(`${this.controller}/CustomViews`);
  	}

  	createCustomView(customView: TableCustomViewData): Observable<TableCustomView> {
		return this.httpClientHelper.invokePostAuth<TableCustomView>(`${this.controller}/CustomView`, customView);
  	}

	updateCustomView(id: string, partialCustomView: Partial<TableCustomView>): Observable<TableCustomView> {
		return this.httpClientHelper.invokePatchAuth<TableCustomView>(`${this.controller}/CustomView/${id}`, partialCustomView);
  	}

	deleteCustomView(id: string): Observable<void> {
		return this.httpClientHelper.invokeDeleteAuth<void>(`${this.controller}/CustomView/${id}`);
  	}
}
