<div *ngIf="ready" class="check check--done" [@background] (@background.done)="backgroundAnimationDone($event)">
	<svg class="check__svg check__svg--done" version="1.1" xmlns="http://www.w3.org/2000/svg" width="38" height="32" viewBox="0 0 38 32">
		<clipPath id="clipV">
			<path d="M30.777 4.184c-1.264-0.66-2.673 0.613-3.499 1.367-1.895 1.792-3.499 3.867-5.297 5.753-1.992 2.075-3.839 4.15-5.88 6.177-1.166 1.132-2.43 2.358-3.207 3.772-1.749-1.65-3.256-3.442-5.2-4.904-1.409-1.037-3.742-1.792-3.693 0.707 0.097 3.254 3.062 6.743 5.248 8.959 0.923 0.943 2.138 1.933 3.548 1.98 1.701 0.094 3.45-1.886 4.471-2.971 1.798-1.886 3.256-4.008 4.908-5.941 2.138-2.546 4.325-5.046 6.415-7.639 1.312-1.603 5.443-5.564 2.187-7.262z"></path>
		</clipPath>
		<rect mask="url(#mask)" x="0" y="0"
				class="check__rect" 
				[ngClass]="{'check__rect--show' : finished, 'check__rect--hide': !finished}"
				[@checkmark]="checkmarkState" (@checkmark.done)="checkmarkDone($event)">
		</rect>
	</svg>
</div>