import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClientHelperService } from './http-client-helper.service';
import { AdditionalTemplateRequest, AutomationResponseBase, AutomationResponseUpdate } from './models/automations/automation-update.interface';
import { AutomationsConfigResponse, CloseTemplatesResponse } from './models/automations/automations.interface';

@Injectable({
	providedIn: 'root'
})
export class ApiClientAutomationsService {
	private readonly controller = 'automations';

	constructor(private httpClientHelper: HttpClientHelperService) { }

	getAutomationsConfig(localeCode: string): Observable<AutomationsConfigResponse> {
		return this.httpClientHelper.invokeGetAuth<AutomationsConfigResponse>(`${this.controller}/GetAutomationsConfig/${localeCode}`);
	}

	updateResponseAutomationsConfig(automationUpdate: AutomationResponseUpdate): Observable<void> {
		return this.httpClientHelper.invokePostAuth<void>(`${this.controller}/UpdateAutomationsConfig`, automationUpdate);
	}

	testResponseAutomationsConfig(automationTest: AutomationResponseBase): Observable<void> {
		return this.httpClientHelper.invokePostAuth<void>(`${this.controller}/TestAutomationEmail`, automationTest);
	}

	getCloseTemplates(localeCode: string): Observable<CloseTemplatesResponse> {
		return this.httpClientHelper.invokeGetAuth<CloseTemplatesResponse>(`${this.controller}/GetCloseTemplates/${localeCode}`);
	}

	addAdditionalLanguage(request: AdditionalTemplateRequest): Observable<void> {
		return this.httpClientHelper.invokePostAuth<void>(`${this.controller}/AdditionalTemplateLocales`, request);
	}

	removeAdditionalLocale(locale: string): Observable<void> {
		return this.httpClientHelper.invokeDeleteAuth<void>(`${this.controller}/AdditionalTemplateLocales/${locale}`);
	}
}
