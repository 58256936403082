import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, computed, DestroyRef, ElementRef, HostBinding, HostListener, OnInit, QueryList, Signal, signal, ViewChild, ViewChildren } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, Validators } from '@angular/forms';
import { combineLatest, map, tap } from 'rxjs';

import { Animations } from '../animations/animations';
import { LoggerService } from '../logger/logger.service';
import { Badge } from '../shared/mine-card/mine-card.component.interface';
import { MineOSChatbotService } from './services/mineos-chatbot.service';
import { MessageTypeEnum } from './models/message.enum';
import { Message } from './models/message.interface';

@Component({
  selector: 'mineos-chatbot',
  templateUrl: './mineos-chatbot.component.html',
  styleUrls: ['./mineos-chatbot.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [Animations.fadeInOnEnter, Animations.pimple]
})
export class MineosChatbotComponent implements OnInit, AfterViewInit {

  private readonly loggerName: string = 'MineosChatbotComponent';

  canSendMessages: Signal<boolean>;
  
  showChatbot: Signal<boolean>;
  
  chatMessages: Signal<Message[]>;
  
  messageCtrl = new FormControl<string>('', [Validators.required]);

  unreadMessagesCounter = signal<number>(0);

  fetchingMessagesFromHistory = signal<boolean>(false);

  copilotTypingState = signal<boolean>(true);

  MessageTypeEnum = MessageTypeEnum;

  readonly typeBadge: Badge = {
    textColor: 'var(--mine-white)',
    backgroundColor: 'var(--mine-red)',
  };

  @HostListener('document:keydown.escape', ['$event']) 
  onEscapeHandler(event: KeyboardEvent) {
    this.mineosChatbotService.close();
  }

  @HostBinding('class.show-chatbot')
	get showChatbotHostClass() {
		return this.showChatbot();
	}

  @ViewChild('chatInput', { read: ElementRef, static: true })
	chatInput: ElementRef<HTMLInputElement>;

  @ViewChild('chatbox', { read: ElementRef, static: true })
  chatbox: ElementRef<HTMLUListElement>;

  @ViewChildren('message', { read: ElementRef })
  messages: QueryList<ElementRef<HTMLLIElement>>;

  constructor(
    private logger: LoggerService,
    private destroyRef: DestroyRef,
    private cdref: ChangeDetectorRef,
    private mineosChatbotService: MineOSChatbotService,
  ) {}

  ngOnInit(): void {
    this.initChatbot();
  }

  ngAfterViewInit(): void {
    this.afterInitChatbot();
  }

  private initChatbot(): void {
    this.showChatbot = this.mineosChatbotService.showChatbot();
    this.chatMessages = this.mineosChatbotService.getChatMessages();
    
    this.canSendMessages = computed(() => {
      return this.mineosChatbotService.getDsReady()() && this.mineosChatbotService.getChannelReady()();
    });

    combineLatest([
      this.mineosChatbotService.selectDsReady(),
      this.mineosChatbotService.selectChannelReady(),
    ]).pipe(
      map(([ds, channel]) => ds && channel),
      tap(ready => ready ? this.enableChatInput() : this.disableChatInput()),
      tap(() => this.cdref.markForCheck()),
      takeUntilDestroyed(this.destroyRef)
    ).subscribe();

    this.mineosChatbotService.subscriber(this.onMessageHandler);
    this.mineosChatbotService.onOpen().pipe(
      tap(() => this.unreadMessagesCounter.set(0)),
      takeUntilDestroyed(this.destroyRef)
    ).subscribe();
  }

  private afterInitChatbot(): void {
    this.scrollToBottom();
    this.messages.changes.pipe(
      tap(() => this.scrollToBottom()),
      takeUntilDestroyed(this.destroyRef)
    ).subscribe();
  }

  private scrollToBottom(): void {
    this.chatbox.nativeElement.scrollTop = this.chatbox?.nativeElement?.scrollHeight;
  }

  onKeydown(e: KeyboardEvent): void {
    // If Enter key is pressed without Shift key and chat enabled, send message
    if (this.messageCtrl.enabled && this.canSendMessages() && e.key === "Enter" && !e.shiftKey && this.mineosChatbotService.isOpen()) {
      e.preventDefault();
      this.sendMessage();
    }
  }

  onTogglerClicked(): void {
    this.unreadMessagesCounter.set(0); 
    this.toggle();
  }

  private toggle(): void {
    this.mineosChatbotService.toggle();
    if (this.mineosChatbotService.isOpen() && this.messageCtrl.enabled) {
      this.scrollToBottom();
      this.chatInput.nativeElement.focus();
    }
  }

  private enableChatInput(): void {
    this.messageCtrl.enable();
    if (this.mineosChatbotService.isOpen()) {
      this.chatInput.nativeElement.focus();
    }
  }

  private disableChatInput(): void {
    this.messageCtrl.disable();
    this.chatInput.nativeElement.blur();
  }

  close(): void {
    this.mineosChatbotService.close();
  }

  sendMessage(): void {
    if (!this.canSendMessages() || this.messageCtrl.disabled || this.messageCtrl.invalid) return;
    
    // Get user entered message and remove extra whitespace
    const userMessage = this.messageCtrl.value?.trim();
    if (!userMessage) return;

    // Clear the input and set its height to default
    this.messageCtrl.reset();

    const message = { 
      content: userMessage, 
      type: MessageTypeEnum.USER_TEXT, 
    } as Message;

    // Send the message via Pusher service
    this.mineosChatbotService.sendMessage(message);

    /* Disable the chat right after the first message arrived
      So that ds will answer In an orderly manner
    */
    this.disableChatInput();

    setTimeout(() => {
      this.copilotTypingState.set(true);
    }, 300);
  }

  onMessageHandler = (message, isNewMessage = true) => {
    this.printLogMessage(`Received the following message: ${JSON.stringify(message)}`);
    
    if (!message?.type || !message?.content) {
      this.logger.error(this.loggerName, `Message type or content is missing: ${JSON.stringify(message)}`);
      return;
    }
    if (message.type === MessageTypeEnum.ASSISTANT_RESPONSE && !this.canSendMessages()) return;
    if (message.type === MessageTypeEnum.DS_IDLE) {
      this.mineosChatbotService.dsReady(true);
      return;
    }
    if (message.type === MessageTypeEnum.HISTORY) {
      this.fetchingMessagesFromHistory.set(true);
      message.content.forEach(message => this.onMessageHandler(message, false));
      this.fetchingMessagesFromHistory.set(false);
      return;
    }
    if (message.type === MessageTypeEnum.DATA_UPDATE && !this.fetchingMessagesFromHistory()) {
      this.mineosChatbotService.updateData(message.content);
      return;
    }

    this.copilotTypingState.set(false);

    // in case chatbot is closed and message arrived, put the message in unread counter
    if (!this.showChatbot() && isNewMessage) {
      this.unreadMessagesCounter.set(this.unreadMessagesCounter() + 1);
    }

    this.mineosChatbotService.addMessage({ 
      content: message.content, 
      type: message.type, 
    } as Message);

    const messageId = `#copilot-message-${this.chatMessages().length - 1}`;
    const messageContent = <string>message.content;

    setTimeout(() => {
      if (this.showChatbot() && isNewMessage) {
        this.typewriteCopilotMessage(messageId, messageContent, 5);
      }
      else {
        // no need for typewriter effect if chat is closed
        const messageElement = document.querySelector(messageId);
        if (messageElement) {
          messageElement.innerHTML = messageContent;
          this.messageCtrl.enable();
          this.enableChatInput();
        }
      }
    }, 300);
  }

  private printLogMessage(message: any): void {
    try {
      console.debug(message);
      this.logger.debug(this.loggerName, message);
    }
    catch(e) {
      this.logger.error(this.loggerName, `Could not parse incoming message: ${JSON.stringify(message)}`);
    }
  }

  private typewriteCopilotMessage(querySelector: string, sentence: string, speed: number): void {
    let index = 0;
    let element = document.querySelector(querySelector);
    
    const context = this;
    let timer = setInterval(function() {
      const char = sentence[index];
      
      // support for HTML tags
      if (char === '<') {
        index = sentence.indexOf('>', index);  // skip to greater-than
      }
      
      element.innerHTML = sentence.slice(0, index);
      context.scrollToBottom();
      
      if (index === sentence.length) {
        clearInterval(timer);
        context.enableChatInput();
      }
      index++;
    }, speed);
  }
}
