import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { Observable } from 'rxjs';
import { VendorManagementState, VendorManagementStore } from './vendor-managment.store';

@Injectable({ 
	providedIn: 'root' 
})
export class VendorManagementQuery extends QueryEntity<VendorManagementState> {
  constructor(protected store: VendorManagementStore) {
    super(store);
  }

  selectRating(id: string): Observable<string> {
    return this.selectEntity(id, ({ riskRating }) => riskRating);
  }
  
  getRating(id: string): string {
    return this.getEntity(id)?.riskRating;
  }
}