export enum FieldUsedInEnum {
    Activity = 'Activity',
    Assessment = 'Assessment',
    DataSource = 'DataSource'
}

export enum FieldInputType {
    Text = 'Text',
    Select = 'Select'
}
