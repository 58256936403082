import { Injectable } from '@angular/core';
import { Observable, filter, map, switchMap } from 'rxjs';

import { ProfileQuery } from '../profile/state/profile.query';
import { RoleEnum } from '../core/models/permission-role.enum';

@Injectable({
  providedIn: 'root'
})
export class PermissionsService {

  constructor(
    private profileQuery: ProfileQuery,
  ) { }

  selectIsAllowed(allowedRoles: RoleEnum[]): Observable<boolean> {
    const allowedRolesSet = new Set<RoleEnum>(allowedRoles);
    return this.profileQuery.selectLoading().pipe(
      filter(loading => !loading),
      switchMap(() => this.profileQuery.selectAgentRole()),
      map(currentUserRole => allowedRolesSet.has(currentUserRole)),
    );
  }

  getIsAllowed(allowedRoles: RoleEnum[]): boolean {
    const allowedRolesSet = new Set<RoleEnum>(allowedRoles);
    const currentUserRole = this.profileQuery.getRole();
    
    return allowedRolesSet.has(currentUserRole);
  }
}
