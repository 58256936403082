export enum ChipColorName {
  Grey = 'grey',
  Purple = 'purple',
  Yellow = 'yellow',
  Blue = 'blue',
  Red = 'red',
  Green = 'green',
  LightPurple = 'light-purple',
  LightPink = 'light-pink',
  VeryLightBlue = 'very-light-blue',
  Cyan = 'cyan'
}