import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';

import { produce } from 'immer';
import { PiiSystem } from '../models/pii-classifier.interface';


export interface PiiClassifierState extends EntityState<PiiSystem> { }

@Injectable({ 
	providedIn: 'root' 
})
@StoreConfig({ 
	name: 'piiClassifier',
	idKey: 'systemId',
	producerFn: produce
})
export class PiiClassifierStore extends EntityStore<PiiClassifierState> {
	
	constructor() {
		super();
	}
}