import { Component, ChangeDetectionStrategy, HostListener, Input, OnInit } from '@angular/core';
import { AnalyticsEvent } from 'src/app/analytics/analytics-event';

import { AnalyticEventsLabels } from 'src/app/analytics/analytics-events-labels';
import { AnalyticsService } from 'src/app/analytics/analytics.service';
import { EventActionType } from 'src/app/analytics/event-action.enum';
import { PlanType } from 'src/app/api/models/plans/plan.enum';
import { ContentPipe } from 'src/app/services/content/content.pipe';

@Component({
  selector: 'trial-banner',
  templateUrl: './trial-banner.component.html',
  styleUrls: ['./trial-banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TrialBannerComponent implements OnInit {

	@Input()
	plan: PlanType;

	text: string;

	constructor(
		private analytics: AnalyticsService,
		private contentPipe: ContentPipe
	) { }

	ngOnInit(): void {
		this.initText();
	}

	@HostListener('click')
	onClick() {
		this.analytics.track({
			eventCategory: AnalyticEventsLabels.FREE_trial_banner,
			eventAction: EventActionType.Click,
		} as AnalyticsEvent);

		window.open(this.contentPipe.transform('menu.plansUrl'), '_blank', 'noopener');
	}

	private initText(): void {
		switch (this.plan) {
			case PlanType.Sandbox:
				this.text = this.contentPipe.transform('common.bannerSandbox');
				return;
			
			default:
				this.text = this.contentPipe.transform('common.bannerFreeTrial');
				return;
		}
	}
}
