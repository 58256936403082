import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { HttpClientHelperService } from './http-client-helper.service';
import { PaginationCursorConfig } from '../shared/mine-pagination/mine-pagination.interface';
import { SystemsDirectoryByIdsResponse, SystemsDirectoryDiscoveryResponse, SystemsDirectoryItem } from './models/systems-directory/systems-directory.interface';
import { SystemsDirectoryDiscoverPayload, SystemsDirectoryListPayload, SystemsDirectoryPaginationPayload, SystemsDirectorySearchPayload } from '../systems-directory/models/systems-directory.interface';

@Injectable({
  providedIn: 'root'
})
export class ApiClientSystemsDirectoryService {
  	private readonly controller: string = 'ServiceCatalog';
  	private readonly PAGINATION_MAX_SIZE = 100;
	private readonly PAGINATION_DEFAULT_SIZE = 21;
  	private readonly DISCOVER_DEFAULT_LIMIT = 3;

	constructor(
		private httpClientHelper: HttpClientHelperService
	) { }

	discover(payload: SystemsDirectoryDiscoverPayload): Observable<SystemsDirectoryDiscoveryResponse> {
		let categoriesStr = payload.categories.reduce((str, category) => str.concat(`&category=${category}`), '');
		return this.httpClientHelper.invokeGetAuth<SystemsDirectoryDiscoveryResponse>(`${this.controller}/Discover?limit=${payload.limit ?? this.DISCOVER_DEFAULT_LIMIT}${categoriesStr}`);
  	}

  	search(payload: SystemsDirectorySearchPayload): Observable<PaginationCursorConfig<SystemsDirectoryItem>> {
		let qParams = this.getPaginationQueryParams(payload);
		qParams = qParams.set('text', payload.text);

		return this.httpClientHelper.invokeGetAuth<PaginationCursorConfig<SystemsDirectoryItem>>(`${this.controller}/Search`, qParams);
	}

  	list(payload: SystemsDirectoryListPayload): Observable<PaginationCursorConfig<SystemsDirectoryItem>> {
		let qParams = this.getPaginationQueryParams(payload);
		if (payload.category) qParams = qParams.set('category', payload.category);
		if (payload.automationType) qParams = qParams.set('automationType', payload.automationType);

		return this.httpClientHelper.invokeGetAuth<PaginationCursorConfig<SystemsDirectoryItem>>(`${this.controller}/List`, qParams);
	}

	getByIds(ids: string[]): Observable<SystemsDirectoryByIdsResponse> {
		const idsStr = ids.reduce((str, id) => str.concat(`&id=${id}`), '').slice(1);
		return this.httpClientHelper.invokeGetAuth<SystemsDirectoryByIdsResponse>(`${this.controller}/ByIds?${idsStr}`);
	}

	private getPaginationQueryParams(payload?: SystemsDirectoryPaginationPayload): HttpParams {
		let qParams = new HttpParams();
		if (payload?.before) {
			qParams = qParams.set('before', payload.before);
		}
		if (payload?.after) {
			qParams = qParams.set('after', payload.after);
		}
		if (payload?.limit) {
			qParams = qParams.set('limit', payload.limit > this.PAGINATION_MAX_SIZE ? this.PAGINATION_MAX_SIZE : payload.limit);
		}
		else {
			qParams = qParams.set('limit', this.PAGINATION_DEFAULT_SIZE);
		}
		return qParams;
	}
}