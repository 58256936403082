import { Pipe, PipeTransform } from '@angular/core';
import { PII_TYPE_TO_DATA_TYPE_ID_MAP } from 'src/app/shared/models/pii-to-data-type-map';

@Pipe({
  standalone: true,
  name: 'convertPiiTypeToDataType'
})
export class ConvertPiiTypeToDataTypePipe implements PipeTransform {  

  transform(value: string): string {
    return PII_TYPE_TO_DATA_TYPE_ID_MAP.get(value);
  }
}
