import { inject, Injector } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, Data, CanActivateFn } from '@angular/router';
import { Observable, merge, combineLatest, filter, map, switchMap } from 'rxjs';

import { FeatureFlags } from '../api/models/profile/profile-feature-flags.enum';
import { ActivatePortalDialogService } from '../core/activate-portal-dialog/activate-portal-dialog.service';
import { FeatureFlagValueStrategy } from './feature-flag-value-strategy';
import { FeatureFlagGuardStrategy } from './feature-flag-guard-strategy';
import { FeatureFlagGuardHelper } from './feature-flag-guard-helper';
import { FeatureFlagQuery } from './state/feature-flag.query';
import { RoutesManager } from '../shared/models/routes.interfaces';

// feature toggle enabled - enable navigation
// feature toggle disabled + premium dialog enabled - show premium dialog and listen to button click - if the user clicked on "upgrade" > move to plans page 
// feature toggle disabled + premium dialog disabled - do nothing (this case should be enforced before the guard)
// featureFlagStrategy - sets the check to perform. If not set, a default check will evaluate the feature flag value.
const FeatureFlagGuardFn = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> => {
    const router = inject(Router);
    const featureFlagQuery = inject(FeatureFlagQuery);
    const activatePortalDialogService = inject(ActivatePortalDialogService);

    const featureFlagName = getFeatureFlagName(next, state);
    const strategy = getStrategy(next.data);
    const canActivate$ = strategy.canActivate(state, next, featureFlagName);
    const premiumDialogFlag$ = featureFlagQuery.selectFlag(FeatureFlags.PremiumDialogFlag);
    const activate$ = canActivate$.pipe(filter(isEnabled => isEnabled));
  
    const premiumDialogBeforeActivation$ = combineLatest([canActivate$, premiumDialogFlag$])
      .pipe(
        filter(featureFlags => !featureFlags[0] && featureFlags[1]),
        map(() => featureFlagName),
        switchMap((flagName: FeatureFlags) => activatePortalDialogService.showPremiumDialog(flagName)),
        map(upgradeClicked => upgradeClicked ? router.parseUrl(`${RoutesManager.plans}`) : checkRedirect(strategy.redirectUrl))
      );

    const disableActivation$ = combineLatest([canActivate$, premiumDialogFlag$])
      .pipe(
        filter(featureFlags => !featureFlags[0] && !featureFlags[1]),
        map(() => false)
    );

    return merge(activate$, premiumDialogBeforeActivation$, disableActivation$); 
}

const getFeatureFlagName = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot): FeatureFlags | string => {
  if (next.data['featureFlagName']) {
    return next.data['featureFlagName'];
  }
  else if (state.url.includes(`/${RoutesManager.automations}/`) || state.url.includes(`/${RoutesManager.data_sources}/`)) {
    const flagName = FeatureFlagGuardHelper.getFeatureFlagByUrl(state.url);

    // check if webhook integration
    if (flagName === FeatureFlags.WebHookEvents) {
      return flagName;
    }

    // add integrations prefix if needed
    return state.url.includes(`/${RoutesManager.data_sources}/`) ? `integrations.${flagName}` : flagName;
  }

  return next.data['featureFlagName'];
}

const checkRedirect = (url: string): boolean | UrlTree => {
  const router = inject(Router);
  return url ? router.parseUrl(url) : false;
}

const getStrategy = (data: Data): FeatureFlagGuardStrategy => {
  const injector = inject(Injector);
  const defaultStrategy = inject(FeatureFlagValueStrategy);

  if (data['featureFlagStrategy']) {
    return injector.get(data['featureFlagStrategy']);
  }

  return defaultStrategy;
}

export const FeatureFlagGuard: CanActivateFn = FeatureFlagGuardFn;