import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { AnalyticEventsLabels } from "src/app/analytics/analytics-events-labels";
import { FeatureFlags } from "src/app/api/models/profile/profile-feature-flags.enum";
import { ContentPipe } from "src/app/services/content/content.pipe";
import { DialogsManagerService } from "src/app/services/dialogs-manager.service";
import { ActivatePortalDialogComponent } from "./activate-portal-dialog.component";
import { PremiumDialogConfiguration } from "./activate-portal-dialog.interface";

@Injectable({
  providedIn: 'root'
})
export class ActivatePortalDialogService {
  
  constructor(
    private dialogsManagerService: DialogsManagerService,
    private contentPipe: ContentPipe,
  ) { }

  // additionalOptions reason: 
  // - sometimes we need to show multiple dialogs inside a feature.
  // - when the condition to show the dialog is not the feature flag itself, but other conditions
  showPremiumDialog(featureFlagName: FeatureFlags, additionalOptions?: { [key: string]: any }): Observable<any> {
    const dialogRef = this.dialogsManagerService.openDialogCommon(ActivatePortalDialogComponent, this.getDialogConfigurationByFF(featureFlagName, additionalOptions));
    return dialogRef.afterClosed$;
  }

  private getDialogConfigurationByFF(featureFlag: FeatureFlags, additionalOptions: { [key: string]: boolean }): PremiumDialogConfiguration {
    switch(featureFlag) {
      case FeatureFlags.WebHookEvents:
        return {
          ...this.contentPipe.transform('common.activatePortalIntegrations'),
          readMoreLink: this.contentPipe.transform('knowledge-base-urls.dataSources'),
          analyticsEventLabel: AnalyticEventsLabels.ACTIVATE_PORTAL_integrations_btn,
        }; 
      case FeatureFlags.ApiKeys:
        return {
          ...this.contentPipe.transform('common.activatePortalApiKeys'),
          readMoreLink: this.contentPipe.transform('knowledge-base-urls.apiDevelopers'),
        };
      case FeatureFlags.CanceledMailAutomation:
      case FeatureFlags.CompletedMailAutomation:
      case FeatureFlags.AckMailAutomation:
      case FeatureFlags.RejectedMailAutomation:
        return {
          ...this.contentPipe.transform('common.activatePortalAutomations'),
          readMoreLink: this.contentPipe.transform('knowledge-base-urls.autoResponses')
        };
      case FeatureFlags.UnLimitedTicketsPerMonth:
        return {
          ...this.contentPipe.transform('common.activatePortalLimitedTicketsPerMonth'),
          readMoreLink: this.contentPipe.transform('knowledge-base-urls.requestsLimit')
        }
      case FeatureFlags.EmailTemplates:
        return {
          ...this.contentPipe.transform('common.activatePortalEmailTemplates'),
          readMoreLink: this.contentPipe.transform('knowledge-base-urls.emailForwarding'),
          analyticsEventLabel: AnalyticEventsLabels.ACTIVATE_PORTAL_templates_btn,
        }
      case FeatureFlags.DataMapping: 
        return this.getDataMappingTemplates();
      case FeatureFlags.AddTeamMember:
        return {
          title: this.contentPipe.transform('profile.reachedMembersLimit'),
          text: this.contentPipe.transform('profile.reachedMembersLimitText'),     
          analyticsEventLabel: AnalyticEventsLabels.ACTIVATE_PORTAL_add_team_member_btn
        }
      case FeatureFlags.PanoraysPlan:
        return {
          ...this.contentPipe.transform('common.activatePanorays'),
          analyticsEventLabel: AnalyticEventsLabels.ACTIVATE_PORTAL_panorays
        }
      case FeatureFlags.VettingPlan:
        return {
          title: this.contentPipe.transform('systems.upgradeVettingDialogTitle'),
          text: this.contentPipe.transform('systems.upgradeVettingDialogText'),
          readMoreLink: null
        }
      case FeatureFlags.PoliciesPlan:
        return {
          ...this.contentPipe.transform('policies.activateFeatureDialog'),

        }
      case FeatureFlags.LockIntegrationTagging:
        return {
          title: this.contentPipe.transform('systems.upgradeTaggingDialogTitle'),
          text: this.contentPipe.transform('systems.upgradeTaggingDialogText'),
          readMoreLink: null
        }
      case FeatureFlags.AutopilotByPlan:
        return {
          title: this.contentPipe.transform('requests-autopilot.upgradePlanTitle'),
          text: this.contentPipe.transform('requests-autopilot.upgradePlanText'),
          readMoreLink: null
        }
      default:
        return {
          ...this.contentPipe.transform('common.activatePortalDefault'),
          readMoreLink: null,
        };
    }
  }

  private getDataMappingTemplates(): PremiumDialogConfiguration {
    return {
      ...this.contentPipe.transform('common.upgradePortalDataMapping'),
      readMoreLink: this.contentPipe.transform('knowledge-base-urls.dataMapping'),
      analyticsEventLabel: AnalyticEventsLabels.ACTIVATE_PORTAL_data_mapping_btn
    };
  }
}