import { CommonModule } from '@angular/common';
import { Component, ChangeDetectionStrategy, Input, forwardRef, ElementRef, ViewChild, Renderer2 } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor, FormsModule } from '@angular/forms';

@Component({
  standalone: true,
  selector: 'mine-radio-button',
  templateUrl: './mine-radio-button.component.html',
  styleUrls: ['./mine-radio-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, FormsModule],
  providers: [{       
    provide: NG_VALUE_ACCESSOR, 
    useExisting: forwardRef(() => MineRadioButtonComponent),
    multi: true     
  }],
})
export class MineRadioButtonComponent implements ControlValueAccessor {

  @Input() id: string;

  @Input() name: string;

  @Input('value') _value: any;

  @Input() hideCheckmark = false;

  @ViewChild('inputRef') inputRef!: ElementRef;

  modelValue: any;
  
  disabled = false;

  constructor(private renderer: Renderer2) {}

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  get value() {
    return this._value;
  }
    
  set value(value) {
    if(!!value){
      this._value = value; 
      this.onChange(value);
      this.onTouched();
    }
  }

  onChange: any = (onchanges => { });

  onTouched: any = () => { };

  registerOnChange(fn) {
    this.onChange = fn;
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  writeValue(value) {
    if (!!this.inputRef && value === this.value) {
      this.renderer.setProperty(this.inputRef.nativeElement, 'checked', true);
    }
    this.modelValue = value;
  }

  toggleChange(event) { }

  valueChanged(event) {
    this.onChange(this.modelValue);
  }
}