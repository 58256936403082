import { Injectable, Inject } from '@angular/core';

import { JL } from 'jsnlog';
import { Observable, of, first } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
	providedIn: 'root'
})
export class LoggerService {
	private appenders: Array<JL.JSNLogAjaxAppender | JL.JSNLogConsoleAppender> = [];
	private projectPrefix = environment.logger.ajax.project;
	public userId: string;
	public sessionId: string;

	private loggers: Set<string> = new Set();

	constructor(@Inject('JSNLOG') private JL: JL.JSNLog) { }

	init(): Observable<void> {
		this.configureAjaxAppender()
		this.configureConsoleAppender();
		return of(void 0).pipe(first());
	}

	public setRequestId() {
		JL.setOptions({
			"requestId": `${this.userId}|${this.sessionId}`
		});
	}

	public setUserId(userId: string) {
		this.userId = userId;
		this.setRequestId();

	}
	public setSessionId(sessionId: string) {
		this.sessionId = sessionId;
		this.setRequestId();
	}

	private configureAjaxAppender() {
		if(!environment.logger.ajax.url)
		{
			// ajax appender is disabled
			return;
		}
		
		const ajaxAppender = JL.createAjaxAppender('ajaxAppender');
		ajaxAppender.setOptions({
			'level': environment.logger.ajax.level,
			'url': environment.logger.ajax.url,
			'batchSize': 5,
			'maxBatchSize': 30,
		});
		this.appenders.push(ajaxAppender);
	}

	private configureConsoleAppender() {
		const consoleAppender = JL.createConsoleAppender('consoleAppender');
		consoleAppender.setOptions({ 'level': environment.logger.console.level });
		this.appenders.push(consoleAppender);
	}

	private config(name: string): void {
		this.JL(this.getLoggerName(name)).setOptions({ 'appenders': this.appenders });
	}

	private checkConfig(name: string): void {
		if (this.loggers.has(name)) {
			return;
		}
		this.config(name);
		this.loggers.add(name);
	}

	private getLoggerName(name: string): string {
		return `${this.projectPrefix}.${name}`;
	}

	trace(name: string, message: string): void {
		this.checkConfig(name);
		this.JL(this.getLoggerName(name)).trace(message);
	}

	debug(name: string, message: string): void {
		this.checkConfig(name);
		this.JL(this.getLoggerName(name)).debug(message);
	}

	info(name: string, message: string): void {
		this.checkConfig(name);
		this.JL(this.getLoggerName(name)).info(message);
	}

	warn(name: string, message: string): void {
		this.checkConfig(name);
		this.JL(this.getLoggerName(name)).warn(message);
	}

	error(name: string, message: string): void {
		this.checkConfig(name);
		this.JL(this.getLoggerName(name)).error(message);
	}

	// fatal(name: string, message: string): void {
	//		this.checkConfig(name);
	// 	this.JL(this.getLoggerName(name)).fatal(message);
	// }

	fatalException(name: string, message: string, exception: any) {
		this.checkConfig(name);
		this.JL(this.getLoggerName(name)).fatalException(message, exception);
	}
}
