import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ApiClientTicketViewService } from 'src/app/api/api-client-ticket-view.service';

@Injectable({
	providedIn: 'root'
})
export class TicketEvidenceService {

  private ticketsWithFetchedEvidenceSubject = new BehaviorSubject<Set<string>>(new Set<string>());
  ticketsWithFetchedEvidence$: Observable<Set<string>> = this.ticketsWithFetchedEvidenceSubject.asObservable();

  constructor(
    private apiClientTicketViewService: ApiClientTicketViewService
  ) {
  }

  updateFetchedEvidence(ticketId: string): void {
    const currentValue = this.ticketsWithFetchedEvidenceSubject.value;
    this.ticketsWithFetchedEvidenceSubject.next(currentValue.add(ticketId));
  }

  updateFetchedEvidenceServer(ticketId: string): Observable<void> {
    return this.apiClientTicketViewService.updateEvidenceViewed(ticketId);
  }
}
