import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	standalone: true,
  	name: 'sentenceCase'
})
export class SentenceCasePipe implements PipeTransform {

	transform(str: string): string {
		return `${str?.charAt(0)?.toUpperCase()}${str?.slice(1)}`;
	}
}