import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { ContentPipe } from 'src/app/services/content/content.pipe';
import { EmployeesTableColumnKeys } from './employees-table.enum';
import { DataMappingEmployeeNamePipe } from 'src/app/shared/pipes/data-mapping-employee-name.pipe';
import { EmployeeDataMapping } from 'src/app/api/models/data-mapping/data-mapping.interface';
import { EmployeeConnectionEnum } from 'src/app/api/models/data-mapping/data-mapping.enum';

@Injectable()
export class EmployeesTableCsvHelper {

    constructor(
        private datePipe: DatePipe,
        private contentPipe: ContentPipe
    ) {}

    getValueByKey(employee: EmployeeDataMapping, key: EmployeesTableColumnKeys): string {
      const values: Record<EmployeesTableColumnKeys, string> = {
        accountStatus: employee.directoryData?.accountStatus ?? '-',
        autoDiscovery: this.getAutoDiscovery(employee),
        changeDate: this.getChangeDate(employee),
        coworkerDataSources: this.getDataSources(employee),
        coworkerEmail: employee.email ?? '-',
        coworkerName: new DataMappingEmployeeNamePipe(this.contentPipe).transform(employee as EmployeeDataMapping),
        organizationPath: employee.directoryData?.organizationalUnit ?? '-',
        roleTitle: employee.directoryData?.roleTitle ?? '-',
      };
      return values[key];
    }

    private getDataSources(employee: EmployeeDataMapping): string {
      return `${employee.dataSourcesCount}` ?? '-';
    }

    private getChangeDate(employee: EmployeeDataMapping): string {
      const date = employee.directoryData?.statusChangeDate;
      return date ? this.datePipe.transform(date, 'dd MMM, yyyy') : '-';
    }

    private getAutoDiscovery(employee: EmployeeDataMapping): string {
      const connectionType = employee.connectionType;
      switch (connectionType) {
        case EmployeeConnectionEnum.Invitee:
          return this.contentPipe.transform('data-mapping.invitationPending');

        default:
          return connectionType;
      }
    }
}