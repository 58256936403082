export enum PartiesWithAccess {
    Unknown = 'Unknown',
    Internal = 'Internal',
    External = 'External'
}

export enum ProcessActivityStatusEnum {
    Operational = 'Operational',
    Draft = 'Draft'
}

export enum ProcessActivityRolesEnum {
    Processor = 'Processor',
    Controller = 'Controller',
    JointController = 'JointController'
}

export enum RiskRateEnum { //DO NOT CHANGE ORDER
    Low = 'Low',
    Medium = 'Medium',
    High = 'High',
} 

export enum DataFlowNodeTypeEnum {
    Unknown = 'Unknown',
    System = 'System',
    CustomEntity = 'CustomEntity'
}