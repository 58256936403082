import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { HttpClientHelperService } from './http-client-helper.service';
import { DeepDiveResponse } from './models/data-types/data-types.interface';

@Injectable({
  providedIn: 'root'
})
export class ApiClientDataTypesService {
  private readonly controller: string = 'dataTypes';

  constructor(private httpClientHelper: HttpClientHelperService) { }

  getDeepDiveData(systemId: string, dataTypes: string): Observable<DeepDiveResponse> {
    return this.httpClientHelper.invokeGetAuth<DeepDiveResponse>(`${this.controller}/DeepDive/${systemId}?dataTypes=${dataTypes}`);
  }
}
