import { NgOptimizedImage } from '@angular/common';
import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { AnimationStateEnum } from 'src/app/animations/animation-state.enum';
import { Animations } from 'src/app/animations/animations';
import { environment } from 'src/environments/environment';

@Component({
    standalone: true,
    selector: 'mine-service-icon',
    templateUrl: './mine-service-icon.component.html',
    styleUrls: ['./mine-service-icon.component.scss'],
    animations: [Animations.fadeIn, Animations.fadeOut],
    imports: [NgOptimizedImage],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MineServiceIconComponent {

    @Input()
    icon: string;

    @Input()
    width = 30;
    
    @Input()
    height = 30;

    readonly fallbackIcon = `${environment.assets.url}/Portal/integrations/data_source_logo_placeholder.png`;

    src: string = '';
    animationState = AnimationStateEnum.Hide;
}