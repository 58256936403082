export enum AutomationType {
   RejectConfiguration = 'emailRejectConfiguration',
   CompletedConfiguration = 'emailCompletedConfiguration',
   AckConfiguration = 'emailAckConfiguration',
   CanceledConfiguration = 'emailCanceledConfiguration',
   CopyRejectConfiguration = 'emailCopyRejectConfiguration',
   CopyCompletedConfiguration = 'emailCopyCompletedConfiguration',
   CopyCanceledConfiguration = 'emailCopyCanceledConfiguration',
   DoNotMailCompleteConfig = 'emailDoNotMailCompletedConfiguration',
   DoNotMailRejectConfig = 'emailDoNotMailRejectConfiguration',
   DoNotMailCancelConfig = 'emailDoNotMailCanceledConfiguration',
   RightToEditCancelConfig = 'emailRightToEditCanceledConfiguration',
   RightToEditRejectConfig = 'emailRightToEditRejectConfiguration',
   RightToEditCompleteConfig = 'emailRightToEditCompletedConfiguration',
   DoNotSellCancelConfig = 'emailDoNotSellCanceledConfiguration',
   DoNotSellRejectConfig = 'emailDoNotSellRejectConfiguration',
   DoNotSellCompleteConfig = 'emailDoNotSellCompletedConfiguration',
}

export enum AutomationResponseType {
   Reject = "Reject",
   Completed = "Completed",
   Canceled = "Canceled",
   AttachmentReply = "AttachmentReply",
   Ack = "Ack"
}