import { Injectable, Signal } from "@angular/core";
import { Observable } from "rxjs";
import { PrivacyRightItem } from "src/app/api/models/dsr/dsr-rights.interface";
import { DsrPrivacyRightsStore } from "./dsr-privacy-rights.store";

@Injectable({
    providedIn: 'root'
})
export class DsrPrivacyRightsQuery {

    constructor(
        private store: DsrPrivacyRightsStore,
    ) { }

    setLoading(loading: boolean): void {
        this.store.set({ 'loading': loading });
    }

    getLoading(): Signal<boolean | undefined> {
        return this.store.signal('loading');
    }

    selectLoading(): Observable<boolean | undefined> {
        return this.store.select('loading');
    }

    setRights(rights: PrivacyRightItem[]): void {
        const mapRights = new Map<string, PrivacyRightItem>();
        rights.forEach(right => {
            mapRights.set(right.id, right);
        });
        
        this.store.set({ 'rights': mapRights });
    }

    updateRightNameAndDescription(rightId: string, name: string, description: string): void {
        this.store.get('rights').get(rightId).displayName = name;
        this.store.get('rights').get(rightId).description = description;
    }

    getRights(): Signal<Map<string, PrivacyRightItem>> {
        return this.store.signal('rights');
    }

    selectRights(): Observable<Map<string, PrivacyRightItem>> {
        return this.store.select('rights');
    }

    setRightEnabled(rightId: string, enabled: boolean): void {
        this.store.get('rights').get(rightId).enabled = enabled;
    }

}