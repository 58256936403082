import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, HostBinding, HostListener, Input, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { first, tap, Observable, map, filter } from 'rxjs';
import { Router } from '@angular/router';

import { Animations } from 'src/app/animations/animations';
import { FeatureFlagQuery } from 'src/app/feature-flag/state/feature-flag.query';
import { AnalyticsEvent } from 'src/app/analytics/analytics-event';
import { AnalyticEventsLabels } from 'src/app/analytics/analytics-events-labels';
import { ChatService } from 'src/app/chat/chat.service';
import { TeamMemberService } from 'src/app/services/team-member.service';
import { ElasticSidenavService } from './app-elastic-sidenav.service';
import { AnalyticsService } from 'src/app/analytics/analytics.service';
import { EventActionType } from 'src/app/analytics/event-action.enum';
import { KnowledgeBaseService } from 'src/app/services/knowledge-base.service';
import { OnboardingTasksService } from 'src/app/services/onboarding-tasks/onboarding-tasks.service';
import { environment } from 'src/environments/environment';
import { RoleEnum } from '../models/permission-role.enum';
import { RoutesManager } from 'src/app/shared/models/routes.interfaces';
import { FeatureFlags } from 'src/app/api/models/profile/profile-feature-flags.enum';
import { MenuItemId } from '../app-sidenav-content/menu-item.enum';
import { RolePermissionsHelper } from 'src/app/auth/role-permmissions-helper';

@Component({
  selector: 'app-elastic-sidenav',
  templateUrl: './app-elastic-sidenav.component.html',
  styleUrls: ['./app-elastic-sidenav.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [Animations.pimple, Animations.collapse]
})
export class AppElasticSidenavComponent implements OnInit, AfterViewInit {
  @Input()
  role: RoleEnum;

  analyticEventsConsts = AnalyticEventsLabels;

  readonly routesManager = RoutesManager;

  RoleEnum = RoleEnum;
  
  assetsUrl = environment.assets.url;

  allActive = false;

  openActive = false;

  closedActive = false;
  
  userSearchFlag: boolean;

  notificationsCount$: Observable<number>;

  finishedTasks = false;

  sidenavScroll = false;

  alwaysOpen: boolean = true;

  beamerNotificationsFF = false;

  homePermittedrRoles: RoleEnum[];
  whatsNewPermittedrRoles: RoleEnum[];

  readonly tpProps = {
    placement: 'right',
  };

  constructor(
    private router: Router,
    private intercom: ChatService,
    private analytics: AnalyticsService,
    private cdr: ChangeDetectorRef,
    private destroyRef: DestroyRef,
    private featureFlagQuery: FeatureFlagQuery,
    private teamMemberService: TeamMemberService,
    private knowledgeBaseService: KnowledgeBaseService,
    private onboardingTasksService: OnboardingTasksService,
    private elasticSidenavService: ElasticSidenavService
  ) { }

  @HostListener('window:resize', ['$event'])
  private listenToResizeScreen() {
    const headerHeight = document.getElementsByClassName('sidenav__mine-logo')[0]?.clientHeight;
    const footerHeight = document.getElementsByClassName('sidenav__app-menu')[0]?.clientHeight;
    const menuElementsMinHeight = document.getElementsByClassName('sidenav__section')[0]?.clientHeight + document.getElementsByClassName('sidenav__section')[1]?.clientHeight + 11; // need to add also padding from bottom (11px)
    this.sidenavScroll = (window.innerHeight - headerHeight - footerHeight) <= menuElementsMinHeight;
  }

  @HostListener("mouseenter")
  onMouseEnter() {
    return !this.shrink ? false : this.toggleSidenav();
  }

  @HostListener("mouseleave")
  onMouseLeave() {
    this.shrink ? false : this.toggleSidenav();
  }

  @HostBinding('class.shrink')
  shrink: boolean = false;

  @HostBinding('class.scroll') get addScroll() {
    return this.sidenavScroll;
  }

  ngOnInit() {

    this.initFlags();
    this.initPermissions();
    this.initSidenavMenu();
    this.elasticSidenavService.shrink$.pipe(
      tap(response => this.shrink = response),
      filter(response => !!response),
      map(() => this.alwaysOpen = false),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe();
  }

  ngAfterViewInit(): void {
    this.listenToResizeScreen();
  }

  private initPermissions(): void {
    this.whatsNewPermittedrRoles = RolePermissionsHelper.getPerrmitedRolesByMenuId(MenuItemId.WhatsNew);
    this.homePermittedrRoles = RolePermissionsHelper.getPerrmitedRoleByRoute([RoutesManager.homePage]);
  }

  toggle(): void {
    this.alwaysOpen = !this.alwaysOpen;
    !this.alwaysOpen ? this.elasticSidenavService.toggle() : '';
  }

  private toggleSidenav(): boolean {
    if (this.alwaysOpen) {
      return false;
    }
    this.elasticSidenavService.toggle();
    return true;
  } 

  private initFlags(): void {
    const featureFlags = this.featureFlagQuery.getMultipleFlags([FeatureFlags.UserSearch, FeatureFlags.BeamerNotifications]);
    this.userSearchFlag = featureFlags.userSearch;
    this.beamerNotificationsFF = featureFlags.beamerNotifications;
  }

  private initSidenavMenu(): void {
    this.onboardingTasksService.isOnbordingFinished().pipe(
      first(),
      tap(res => this.finishedTasks = res),
      tap(() => this.cdr.detectChanges())
    ).subscribe();
  }

  openIntercom(): void {
    this.intercom.show();
  }

  onHelpClick(): void {
    this.logAnalyticsEvent(AnalyticEventsLabels.SIDEBAR_help_center);
    this.knowledgeBaseService.navigate('getting-started-with-your-data-inventory');
  }

  onAddMember(): void {
    this.logAnalyticsEvent(AnalyticEventsLabels.SIDEBAR_add_a_team_member);
    this.teamMemberService.addTeamMember();
  }

  logAnalyticsEvent(eventCategory: string): void {
    this.analytics.track({
      eventCategory: eventCategory,
      eventAction: EventActionType.Click,
    } as AnalyticsEvent);
  }

  collapseProcessBar(): void {
    this.finishedTasks = true;
  }

  navigateToHome(): void {
    this.analytics.track({
      eventCategory: AnalyticEventsLabels.SIDEBAR_progrees_bar,
      eventAction: EventActionType.Click,
    } as AnalyticsEvent);

    this.router.navigate([RoutesManager.homePage]);
  }
}