import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClientHelperService } from './http-client-helper.service';
import { IntegrationPreviewResponse, IntegrationRecordsResponse } from './models/integrations/integrations.interface';

@Injectable({
  providedIn: 'root'
})
export class ApiClientUserSearchService {
	private readonly controller = 'integrationsSearch';

  constructor( 
    private httpClientHelper: HttpClientHelperService
  ) { }

	fetchUserData(integrationId: string, email: string): Observable<IntegrationRecordsResponse> {
		return this.httpClientHelper.invokeGetAuth<IntegrationRecordsResponse>(`${this.controller}/fetch/${integrationId}?email=${encodeURIComponent(email)}`);
	}

	previewUserData(integrationId: string, email: string): Observable<IntegrationPreviewResponse> {
		return this.httpClientHelper.invokeGetAuth<IntegrationPreviewResponse>(`${this.controller}/preview/${integrationId}?email=${encodeURIComponent(email)}`);
	}

}
