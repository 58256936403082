export enum PiiSystemSchemaFiltersEnum {
    Frameworks = 'Frameworks',
    Violations = 'Violations',
    DataTypes = 'DataTypes', 
}

export enum PiiSystemSchemaColumnsEnum {
    DataType = 'dataType',
    MatchesFound = 'matches',
    Frameworks = 'frameworks',
}

export enum PiiSystemSchemaViolationOptions {
    NoViolation = 'NoViolation',
    ViolationFound = 'ViolationFound',
}