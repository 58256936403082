import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { RoutesManager } from "./shared/models/routes.interfaces";

// list of old routes that we need to redirect to a new rotes
const routes: Routes = [

	// data-mapping/aacounts old route --> redirect to /employees
	{
		path: `${RoutesManager.dataMapping}/${RoutesManager.dataMappingAccountsManagement}`,
		redirectTo: `/${RoutesManager.employees}`,
	},

	// data-mapping old routes --> redirect to /system
	{
		path: RoutesManager.dataMapping,
		redirectTo: `/${RoutesManager.systems}`,
	},
	{
		path: `${RoutesManager.dataMapping}/${RoutesManager.dataMappingDataSources}`,
		redirectTo: `/${RoutesManager.systems}`,
	},

	// discovery-tools old routes --> redirects to /discovery/...
	{
		path: `${RoutesManager.discoveryTools}/${RoutesManager.discoveryToolsEmail}/:provider`,
		redirectTo: `${RoutesManager.discovery}/${RoutesManager.discoveryToolsEmail}/:provider`,
	},
	{
		path: `${RoutesManager.discoveryTools}/${RoutesManager.discoveryToolsSSO}/:provider`,
		redirectTo: `${RoutesManager.discovery}/${RoutesManager.discoveryToolsSSO}/:provider`,
	},
	{
		path: RoutesManager.discoveryTools,
		redirectTo: `/${RoutesManager.discovery}`,
	},
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule],
})
export class OldRoutesRoutingModule {}
