export class CustomLodashHelper {
    
    static customPick<T>(object: any, fields: string[]): Partial<T> {
        const objCopy = {};
        for (let i of fields) {
            objCopy[i] = object[`${i}`];
        }
        return objCopy;
    }
}
