<p class="mine-title-m member-dialog__title">{{'profile.memberTitle' | content}}</p>

<form class="member-dialog" [formGroup]="addMemberFormGroup">

    <mine-input 
        class="member-dialog__invitee"
        formControlName="inviteeEmail"
        [placeholder]="'profile.memberEmailPlaceholder' | content" 
        [required]="true"
        [error]="addMemberFormGroup.get('inviteeEmail')?.errors?.required ? '' : addMemberFormGroup.get('inviteeEmail')?.errors?.error | content">
        <span mine-input-label>{{'profile.memberEmail' | content}}</span>
    </mine-input>

    <mine-dropdown 
        formControlName="department"
        class="member-dialog__dropdown" 
        [id]="'dropdownMemberDepartment'" [required]="true"
        [placeholder]="'profile.memberDepartmentPlaceholder' | content" 
        [height]="'193px'"
        [options]="options">
        <span mine-dropdown-label>{{'profile.memberDepartment' | content}}</span>
    </mine-dropdown>

    <div *ngIf="devExtendRoles; else oldRoles"  class="member-dialog__permission-role-container">
        <p class="member-dialog__permission-role-label">{{'profile.choosePermissionRole' | content}}</p>
        
        <fieldset *ngFor="let role of RoleEnum | keyvalue: originalOrder;" class="member-dialog__permission-role-fieldset">
            <mine-radio-button *ngIf="role.key !== RoleEnum.Unknown" 
                class="member-dialog__permission-role-btn" 
                [id]="role.value"
                name="permission-role" 
                formControlName="role"
                [value]="role.value">
                {{role.value | userRoleToText}}
                
                    <mine-info-icon class="member-dialog__permission-role-info"
                        [heliText]="role.value | userRoleToTooltip">
                    </mine-info-icon>
            </mine-radio-button>
        </fieldset>

    </div>
    
    <ng-template #oldRoles>
        <div class="member-dialog__permission-role-container">
            <p class="member-dialog__permission-role-label">{{'profile.choosePermissionRole' | content}}</p>
    
            <mine-radio-button 
                class="member-dialog__permission-role-btn" 
                id="permission-role-agent"
                name="permission-role" 
                formControlName="role"
                [value]="RoleEnum.Agent">
                {{'profile.agentPermissionRole' | content}}
            </mine-radio-button>
        
            <fieldset class="member-dialog__permission-role-fieldset" 
                [disabled]="disableReviewerRole">
                <mine-radio-button
                    class="member-dialog__permission-role-btn" 
                    id="permission-role-reviewer"
                    name="permission-role" 
                    formControlName="role"
                    [value]="RoleEnum.Reviewer"
                    
                    [tp]="'profile.reviewerPermissionRoleLockedTooltip' | content"
                    [tpIsEnabled]="disableReviewerRole"
                    [class.member-dialog__permission-role-btn--disabled]="disableReviewerRole">
                    {{'profile.reviewerPermissionRole' | content}}
        
                    <mine-info-icon class="member-dialog__permission-role-info"
                        [heliText]="disableReviewerRole ? '' : 'profile.reviewerPermissionRoleTooltip' | content">
                    </mine-info-icon>
                </mine-radio-button>
            </fieldset>
        </div>
    </ng-template>

    <div class="member-dialog__button-container">

        <mine-button-tertiary 
            (onClick)="cancelClicked()" 
            class="member-dialog__btn-cancel mine-button-primary-lg"
            [label]="'profile.memberBtn1Label' | content">
            <span>{{'profile.memberBtn1' | content}}</span>
        </mine-button-tertiary>

        <mine-button-primary 
            (onClick)="onInviteMember()" 
            class="mine-button-primary-lg"
            [text]="'profile.memberBtn2' | content"
            [disabled]="addMemberFormGroup.invalid"
            [spinner]="spinner">
        </mine-button-primary>
        
    </div>
    
</form>