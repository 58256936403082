<button
	id="btn-tertiary"
	(click)="onBtnClick()"
	class="button-tertiary"
	[ngStyle]="customStyle"
	[class.button-tertiary--disabled]="isDisabled"
	[class.button-tertiary--disabled-events]="isDisabled && !tooltip"
	[tp]="tooltip"
	[tpIsEnabled]="!!tooltip">

	<p #content>
		<ng-container *ngIf="spinner; else textTpl">
			<mine-spinner class="button-tertiary__spinner" 
				[width]="24" 
				[height]="24" 
				[revert]="true">
			</mine-spinner>
		</ng-container>

		<ng-template #textTpl>
			<ng-content></ng-content>
		</ng-template>
	</p>

</button>