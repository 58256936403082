export enum PiiProgress {
    NotStarted = "NotStarted",
    Processing = "Processing",
    Error = "Error",
    Done = "Done"
}

export enum ScanIntegrationMessage {
    LimitedScans = 'LimitedScans',
    StillProcessing = 'StillProcessing',
    NoDiscoverableIntegrations = 'NoDiscoverableIntegrations',
    Other = 'Other',
}

export enum ScanIntegrationType {
    Sample = 'Sample',
    SmartSample = 'SmartSample',
    Full = 'Full',
}

export enum ScanPiiThreshold {
    VeryUnlikely = 'VeryUnlikely',
    Unlikely = 'Unlikely',
    Possible = 'Possible',
    Likely = 'Likely',
    VeryLikely = 'VeryLikely'
}
