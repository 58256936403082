import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  standalone: true,
  name: 'displayNumber'
})
export class DisplayNumberPipe implements PipeTransform {

  transform(value: number): string | number {
    if (value === null) {
      return 'Unknown';
    }

    if (value < 1000) {
      return `${value}`;
    }

    const thousandsNumber = Math.floor(value / 1000); 
    const suffix = Math.floor((value % 1000) / 100);
    // example: 17500
    // thousandsNumber = 17
    // suffix = 5
    
    return `${thousandsNumber}${suffix > 0 ? `.${suffix}` : ''}K`;
  }

}
