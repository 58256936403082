import { SystemUsageRate } from './system-usage.enum';

export class SystemUsageHelper {

  static convertScoreToUsageEnum(score: number): SystemUsageRate {
    if (score === null) {
      return SystemUsageRate.Unknown;
    }

    if (score > 0.79) {
      return SystemUsageRate.High;
    } else if (score > 0.3 && score <= 0.79) {
      return SystemUsageRate.Medium;
    } else {
      return SystemUsageRate.Low;
    }  
  }

}
