import { Injectable } from '@angular/core';
import { HttpClientHelperService } from './http-client-helper.service';
import { ProfileResponse } from './models/profile/profile-response';
import { PlanLinkType } from './models/plans/plan-link-type';
import { Observable } from 'rxjs';
import { OnboardingTasks } from './models/profile/profile-onboarding-tasks.interface';

@Injectable({
  	providedIn: 'root'
})
export class ApiClientProfileService {

	private readonly controller = 'profileportal';

	constructor(private httpClientHelper: HttpClientHelperService) { }

	getInfo(): Observable<ProfileResponse> {
		return this.httpClientHelper.invokeGetAuth<ProfileResponse>(`${this.controller}/info`);
	}

	getPlanPurchaseLink(planLinkType: PlanLinkType): Observable<string> {
		return this.httpClientHelper.invokeGetAuth<string>(`${this.controller}/PlanPurchaseLink?plan=${planLinkType}`);
	}

	getPlanManagementPage(): Observable<string> {
		return this.httpClientHelper.invokeGetAuth<string>(`${this.controller}/PlanManagementsPage`);
	}

	getHubspotToken(): Observable<string> {
		return this.httpClientHelper.invokeGetAuth<string>(`${this.controller}/hubspotVisitorToken`);
	}

	updateOnboardingProgress(tasks: OnboardingTasks): Observable<void> {
		return this.httpClientHelper.invokePostAuth<void>(`${this.controller}/UpdateOnboardingProgress`, tasks);
	}

	// todo: remove when "my profile" is ready
	updateAgentName(firstName: string, lastName: string): Observable<void> {
		return this.httpClientHelper.invokePostAuth<void>(`${this.controller}/updateInfo`, { firstName, lastName });
	}

	updatePersonalProfile(firstName: string, lastName: string, department?: string): Observable<void> {
		return this.httpClientHelper.invokePostAuth<void>(`${this.controller}/updateInfo`, { firstName, lastName, department });
	}

}
