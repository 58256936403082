import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { Router, NavigationEnd, Event } from '@angular/router';
import { BehaviorSubject, Subject } from 'rxjs';
import { LoggerService } from '../logger/logger.service';

@Injectable({
	providedIn: 'root'
})
export class RouterEventsListenerService {
	private readonly loggerName: string = 'RouterEventsListenerService';
	private history: string[] = [];
	
	private url = new BehaviorSubject<string>('');
	url$ = this.url.asObservable();

	private previousUrl = new BehaviorSubject<string>('');
	previousUrl$ = this.previousUrl.asObservable();

	private event = new Subject<Event>();
	event$ = this.event.asObservable();

	constructor(private router: Router,
				private location: Location,
				private logger: LoggerService) { }

	startListening(): void {
		this.logger.debug(this.loggerName, 'startListening()');
		this.router.events.subscribe(event => {
			if (event instanceof NavigationEnd) {
				const last = this.url.getValue();
				this.previousUrl.next(last);
				this.history.push(last);
				this.url.next(event.urlAfterRedirects);
			}

			this.event.next(event);
		});
	}

	back(url: string = null): void {
		if (!!url) {
			this.router.navigateByUrl(url);
			return;
		}

		this.history.pop();

		if (this.history.length) {
			this.location.back();
		}
		else {
			this.router.navigate(['/']);
		}
	}
}
