import { Component, Output, EventEmitter } from '@angular/core';

@Component({
	standalone: true,
	selector: 'mine-x-button',
	templateUrl: './mine-x-button.component.html',
	styleUrls: ['./mine-x-button.component.scss']
})
export class MineXButtonComponent {

	@Output()
	onClick = new EventEmitter<void>();

	constructor() { }

	onClickBtn() {
		this.onClick.emit();
	}
}
