export enum AnimationStateEnum {
    BeforeCreation = 'void',
    Any = '*',
    Hide = 'hide',
    Show = 'show',
    Enter = ':enter',
    Leave = ':leave',
    EnterOrLeave = ':enter, :leave'
}

export enum AnimationSpeedEnum {
    ExtremeSlow = '2s',
    VerySlow = '1s',
    Slow = '.7s',
    Moderate = '.5s',
    Normal = '.3s',
    Fast = '.1s'
}