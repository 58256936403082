
import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { Observable } from 'rxjs';
import { Policy } from 'src/app/api/models/policies/policies.interface';
import { PoliciesState, PoliciesStore } from './policies.store';

@Injectable({
	providedIn: 'root',
})
export class PoliciesQuery extends QueryEntity<PoliciesState> {
  constructor(protected store: PoliciesStore) {
    super(store);
  }

  selectPoliciesWithSystem(systemId: string): Observable<Policy[]> {
    return this.selectAll({
      filterBy: ({ systems }) => systems.filter(s => s.id === systemId)?.length > 0
		});
  }
}