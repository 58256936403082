import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { Observable } from 'rxjs';
import { ProfileFeatureFlags } from 'src/app/api/models/profile/profile-feature-flags';
import { FeatureFlags, PublicFeatureFlags } from 'src/app/api/models/profile/profile-feature-flags.enum';
import { FeatureFlagsStore } from './feature-flag.store';
import { CustomLodashHelper } from 'src/app/shared/custom-lodash-helper';

@Injectable({ 
	providedIn: 'root' 
})
export class FeatureFlagQuery extends Query<ProfileFeatureFlags> {

	constructor(protected store: FeatureFlagsStore) {
		super(store);
	}

	//send as string to find nested flags
	getFlag(featureFlag: FeatureFlags | PublicFeatureFlags | string): boolean {
		return this.getValue()[`${featureFlag}`];
	}

	selectFlag(featureFlag: FeatureFlags | PublicFeatureFlags | string): Observable<boolean> {
		return this.select(item => item[`${featureFlag}`]);
	}

	selectMultipleFlags(featureFlags: (FeatureFlags | PublicFeatureFlags)[]): Observable<Partial<ProfileFeatureFlags>> {
		return this.select(item => CustomLodashHelper.customPick(this.getValue(), featureFlags));
	}

	getMultipleFlags(featureFlags: (FeatureFlags | PublicFeatureFlags)[]): Partial<ProfileFeatureFlags> {
		return CustomLodashHelper.customPick(this.getValue(), featureFlags);
	}
}