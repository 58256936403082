import { SystemInstance } from "src/app/systems/models/systems.interface";

export interface ImpactAssessmentChartSetting {
    position: ImpactAssessmentChartPositionEnum;
    highlight: ImpactAssessmentHighlight;
    backgroundColor: string;
    xAxisLabel: string;
    yAxisLabel: string;
    isHighRisk?: boolean;
}

export interface ImpactAssessmentHighlight {
    title: string;
    description?: string;
    backgroundColor: string;
    pimpleColor: string;
    count: number;
    order?: number;
}

export interface ImpactAssessmentSystemCoordinate {
    systems: SystemInstance[];
    x: number;
    y: number;
}

export interface ImpactAssessmentEmptyState {
    title: string;
    description: string;
    buttonText: string;
    assetRelativePath: string;
}

export interface ImpactAssessmentTableRow extends SystemInstance {
    usage: string;
    disabled: boolean;
    cyberPosture?: string;
    dataSensitivity?: string;
    chartSetting: ImpactAssessmentChartSetting;
}

export enum ImpactAssessmentTableHeaderEnum {
    System = 'system',
    Usage = 'usage',
    Employees = 'employees',
    EstimatedImpact = 'estimatedImpact',
    CyberPosture = 'cyberPosture',
    DataSensitivity = 'dataSensitivity'
}

export enum ImpactAssessmentChartPositionEnum {
    TOP_LEFT = 'top-left',
    TOP_RIGHT = 'top-right',
    BOTTOM_LEFT = 'bottom-left',
    BOTTOM_RIGHT = 'bottom-right',
}