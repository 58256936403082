import { AiAssessmentExportRequestData } from 'src/app/ai-assessments/models/ai-assessment-export.interface';
import { ContentSchema } from '../services/content/content-schema.interface';

export enum ExportTypeEnum {
	Assessment = 'assessment'
}

export interface PdfConversionRequest {
    exportType: ExportTypeEnum;
    data: PdfConversionRequestData;
    squidexContents: ContentSchema[];
}

export type PdfConversionRequestData = AiAssessmentExportRequestData;