export enum AuthProviders {
   google = 'google',
   googleWorkspace = 'googleWorkspace',
   microsoft = 'microsoft',
   auth0 = 'auth0',
   slack = 'slack',
   okta = 'okta',
   azure = 'entra',
   gcp = 'gcp',
   aws = 'aws',
   sso = 'sso',
   website = 'website',
  jira = 'jira',
}