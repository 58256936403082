import { Injectable } from '@angular/core';
import { LoggerService } from '../logger/logger.service';
import { ProfileQuery } from '../profile/state/profile.query';
import { ApiClientProfileService } from '../api/api-client-profile.service';
import { AuthService } from '../auth/auth.service';
import { Subscription, first } from 'rxjs';
import { FeatureFlagQuery } from '../feature-flag/state/feature-flag.query';
import { FeatureFlags } from '../api/models/profile/profile-feature-flags.enum';

declare global {
	interface Window {
      hsConversationsSettings: any;
      HubSpotConversations: any;
      hsConversationsOnReady: any;
    }
}

@Injectable({
	providedIn: 'root',
})
export class HubspotChatService {

  private readonly loggerName: string = 'HubspotChatService';
  private chatEnabled = false;
  private loginSubscription: Subscription = undefined;

  constructor(
    private logger: LoggerService,
    private profileQuery: ProfileQuery,
    private featureFlagQuery: FeatureFlagQuery,
    private profileClient: ApiClientProfileService,
    private authService: AuthService) { }

  public init() {

    this.chatEnabled = false;

    if(!this.featureFlagQuery.getFlag(FeatureFlags.PortalHubspotWidget)) {
      this.logger.debug(this.loggerName, 'Skipping hubspot chat initialization');
      return;
    }
    
    // If external API methods are already available, use them.
    if (window.HubSpotConversations)
    {
      this.onConversationsAPIReady();
    }
    else
    {
      /*
        Otherwise, callbacks can be added to the hsConversationsOnReady on the window object.
        These callbacks will be called once the external API has been initialized.
      */
      window.hsConversationsOnReady = [this.onConversationsAPIReady];
    }
  }

  private setConversationToken() {
    this.profileClient.getHubspotToken().pipe(first()).subscribe(token => this.loadUserConversationApi(token));
  }

  private onConversationsAPIReady() {
    this.logger.info(this.loggerName, `HubSpot Conversations API ready`);

    if(this.loginSubscription === undefined) {

      // Open chat on new messages
      window.HubSpotConversations.on('unreadConversationCountChanged', payload => {
        if(payload.unreadCount > 0) {
          this.show();
        }
      });
      
      // this is to make sure we subscribe only once
      this.loginSubscription = this.authService.isLoggedIn$.subscribe(isLoggedIn => {
        
        if(isLoggedIn === true) {
          // load the chat with an identified user
          this.setConversationToken();
        }
        else {
          // load the chat for a visitor
          this.loadLeadConversationApi();
        }
      });
    }
  }

  private loadUserConversationApi(token: string) {
    window.hsConversationsSettings = {
      identificationEmail: this.profileQuery.getValue().email,
      identificationToken: token
    };

    window.HubSpotConversations.widget.load();
    this.chatEnabled = true;
  }

  public loadLeadConversationApi() {
    window.HubSpotConversations.widget.load();
    this.chatEnabled = true;
  }

  public show() { 
    window.HubSpotConversations.widget.open();
  }

  public hide() {
    window.HubSpotConversations.widget.close();
  }

  public remove() {
    window.HubSpotConversations.widget.remove();
  }

  // This request is throttled to 1rps
  // not sure when to use this
  public refreshChatWidget() {
    if(this.chatEnabled === false) {
      return;
    }

    window.HubSpotConversations.widget.refresh();
  }
}
