import { AfterViewInit, ChangeDetectorRef, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { finalize, first, tap } from 'rxjs';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { CommonModule, NgOptimizedImage } from '@angular/common';

import { ContentPipe } from 'src/app/services/content/content.pipe';
import { RoleEnum } from 'src/app/core/models/permission-role.enum';
import { environment } from 'src/environments/environment';
import { RoutesManager } from '../models/routes.interfaces';
import { ContentPipeModule } from 'src/app/services/content/content-pipe.module';
import { PermissionsService } from 'src/app/auth/permissions.service';

@Component({
  standalone: true,
  selector: 'error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss'],
  imports: [CommonModule, RouterModule, ContentPipeModule, NgOptimizedImage],
})
export class ErrorPageComponent implements OnInit, AfterViewInit {

  @ViewChild('error401')
  pageNotAllowedTpl: TemplateRef<any>;
  
  @ViewChild('error404')
  pageNotFoundTpl: TemplateRef<any>;
  
  errorPageTpl: TemplateRef<any>;

  userHomePage: string;

  userHomePageText: string;
  
  assetsUrl = environment.assets.url;

  code = 404;

  constructor(
    private route: ActivatedRoute,
    private cdref: ChangeDetectorRef,
    private contentPipe: ContentPipe,
    private permissionsService: PermissionsService,
  ) { }

  ngOnInit(): void {
    this.code = this.route.snapshot.data['code'];

    if (this.code !== 401) {
      this.userHomePage = `/${RoutesManager.homePage}`;
      this.userHomePageText = this.contentPipe.transform(`common.errorPageNavigateHome`);
        
      this.permissionsService.selectIsAllowed([RoleEnum.Admin, RoleEnum.Agent]).pipe(
        first(),
        tap(isAllowed => this.userHomePage = isAllowed ? `/${RoutesManager.homePage}` : `/${RoutesManager.tasks}`),
        tap(isAllowed => this.userHomePageText = this.contentPipe.transform(isAllowed ? `common.errorPageNavigateHome` : `common.errorPageNavigateTasks`)),
        finalize(() => this.cdref.detectChanges())
      ).subscribe();
    }
  }

  ngAfterViewInit(): void {
    switch (this.code) {
      case 401:
        this.errorPageTpl = this.pageNotAllowedTpl;
        break;
      
      case 404:
      default:
        this.errorPageTpl = this.pageNotFoundTpl;
        break;
    }

    this.cdref.detectChanges();
  }
}
