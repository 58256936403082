import { PaginationResponse } from '@datorama/akita';
import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { EmployeeDepartmentsResponse, EmployeeInfo, EmployeesFilter, EmployeesSearchResult, EmployeesSystemAdditionalData, ManageEmployee, SearchEmployee } from './models/employees/employees.intrface';
import { Employee, EmployeeDataMapping, EmployeesListResponse } from './models/data-mapping/data-mapping.interface';
import { SystemEmployeesFilter } from '../systems/panels/employees-view-panel/employee.interface';
import { HttpClientHelperService } from './http-client-helper.service';
import { MineSort } from '../shared/mine-sort/mine-sort.interface';

@Injectable({
  providedIn: 'root'
})
export class ApiClientEmployeesService {
	readonly controller: string = 'employee';

	constructor(private httpClientHelper: HttpClientHelperService) { }

	getEmployeesDepartments(): Observable<EmployeeDepartmentsResponse> { 
		return this.httpClientHelper.invokeGetAuth<EmployeeDepartmentsResponse>(`${this.controller}/departments`);
	}

	inviteEmployee(employee: Employee): Observable<Employee> {
		return this.httpClientHelper.invokePostAuth<Employee>(`${this.controller}/add`, employee);
	}

	manageEmployee(employee: ManageEmployee): Observable<void> {
		return this.httpClientHelper.invokePostAuth<void>(`${this.controller}/Manage`, employee);
	}

	// get active employees (not archived) in pagination model
	getPaginatedEmployees(page: number, perPage: number, filterBy: EmployeesFilter, sortBy: MineSort): Observable<PaginationResponse<EmployeeDataMapping>> {
		const qParams = this.getEmployeesPaginationQueryParams(page, perPage, filterBy, sortBy);
		return this.httpClientHelper.invokeGetAuth<PaginationResponse<EmployeeDataMapping>>(`${this.controller}/listV2`, qParams);
	}

	// get unused employees (archived) in pagination model
	getPaginatedUnusedEmployees(page: number, perPage: number, filterBy: EmployeesFilter, sortBy: MineSort): Observable<PaginationResponse<EmployeeDataMapping>> {
		const qParams = this.getUnusedEmployeesPaginationQueryParams(page, perPage, filterBy, sortBy);
		return this.httpClientHelper.invokeGetAuth<PaginationResponse<EmployeeDataMapping>>(`${this.controller}/listV2`, qParams);
	}
	
	private getEmployeesPaginationQueryParams(page: number, perPage: number, filterBy: EmployeesFilter, sortBy: MineSort): HttpParams {
		let qParams = new HttpParams();
		qParams = qParams.appendAll({
			page,
			perPage,
			isArchived: false,
			searchTerm: filterBy.searchTerm,
			departments: filterBy.departments
		});
		if (filterBy.accountStatus?.length > 0) {
			filterBy.accountStatus.forEach(s => qParams = qParams.append('accountStatus', s));
		}
		if (sortBy) {
			qParams = qParams.appendAll({
				sortColumn: sortBy.active,
				sortDirection: sortBy.direction,
			});
		}

		return qParams;
	}

	private getUnusedEmployeesPaginationQueryParams(page: number, perPage: number, filterBy: EmployeesFilter, sortBy: MineSort): HttpParams {
		let qParams = new HttpParams();
		qParams = qParams.appendAll({
			page,
			perPage,
			isArchived: true,
			searchTerm: filterBy.searchTerm,
		});
		if (sortBy) {
			qParams = qParams.appendAll({
				sortColumn: sortBy.active,
				sortDirection: sortBy.direction,
			});
		}

		return qParams;
	}

    getEmployeesByIds(ids: string[]): Observable<EmployeesListResponse> {
        let qParams = new HttpParams();
        qParams = qParams.append('ids', ids.join(','));
        return this.httpClientHelper.invokeGetAuth<EmployeesListResponse>(`${this.controller}/byIds`, qParams);
    }

	searchEmployee(search: SearchEmployee): Observable<EmployeesSearchResult> {
		let qParams = new HttpParams();
		qParams = qParams.append('searchTerm', search.searchTerm || '');
		if (search.excludeSystemId) {
			qParams = qParams.append('excludeSystemId', search.excludeSystemId);
		}
		return this.httpClientHelper.invokeGetAuth<EmployeesSearchResult>(`${this.controller}/search`, qParams);
	}

	// get employees in system, paginated api call
	getPaginatedEmployeesBySystem(systemId: string, page: number, perPage: number, filterBy: SystemEmployeesFilter): Observable<PaginationResponse<EmployeeInfo>> {
		let qParams = new HttpParams();
		qParams = qParams.appendAll({
			page,
			perPage,
			isHidden: filterBy.isHidden,
			searchTerm: filterBy.searchTerm ?? '',
		});
		if (filterBy.accountStatus) {
			qParams = qParams.append('accountStatus', filterBy.accountStatus);
		}
		return this.httpClientHelper.invokeGetAuth<PaginationResponse<EmployeeInfo>>(`${this.controller}/${systemId}/systemEmployees`, qParams);
	}

	// get additional data of employees in system
	getEmployeesSystemAdditionalData(systemId: string): Observable<EmployeesSystemAdditionalData> {
		return this.httpClientHelper.invokeGetAuth<EmployeesSystemAdditionalData>(`${this.controller}/${systemId}/additionalData`);
	}
}
