import { Injectable } from "@angular/core";
import { Observable, tap } from "rxjs";

import { ApiClientSystemsService } from "src/app/api/api-client-systems.service";
import { SystemsIconsResponse, SystemsIconsV2Payload, SystemsIconsV2Response } from "src/app/api/models/systems/systems.interface";
import { LoggerService } from "src/app/logger/logger.service";
import { SystemsIconsStore } from "./systems-icons.store";
import { SystemIcon } from "../models/systems.interface";
import { SpecialSystemType } from "../models/custom-system-type.enum";

@Injectable({
	providedIn: 'root',
})
export class SystemsIconsService {
    private readonly loggerName: string = 'SystemsIconsService';

    private readonly MineCustomSystemType: string = SpecialSystemType.Custom;

    constructor(
        private apiClientSystems: ApiClientSystemsService, 
        private systemsIconsStore: SystemsIconsStore,
        private logger: LoggerService,
    ) { }

    getSystemsIcons(systemTypes: string[]): Observable<SystemsIconsResponse> {
        return this.apiClientSystems.getSystemsIcons(systemTypes).pipe(
            tap(res => this.updateIconsInStore(res)),
            tap(() => this.logger.info(this.loggerName, 'get systems icons from server'))
        );
    }

    getSystemsIconsV2(systems: SystemsIconsV2Payload): Observable<SystemsIconsV2Response> {
        return this.apiClientSystems.iconsV2(systems).pipe(
                tap(res => this.updateIconsV2InStore(res)),
                tap(() => this.logger.info(this.loggerName, 'got systems icons V2 from server'))
            );
    }

    private updateIconsInStore(res: SystemsIconsResponse): void {
        for (let [key, value] of Object.entries(res.icons)) {
            this.upsertToStore(key, key, value);
        }
    }

    private updateIconsV2InStore(res: SystemsIconsV2Response): void {
        // Insert systems from catalog
        for (let [key, value] of Object.entries(res.iconsByService)) {
            this.upsertToStore(key, key, value);
        }
        
        // Insert custom systems
        for (let [key, value] of Object.entries(res.customSystemIcons)) {
            this.upsertToStore(key, this.MineCustomSystemType, value);
        }
    }

    private upsertToStore(key: string, type: string, url: string): void {
        this.systemsIconsStore.upsert(key, {
            systemType: type,
            iconPath: url
        } as SystemIcon);
    }
}
