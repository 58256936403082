import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { FeatureFlags } from "../api/models/profile/profile-feature-flags.enum";
import { FeatureFlagGuardStrategy } from "./feature-flag-guard-strategy";
import { FeatureFlagQuery } from "./state/feature-flag.query";

@Injectable({
    providedIn: 'root'
  })
export class FeatureFlagValueStrategy implements FeatureFlagGuardStrategy{
    constructor(private featureFlagQuery: FeatureFlagQuery) {
        
    }

    canActivate(state: RouterStateSnapshot, next: ActivatedRouteSnapshot, featureFlagName: string | FeatureFlags): Observable<boolean> {
        return this.featureFlagQuery.selectFlag(featureFlagName);
    }
}