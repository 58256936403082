import { Injectable } from "@angular/core";
import { BehaviorSubject, combineLatest, first, map, Observable } from "rxjs";

import { ContentPipe } from "src/app/services/content/content.pipe";
import { SystemsQuery } from "../../systems/state/systems.query";
import { DataSensitivityEnum } from "src/app/vendor-management/models/data-sensitivity.enum";
import { VendorManagerService } from "src/app/vendor-management/services/vendor-manager.service";
import { ImpactAssessmentChartPositionEnum, ImpactAssessmentChartSetting, ImpactAssessmentHighlight, ImpactAssessmentSystemCoordinate, ImpactAssessmentTableHeaderEnum, ImpactAssessmentTableRow } from "../models/Impact-assessment.interface";
import { ImpactAssessmentTabsEnum } from "../models/impact-assessment-tabs.enum";
import { SystemInstance } from "src/app/systems/models/systems.interface";
import { ExtendedDataType, SystemUsage } from "src/app/api/models/systems/systems.interface";
import { VendorManagementQuery } from "src/app/vendor-management/vendor-managment.query";
import { DataTypeSource } from "src/app/api/models/systems/systems.enum";
import { RiskRatingEnum } from "src/app/vendor-management/models/risk-rating.enum";
import { SystemUsageEnum } from "src/app/vendor-management/models/usage.enum";
import { SortDirectionEnum } from "src/app/shared/sort-direction.enum";
import { MineSort } from "src/app/shared/mine-sort/mine-sort.interface";

@Injectable({
    providedIn: "root"
})
export class ImpactAssessmentService {

    private readonly systemCategories = this.contentPipe.transform('impact-assessment.systemCategories');
    private readonly systemUsageEnumContent = this.contentPipe.transform('vendor-management.systemUsageEnum')[0];

    private activeTab = new BehaviorSubject<ImpactAssessmentTabsEnum>
        (ImpactAssessmentTabsEnum.UsageVsEmployees);
    
    constructor(
        private contentPipe: ContentPipe,
        private systemsQuery: SystemsQuery,
        private vendorManager: VendorManagerService,
        private vendorManagementQuery: VendorManagementQuery
    ) { }

    selectLoading(): Observable<boolean> {
        return combineLatest([
            this.systemsQuery.selectLoading(),
            this.vendorManagementQuery.selectLoading()
        ]).pipe(
            map(loading => loading[0] || loading[1])
        );
    }

    getActiveTab(): ImpactAssessmentTabsEnum {
        return this.activeTab.getValue();
    }

    selectActiveTab(): Observable<ImpactAssessmentTabsEnum> {
        return this.activeTab.asObservable();
    }

    setActiveTab(tab: ImpactAssessmentTabsEnum): void {
        this.activeTab.next(tab);
    }

    getSubtitle(): string {
        return this.contentPipe.transform('impact-assessment.subtitle', {
            params: {
                systems: (this.systems?.length || 0) - (this.systemsMissingImpactInfo?.length || 0),
                systemsMissingImpactInfo: this.systemsMissingImpactInfo?.length || 0
            }
        });
    }

    getHighlights(): ImpactAssessmentHighlight[] {
        const highlightsMap = new Map<string, ImpactAssessmentHighlight>();
        let chartsSettings = this.getChartSettings();

        [...chartsSettings].map(chart => {
            const key = chart.backgroundColor;
            if (highlightsMap.has(key)) {
                highlightsMap.get(key).count += this.getSystemsCountPerChartPosition(chart.position);
            }
            else {
                highlightsMap.set(key, {
                    title: chart.highlight[0].title,
                    description: chart.highlight[0].description,
                    order: chart.highlight[0].order,
                    backgroundColor: chart.backgroundColor,
                    count: this.getSystemsCountPerChartPosition(chart.position)
                } as ImpactAssessmentHighlight);
            }
        });

        return Array.from(highlightsMap.values())
            .sort((a, b) => (a.order > b.order) ? 1 : -1);
    }

    //impactAssessmentEnum needed when showing risks not related to the bussiness impact tabs
    getChartSettings(impactAssessmentEnum?: ImpactAssessmentTabsEnum): ImpactAssessmentChartSetting[] {
        let charts = this.contentPipe.transform('impact-assessment.charts');
        
        switch (impactAssessmentEnum ?? this.activeTab.getValue() ) {
            case ImpactAssessmentTabsEnum.UsageVsEmployees:
            default:
                charts = this.contentPipe.transform('impact-assessment.charts');
                break;
            case ImpactAssessmentTabsEnum.EmployeesVsDataSensitivity:
                charts = this.contentPipe.transform('impact-assessment.employeesVsDataSensitivityCharts');
                break;
            case ImpactAssessmentTabsEnum.EmployeesVsCyberPosture:
                charts = this.contentPipe.transform('impact-assessment.employeesVsCyberPostureCharts');
                break;
            case ImpactAssessmentTabsEnum.CyberPostureVsDataSensitivity:
                charts = this.contentPipe.transform('impact-assessment.cyberPostureVsDataSensitivityCharts');
                break;
        }

        return [...charts].map(chart => {
            return {
                ...chart,
            }  as ImpactAssessmentChartSetting
        });
    }

    getSystemsCoordinates(): Map<string, SystemInstance[]> {
        let coordinates: ImpactAssessmentSystemCoordinate[] = [];
        if (this.hasMinimumDataSources) {
            for (let system of this.systems) {           
                coordinates.push({
                    systems: [system],
                    y: this.getYCoordinate(system),
                    x: this.getXCoordinate(system),
                } as ImpactAssessmentSystemCoordinate);
            }
        }
        
        return coordinates
            .filter(coordinate => !!coordinate.x && !!coordinate.y)
            .reduce(function (map, coordinate) {
                if (map.has(`${coordinate.x},${coordinate.y}`)) {
                    map.set(`${coordinate.x},${coordinate.y}`, map.get(`${coordinate.x},${coordinate.y}`).concat(coordinate.systems));
                }
                else {
                    map.set(`${coordinate.x},${coordinate.y}`, coordinate.systems);
                }
                return map;
            }, new Map<string, SystemInstance[]>());
    }

    getSystemChartSetting(system: SystemInstance, impactAssessmentEnum?: ImpactAssessmentTabsEnum): ImpactAssessmentChartSetting {
        let chart: ImpactAssessmentChartSetting;
        const xMapCoordinate = this.getXCoordinate(system, impactAssessmentEnum);
        const yMapCoordinate = this.getYCoordinate(system, impactAssessmentEnum);
        
        // TOP_LEFT
        if ((xMapCoordinate >= 1 && xMapCoordinate <= 3) && (yMapCoordinate > 3 && yMapCoordinate <= 5)) {
            return this.getChartSettings(impactAssessmentEnum).find(chart => chart.position === ImpactAssessmentChartPositionEnum.TOP_LEFT);
        }
        // TOP_RIGHT
        else if ((xMapCoordinate > 3 && xMapCoordinate <= 5) && (yMapCoordinate > 3 && yMapCoordinate <= 5)) {
            return this.getChartSettings(impactAssessmentEnum).find(chart => chart.position === ImpactAssessmentChartPositionEnum.TOP_RIGHT);
        }
        // BOTTOM_LEFT
        else if ((xMapCoordinate >= 1 && xMapCoordinate <= 3) && (yMapCoordinate >= 1 && yMapCoordinate <= 3)) {
            return this.getChartSettings(impactAssessmentEnum).find(chart => chart.position === ImpactAssessmentChartPositionEnum.BOTTOM_LEFT);
        }
        // BOTTOM_RIGHT
        else if ((xMapCoordinate > 3 && xMapCoordinate <= 5) && (yMapCoordinate >= 1 && yMapCoordinate <= 3)) {
            return this.getChartSettings(impactAssessmentEnum).find(chart => chart.position === ImpactAssessmentChartPositionEnum.BOTTOM_RIGHT);
        }

        return chart;
    }

    getHighRiskNum(systemInstance: SystemInstance): number {
        let highPotentialRiskCount = 0;
        Object.values(ImpactAssessmentTabsEnum).forEach(item => {
            const impactAssessmentData = this.getSystemChartSetting(systemInstance, item);
      
            if (impactAssessmentData?.isHighRisk) {
              highPotentialRiskCount++;
            }
    });
    return highPotentialRiskCount
}

    private getSystemsCountPerChartPosition(position: ImpactAssessmentChartPositionEnum): number {
        const coordinates = this.getSystemsCoordinates();
        let count: number = 0;

        for (let coordinate of coordinates.keys()) {
            const mapCoordinate = coordinate.split(',');
            const xMapCoordinate = +mapCoordinate[0];
            const yMapCoordinate = +mapCoordinate[1];

            switch (position) {
                case ImpactAssessmentChartPositionEnum.TOP_LEFT:
                    if (xMapCoordinate >= 1 && xMapCoordinate <= 3 && yMapCoordinate > 3 && yMapCoordinate <= 5) {
                        count += coordinates.get(coordinate)?.length;
                    }
                    break;
                
                case ImpactAssessmentChartPositionEnum.TOP_RIGHT:
                    if (xMapCoordinate > 3 && xMapCoordinate <= 5 && yMapCoordinate > 3 && yMapCoordinate <= 5) {
                        count += coordinates.get(coordinate)?.length;
                    }
                    break;
                
                case ImpactAssessmentChartPositionEnum.BOTTOM_LEFT:
                    if (xMapCoordinate >= 1 && xMapCoordinate <= 3 && yMapCoordinate >= 1 && yMapCoordinate <= 3) {
                        count += coordinates.get(coordinate)?.length;
                    }
                    break;
                
                case ImpactAssessmentChartPositionEnum.BOTTOM_RIGHT:
                    if (xMapCoordinate > 3 && xMapCoordinate <= 5 && yMapCoordinate >= 1 && yMapCoordinate <= 3) {
                        count += coordinates.get(coordinate)?.length;
                    }
                    break;
                
                default: return 0;
            }
        }

        return count;
    }

    private getXCoordinate(system: SystemInstance, impactAssessmentEnum?: ImpactAssessmentTabsEnum): number {
        switch (impactAssessmentEnum ?? this.activeTab.getValue() ) {
            case ImpactAssessmentTabsEnum.UsageVsEmployees:
            default:
                const systemUsage: SystemUsage = system.systemUsage;
                if (!!systemUsage?.manuallySet && !!systemUsage.score) {
                    return this.getSystemUsageCalculatedScore(systemUsage.score);
                }
                else if (!!systemUsage?.discoveryScore) {
                    return this.getSystemUsageCalculatedScore(systemUsage.discoveryScore);
                }
                return 0;
            
            case ImpactAssessmentTabsEnum.EmployeesVsCyberPosture:
            case ImpactAssessmentTabsEnum.EmployeesVsDataSensitivity:
                if (!!this.excessiveSystemConnectedEmployees) {
                    if (system.connectedAccounts > 0 && system.connectedAccounts <= ((this.excessiveSystemConnectedEmployees / 5) * 1)) return 1;
                    if (system.connectedAccounts > ((this.excessiveSystemConnectedEmployees / 5) * 1) && system.connectedAccounts <= ((this.excessiveSystemConnectedEmployees / 5) * 2)) return 2;
                    if (system.connectedAccounts > ((this.excessiveSystemConnectedEmployees / 5) * 2) && system.connectedAccounts <= ((this.excessiveSystemConnectedEmployees / 5) * 3)) return 3;
                    if (system.connectedAccounts > ((this.excessiveSystemConnectedEmployees / 5) * 3) && system.connectedAccounts <= ((this.excessiveSystemConnectedEmployees / 5) * 4)) return 4;
                    if (system.connectedAccounts > ((this.excessiveSystemConnectedEmployees / 5) * 4) && system.connectedAccounts <= ((this.excessiveSystemConnectedEmployees / 5) * 5)) return 5;
                    if (system.connectedAccounts > this.excessiveSystemConnectedEmployees) return 5;
                }
                return 0;
            
            case ImpactAssessmentTabsEnum.CyberPostureVsDataSensitivity:
                return this.getSystemCategoriesSensitivity(system.extendedDataTypes);
        }
    }

    private getYCoordinate(system: SystemInstance, impactAssessmentEnum?: ImpactAssessmentTabsEnum): number {
        switch (impactAssessmentEnum ?? this.activeTab.getValue() ) {
            case ImpactAssessmentTabsEnum.UsageVsEmployees:
            default:
                if (!!this.excessiveSystemConnectedEmployees) {
                    if (system.connectedAccounts > 0 && system.connectedAccounts <= ((this.excessiveSystemConnectedEmployees / 5) * 1)) return 1;
                    if (system.connectedAccounts > ((this.excessiveSystemConnectedEmployees / 5) * 1) && system.connectedAccounts <= ((this.excessiveSystemConnectedEmployees / 5) * 2)) return 2;
                    if (system.connectedAccounts > ((this.excessiveSystemConnectedEmployees / 5) * 2) && system.connectedAccounts <= ((this.excessiveSystemConnectedEmployees / 5) * 3)) return 3;
                    if (system.connectedAccounts > ((this.excessiveSystemConnectedEmployees / 5) * 3) && system.connectedAccounts <= ((this.excessiveSystemConnectedEmployees / 5) * 4)) return 4;
                    if (system.connectedAccounts > ((this.excessiveSystemConnectedEmployees / 5) * 4) && system.connectedAccounts <= ((this.excessiveSystemConnectedEmployees / 5) * 5)) return 5;
                    if (system.connectedAccounts > this.excessiveSystemConnectedEmployees) return 5;
                }
                return 0;
            
            case ImpactAssessmentTabsEnum.EmployeesVsCyberPosture:
            case ImpactAssessmentTabsEnum.CyberPostureVsDataSensitivity:
                const risk = this.vendorManagementQuery.getEntity(system.systemId);
                if (!!risk) {
                    if (+risk.riskRating <= 20) return 1;
                    if (+risk.riskRating <= 40) return 2;
                    if (+risk.riskRating <= 60) return 3;
                    if (+risk.riskRating <= 80) return 4;
                    if (+risk.riskRating <= 100) return 5;
                }
                return 0;
            
            case ImpactAssessmentTabsEnum.EmployeesVsDataSensitivity:
                return this.getSystemCategoriesSensitivity(system.extendedDataTypes);
        }
    }

    private getSystemUsageCalculatedScore(score: number): number {
        if (score === 0) return 0;
        if (score <= 0.2) return 1;
        if (score <= 0.4) return 2;
        if (score <= 0.6) return 3;
        if (score <= 0.8) return 4;
        if (score > 0.8) return 5;
        return 0;
    }

    private getDataSensitivityValue(score: number): DataSensitivityEnum {
        if (score === 0 || !score) {
            return;
        }
        else if (score === 1) {
            return DataSensitivityEnum.VeryLow;
        }
        else if (score === 2) {
            return DataSensitivityEnum.Low;
        }
        else if (score === 3) {
            return DataSensitivityEnum.Medium;
        }
        else if (score === 4) {
            return DataSensitivityEnum.High;
        }
        else if (score === 5) {
            return DataSensitivityEnum.VeryHigh;
        }
    }

    getSystemCategoriesSensitivity(dataTypes: ExtendedDataType[]): number {
        const sensitivities = dataTypes.filter(res => res.source === DataTypeSource.Manual)
            .map(category => this.systemCategories.find(sc => sc.category === category.id)?.sensitivity).filter(res => !!res);

        return sensitivities?.length ? Math.max(...sensitivities) : 0;
    }
    
    getSystemCyberPosture(system: SystemInstance): string {
        const rating = this.vendorManagementQuery.getEntity(system.systemId)?.riskRating;
        return rating ? this.vendorManager.getCyberPosture(+rating): undefined;
    }

    getSystemDataSensitivity(dataTypes: ExtendedDataType[]): DataSensitivityEnum {
        const sensitivity = this.getSystemCategoriesSensitivity(dataTypes);
        return sensitivity ? this.getDataSensitivityValue(sensitivity) : undefined;
    }

    getSystemUsageScore(system: SystemInstance): string {
        const score = system.systemUsage.manuallySet ? system.systemUsage.score : system.systemUsage.discoveryScore;
        const scoreValue = this.vendorManager.getScoreDropdownValue(score);
        return scoreValue?.value ?? undefined;
    }

    selectSortedSystems(sort: MineSort): Observable<ImpactAssessmentTableRow[]> {
        return this.systemsQuery.selectAll()
        .pipe(
            first(),
            map(systems => systems.map(system => {
                return {
                    ...system,
                    disabled: this.isSystemDisabled(system),
                    usage: this.getSystemUsageScore(system),
                    chartSetting: this.getSystemChartSetting(system),
                    cyberPosture: this.getSystemCyberPosture(system),
                    dataSensitivity: this.getSystemDataSensitivity(system.extendedDataTypes)
                } as ImpactAssessmentTableRow
            })),
            map(systems => this.sortSystems(systems, sort))
        );
    }

    private sortSystems(systems: ImpactAssessmentTableRow[], sort: MineSort): ImpactAssessmentTableRow[] {
        const riskRatingSortedArray = [RiskRatingEnum.Bad, RiskRatingEnum.Poor, RiskRatingEnum.Fair, RiskRatingEnum.Good, RiskRatingEnum.Excellent];
        const systemUsageSortedArray = [SystemUsageEnum.VeryLow, SystemUsageEnum.Low, SystemUsageEnum.Medium, SystemUsageEnum.High, SystemUsageEnum.Critical];
        const dataSensitivitySortedArray = [DataSensitivityEnum.VeryLow, DataSensitivityEnum.Low, DataSensitivityEnum.Medium, DataSensitivityEnum.High, DataSensitivityEnum.VeryHigh];
    
        switch (sort.active) {
            case ImpactAssessmentTableHeaderEnum.CyberPosture:
              return systems.sort((a, b) => {
                const aSortOrder = riskRatingSortedArray.findIndex(riskRatingEnum => riskRatingEnum === a.cyberPosture);
                const bSortOrder = riskRatingSortedArray.findIndex(riskRatingEnum => riskRatingEnum === b.cyberPosture);
      
                return sort.direction === SortDirectionEnum.Asc ? 
                  aSortOrder - bSortOrder : bSortOrder - aSortOrder;
              });
              
            case ImpactAssessmentTableHeaderEnum.DataSensitivity:
              return systems.sort((a, b) => {
                const aSortOrder = dataSensitivitySortedArray.findIndex(dataSensitivityEnum => dataSensitivityEnum === a.dataSensitivity);
                const bSortOrder = dataSensitivitySortedArray.findIndex(dataSensitivityEnum => dataSensitivityEnum === b.dataSensitivity);
      
                return sort.direction === SortDirectionEnum.Asc ? 
                  aSortOrder - bSortOrder : bSortOrder - aSortOrder;
              });
      
            case ImpactAssessmentTableHeaderEnum.Employees:
              return systems.sort((a, b) => {
                return sort.direction === SortDirectionEnum.Asc ? 
                  a.connectedAccounts ? a.connectedAccounts - b.connectedAccounts : -1 :
                  b.connectedAccounts ? b.connectedAccounts - a.connectedAccounts : -1;
              });
      
            case ImpactAssessmentTableHeaderEnum.EstimatedImpact:
                return systems.sort((a, b) => {
                    const aSortOrder = a.chartSetting?.highlight[0]?.order ? a.chartSetting.highlight[0].order : 999;
                    const bSortOrder = b.chartSetting?.highlight[0]?.order ? b.chartSetting.highlight[0].order : 999;

                    return sort.direction === SortDirectionEnum.Asc ? 
                        bSortOrder - aSortOrder : aSortOrder - bSortOrder;
                });
      
            case ImpactAssessmentTableHeaderEnum.System:
              return systems.sort((a, b) => {
                return sort.direction === SortDirectionEnum.Asc ? 
                  a.name?.localeCompare(b.name) :
                  b.name?.localeCompare(a.name);
              });
      
            case ImpactAssessmentTableHeaderEnum.Usage:        
              return systems.sort((a, b) => {
                const aSortOrder = systemUsageSortedArray.findIndex(usageEnum => this.systemUsageEnumContent[usageEnum] === a.usage);
                const bSortOrder = systemUsageSortedArray.findIndex(usageEnum => this.systemUsageEnumContent[usageEnum] === b.usage);
      
                return sort.direction === SortDirectionEnum.Asc ? 
                  aSortOrder - bSortOrder : bSortOrder - aSortOrder;
              });
      
            default:
              return systems;
          }
    }

    private isSystemDisabled(system: SystemInstance): boolean {
        switch (this.getActiveTab()) {
            default:
            case ImpactAssessmentTabsEnum.UsageVsEmployees:
                return (!system.systemUsage?.discoveryScore && !system.systemUsage?.score) || !system.connectedAccounts;
            case ImpactAssessmentTabsEnum.EmployeesVsCyberPosture:
                return !this.getSystemCyberPosture(system) || !system.connectedAccounts;
            case ImpactAssessmentTabsEnum.CyberPostureVsDataSensitivity:
                return !this.getSystemCyberPosture(system) || !system.extendedDataTypes?.length;
            case ImpactAssessmentTabsEnum.EmployeesVsDataSensitivity:
                return !system.connectedAccounts || !system.extendedDataTypes?.length;
        }
    }

    get systems(): SystemInstance[] {
        return this.systemsQuery.getAll().filter(system => !system.isArchived);
    }

    get hasMinimumDataSources(): boolean {
        return this.systems.length > +this.contentPipe.transform('impact-assessment.emptyDataSourcesThreshold');
    }

    get hasSystemImpactInfo(): boolean {
        return this.systems.length !== this.systemsMissingImpactInfo.length;
    }

    get systemsWithImpactInfo(): SystemInstance[] {
        switch (this.getActiveTab()) {
            default:
            case ImpactAssessmentTabsEnum.UsageVsEmployees:
                return this.systems.filter(system => (!!system.systemUsage?.discoveryScore || !!system.systemUsage?.score) && !!system.connectedAccounts);
            case ImpactAssessmentTabsEnum.EmployeesVsCyberPosture:
                return this.systems.filter(system => !!this.getSystemCyberPosture(system) && !!system.connectedAccounts);
            case ImpactAssessmentTabsEnum.CyberPostureVsDataSensitivity:
                return this.systems.filter(system => !!this.getSystemCyberPosture(system) && !!system.extendedDataTypes?.length);
            case ImpactAssessmentTabsEnum.EmployeesVsDataSensitivity:
                return this.systems.filter(system => !!system.connectedAccounts && !!system.extendedDataTypes?.length);
        }
    }

    get systemsMissingImpactInfo(): SystemInstance[] {
        switch (this.getActiveTab()) {
            default:
            case ImpactAssessmentTabsEnum.UsageVsEmployees:
                return this.systems.filter(system => (!system.systemUsage?.discoveryScore && !system.systemUsage?.score) || !system.connectedAccounts);
            case ImpactAssessmentTabsEnum.EmployeesVsCyberPosture:
                return this.systems.filter(system => !this.getSystemCyberPosture(system) || !system.connectedAccounts);
            case ImpactAssessmentTabsEnum.CyberPostureVsDataSensitivity:
                return this.systems.filter(system => !this.getSystemCyberPosture(system) || !system.extendedDataTypes?.length);
            case ImpactAssessmentTabsEnum.EmployeesVsDataSensitivity:
                return this.systems.filter(system => !system.connectedAccounts || !system.extendedDataTypes?.length);
        }
    }

    get excessiveSystemConnectedEmployees(): number {
        return +this.contentPipe.transform('impact-assessment.excessiveSystemConnectedEmployees');
    }
}