import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

import { AuthService } from 'src/app/auth/auth.service';
import { ProfileQuery } from 'src/app/profile/state/profile.query';
import { RoutesManager } from 'src/app/shared/models/routes.interfaces';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-header-website',
	templateUrl: './app-header-website.component.html',
	styleUrls: ['./app-header-website.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppHeaderWebsiteComponent implements OnInit {
	
	assetsUrl = environment.assets.url;

	onboardingCompleted: boolean;

	loggedIn$: Observable<boolean>;

	constructor(
		private router: Router,
		private authService: AuthService,
		private profileQuery: ProfileQuery
	) { }

	onClick() {
		if (!this.onboardingCompleted) {
			window.location.href = environment.routing.mineForBusiness;
		}
		else {
			this.router.navigate([RoutesManager.homePage]);
		}
	}

	ngOnInit() {
		this.loggedIn$ = this.authService.isLoggedIn$;
		this.onboardingCompleted =
			this.authService.isAuthenticated() &&
			this.profileQuery.getValue().verifiedToCompany;
	}
}