import { RoutesManager } from "../shared/models/routes.interfaces";
import { RoleEnum } from "../core/models/permission-role.enum";
import { MenuItemId } from "../core/app-sidenav-content/menu-item.enum";

export class RolePermissionsHelper {

    static getPerrmitedRoleByRoute(route: string[]): RoleEnum[] {
        switch (route[0]) {
            case RoutesManager.homePage:
            case RoutesManager.dpoAdvisor:
            case RoutesManager.plans:
                return [RoleEnum.Admin, RoleEnum.Agent, RoleEnum.DsrManager, RoleEnum.InventoryManager];
        
            case RoutesManager.dashboard:
                return this.getDashboardRoles(route[1]);
                
            case RoutesManager.tasks:
            case RoutesManager.general:
                return [RoleEnum.Admin, RoleEnum.Agent, RoleEnum.DsrManager, RoleEnum.InventoryManager, RoleEnum.Reviewer];
                
            case RoutesManager.companySettings: 
                return this.getSettingsRoles(route[1]);

            case RoutesManager.policies:
            case RoutesManager.consentManagement:
                return [RoleEnum.Admin, RoleEnum.Agent];

            case RoutesManager.unusedAssets:
            case RoutesManager.emailForwarding:
            case RoutesManager.requests:
            case RoutesManager.automations:
            case RoutesManager.userSearch:
            case RoutesManager.dashboardRequestHandling:
                return [RoleEnum.Admin, RoleEnum.Agent, RoleEnum.DsrManager];

            case RoutesManager.privacyCenter:
            case RoutesManager.dsr_setup:
                return [RoleEnum.Admin, RoleEnum.Agent];

            case RoutesManager.systems:
            case RoutesManager.radar:
            case RoutesManager.discovery:
            case RoutesManager.employees:
            case RoutesManager.dataClassifier:
            case RoutesManager.processingActivities:
            case RoutesManager.vendorManagement:
            case RoutesManager.impactAssessment:
            case RoutesManager.dashboardDataClassification:
            case RoutesManager.dashboardDataInventory:
                return [RoleEnum.Admin, RoleEnum.Agent, RoleEnum.InventoryManager]

            default:
                return [RoleEnum.Admin, RoleEnum.Agent, RoleEnum.DsrManager, RoleEnum.InventoryManager, RoleEnum.Reviewer];
        
        }
    }
    
    private static getDashboardRoles(route: RoutesManager): RoleEnum[] {
        switch (route) {
            case RoutesManager.dashboardRequestHandling:
                return [RoleEnum.Admin, RoleEnum.Agent, RoleEnum.DsrManager];
            case RoutesManager.dashboardDataInventory:
            case RoutesManager.dashboardDataClassification:
                return [RoleEnum.Admin, RoleEnum.Agent, RoleEnum.InventoryManager];
            default:
                return [RoleEnum.Admin, RoleEnum.Agent, RoleEnum.DsrManager, RoleEnum.InventoryManager];
        }
    }

    private static getSettingsRoles(route: RoutesManager): RoleEnum[] {
        switch (route) {
            case RoutesManager.profile:
                return [RoleEnum.Admin, RoleEnum.Agent, RoleEnum.DsrManager, RoleEnum.InventoryManager, RoleEnum.Reviewer];

            case RoutesManager.branding:
                return [RoleEnum.Admin, RoleEnum.Agent, RoleEnum.DsrManager];

            default:
                return [RoleEnum.Admin, RoleEnum.Agent];
        }
    }

    static getPerrmitedRolesByMenuId(id: MenuItemId): RoleEnum[] {
        switch (id) {
            case MenuItemId.DataInventory:
            case MenuItemId.Compliance:
                return [RoleEnum.Admin, RoleEnum.Agent, RoleEnum.InventoryManager];

            case MenuItemId.WhatsNew:
                return [RoleEnum.Admin, RoleEnum.Agent, RoleEnum.DsrManager, RoleEnum.InventoryManager];

            case MenuItemId.DsrHandling:
                return [RoleEnum.Admin, RoleEnum.Agent, RoleEnum.DsrManager];

            default:
                return [RoleEnum.Admin, RoleEnum.Agent, RoleEnum.DsrManager, RoleEnum.InventoryManager, RoleEnum.Reviewer];
        }
    }
}