import { DestroyRef, Injectable } from '@angular/core';
import { LoggerService } from '../logger/logger.service';
import { ProfileQuery } from '../profile/state/profile.query';
import { UserPropertiesStore } from './user-properties.store';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Injectable({
	providedIn: 'root'
})
export class UserPropertiesService {
    private readonly loggerName = 'UserPropertiesService';

    constructor(
        private destroyRef: DestroyRef,
        private logger: LoggerService,
        private profileQuery: ProfileQuery,
        private userPropertiesStore: UserPropertiesStore
    ) { }

    public init(): void {
        this.profileQuery.select().pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(profile => {
                this.logger.debug(this.loggerName, `update user profile store - ${profile.email}`);
                this.userPropertiesStore.update({
                    Email: profile.email,
                    Id: profile.userId,
                    FirstName: profile.firstName,
                    LastName: profile.lastName,
                    CompanyId: profile.companyId,
                    CompanyName: profile.companyName,
                    PlanName: profile.planDetails?.plan,
                    PlanLastModifiedAt: profile.planDetails?.lastModifiedAt,
                    IsVerified: profile.verifiedToCompany,
                    IdentityProvider: profile.identityProvider,
                    ConnectedToMineStatus: profile.connectedToMineStatus
                });
            });
    }
}