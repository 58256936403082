import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml, SafeStyle, SafeScript, SafeUrl, SafeResourceUrl } from '@angular/platform-browser';

export enum SafeResourceTypeEnum {
    Url = 'url',
    Html = 'html',
    Style = 'style',
    Script = 'script',
    ResourceUrl = 'resourceUrl',
}

/**
 * Sanitize HTML
 */
@Pipe({
    standalone: true,
    name: 'safe'
})
export class SafePipe implements PipeTransform {
    /**
     * Pipe Constructor
     *
     * @param _sanitizer: DomSanitezer
     */
    // tslint:disable-next-line
    constructor (protected _sanitizer: DomSanitizer) {
    }

    /**
     * Transform
     *
     * @param value: string
     * @param type: SafeResourceTypeEnum
     */
    transform (value: string, type: SafeResourceTypeEnum = SafeResourceTypeEnum.Html): SafeHtml | SafeStyle | SafeScript | SafeUrl | SafeResourceUrl {
        switch (type) {
            case SafeResourceTypeEnum.Html:
                return this._sanitizer.bypassSecurityTrustHtml(value);
            case SafeResourceTypeEnum.Style:
                return this._sanitizer.bypassSecurityTrustStyle(value);
            case SafeResourceTypeEnum.Script:
                return this._sanitizer.bypassSecurityTrustScript(value);
            case SafeResourceTypeEnum.Url:
                return this._sanitizer.bypassSecurityTrustUrl(value);
            case SafeResourceTypeEnum.ResourceUrl:
                return this._sanitizer.bypassSecurityTrustResourceUrl(value);
        }
    }
}