export enum FeedbackStateEnum { 
    None = 'None', 
    Pending = 'Pending', 
    Resolved = 'Resolved',
    Responded = 'Responded',
    Canceled = 'Canceled',
}

export enum FeedbackTypeEnum { 
    Datatypes = 'DataTypes', 
    ProcessingActivities = 'ProcessingActivities', 
    System = 'System',
}

export enum FeedbackDecision { 
    Yes = 'Yes', 
    No = 'No', 
    Unsure = 'Unsure'
}