import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { EMPTY, Observable, catchError, first, tap } from 'rxjs';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { DialogRef } from '@ngneat/dialog';

import { LoggerService } from 'src/app/logger/logger.service';
import { CountriesHelper } from 'src/app/requests/request-form/countries-helper';
import { RequestsService } from 'src/app/requests/state/requests.service';
import { ContentPipe } from 'src/app/services/content/content.pipe';
import { DropdownOption } from 'src/app/shared/mine-dropdown/mine-dropdown.interface';
import { MineSnackbarType } from 'src/app/shared/mine-snackbar/mine-snackbar-type';
import { MineSnackbarService } from 'src/app/shared/mine-snackbar/mine-snackbar.service';
import { CommonModule } from '@angular/common';
import { ContentPipeModule } from 'src/app/services/content/content-pipe.module';
import { MineDropdownComponent } from 'src/app/shared/mine-dropdown/mine-dropdown.component';
import { MineButtonPrimaryComponent } from 'src/app/shared/mine-button-primary/mine-button-primary.component';
import { MineButtonTertiaryComponent } from 'src/app/shared/mine-button-tertiary/mine-button-tertiary.component';
import { LocationCodesToTextPipe } from 'src/app/shared/pipes/location-codes-to-text.pipe';
import { RegionsService } from 'src/app/services/regions/regions.service';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    ContentPipeModule,
    MineDropdownComponent,
    MineButtonPrimaryComponent,
    MineButtonTertiaryComponent,
    ReactiveFormsModule,
    LocationCodesToTextPipe
  ],
  providers: [LocationCodesToTextPipe],
  selector: 'edit-country-dialog',
  templateUrl: './edit-country-dialog.component.html',
  styleUrls: ['./edit-country-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditCountryDialogComponent implements OnInit {
  private readonly loggerName = 'EditCountryDialogComponent';

  countryCtrl: FormControl<DropdownOption>;

  dropdownOptions: DropdownOption[];

  constructor(
    private dialogRef: DialogRef,
    private snackbarService: MineSnackbarService,
    private contentPipe: ContentPipe,
    private loggerService: LoggerService,
    private requestsService: RequestsService,
    private locationCodesToText: LocationCodesToTextPipe,
    private regionsService: RegionsService,
  ) {}

  ngOnInit(): void {
    this.dropdownOptions = this.generateOptions(this.regionsService.getContinentsMap());
    const countryCode = this.dialogRef.data.countryCode;
    this.countryCtrl = new FormControl<DropdownOption>(countryCode ? { id: countryCode, value: CountriesHelper.getCountryNameByCode(countryCode) } : null);
  }

  private generateOptions(optionMap: Map<string, string[]>): DropdownOption[] {
		let options: DropdownOption[] = [];
		
		for (const [key, value] of optionMap) {
			const mappedOptions = value.map(country => {
				return {
				id: country,
				value: this.locationCodesToText.transform(`${key}:${country}`)
			} as DropdownOption});
			
			options = [...options, ...mappedOptions];
		}
		return options.sort((a, b) => a.value.localeCompare(b.value));
	}
  
  cancel(): void {
    this.dialogRef.close();
  }

  save(): void {
    this.requestsService.updateTicketDetails(this.dialogRef.data.id, { countryCode: this.countryCtrl.value.id }).pipe(
      first(),
      tap(() => this.snackbarService.showTimed(MineSnackbarType.Confirmation, this.contentPipe.transform('ticket.editRequestSuccess'))),
      tap(() => this.dialogRef.close(this.countryCtrl.value.id)),
      catchError(() => this.updateTicketDetailsFailed()),
    ).subscribe();
  }

  private updateTicketDetailsFailed(): Observable<never> {
    this.snackbarService.showTimed(MineSnackbarType.Error, this.contentPipe.transform('ticket.editRequestFailed'));
    this.loggerService.error(this.loggerName, `Failed to update country for ticket: ${this.dialogRef.data.id}`);
    return EMPTY;
  }
}
