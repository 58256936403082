import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClientHelperService } from './http-client-helper.service';
import { SearchEvidenceResponse } from './models/search-evidence/search-evidence.interface';

@Injectable({
	providedIn: 'root'
})
export class ApiClientSearchEvidenceService {

	private readonly controller = 'EvidenceSearch';

	constructor(private httpClientHelper: HttpClientHelperService) { }

	getEvidenceByRequest(actionId: string): Observable<SearchEvidenceResponse> {
		return this.httpClientHelper.invokeGetAuth<SearchEvidenceResponse>(`${this.controller}/Request/${actionId}`);
	}
}
