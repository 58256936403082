import { Pipe, PipeTransform } from '@angular/core';
import { ContentPipe } from 'src/app/services/content/content.pipe';
import { CompanySettingsQuery } from 'src/app/company-settings/state/company-settings.query';
import { FeatureFlagQuery } from 'src/app/feature-flag/state/feature-flag.query';
import { FeatureFlags } from 'src/app/api/models/profile/profile-feature-flags.enum';

@Pipe({
    standalone: true,
	name: 'dataSubjectIdToName',
})
export class DataSubjectIdToNamePipe implements PipeTransform {
    constructor(
        private contentPipe: ContentPipe,
        private featureFlagQuery: FeatureFlagQuery,
        private companySettingsQuery: CompanySettingsQuery,
    ) {}

    transform(id: string | string[]): string {
        return Array.isArray(id) ? this.transformIdsArray(id) : this.transformId(id);
    }

    private transformId(id: string): string {
        if (!this.featureFlagQuery.getFlag(FeatureFlags.DevCustomDataSubject)) {
            return id;
        }
        return this.getDataSubjectNameById(id);
    }

    private transformIdsArray(ids: string[]): string {
        if (!this.featureFlagQuery.getFlag(FeatureFlags.DevCustomDataSubject)) {
            return ids.join(', ');
        }
        return ids.map(id => this.getDataSubjectNameById(id)).join(', ');
    }

    private getDataSubjectNameById(id: string): string {
        const customDataSubjects = this.companySettingsQuery.getCustomDataSubjects();

        const name = customDataSubjects.find(m => m.id === id)?.name 
            || this.contentPipe.transform('ropa.dataSubjectCategoriesList').find(m => m.key === id)?.value 
            || id;

        return name?.trim();
    }
}