import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter, HostBinding } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TippyModule } from '../tippy.module';
import { ChipColorName } from './mine-chip.enum';
import { MineChip } from './mine-chip.interface';
import { ContentPipeModule } from 'src/app/services/content/content-pipe.module';

@Component({
  standalone: true,
  selector: 'mine-chip',
  templateUrl: './mine-chip.component.html',
  styleUrls: ['./mine-chip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, ContentPipeModule, TippyModule]
})
export class MineChipComponent {

  private readonly defaultChip = MineChip.get(ChipColorName.Grey);

  @Input() color = this.defaultChip.textColor;

  @Input() backgroundColor = this.defaultChip.backgroundColor;

  @Input() isRemovable = false;

  @Input() tooltip: string = '';

  @Output() onRemoveClick = new EventEmitter<void>();

  @HostBinding('style.color') get addColor() {
    return this.color;
  }

  @HostBinding('style.background-color') get addBackgroundColor() {
    return this.backgroundColor;
  }

  removeChip(event): void {
    event.stopPropagation();
    event.preventDefault();

    if (this.isRemovable) {
      this.onRemoveClick.emit();
    }
  }
}