import { NgModule } from '@angular/core';
import { DataCyDirective } from './directives/data-cy.directive';


@NgModule({
  declarations: [
    DataCyDirective
  ],
  exports: [
    DataCyDirective
  ]
})
export class E2EModule { }