import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { UserPropertiesStore } from './user-properties.store';
import { UserProperties } from './user-properties';

@Injectable({ 
	providedIn: 'root' 
})
export class UserPropertiesQuery extends Query<UserProperties> {

	constructor(protected store: UserPropertiesStore) {
		super(store);
	}

}