import { RoutesManager } from 'src/app/shared/models/routes.interfaces';
import { OnboardingFlow } from '../../models/onboarding-manager.interface';
import { OnboardingFlowName } from './flow-name.enum';

export const DefaultOnboardingFlow: OnboardingFlow = {
	name: OnboardingFlowName.Default,
	route: `${RoutesManager.onboarding}/${RoutesManager.onboarding_default}`,
	entryStep: {
		path: `${RoutesManager.onboarding}/${RoutesManager.onboarding_default}/${RoutesManager.onboarding_welcome}`,
	},
	postSignupStep: {
		path: `${RoutesManager.onboarding}/${RoutesManager.onboarding_data_mapping}/${RoutesManager.onboarding_create_account}`,
	},
	lastStep: {
		path: `${RoutesManager.onboarding}/${RoutesManager.onboarding_data_mapping}/${RoutesManager.onboarding_scheduler}`,
        nextRoute: `${RoutesManager.dataMapping}`
	},
};

export const DataMappingOnboardingFlow: OnboardingFlow = {
	name: OnboardingFlowName.DataMapping,
	route: `${RoutesManager.onboarding}/${RoutesManager.dataMapping}`,
	entryStep: {
		path: `${RoutesManager.onboarding}/${RoutesManager.onboarding_data_mapping}/${RoutesManager.onboarding_welcome}`,
	},
	postSignupStep: {
		path: `${RoutesManager.onboarding}/${RoutesManager.onboarding_data_mapping}/${RoutesManager.onboarding_create_account}`,
	},
	lastStep: {
		path: `${RoutesManager.onboarding}/${RoutesManager.onboarding_data_mapping}/${RoutesManager.onboarding_scheduler}`,
        nextRoute: `${RoutesManager.dataMapping}`
	},
};
