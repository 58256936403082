import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ 
    standalone: true,
    name: 'camelCaseWithSpaces'
})
export class CamelCaseWithSpacesPipe implements PipeTransform {
  transform(value: string): string {
    if (!value) return '';

    // Convert string to lower case and remove leading/trailing spaces
    value = value.toLowerCase().trim();
    
    value = value.replace(/[-\s]/g, ' ');

    // Split string into words and remove invalid characters
    const words = value.replace(/[^\w\s]/g, '').split(/\s+/);

    // Capitalize the first letter of each word starting from the second word
    if (words.length < 2) return words.join('');
    const capitalizedWords = words.slice(1).map(word => word.charAt(0).toUpperCase() + word.slice(1));

    // Join words with no spaces
    return words[0] + capitalizedWords.join('');
  }
}
