import { Component, OnInit, ChangeDetectionStrategy, ElementRef } from '@angular/core';

@Component({
	standalone: true,
	selector: 'mine-skeleton',
	templateUrl: './mine-skeleton.component.html',
	styleUrls: ['./mine-skeleton.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MineSkeletonComponent implements OnInit {

	type: 'rect' | 'circle' | 'graph' = 'rect';
	width: string;
	height: string;
	style: string;
	class: string;

	constructor(private host: ElementRef<HTMLElement>) { }

	ngOnInit(): void {
		const host = this.host.nativeElement;

		if (this.style) {
			host.setAttribute('style', this.style);
		}
		
		host.style.setProperty('--skeleton-width', this.width ?? '100%');
		host.style.setProperty('--skeleton-height', this.height ?? '100%');
		host.style.setProperty('--skeleton-background-color', this.type === 'graph' ? 'transparent' : '#eff1f6');

		switch (this.type) {
			case 'rect':
			default:
				host.style.setProperty('--skeleton-border-radius', '19px');
				break;
				
			case 'circle':
				host.style.setProperty('--skeleton-border-radius', '50%');
				break;

			case 'graph':
				host.innerHTML = `
				<svg width="100%" height="100%" style="fill: #eff1f6;" viewBox="0 0 100 20" preserveAspectRatio="none">
					<use xlink:href="../../../assets/img/sprite.svg#icon-graph-stroke"></use>
				</svg>
				`;
				break;
		}
	}
}