import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ElasticSidenavService {

	private shrink = new BehaviorSubject<boolean>(false);
	
	shrink$ = this.shrink.asObservable();

	toggle(): void {
		this.shrink.next(!this.shrink.getValue());
	}

	open(): void {
		this.shrink.next(false);
	}

	close(): void {
		this.shrink.next(true);
	}
}
