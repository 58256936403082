<p class="mine-title-m dialog__title">{{'ticket.editDueDateTitle' | content}}</p>
<p class="dialog__sub-title">{{'ticket.editDueDateText' | content}}</p>
<form [formGroup]="formGroup">
    <mine-radio-button *ngFor="let date of possibleDueDates; index as i" 
        class="dialog__radio-btn" [id]="i"
        [name]="'dueDate'" 
        [value]="i" 
        formControlName="dueDateCtrl">

        <ng-container *ngIf="i !== 2; else other">
            <span>{{'ticket.editDueDateRadioBtn' | content: { params: { number: extendOptions[i] } } }}&nbsp;</span>
            <span class="dialog__grey-text">({{date | date: 'dd MMM' }})</span>
        </ng-container>

        <ng-template #other>{{'ticket.otherOption' | content}}</ng-template>

    </mine-radio-button>

    <div *ngIf="formGroup.get('dueDateCtrl')?.value === 2" class="dialog__due-date-container"
        [ngClass]="{'dialog__due-date-container--error' : dueDateData?.isDueDatePassed}"
        >
        <mine-date-input
            class="dialog__due-date"
            formControlName="otherDueDateCtrl"
            id="due-date-picker"
            [required]="false"
            [placeholder]="''"
            [error]="dueDateData?.isDueDatePassed ? ('ticket.editDueDateErrorText' | content) : ''">
        </mine-date-input>

        <div class="dialog__grey-text dialog__due-date-text">{{dueDateData?.isDueDatePassed ? dueDateData?.text : '('+dueDateData?.text+')'}}</div>
    </div>
</form>

<mine-info-box class="dialog__info-box" [bgColor]="InfoBoxBgColor">
    <svg class="dialog__info-icon" box-icon>
        <use xlink:href="../../../assets/img/sprite.svg#icon-info-outline"></use>
    </svg>

    <div box-content>
        {{'ticket.editDueDateInfoText' | content}}
        &nbsp;
        <mine-button-secondary class="dialog__learn-more" [label]="'common.learnMore' | content"
            (onClick)="learnMore()">

            <span>{{'common.learnMore' | content}}</span>
        </mine-button-secondary>
    </div>
</mine-info-box>


<div class="dialog__buttons">
    <mine-button-tertiary class="dialog__btn" (onClick)="cancel()">
        {{ 'common.cancelBtn' | content }}
    </mine-button-tertiary>

    <mine-button-primary class="dialog__btn" [disabled]="dueDateData?.isDueDatePassed" (onClick)="save()">
        {{ 'ticket.updateBtn' | content }}
    </mine-button-primary>
</div>