export enum EmployeeProgress {
    Ready = "Ready",
    Completed = "Completed",
    Pending = "Pending",
}

export enum EmployeeConnectionEnum {
    EmployeeOnly = 'EmployeeOnly',
    Invitee = 'Invitee',
    WithAutoDiscovery = 'WithAutoDiscovery'
}

export enum EmployeeAccountStatus {
    Active = 'Active',
    InActive = 'Inactive',
    DeletedInWorkspace = 'DeletedInWorkspace'
}

export enum GoogleDataMappingGroupStateEnum {
    Active = 'Active',
    Invalid = 'Invalid',
}

export enum DiscoveryTypeEnum {
    NotSet = 'NotSet',
    EntireOrg = 'EntireOrg',
    GroupScan = 'GroupScan'
}