import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ConfettiService {
	private celebrate = new Subject<void>();
	public celebrate$ = this.celebrate.asObservable();

	public startCelebration(): void {
		this.celebrate.next();
	}
}
