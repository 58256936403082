export enum PolicyType {
    Misplacement = 'misplacement',
    Irregularity = 'irregularity'
}

export enum PolicyId {
    GdprSpecial = 'gdprSpecial',
    PHI = 'phi',
    PCI = 'pci',
    Count = 'count'
}