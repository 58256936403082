import { NgModule } from "@angular/core";
import { ContentPipe } from "./content.pipe";

@NgModule({
	declarations: [
		ContentPipe,
	],
	exports: [
		ContentPipe,
	],
	providers: [
		ContentPipe,
	]
})
export class ContentPipeModule {}
