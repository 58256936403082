import { JiraConnection } from './../../api/models/company-settings/jira.interface';
import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { Observable } from 'rxjs';

import { CompanySettingsStore } from './company-settings.store';
import {
  Activities,
  CompanySettings,
  CustomField,
  Customization,
  Inventory,
  RopaDetail,
  Security,
  SystemFieldResponse
} from 'src/app/api/models/company-settings/company-settings.interface';

@Injectable({ 
	providedIn: 'root' 
})
export class CompanySettingsQuery extends Query<CompanySettings> {

	constructor(protected store: CompanySettingsStore) {
		super(store);
	}

	selectConfiguration(): Observable<Customization> {
		return this.select(state => state.customization);
	}

	selectWorkflows(): Observable<string[]> {
		return this.select(state => state.inventory?.workflows);
	}

	selectEntities(): Observable<SystemFieldResponse[]> {
		return this.select(state => state.inventory?.inventoryEntities);
	}

	selectPrivacyPolicyLink(): Observable<string> {
		return this.select(state => state.copyPreview?.privacyPolicyLink);
	}

  selectJiraData(): Observable<JiraConnection> {
    return this.select(state => state.jiraConnection);
  }

	getActivities(): Activities {
		return this.getValue().activities;
	}

	getCopyDisclaimerTitle(): string {
		return this.getValue().copyPreview.disclaimerTitle;
	}

	getInventory(): Inventory {
		return this.getValue().inventory;
	}

	getCustomization(): Customization {
		return this.getValue().customization;
	}

	getDebugMode(): boolean {
		return this.getValue().dataClassifier?.piiDebugMode;
	}

	getSecurity(): Security {
		return this.getValue()?.security;
	}

	selectSecurity(): Observable<Security> {
		return this.select(settings => settings.security);
	}

	selectCustomFields(): Observable<SystemFieldResponse[]> {
		return this.select(state => state?.inventory?.customFields);
	}

	selectCustomMitigations(): Observable<CustomField[]> {
		return this.select(state => state?.activities?.customMitigations);
	}

	selectAutoRedact(): Observable<boolean> {
		return this.select(state => state?.customization?.autoRedact);
	}

	selectCustomDataSubjects(): Observable<CustomField[]> {
		return this.select(state => state?.dataSubjects?.customDataSubjects);
	}

	getCustomDataSubjects(): CustomField[] {
		return this.getValue().dataSubjects.customDataSubjects;
	}

    getCustomBusinessUnits(): CustomField[] {
        return this.getValue().businessUnits.customBusinessUnits;
    }

	selectRopaDetails(): Observable<RopaDetail[]> {
		return this.select(state => state?.ropaDetail?.details);
	}

	selectCustomApiKey(): Observable<string> {
		return this.select(state => state?.verificationKeys.customIntegrationVerificationKey);
	}

	getEntities(): SystemFieldResponse[] {
		return this.getInventory()?.inventoryEntities;
	}

	getCustomMitigations(): CustomField[] {
		return this.getActivities()?.customMitigations;
	}

	getServerLocationsByEntityId(id: string): string[] {
		return this.getEntities()?.find(i => i.id === id)?.serverLocations ?? [];
	}

  getWorkflows(): string[] {
    return this.getInventory()?.workflows;
  }

	getCustomRight(): Pick<Customization, 'doNotMailDescription' | 'doNotMailDisplayName'> {
		const customization = this.getCustomization();
		return {
			doNotMailDisplayName: customization?.doNotMailDisplayName,
			doNotMailDescription: customization?.doNotMailDescription,
		};
	}

	isWorkflowExists(name: string): boolean {
		return this.getWorkflows()?.some(workflow => workflow?.toLowerCase() === name?.toLowerCase());
	}

	isDataSubjectExists(name: string): boolean {
		return this.getCustomDataSubjects()?.some(dataSubject => dataSubject.name?.toLowerCase() === name?.toLowerCase());
	}

	isMitigationExists(name: string): boolean {
		return this.getCustomMitigations()?.some(mitigation => mitigation.name?.toLowerCase() === name?.toLowerCase());
	}
}
