import { Injectable } from "@angular/core";
import { RxState } from "@rx-angular/state";
import { DsrPrivacyRightsState } from "./dsr-privacy-rights-state.interface";
import { PrivacyRightItem } from "src/app/api/models/dsr/dsr-rights.interface";

@Injectable({
    providedIn: 'root'
})
export class DsrPrivacyRightsStore extends RxState<DsrPrivacyRightsState> {
    private readonly initState = {
        loading: true,
        rights: new Map<string, PrivacyRightItem>(),
    } as DsrPrivacyRightsState;

    constructor() {
        super();
        this.set({...this.initState}); // set the initial state of the store
    }
}