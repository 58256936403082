import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	standalone: true,
  	name: 'splitUpperCase'
})
export class SplitUpperCasePipe implements PipeTransform {

	transform(value: string): string {
		return value.split(/(?=[A-Z])/).join(' ');
	}
}