import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { EMPTY, Observable, catchError, first, map, tap } from 'rxjs';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { DialogRef } from '@ngneat/dialog';

import { LoggerService } from 'src/app/logger/logger.service';
import { ContentPipe } from 'src/app/services/content/content.pipe';
import { DropdownOption } from 'src/app/shared/mine-dropdown/mine-dropdown.interface';
import { MineSnackbarService } from 'src/app/shared/mine-snackbar/mine-snackbar.service';
import { TicketTypeDisplayNamePipe } from '../../../../../../../requests/request-ticket-v2/pipes/ticket-type-display-name.pipe';
import { MineDropdownComponent } from 'src/app/shared/mine-dropdown/mine-dropdown.component';
import { MineButtonPrimaryComponent } from 'src/app/shared/mine-button-primary/mine-button-primary.component';
import { MineButtonTertiaryComponent } from 'src/app/shared/mine-button-tertiary/mine-button-tertiary.component';
import { ContentPipeModule } from 'src/app/services/content/content-pipe.module';
import { Customization } from 'src/app/api/models/company-settings/company-settings.interface';
import { TicketType } from 'src/app/api/models/requests/ticket-type.enum';
import { CompanySettingsQuery } from 'src/app/company-settings/state/company-settings.query';
import { MineSnackbarType } from 'src/app/shared/mine-snackbar/mine-snackbar-type';
import { RequestsService } from 'src/app/requests/state/requests.service';

@Component({
  standalone: true,
  selector: 'edit-ticket-type-dialog',
  templateUrl: './edit-ticket-type-dialog.component.html',
  styleUrls: ['./edit-ticket-type-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MineDropdownComponent,
    MineButtonPrimaryComponent,
    MineButtonTertiaryComponent,
    TicketTypeDisplayNamePipe,
    ContentPipeModule,
    CommonModule,
    ReactiveFormsModule
  ],
  providers: [
    TicketTypeDisplayNamePipe,
  ]
})
export class EditTicketTypeDialogComponent implements OnInit {
  private readonly loggerName = 'EditTicketTypeDialogComponent';

  ticketTypeCtrl: FormControl<DropdownOption>;

  dropdownOptions$: Observable<DropdownOption[]>;

  constructor(
    private dialogRef: DialogRef,
    private contentPipe: ContentPipe,
    private loggerService: LoggerService,
    private requestsService: RequestsService,
    private snackbarService: MineSnackbarService,
    private companySettingsQuery: CompanySettingsQuery,
    private ticketTypeDisplayNamePipe: TicketTypeDisplayNamePipe,
  ) {}

  ngOnInit(): void {
    const ticketType = this.dialogRef?.data?.ticketType;
    this.ticketTypeCtrl = new FormControl<DropdownOption>(ticketType && ticketType !== TicketType.Undetermined ? { id: ticketType, value: this.ticketTypeDisplayNamePipe.transform(ticketType) } : null);
    this.dropdownOptions$ = this.companySettingsQuery.selectConfiguration().pipe(
      map(customization => this.initDropdownOptions(customization)),
    );
  }

  private initDropdownOptions(customization: Customization): DropdownOption[] {
    const dropdownOptions = [
      { id: TicketType.Delete, value: this.ticketTypeDisplayNamePipe.transform(TicketType.Delete) },
      { id: TicketType.GetCopy, value: this.ticketTypeDisplayNamePipe.transform(TicketType.GetCopy) },
    ];
    if (customization?.doNotSell) {
      dropdownOptions.push({ id: TicketType.DoNotSell, value: this.ticketTypeDisplayNamePipe.transform(TicketType.DoNotSell) })
    }
    if(customization?.doNotMail){
      dropdownOptions.push({ id: TicketType.DoNotMail, value: this.ticketTypeDisplayNamePipe.transform(TicketType.DoNotMail) })
    }
    if(customization?.rightToEdit){
      dropdownOptions.push({ id: TicketType.RightToEdit, value: this.ticketTypeDisplayNamePipe.transform(TicketType.RightToEdit) })
    }
    return dropdownOptions;
  }

  cancel(): void {
    this.dialogRef.close();
  }

  save(): void {
    this.requestsService.updateTicketDetails(this.dialogRef.data.id, { ticketType: this.ticketTypeCtrl.value.id as TicketType }).pipe(
      first(),
      tap(() => this.snackbarService.showTimed(MineSnackbarType.Confirmation, this.contentPipe.transform('ticket.editRequestSuccess'))),
      tap(() => this.dialogRef.close(this.ticketTypeCtrl.value.id)),
      catchError(() => this.updateTicketDetailsFailed()),
    ).subscribe();
  }

  private updateTicketDetailsFailed(): Observable<never> {
    this.snackbarService.showTimed(MineSnackbarType.Error, this.contentPipe.transform('ticket.editRequestFailed'));
    this.loggerService.error(this.loggerName, `Failed to update ticket type (${this.ticketTypeCtrl.value.id}) for ticket: ${this.dialogRef.data.id}`);
    return EMPTY;
  }
}
