<button
	id="{{ id }}"
	class="button-primary"
	[class.button-primary--active]="spinner"
	[class.button-primary--disabled]="disabled"
	[ngStyle]="customStyle"	
	[tp]="tooltip"
	[tpIsEnabled]="!!tooltip"
	(click)="onBtnClick($event)">

	<p #content [attr.data-label]="text">
		<ng-container *ngIf="spinner; else textTpl">
			<mine-spinner class="button-primary__spinner" 
				[ngStyle]="spinnerCustomStyle"
				[width]="spinnerSize" 
				[height]="spinnerSize" 
				[revert]="true">
			</mine-spinner>
		</ng-container>
		<ng-content *ngIf="!spinner"></ng-content>

		<ng-template #textTpl>
			{{ text }}
		</ng-template>
	</p>
	
</button>