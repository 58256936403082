import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { RadarColumnKeys } from './radar-table.enum';
import { ContentPipe } from 'src/app/services/content/content.pipe';
import { UnverifiedSystem } from 'src/app/api/models/systems/systems.interface';
import { FeedbackStateEnum } from 'src/app/api/models/feedback/feedback.enum';

@Injectable()
export class RadarTableCsvHelper {

    constructor(
        private datePipe: DatePipe,
        private contentPipe: ContentPipe
    ) {}

    getValueByKey(system: UnverifiedSystem, key: RadarColumnKeys): string {
      const values: Record<RadarColumnKeys, string> = {
        dataSource: system.name ?? '',
        employees: `${system.connectedAccounts}` ?? '-',
        usage: system.systemUsage?.discoveryScore ? `${system.systemUsage?.discoveryScore}` : '-',
        category: system.category ?? '-',
        discoveryDate: system.discoveryDate ? this.datePipe.transform(system.discoveryDate, 'dd MMM, yyyy') : '-',
        feedbackStatus: this.getFeedbackStatus(system),
        dataSourceOrigin: system.dataSourceOrigin ? system.dataSourceOrigin.join(", ") : '-',
      };
      return values[key];
    }

    private getFeedbackStatus(system: UnverifiedSystem): string {
      const generalFeedbackState = system.generalFeedbackState;
      switch (generalFeedbackState) {
        case FeedbackStateEnum.Pending:
          return this.contentPipe.transform('common.pendingBadgeText');

        case FeedbackStateEnum.Responded:
          return this.contentPipe.transform('common.respondedBadgeText');

        default:
          return '-';
      }
    }
}