import { Injectable } from '@angular/core';
import { HttpClientHelperService } from './http-client-helper.service';
import { environment } from 'src/environments/environment';
import { CallbackArgs } from './models/auth/callback-args';
import { OAuthAuthorizationResponse } from './models/auth/o-auth-authorization-response';
import { AuthProviders } from './models/auth/auth-providers';
import { Observable } from 'rxjs';
import { JwtTokenResponse } from './models/auth/jwt-token-response';

@Injectable({
	providedIn: 'root'
})
export class ApiClientAuthService {

	constructor(private httpClientHelper: HttpClientHelperService) { }

	private buildAuthArgsBody(authParams: OAuthAuthorizationResponse): CallbackArgs {
		const body: CallbackArgs = new CallbackArgs();

		body.oAuthAuthorizationResponse.code = authParams.code;
		body.oAuthAuthorizationResponse.state = authParams.state;
		body.oAuthAuthorizationResponse.error = authParams.error;
		body.oAuthAuthorizationResponse.errorDescription = authParams.errorDescription;

		return body;
	}

	getJwt(params: OAuthAuthorizationResponse, provider: AuthProviders): Observable<JwtTokenResponse> {
		const reqBody = this.buildAuthArgsBody(params);
		reqBody.redirectUrl = this.getRedirectUrl(provider);
		return this.httpClientHelper.invokePost<JwtTokenResponse>(`${provider.toLowerCase()}${ provider === AuthProviders.auth0 ? '' : 'authportal'}/callback`, reqBody);
	}

	getRedirectUrl(provider: AuthProviders): string {
		return environment.authentication.redirectUrl[provider];
	}
}
