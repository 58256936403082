import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, DestroyRef} from '@angular/core';
import { Router } from '@angular/router';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { tap } from 'rxjs';
import { Animations } from 'src/app/animations/animations';
import { OnboardingTaskEnum } from 'src/app/api/models/profile/profile-onboarding-tasks.enum';
import { OnboardingTasks } from 'src/app/api/models/profile/profile-onboarding-tasks.interface';
import { ContentPipe } from 'src/app/services/content/content.pipe';
import { OnboardingTasksService } from 'src/app/services/onboarding-tasks/onboarding-tasks.service';
import { TeamMemberService } from 'src/app/services/team-member.service';
import { RoutesManager } from 'src/app/shared/models/routes.interfaces';
import { OnboardingTaskCopy } from './onboarding-checklist.interface';

@Component({
	selector: 'onboarding-checklist',
	templateUrl: './onboarding-checklist.component.html',
	styleUrls: ['./onboarding-checklist.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [Animations.pimple, Animations.fadeIn]
})
export class OnboardingChecklistComponent implements OnInit {

	title: string;

	tasksCopy: OnboardingTaskCopy[];

	tasks: OnboardingTasks;

	nextTask: OnboardingTaskEnum; 

	tasksOrder: OnboardingTaskEnum[];

	gaugeType = 'arch';
	gaugeCap = 'round';
	gaugeSize = 85;
	gaugeThick = 8;
	gaugeValue;
	gaugeColorForeground = '#9897ee';
	gaugeColorBackground = '#eeeafe';
	gaugeDuration = 100;

	constructor(private contentPipe: ContentPipe,
				private router: Router,
				private teamMemberService: TeamMemberService,
				private onboardingTasksService: OnboardingTasksService,
				private cdr: ChangeDetectorRef,
				private destroyRef: DestroyRef,) { }

	ngOnInit(): void {
		this.tasksOrder = this.onboardingTasksService.getOrderHomePageTasks();
		
		this.tasksCopy = this.contentPipe.transform('home.onboardingChecklist').tasks;

		this.onboardingTasksService.onboardingTasks$.pipe(
				tap(res => this.updateOnboardingTasks(res)),
				tap(() => this.calculateGauge()),
				tap(() => this.initTitle()),
				takeUntilDestroyed(this.destroyRef)
			)
			.subscribe();
	}

	onClickTask(task: OnboardingTaskEnum): void {
		switch (task) {
			case OnboardingTaskEnum.dataMapping:
			case OnboardingTaskEnum.setupIntegration:
				this.router.navigate([RoutesManager.systems]);
				return;
			
			case OnboardingTaskEnum.closeRequest:
				this.router.navigate([RoutesManager.requests]);
				return;

			case OnboardingTaskEnum.customizePrivacyCenter:
				this.router.navigate([RoutesManager.privacyCenter]);
				return;

			case OnboardingTaskEnum.inviteTeamMember:
				this.teamMemberService.addTeamMember();
				return;
			
			default:
				return;
		}
	}

	private initTitle(): void {
		const copy = this.contentPipe.transform('home.onboardingChecklist');
		this.title = this.gaugeValue < 100 ? copy.title : copy.titleDone;
		this.cdr.detectChanges();
	}

	private updateOnboardingTasks(tasks: OnboardingTasks): void {
		this.tasks = tasks;
		this.setNextTask();
	}

	private setNextTask(): void {
		for (let t of this.tasksOrder) {
			if (!this.tasks[t]) {
				this.nextTask = this.tasksCopy.find(task => task.key === t).key;
			  	break;
			}
		}
	}

	private calculateGauge(): void {
		let done = 0;
		
		this.tasksOrder.forEach(t => done += +this.tasks[t]);

		this.gaugeValue = Math.round(100*(done / this.tasksOrder.length));
	}
}
