import { CommonModule } from '@angular/common';
import { Component, DestroyRef, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { MineSnackbarType } from './mine-snackbar-type';
import { MineSnackbarService } from './mine-snackbar.service';
import { Animations } from 'src/app/animations/animations';
import { SafePipe } from '../pipes/safe.pipe';

@Component({
	standalone: true,
	selector: 'mine-snackbar',
	templateUrl: './mine-snackbar.component.html',
	styleUrls: ['./mine-snackbar.component.scss'],
	imports: [CommonModule, SafePipe],
	animations: [Animations.snackbar]
})
export class MineSnackbarComponent implements OnInit {
	
	visible: boolean;	
	type: MineSnackbarType;
	text: string;
	dismissible: boolean;

	constructor(
		private destroyRef: DestroyRef,
		private service: MineSnackbarService
	) { }

	ngOnInit() {
		this.service.visible$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(visible => {
			this.visible = visible;
		});
		
		this.service.params$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(response => {
			this.type = response.type;
			this.text = response.text;
			this.dismissible = response.dismissible;
		});
	}

	onDismiss() {
		this.service.hide();
	}

	get backgroundColor() {
		return {
			'snackbar--confirmation': this.type === MineSnackbarType.Confirmation,
			'snackbar--notification': this.type === MineSnackbarType.Notification,
			'snackbar--error': this.type === MineSnackbarType.Error
		}
	}

}
