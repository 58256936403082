<svg class="inline-spinner" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" 
		[ngStyle]="{'width.px': width, 'height.px': height}">
	
	<circle class="inline-spinner__path-primary" [ngStyle]="{'stroke': stroke}" 
				fill="none" stroke-width="10" stroke-linecap="round" cx="50" cy="50" r="40">
	</circle>

	<circle class="inline-spinner__path-secondary" [ngStyle]="{'stroke': stroke}"
				fill="none" stroke-width="10" cx="50" cy="50" r="40">
	</circle>

</svg>