import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { SystemsIconsState, SystemsIconsStore } from './systems-icons.store';

@Injectable({
	providedIn: 'root'
})
export class SystemsIconsQuery extends QueryEntity<SystemsIconsState> {
	constructor(protected store: SystemsIconsStore) {
		super(store);
	}

    getIcon(type: string): string {
        return this.getEntity(type)?.iconPath;
    }
}
