import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { DialogRef } from '@ngneat/dialog';
import { ContentPipeModule } from 'src/app/services/content/content-pipe.module';
import { MineButtonPrimaryComponent } from 'src/app/shared/mine-button-primary/mine-button-primary.component';
import { environment } from 'src/environments/environment';

@Component({
  standalone: true,
  selector: 'session-expired-dialog',
  templateUrl: './session-expired-dialog.component.html',
  styleUrls: ['./session-expired-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    MineButtonPrimaryComponent,
    ContentPipeModule,
  ]
})
export class SessionExpiredDialogComponent {
	assetsUrl = environment.assets.url;

  constructor (
    private dialogRef: DialogRef,
    ) { }

  signIn(): void {
    this.dialogRef.close();
  }
}
