import { Router } from '@angular/router';
import { Component, OnInit, ChangeDetectionStrategy, Input, AfterViewInit, ElementRef, ViewChild, HostListener } from '@angular/core';

import { environment } from 'src/environments/environment';
import { AnalyticsService } from 'src/app/analytics/analytics.service';
import { EventActionType } from 'src/app/analytics/event-action.enum';
import { ContentPipe } from 'src/app/services/content/content.pipe';
import { ExploreMoreCopy } from './explore-more.models';
import { AnalyticEventsLabels } from 'src/app/analytics/analytics-events-labels';
import { AnalyticsEvent } from 'src/app/analytics/analytics-event';

@Component({
	selector: 'explore-more',
	templateUrl: './explore-more.component.html',
	styleUrls: ['./explore-more.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExploreMoreComponent implements OnInit, AfterViewInit {

	assetsUrl = environment.assets.url;

	title: string;

	showVideo = false;

	videoHeight: string;

	@ViewChild('video', { static: false }) video: ElementRef;
	
	copy: ExploreMoreCopy;

	constructor(private contentPipe: ContentPipe,
				private router: Router,
				private analyticsService: AnalyticsService,
	) { }

	ngOnInit(): void {
		this.copy = <ExploreMoreCopy>this.contentPipe.transform('home.exploreMore');
	}

	ngAfterViewInit(): void {
		this.listenToResizeScreen();
	}

	@HostListener('window:resize', ['$event'])
	private listenToResizeScreen() {
		this.videoHeight = `${(this.video.nativeElement.clientWidth * 0.47)}px`; // keep video proportion
	}

	startVideo(): void {
		this.showVideo = true;
		this.analyticsService.track({
			eventCategory: AnalyticEventsLabels.HOME_introduction_video,
			eventAction: EventActionType.Click,
		} as AnalyticsEvent);
	}

	onClickCta(route: string, buttonText: string): void {
		this.analyticsService.track({
			eventAction: EventActionType.Click,
			eventCategory: buttonText,
		});
		this.router.navigate([`/${route}`]);
	}
}
