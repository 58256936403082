import { Injectable } from '@angular/core';
import { filter, first } from 'rxjs';

import { DialogsManagerService } from './dialogs-manager.service';
import { MineSnackbarService } from '../shared/mine-snackbar/mine-snackbar.service';
import { MineSnackbarType } from '../shared/mine-snackbar/mine-snackbar-type';
import { FeatureFlags } from '../api/models/profile/profile-feature-flags.enum';
import { FeatureFlagQuery } from '../feature-flag/state/feature-flag.query';
import { ProfileQuery } from '../profile/state/profile.query';
import { ChatService } from '../chat/chat.service';
import { ContentPipe } from './content/content.pipe';
import { ProfileMemberDialogComponent } from '../profile/profile-member-dialog/profile-member-dialog.component';
import { ActivatePortalDialogService } from '../core/activate-portal-dialog/activate-portal-dialog.service';
import { ProfileInviteMember } from '../api/models/profile/profile-invite-member.interface';

@Injectable({
  providedIn: 'root'
})
export class TeamMemberService {
   
  constructor(
    private profileQuery: ProfileQuery,
    private featureFlagQuery: FeatureFlagQuery,
    private dialogService: DialogsManagerService,
    private snackbarService: MineSnackbarService,
    private chat: ChatService,
    private contentPipe: ContentPipe,
    private activatePortalService: ActivatePortalDialogService,
  ) { }

  addTeamMember(): void {
    const profile = this.profileQuery.getValue();

    // check if feature flag is on
    if (!this.featureFlagQuery.getFlag(FeatureFlags.AddTeamMember)) {
      this.openIntercom();
      return
    }

    // check if unlimitedAgents flag is on OR current agents number is less than threshold
    if (this.featureFlagQuery.getFlag(FeatureFlags.UnlimitedAgents) || profile.agents.length < profile.numOfMaxAgentsAllowedIfLimited) {
      const dialogRef = this.dialogService.openMemberComponent(ProfileMemberDialogComponent);
      dialogRef.afterClosed$.pipe(
        first(),
        filter(res => !!res),
      ).subscribe((res: ProfileInviteMember) => this.snackbarService.showTimed(MineSnackbarType.Confirmation, `${this.contentPipe.transform('profile.memberAddedSuccess')} ${res.inviteeEmail}`));
    }
    else {
      this.openReachedLimitDialog();
    }
  }

  private openReachedLimitDialog(): void {
    this.activatePortalService.showPremiumDialog(FeatureFlags.AddTeamMember).pipe(first()).subscribe();
  }

  private openIntercom(): void {
    this.chat.show();
  }
}