export enum IntegrationType {
    Webhook = "webhook",
    OAuth = "oauth",
    ApiKey = "apiKey",
    Email = "email",
    WebhookSlim = "webhookSlim", // loads Internal System component,
    ServiceAccount = "serviceAccount",
    Ipaas = "ipaas",
    Manual = "manual",
    Custom = "custom"
 }

export enum IntegrationInstanceType {
    Automated = "Automated",
    Manual = "Manual",
    Workflow = "Workflow",
    Owner = "Owner",
    Email = "Email",
    Jira = "Jira",
    CustomIntegration = "CustomIntegration",
    CustomIntegrationPii = "CustomIntegrationPii"
 }

export enum IdentifierType {
    Email = 'Email', // default identifier
    PhoneNumber = 'PhoneNumber',
    InternalId = 'InternalId'
}

export enum PreviewResult {
    Success = "Success",
    Error = "Error"
}

export enum PreviewErrorType {
    ProviderError = "ProviderError",
    Unauthenticated = "Unauthenticated",
    InternalError = "InternalError"
}

export enum DeleteConfig {
    Delete = "Delete",
    Anonymize = "Anonymize",
    Retain ="Retain"
}

export enum CopyConfig {
    Include = "Include",
    Skip = "Skip",
    PartiallyInclude = "PartiallyInclude"
}

export enum PublishState {
    Publish = "Publish",
    Draft = "Draft",
    Pending = "Pending",
}

export enum TestResultErrorType {
    NoError = "NoError",
    ProviderError = "ProviderError",
    ServerError = "ServerError"
}
