import { CommonModule } from '@angular/common';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { MatNativeDateModule, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { Component, ChangeDetectionStrategy, ElementRef, forwardRef, Input, ViewChild } from '@angular/core';

export const DATEPICKER_FORMATS = {
  parse: {
    dateInput: 'MM/DD/YYYY',
  },
  display: {
    dateInput: 'MM/DD/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'MM/DD/YYYY',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  standalone: true,
  selector: 'mine-date-input',
  templateUrl: './mine-date-input.component.html',
  styleUrls: ['./mine-date-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, MatDatepickerModule, MatNativeDateModule],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MineDateInputComponent),
      multi: true
    },
    {
      provide: MAT_DATE_LOCALE,
      useValue: 'en-US'
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: DATEPICKER_FORMATS
    }
  ]
})
export class MineDateInputComponent implements ControlValueAccessor {

  @Input() id: string; //unique id is a must
  @Input() required: boolean;
  @Input() placeholder: string;
  @Input() error = '';
  @Input() maxDate: Date | null = null;
  @Input() minDate: Date | null = null;

  @ViewChild('input', { static: true, read: ElementRef }) input: ElementRef<HTMLInputElement>;

  value: Date | null = null;

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  writeValue(value: string): void {
    this.value = value ? new Date(value) : null; // Ensure value is a Date object or null
    if (this.input) {
      this.input.nativeElement.value = this.value ? this.formatDate(this.value) : '';
    }
  }
  
  onDateChange(date: Date): void {
    this.value = date;
    this.onChange(this.formatDate(this.value));
    if (this.input) {
      this.input.nativeElement.value = this.formatDate(this.value);
    }
  }
  
  private formatDate(date: Date): string {
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${month}/${day}/${year}`;
  }

  onChange = (val: string) => {}
  onTouched = () => {}
}