import {Injectable} from "@angular/core";
import {
    AiAssessmentDataFlow,
    AiAssessmentDataFlowEdge,
    AiAssessmentEntity,
    AiAssessmentInstance,
    AiAssessmentPageRequest, AiAssessmentPageResponse,
    AiAssessmentPageSection, AssessmentExternalEntity,
    AssessmentTemplatePageRequest,
    AssessmentTemplatePageResponse,
    AssessmentTemplateRequest,
    AssessmentTemplateResponse
} from "../../api/models/ai-assessments/ai-assessments.interface";
import {AiAssessmentsFormControl} from "../models/ai-assessments.interface";
import {
    AiAssessmentCustomComponentEnum,
    AiAssessmentsFormControlTypeEnum
} from "../models/ai-assessments.enum";
import {AiAssessmentEntityTypeEnum} from "../../api/models/ai-assessments/ai-assessments.enum";
import { DataFlowEdge, DataFlowNode } from "src/app/api/models/processing-activities/processing-activities.interface";
import { FeatureFlagQuery } from "src/app/feature-flag/state/feature-flag.query";
import { FeatureFlags } from "src/app/api/models/profile/profile-feature-flags.enum";
import { CustomFieldsQuery } from "src/app/company-settings/state/custom-fields/custom-fields.query";
import { CustomValuesQuery } from "src/app/company-settings/state/custom-values/custom-values.query";
import { ConvertPiiTypeToDataTypePipe } from "src/app/shared/pipes/convert-pii-type-to-data-type.pipe";
import { DataFlowNodeTypeEnum } from "src/app/api/models/processing-activities/processing-activities.enum";
import { DropdownOption } from "src/app/shared/mine-dropdown/mine-dropdown.interface";
import { MineChip } from "src/app/shared/mine-chip/mine-chip.interface";
import { ChipColorName } from "src/app/shared/mine-chip/mine-chip.enum";
import { FieldUsedInEnum } from "src/app/api/models/company-settings/custom-fields.enum";
import { DataType } from "src/app/data-types/models/data-types.interface";

@Injectable({
    providedIn: 'root'
})
export class AiAssessmentsV2ParserService {
    
    constructor(
        private featureFlagQuery: FeatureFlagQuery,
        private customValuesQuery: CustomValuesQuery,
        private customFieldsQuery: CustomFieldsQuery,
        private convertPiiTypeToDataTypePipe: ConvertPiiTypeToDataTypePipe
    ) {}

    parseInstanceToPageRequestV2(instance: AiAssessmentInstance): AiAssessmentPageRequest {        
        const sections: AiAssessmentPageSection[] = [];
        let instanceSchema: AiAssessmentsFormControl[];
        let instanceData;

        try {
            instanceSchema = JSON.parse(this.strEscape(instance.templateSchema)) as AiAssessmentsFormControl[];
        }
        catch (err) {
            console.error('Could not parse instance schema', err);
            instanceSchema = [];
        }

        try {
            instanceData = JSON.parse(this.strEscape(instance.data));
        }
        catch (err) {
            console.error('Could not parse instance data', err);
            instanceData = {};
        }

        let dataFlow = undefined;
        instanceSchema.forEach((instanceSectionSchema, index) => {
            let pageSection: AiAssessmentPageSection;
            const pageSectionSchema = instanceSectionSchema ?? {};
            const pageSectionData = this.selectSectionData(instanceData, instanceSectionSchema, instanceSectionSchema.name);

            pageSection = {
                ordinal: index,
                data: JSON.stringify(pageSectionData),
                schema: JSON.stringify(pageSectionSchema),
                type: instanceSectionSchema.type,
                name: instanceSectionSchema.name,
                label: instanceSectionSchema.label
            };

            if (pageSectionData.hasOwnProperty(AiAssessmentCustomComponentEnum.DataFlow)) {
                dataFlow = this.parseDataFlowToV2(instance, pageSectionData[AiAssessmentCustomComponentEnum.DataFlow]);
            }

            if (this.featureFlagQuery.getFlag(FeatureFlags.DevAssessmentTemplateSavedQuestions)) {
                this.setCustomFieldsAsRelatedEntities(instance, pageSectionSchema as AiAssessmentsFormControl);
            }

            sections.push(pageSection);
        });

        const page: AiAssessmentPageRequest = {
            id: instance.id,
            name: instance.name,
            agentId: instance.agentId,
            type: instance.templateType,
            createdDate: instance.createdAt,
            updatedDate: instance.lastModified,
            state: instance.state,
            sections,
            reviewDate: instance.reviewDate,
            lastApprovedAt: instance.approvedAt,
            approverId: instance.ownerId ?? instance.approverId,
            ownerId: instance.ownerId ?? instance.approverId
        };

        if (dataFlow) {
            page.dataFlow = dataFlow;
        }

        const externalRelatedEntities = instance.relatedEntities
            ?.filter(entity => entity.type !== AiAssessmentEntityTypeEnum.LinkedAssessment)
            ?.map(this.parseRelatedEntityToExternal.bind(this))
            ?.filter(Boolean)
            ?.reduce((acc: AssessmentExternalEntity[], current: AssessmentExternalEntity) => {
                const x = acc.find(item => item.externalRelatedEntityId === current.externalRelatedEntityId);
                return x ? acc : acc.concat([current])
            }, []) as AssessmentExternalEntity[] // remove duplicates

        if (externalRelatedEntities.length > 0) page.externalRelatedEntities = externalRelatedEntities;
        const relatedAssessmentIds = instance.relatedAssessments?.map(entity => entity.id) || [];
        if (relatedAssessmentIds.length > 0) page.relatedAssessmentIds = relatedAssessmentIds;
        return page;
    }

    private selectSectionData(data: any, schema: AiAssessmentsFormControl, sectionName: string): any {
        if (!schema) {
            return {};
        }
        const hasRisksSection = !schema.controls 
            ? schema.type === AiAssessmentsFormControlTypeEnum.CustomComponent && schema.name === AiAssessmentCustomComponentEnum.Risks 
            : schema.controls.find(({ type, name }) => type === AiAssessmentsFormControlTypeEnum.CustomComponent && name === AiAssessmentCustomComponentEnum.Risks);
            
        const pageSectionData = data[sectionName] ?? {};
        if (hasRisksSection) {
            if (data[AiAssessmentCustomComponentEnum.Risks]) {
                return { ...pageSectionData, [AiAssessmentCustomComponentEnum.Risks]: data[AiAssessmentCustomComponentEnum.Risks] };
            }
            return { ...pageSectionData };
        }
        return pageSectionData;
    }

    private parseDataFlowToV2(instance: AiAssessmentInstance, edges: DataFlowEdge[]): AiAssessmentDataFlow {
        if (edges && edges.length) {
            if (this.featureFlagQuery.getFlag(FeatureFlags.DevAssessmentTemplateSavedQuestions)) {
                edges.reduce((prev, curr) => prev.concat(curr.source, curr.destination), [] as DataFlowNode[])
                    .forEach(edge => {
                        if (edge.type === DataFlowNodeTypeEnum.CustomEntity && !!this.customValuesQuery.getEntity(edge.id)) {
                            const customValueEntity = {
                                id: edge.id,
                                type: AiAssessmentEntityTypeEnum.CustomValue,
                                isCustom: true
                            } as AiAssessmentEntity;
                            
                            instance.relatedEntities = instance.relatedEntities?.length ?
                                instance.relatedEntities.concat(customValueEntity) : [customValueEntity];
                        }
                });
            }
            return {
                edges: edges.map(edge => ({ 
                    source: {
                        entityId: edge.source.id,
                        nodeType: edge.source.type
                    },
                    target: {
                        entityId: edge.destination.id,
                        nodeType: edge.destination.type
                    }
                } as AiAssessmentDataFlowEdge))
            } as AiAssessmentDataFlow;
        }
        return undefined;
    }

    private setCustomFieldsAsRelatedEntities(instance: AiAssessmentInstance, pageSectionSchema: AiAssessmentsFormControl): void {
        const customValueEntities: AiAssessmentEntity[] = [];
        instance.relatedEntities.forEach(entity => {
            if (entity.isCustom && !!this.customValuesQuery.getEntity(entity.id)) {
                customValueEntities.push({
                    id: entity.id,
                    type: AiAssessmentEntityTypeEnum.CustomValue,
                    isCustom: true
                } as AiAssessmentEntity);
            }
        });

        instance.relatedEntities = instance.relatedEntities?.length ?
            instance.relatedEntities.concat(customValueEntities) : customValueEntities;

        if (pageSectionSchema?.controls?.length) {
            for (let control of pageSectionSchema.controls) {
                if (control.customFieldId && !!this.customFieldsQuery.getEntity(control.customFieldId)) {
                    const customFieldEntity = {
                        id: control.customFieldId,
                        type: AiAssessmentEntityTypeEnum.CustomField,
                        isCustom: true
                    } as AiAssessmentEntity;
                    
                    instance.relatedEntities = instance.relatedEntities?.length ? instance.relatedEntities.concat(customFieldEntity) : [customFieldEntity];
                }
            }
        }
    }

    parseRelatedEntityToExternal(entity: AiAssessmentEntity): AssessmentExternalEntity {
        if (this.featureFlagQuery.getFlag(FeatureFlags.DevAssessmentTemplateSavedQuestions) && entity.isCustom && this.customValuesQuery.getEntity(entity.id)) {
            return {
                externalRelatedEntityId: entity.id,
                type: AiAssessmentEntityTypeEnum.CustomValue,
                isDefault: false
            };
        }

        return {
            externalRelatedEntityId: entity.id,
            type: entity.type,
            isDefault: !entity.isCustom
        };
    }

    parseExternalRelatedEntityToV1(externalEntity: AssessmentExternalEntity): AiAssessmentEntity {
        if (this.featureFlagQuery.getFlag(FeatureFlags.DevAssessmentTemplateSavedQuestions)) {
            if (externalEntity.type === AiAssessmentEntityTypeEnum.CustomValue) {
                const customValueEntity = this.customValuesQuery.getEntity(externalEntity.externalRelatedEntityId);
                if (!!customValueEntity) {                    
                    return {
                        id: customValueEntity.id,
                        type: AiAssessmentEntityTypeEnum[customValueEntity.type],
                        isCustom: true,
                    };
                }
                return;
            }
        }
            
        return {
            id: externalEntity.externalRelatedEntityId,
            type: externalEntity.type,
            isCustom: !externalEntity.isDefault
        };
    }

    mergeEquivalentDataTypes(dataTypes: { dataType: DataType, dataSources: DropdownOption[] }[]): { dataType: DataType, dataSources: DropdownOption[] }[] {
        const mergedDataTypesMap = new Map<string, { dataType: DataType, dataSources: DropdownOption[] }>();
    
        dataTypes.forEach(({ dataType, dataSources }) => {
            const normalizedId = this.convertPiiTypeToDataTypePipe.transform(dataType.id) || dataType.id;
    
            // If an equivalent dataType already exists in the map, merge the dataSources
            if (mergedDataTypesMap.has(normalizedId)) {
                const existingEntry = mergedDataTypesMap.get(normalizedId);
    
                // Merge unique dataSources
                const uniqueDataSources = [
                    ...existingEntry.dataSources,
                    ...dataSources.filter(ds => !existingEntry.dataSources.some(existingDs => existingDs.id === ds.id))
                ];
    
                // Update the map with merged dataSources
                mergedDataTypesMap.set(normalizedId, {
                    dataType: existingEntry.dataType, // Keep the first dataType as the main representation
                    dataSources: uniqueDataSources
                });
            } else {
                mergedDataTypesMap.set(normalizedId, { dataType, dataSources });
            }
        });
    
        // Return the merged result as an array
        return Array.from(mergedDataTypesMap.values());
    }
    

    parsePageResponseToInstanceV1(page: AiAssessmentPageResponse): AiAssessmentInstance {
        let dataFlowLocations;
        let data = page.sections?.sort((a, b) => a.ordinal - b.ordinal)?.reduce((acc, section) => {
            let sectionData: string | object = section.data;
            while (typeof sectionData === "string") {
                try {
                    sectionData = JSON.parse(sectionData);
                } catch (err) {
                    sectionData = {};
                }
            }

            if (Object.keys(sectionData).includes(AiAssessmentCustomComponentEnum.DataTypes)) {
                // Clone the dataTypes from the sectionData
                const dataTypes = structuredClone(sectionData[AiAssessmentCustomComponentEnum.DataTypes]);

                if(dataTypes && Array.isArray(dataTypes)) {
                    // Use the mergeEquivalentDataTypes function to merge equivalent data types
                    const mergedDataTypes = this.mergeEquivalentDataTypes(dataTypes || []);
                        
                    // Optionally, if you're updating sectionData:
                    sectionData[AiAssessmentCustomComponentEnum.DataTypes] = mergedDataTypes;
                }
            }
            

            if (Object.keys(sectionData).includes(AiAssessmentCustomComponentEnum.Risks)) {
                const risks = structuredClone(sectionData[AiAssessmentCustomComponentEnum.Risks]);
                sectionData[AiAssessmentCustomComponentEnum.Risks] = '';

                return {...acc, [section.name]: sectionData, risks};
            }

            if (Object.keys(sectionData).includes(AiAssessmentCustomComponentEnum.DataFlow)) {
                if(sectionData['dataFlowLocations']) {
                    dataFlowLocations = sectionData['dataFlowLocations'];
                };
            }

            return {...acc, [section.name]: sectionData};
        }, {});

        const relatedEntities = (page.externalRelatedEntities?.map(this.parseExternalRelatedEntityToV1.bind(this))?.filter(Boolean) || []);
        const relatedAssessments = (page.relatedAssessments?.map(page => ({ id: page.id, type: AiAssessmentEntityTypeEnum.LinkedAssessment })) || []);

        let templateSchema = page.sections?.sort((a, b) => a.ordinal - b.ordinal)?.map(section => {
            let sectionSchema: string | AiAssessmentsFormControl = section.schema;
            while (typeof sectionSchema === "string") {
                try {
                    sectionSchema = JSON.parse(sectionSchema);
                } catch (err) {
                    sectionSchema = {} as AiAssessmentsFormControl;
                }
            }

            if (section.type === AiAssessmentsFormControlTypeEnum.Group) {
                return {
                    type: section.type,
                    name: section.name,
                    label: section.label,
                    controls: sectionSchema?.controls
                };
            } else if (section.type === AiAssessmentsFormControlTypeEnum.CustomComponent) {
                return {
                    type: section.type,
                    name: section.name,
                    label: section.label,
                    placeholder: sectionSchema.placeholder
                };
            }
        });

        return {
            id: page.id,
            name: page.name,
            agentId: page.agentId,
            ownerId: page.ownerId ?? page.approverId,
            templateType: page.type,
            data: JSON.stringify(data),
            createdAt: page.createdDate,
            lastModified: page.updatedDate,
            state: page.state,
            relatedEntities: relatedEntities as AiAssessmentEntity[],
            relatedAssessments,
            reviewDate: page.reviewDate,
            approvedAt: page.lastApprovedAt,
            approverId: page.ownerId ?? page.approverId,
            templateSchema: JSON.stringify(templateSchema),
            dataFlowLocations,
        };
    }

    parseTemplateRequestToPageV2(request: AssessmentTemplateRequest): AssessmentTemplatePageRequest {
        const sections = [];
        const {name, type} = request;

        let requestSchema;

        try {
            requestSchema = JSON.parse(request.schema);
        } catch (err) {
            console.error('Could not parse request schema', err);
            requestSchema = [];
        }

        requestSchema.forEach((section, index) => {
            const {name, label, type} = section;
            const ordinal = index;
            const data = JSON.stringify({});
            const schema = JSON.stringify(section.controls?.length ? section.controls : []);
            sections.push({name, label, type, ordinal, schema, data});
        });

        return {name, sections, type} as AssessmentTemplatePageRequest;
    }

    parseTemplatePageToResponse(templatePage: AssessmentTemplatePageResponse): AssessmentTemplateResponse {        
        const templateResponse = {
            id: templatePage.id.toString(),
            name: templatePage.name,
            agentId: templatePage.agentId,
            type: templatePage.type,
            createdAt: templatePage.createdAt,
            lastModified: templatePage.updatedDate
        };

        const schema = [];
        templatePage.sections
            ?.sort((a, b) => a.ordinal - b.ordinal)
            ?.forEach(section => {
                let controls = JSON.parse(section.schema);
                if (typeof controls === "string") {
                    controls = JSON.parse(controls);
                }
                if (this.isObjectWithKey(controls, 'controls')) {
                    controls = controls.controls;
                }
                schema.push({
                    name: section.name, 
                    label: section.label, 
                    controls: controls, 
                    type: section.type
                });
        });
        
        return {...templateResponse, schema: JSON.stringify(schema)} as AssessmentTemplateResponse;
    }

    private isObjectWithKey(variable: any, key: string): boolean {
        return typeof variable === 'object' && variable !== null && variable.hasOwnProperty(key);
    }

    parseSectionControls(section: AiAssessmentsFormControl): AiAssessmentsFormControl {
        let controls = section.controls;
        if (typeof controls === 'string') {
          controls = JSON.parse(controls);
        }
        return {...section, controls: controls?.length ? controls : []} as AiAssessmentsFormControl;
    }

    getInstanceCustomFieldValues(instance: AiAssessmentInstance): Map<string, DropdownOption[]> {
        const map = new Map<string, DropdownOption[]>();
        const instanceCustomFieldsArr = instance.relatedEntities
            ?.filter(entity => entity.isCustom && entity.type === AiAssessmentEntityTypeEnum.CustomField)
            ?.map(entity => entity.id);
        
        if (instanceCustomFieldsArr?.length) {
            let instanceSchema: AiAssessmentsFormControl[];
            let instanceData;

            try {
                instanceSchema = JSON.parse(this.strEscape(instance.templateSchema)) as AiAssessmentsFormControl[];
            }
            catch (err) {
                console.error('Could not parse instance schema', err);
                instanceSchema = [];
            }

            try {
                instanceData = JSON.parse(this.strEscape(instance.data));
            }
            catch (err) {
                console.error('Could not parse instance data', err);
                instanceData = {};
            }

            instanceSchema.forEach(instanceSectionSchema => {
                const controls = instanceSectionSchema.controls;
                if (controls?.length) {
                    for (let control of controls) {
                        if (control.customFieldId) {
                            const customField = this.customFieldsQuery.getEntity(control.customFieldId);
                            if (customField && (customField.usedIn === FieldUsedInEnum.Assessment)) {
                                let value: DropdownOption[];
                                
                                switch (control.type) {
                                    case AiAssessmentsFormControlTypeEnum.Dropdown: {
                                        const options = customField.inputData?.inputOptions;
                                        const controlOptions = options?.map(o => ({ 
                                            id: o.id, 
                                            value: o.name, 
                                            chipColor: {
                                                textColor: '--mine-blue-dark',
                                                backgroundColor: o.color, 
                                            }} as DropdownOption));

                                        if (control.multiple) {
                                            value = control.value?.split(",").map(val => (controlOptions ?? control.options)?.find(option => option.value === val)
                                                ?? { value: val, chipColor: MineChip.get(ChipColorName.Grey) } as DropdownOption) as DropdownOption[];
                                        }
                                        else {
                                            value = [instanceData[instanceSectionSchema.name][control.name] as DropdownOption];
                                        }
                                    }
                                    break;
                                    
                                    case AiAssessmentsFormControlTypeEnum.Text:
                                        value = [{
                                            id: control.customFieldId,
                                            value: instanceData[instanceSectionSchema.name][control.name]
                                        } as DropdownOption];
                                        break;
                                    
                                    default:
                                        continue;
                                }
                                
                                map.set(control.customFieldId, value);
                            }
                        }
                    }
                }
            });
        }
        return map;
    }

    private strEscape(str: string): string {
        if (!str?.length) return str;
        return str.replace(/&quot;/g,'"');
    }
}
