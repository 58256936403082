export enum InviteMemberResponseMsg {
    Unknown = 'Unknown',
    NotAllowed = 'NotAllowed',
    NotAllowedUnlimitedAgents = 'NotAllowedUnlimitedAgents',
    MissingDepartment = 'MissingDepartment',
    InvalidEmailAddress = 'InvalidEmailAddress',
    NotAssociatedEmailAddress = 'NotAssociatedEmailAddress',
    InvalidDepartment = 'InvalidDepartment',
    InviteeAlreadyAgent = 'InviteeAlreadyAgent',
    NotAllowedProviderForUser = 'NotAllowedProviderForUser',
}