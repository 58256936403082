import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';

import { produce } from 'immer';
import { PiiSchemaItem } from 'src/app/api/models/pii-classifier/pii-classifier.interface';


export interface SystemSchemaState extends EntityState<PiiSchemaItem> { }

@Injectable({ 
	providedIn: 'root' 
})
@StoreConfig({ 
	name: 'systemSchema',
	idKey: 'id',
	producerFn: produce
})
export class SystemSchemaStore extends EntityStore<SystemSchemaState> {

	constructor() {
		super();
	}
}