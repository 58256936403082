import { style } from '@angular/animations';

export const fadeIn = [
   style({ opacity: 0, offset: 0 }),
   style({ opacity: 1, offset: 1 })
]

export const fadeInUp = [
   style({ opacity: 0, transform: 'translate3d(0, 100%, 0)', offset: 0 }),
   style({ opacity: 1, transform: 'translate3d(0, 0, 0)', offset: 1 })
]

export const fadeOutUp = [
   style({ opacity: 1, offset: 0 }),
   style({ opacity: 0, transform: 'translate3d(0, -100%, 0)', offset: 1 }),
]

export const scaleIn = [
   style({ transform: 'scale(0.1)', offset: 0 }),
   style({ transform: 'scale(1)', offset: 1 })
]

export const scaleOut = [
   style({ transform: 'scale(1)', offset: 0 }),
   style({ transform: 'scale(0.1)', opacity: 0, offset: 1 })
]

export const serviceCardFillDesktop = [
   style({ 'stroke-dasharray': '0, 1030', 'stroke-dashoffset': 1, offset: 0 }),
   style({ 'stroke-dasharray': '990, 30', 'stroke-dashoffset': -5, offset: .5 }),
   style({ 'stroke-dasharray': '220, 800', 'stroke-dashoffset': -1023, offset: 1 })
]

export const serviceCardFillTablet = [
   style({ 'stroke-dasharray': '0, 930', 'stroke-dashoffset': 1, offset: 0 }),
   style({ 'stroke-dasharray': '860, 70', 'stroke-dashoffset': -20, offset: .5 }),
   style({ 'stroke-dasharray': '191, 732', 'stroke-dashoffset': -930, offset: 1 })
]

export const serviceCardFillMobile = [
   style({ 'stroke-dasharray': '0, 710', 'stroke-dashoffset': 1, offset: 0 }),
   style({ 'stroke-dasharray': '670, 40', 'stroke-dashoffset': -10, offset: .5 }),
   style({ 'stroke-dasharray': '125, 575', 'stroke-dashoffset': -707, offset: 1 })
]

export const serviceCardEmptyDesktop = [
   style({ 'stroke-dasharray': '220, 800', 'stroke-dashoffset': -1023, offset: 0 }),
   style({ 'stroke-dasharray': '990, 30', 'stroke-dashoffset': -254, offset: .5 }),
   style({ 'stroke-dasharray': '0, 1030', 'stroke-dashoffset': 1, offset: 1 })
]

export const serviceCardEmptyTablet = [
   style({ 'stroke-dasharray': '191, 732', 'stroke-dashoffset': -930, offset: 0 }),
   style({ 'stroke-dasharray': '860, 70', 'stroke-dashoffset': -20, offset: .5 }),
   style({ 'stroke-dasharray': '0, 930', 'stroke-dashoffset': 1, offset: 1 })
]

export const serviceCardEmptyMobile = [
   style({ 'stroke-dasharray': '125, 575', 'stroke-dashoffset': -707, offset: 0 }),
   style({ 'stroke-dasharray': '670, 40', 'stroke-dashoffset': -10, offset: .5 }),
   style({ 'stroke-dasharray': '0, 710', 'stroke-dashoffset': 1, offset: 1 })
]

export const clusterCheckDone = [
   style({ 'stroke-dasharray': '5, 105', 'stroke-dashoffset': 3, offset: 0 }),
   style({ 'stroke-dasharray': '14, 85', 'stroke-dashoffset': 3, offset: .25 }),
   style({ 'stroke-dasharray': '15, 83', 'stroke-dashoffset': 9, offset: .5 }),
   style({ 'stroke-dasharray': '24, 74', 'stroke-dashoffset': 16, offset: .75 }),
   style({ 'stroke-dasharray': '35, 65', 'stroke-dashoffset': 25, offset: 1 })
]