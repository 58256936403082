import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, map } from 'rxjs';

import { AnalyticEventsLabels } from 'src/app/analytics/analytics-events-labels';
import { AnalyticsService } from 'src/app/analytics/analytics.service';
import { EventActionType } from 'src/app/analytics/event-action.enum';
import { Animations } from 'src/app/animations/animations';
import { FeatureFlags } from 'src/app/api/models/profile/profile-feature-flags.enum';
import { Channel } from 'src/app/channels/connection-channels/channel.interface';
import { ChannelService } from 'src/app/channels/connection-channels/channel.service';
import { ChannelId } from 'src/app/channels/connection-channels/connect-channel-id.enum';
import { FeatureFlagQuery } from 'src/app/feature-flag/state/feature-flag.query';
import { RoutesManager } from 'src/app/shared/models/routes.interfaces';

@Component({
  selector: 'home-channels',
  templateUrl: './home-channels.component.html',
  styleUrls: ['./home-channels.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [Animations.fadeInOnEnter]
})
export class HomeChannelsComponent implements OnInit {

  channels$: Observable<Channel[]>;

  private hideConnectToMine = false;

  constructor(
    private router: Router,
    private channelService: ChannelService,
    private featureFlagQuery: FeatureFlagQuery,
    private analyticsService: AnalyticsService,
  ) { }

  ngOnInit(): void {
		this.initFeatureFlags();
		this.initChannels();
  }

  private initFeatureFlags(): void {
		this.hideConnectToMine = this.featureFlagQuery.getFlag(FeatureFlags.HideConnectToMine);
  }
  
  private initChannels(): void {
		this.channels$ = this.channelService.getChannels(true).pipe(
			map(channels => channels.filter(channel => this.hideConnectToMine ? channel.id !== ChannelId.Mine : true))
		);
	}

  navigateToChannels(): void {
    this.analyticsService.track({
      eventAction: EventActionType.Click,
      eventCategory: AnalyticEventsLabels.HOME_channels,
    });

    this.router.navigate([RoutesManager.companySettings, RoutesManager.general]);
    return;
  }
}
