import { Injectable } from '@angular/core';
import { first, map } from 'rxjs';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { tap } from 'rxjs';
import { ApiClientProfileService } from 'src/app/api/api-client-profile.service';
import { OnboardingTaskEnum } from 'src/app/api/models/profile/profile-onboarding-tasks.enum';
import { OnboardingProgressResponse, OnboardingTasks } from 'src/app/api/models/profile/profile-onboarding-tasks.interface';

@Injectable({
  providedIn: 'root'
})
export class OnboardingTasksService {

  private onboardingTasksSub = new BehaviorSubject<OnboardingTasks>({} as OnboardingTasks);
  public onboardingTasks$ = this.onboardingTasksSub.asObservable();
  
  constructor(private apiClientProfile: ApiClientProfileService) { }

  initOnboardingTasks(profileTasks: OnboardingProgressResponse): void {
      const tasks = {
        verifyCompany: true,
        ... profileTasks
      } as OnboardingTasks

      this.onboardingTasksSub.next(tasks);
  }
  
  updateOnboardingTasks(task: OnboardingTaskEnum): Observable<void> {
    const tasks = this.onboardingTasksSub.getValue(); 
    
    if (!tasks[`${task}`]) { 
      tasks[`${task}`] = true; 
      return this.apiClientProfile.updateOnboardingProgress(tasks).pipe( 
        tap(() => this.onboardingTasksSub.next(tasks)) 
      )} 
      
    return of(void 0); //returns stream
	}

  isOnbordingFinished(): Observable<boolean> {
    return this.onboardingTasks$.pipe(
      first(),
      map(tasks => Object.values(tasks).every(item => item))
    );
  }

  getOrderHomePageTasks(): OnboardingTaskEnum[] {
    return [
      OnboardingTaskEnum.verifyCompany,
      OnboardingTaskEnum.dataMapping,
      OnboardingTaskEnum.closeRequest,
      OnboardingTaskEnum.customizePrivacyCenter,
      OnboardingTaskEnum.inviteTeamMember,
      OnboardingTaskEnum.setupIntegration
    ];
  }
}
 