import { Injectable } from "@angular/core";
import { RxState } from "@rx-angular/state";
import { rxActions } from "@rx-angular/state/actions";
import { catchError, combineLatest, delay, filter, map, Observable, of, shareReplay, switchMap } from "rxjs";

import { LoggerService } from "src/app/logger/logger.service";
import { AiAssessmentsState } from "../models/ai-assessments-state.interface";
import { AiAssessmentActions } from "../models/ai-assessment-actions.interface";
import { ApiClientAiAssessmentsService } from "src/app/api/api-client-ai-assessments.service";
import { FeatureFlagQuery } from "src/app/feature-flag/state/feature-flag.query";
import { FeatureFlags } from "src/app/api/models/profile/profile-feature-flags.enum";
import { AssessmentTemplateResponse } from "src/app/api/models/ai-assessments/ai-assessments.interface";

@Injectable({
    providedIn: 'root'
})
export class AiAssessmentsStore extends RxState<AiAssessmentsState> {

    private readonly loggerName: string = 'AiAssessmentsStore';
    
    private readonly initState = {
        empty: undefined,
        loading: true,
        disabled: false,
        templates: undefined,
        assessments: undefined,
    } as AiAssessmentsState;

    private readonly _templates$ = this.featureFlagQuery.selectLoading().pipe(
        filter(loading => !loading),
        switchMap(() => this.featureFlagQuery.selectFlag(FeatureFlags.DevAIAssessmentsCustomTemplates)),
        switchMap(flag => flag ? this.initTemplates() : of(new Map()))
    );

    private initTemplates(): Observable<Map<string, AssessmentTemplateResponse>> {
        return this.apiClientAiAssessmentsService.getTemplates().pipe(
            shareReplay(),
            delay(100),
            map(templates => new Map(templates.map(template => ([template.id, template])))),
            catchError(err => {
                console.error(err);
                this.logger.error(this.loggerName, err);
                return of(new Map());
            })
        );
    }
    
    private readonly _assessments$ = this.apiClientAiAssessmentsService.getInstances().pipe(
        shareReplay(),
        delay(100),
        map(assessments => new Map(assessments.map(assessment => ([assessment.id, assessment])))),
        catchError(err => {
            console.error(err);
            this.logger.error(this.loggerName, err);
            return of(new Map());
        })
    );

    public actions = rxActions<AiAssessmentActions>();

    constructor(
        private logger: LoggerService,
        private featureFlagQuery: FeatureFlagQuery,
        private apiClientAiAssessmentsService: ApiClientAiAssessmentsService,
    ) {
        super();
        this.set(this.initState);
        this.connect('templates', this._templates$);
        this.connect('assessments', this._assessments$);
        this.connect('loading',  combineLatest([this._templates$, this._assessments$]).pipe(
            map(() => false)
        ));
        this.connect('empty', this._assessments$.pipe(
            map(assessments => !assessments?.size)
        ));
    }
}