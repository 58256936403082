import { Component, ChangeDetectionStrategy, Input, OnInit } from '@angular/core';
import { DialogRef } from '@ngneat/dialog';
import { ContentPipeModule } from 'src/app/services/content/content-pipe.module';
import { MineSpinnerComponent } from 'src/app/shared/mine-spinner/mine-spinner.component';

@Component({
	standalone: true,
	selector: 'mine-async-operation-spinner-dialog',
	templateUrl: './mine-async-operation-spinner-dialog.html',
	styleUrls: ['./mine-async-operation-spinner-dialog.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [MineSpinnerComponent, ContentPipeModule]
})
export class MineAsyncOperationSpinnerDialogComponent implements OnInit {
	
	@Input()
	title: string;

  	constructor(private dialogRef: DialogRef) { }
  
	ngOnInit(): void {
		this.title = this.dialogRef.data;
	}

	closeDialog() {
		this.dialogRef.close();
	}
}