import { Component, OnInit, HostListener, ElementRef, ChangeDetectionStrategy, ChangeDetectorRef, DestroyRef } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Observable, filter, finalize, first, tap } from 'rxjs';
import { Router } from '@angular/router';

import { AuthService } from '../../auth/auth.service';
import { ProfileQuery } from 'src/app/profile/state/profile.query';
import { FeatureFlagQuery } from 'src/app/feature-flag/state/feature-flag.query';
import { ProfileFeatureFlags } from 'src/app/api/models/profile/profile-feature-flags';
import { Animations } from 'src/app/animations/animations';

import { ChatService } from 'src/app/chat/chat.service';
import { AuthProviders } from 'src/app/api/models/auth/auth-providers';
import { ElasticSidenavService } from '../app-elastic-sidenav/app-elastic-sidenav.service';
import { LocalStorageHelper } from 'src/app/services/localStorage-helper';
import { ConnectedCompany } from 'src/app/api/models/profile/profile-connected-companies';
import { ContentPipe } from 'src/app/services/content/content.pipe';
import { RoleEnum } from '../models/permission-role.enum';
import { RoutesManager } from 'src/app/shared/models/routes.interfaces';
import { RolePermissionsHelper } from 'src/app/auth/role-permmissions-helper';
import { FeatureFlags } from 'src/app/api/models/profile/profile-feature-flags.enum';
import { PusherService } from 'src/app/mineos-chatbot/pusher.service';
import { Profile } from 'src/app/profile/models/profile.interface';
import { IntegrationIpaasService } from "../../systems/services/integration-ipaas.service";

@Component({
	selector: 'app-menu',
	templateUrl: './app-menu.component.html',
	styleUrls: ['./app-menu.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [Animations.fadeInOut]
})
export class AppMenuComponent implements OnInit {	
	readonly routesManager = RoutesManager;
	
	readonly developersRoute = `${RoutesManager.developers}/${RoutesManager.api_keys}`;
	
	profile$: Observable<Profile>;
	featureFlags$: Observable<ProfileFeatureFlags>;
	agentName: string;
	checked: boolean;
	shrink = false;	
	connectedCompanies: ConnectedCompany[];

	planPerrmissions: RoleEnum[];

	constructor(
		private profileQuery: ProfileQuery,
		private authService: AuthService,
		private router: Router,
		private intercom: ChatService,
		private elementRef: ElementRef,
		private featureFlagQuery: FeatureFlagQuery,
		private elasticSidenavService: ElasticSidenavService,
		private pusherService: PusherService,
		private contentPipe: ContentPipe,
        private ipaasService: IntegrationIpaasService,
		private destroyRef: DestroyRef,
		private cdr: ChangeDetectorRef
	) { }

	ngOnInit() {
		this.initAllowedRoles();
		this.profile$ = this.profileQuery
			.select()
			.pipe(
				tap((profile) => (this.agentName = this.getAgentName(profile))),
				tap(profile => this.setConnectedCompanies(profile)),
			);
		
		this.featureFlags$ = this.featureFlagQuery.select();
		this.elasticSidenavService.shrink$.pipe(
			tap(res => this.shrink = res),
			tap(res => this.cdr.detectChanges()),
			takeUntilDestroyed(this.destroyRef)
		).subscribe();
	}

	private initAllowedRoles(): void {
		this.planPerrmissions = RolePermissionsHelper.getPerrmitedRoleByRoute([RoutesManager.plans]);
	}

	private setConnectedCompanies(profile: Profile): void {
		this.connectedCompanies = profile.connectedCompanies
			.filter(res => res.companyId !== profile.companyId)
			.sort((a, b) => a.companyName.localeCompare(b.companyName));
	}

	private getAgentName(profile: Profile): string {
		const name = `${profile.firstName ?? ''} ${profile.lastName ?? ''}`;
		// when no first + last name --> show email instead
		return name.trim() === '' ? profile.email : name;
	}

	@HostListener('document:click', ['$event.target'])
	onClick(targetElement) {
		const clickedInside = this.elementRef.nativeElement.contains(targetElement);
		if (!clickedInside) {
			this.checked = false;
		}
	}

	navigate(route: string): void {
		this.checked = false;
		if (route == RoutesManager.login) {
			const isAuth0Provider = this.profileQuery.getValue()?.identityProvider?.toLowerCase() === AuthProviders.auth0;
			this.authService.userLogoutSession().pipe(
				first(),
				filter(() => !isAuth0Provider),
				finalize(() => this.router.navigate([route])),
			).subscribe();
		} 
		else {
			this.router.navigateByUrl(route);
		}
	}

	onFeedbackClick(): void {
		this.checked = false;
		this.intercom.show();
	}

	changeCompany(companyId: string): void {
        this.ipaasService.removeJwtToken();
        if (this.featureFlagQuery.getFlag(FeatureFlags.DevMineOSChatbot)) {
			// remove copilot last ready message
			sessionStorage.removeItem(`fe_ready-${this.profileQuery.getCompanyId()}`);
			// disconnect from pusher
			this.pusherService.disconnect();
		}
		
		LocalStorageHelper.setUserIdAndConnectedCompany(this.profileQuery.getValue().userId, companyId);
		window.location.href = RoutesManager.homePage;
	}

	openCompanySettings(): void {
		this.checked = false;
		this.router.navigate([RoutesManager.companySettings, RoutesManager.profile]);
	}

	openPlans(): void {
		window.open(this.contentPipe.transform('menu.plansUrl'), '_blank');
	}
}
