<label class="radio-button__container" [ngClass]="{
		'radio-button__container--disabled': !!disabled,
    'radio-button__container--hide-checkmark': hideCheckmark
	}">

  <input type="radio" #inputRef
    [id]="id" 
    [name]="name" 
    [value]="value" 
    [disabled]="disabled" 
    [(ngModel)]="modelValue"
    (change)="valueChanged(value)">
  
  <span class="radio-button__checkmark"></span>
  
  <ng-content></ng-content>

</label>