import { Component, OnInit, Output, EventEmitter, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { AnimationEvent } from '@angular/animations';

import { AnimationStateEnum } from 'src/app/animations/animation-state.enum';
import { Animations } from 'src/app/animations/animations';
import { CommonModule } from '@angular/common';

@Component({
	standalone: true,
	selector: 'mine-check-mark',
	templateUrl: './mine-check-mark.component.html',
	styleUrls: ['./mine-check-mark.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [Animations.checkmark],
	imports: [CommonModule]
})
export class MineCheckMarkComponent implements OnInit {

	ready: boolean;
	checkmarkState = AnimationStateEnum.Hide;
	finished: boolean;

	@Output()
	done = new EventEmitter<void>();

	constructor(private cdr: ChangeDetectorRef) { }

	ngOnInit() {
		window.setTimeout(() => {
			this.cdr.markForCheck();
			this.ready = true;
		}, 500);
	}

	backgroundAnimationDone(event: AnimationEvent): void {
		if (event.fromState === AnimationStateEnum.BeforeCreation) {
			this.checkmarkState = AnimationStateEnum.Show;
		}
	}

	checkmarkDone(event: AnimationEvent): void {
		if (event.toState === AnimationStateEnum.Show) {
			this.finished = true;
			this.done.emit();
		}
	}
}
