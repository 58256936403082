import { Injectable } from '@angular/core';
import { HttpClientHelperService } from './http-client-helper.service';
import { Observable } from 'rxjs';
import { PiiDataTypesDeepDiveResponse, PiiSchemaDeepDiveResponse, PiiSystemResponse, PiiSystemDataTypesResponse, PiiSystemSchemaResponse, PiiSystemsResponse, ExportDataTypesResponse, DebugValueResponse, DebugClassifierData } from './models/pii-classifier/pii-classifier.interface';
import { HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ApiClientPiiClassifierService {
	private readonly controller = 'pii-classifier';
	private readonly systemsPath = 'systems';

  	constructor(private httpClientHelper: HttpClientHelperService) { }

  	getAllPiiSystems(): Observable<PiiSystemsResponse> {
		return this.httpClientHelper.invokeGetAuth<PiiSystemsResponse>(`${this.controller}/v2/${this.systemsPath}`);
	}

  	getPiiSystem(systemId: string): Observable<PiiSystemResponse> {
		return this.httpClientHelper.invokeGetAuth<PiiSystemResponse>(`${this.controller}/v2/${this.systemsPath}/${systemId}`);
	}

  	getPiiSystemDataTypes(systemId: string): Observable<PiiSystemDataTypesResponse> {
		return this.httpClientHelper.invokeGetAuth<PiiSystemDataTypesResponse>(`${this.controller}/v2/${this.systemsPath}/${systemId}/pii-data-types`);
	}

  	getPiiSystemDataTypesDeepDive(systemId: string, dataTypes: string[]): Observable<PiiDataTypesDeepDiveResponse> {
		let qParams = new HttpParams();
		qParams = qParams.appendAll({piiDataTypes: dataTypes.join(',')})
		return this.httpClientHelper.invokeGetAuth<PiiDataTypesDeepDiveResponse>(`${this.controller}/v2/${this.systemsPath}/${systemId}/deep-dive`, qParams);
	}

  	getPiiSystemSchema(systemId: string): Observable<PiiSystemSchemaResponse> {
		return this.httpClientHelper.invokeGetAuth<PiiSystemSchemaResponse>(`${this.controller}/v2/${this.systemsPath}/${systemId}/schemas`);
	}

  	getPiiSystemSchemaDeepDive(systemId: string, rowId: string): Observable<PiiSchemaDeepDiveResponse> {
		let qParams = new HttpParams();
		qParams = qParams.appendAll({rowId})
		
		return this.httpClientHelper.invokeGetAuth<PiiSchemaDeepDiveResponse>(`${this.controller}/v2/${this.systemsPath}/${systemId}/schemas/deep-dive`, qParams);
	}

  	exportByDataTypes(systemId: string, piiDataTypes: string[], dataType: string): Observable<ExportDataTypesResponse> {
		const body = {
			dataType,
			piiDataTypes
		}

		return this.httpClientHelper.invokePost<ExportDataTypesResponse>(`${this.controller}/v2/${this.systemsPath}/${systemId}/export-by-data-types`, body);
	}


	getDebugMode(systemId: string, data: DebugClassifierData): Observable<DebugValueResponse> {
		return this.httpClientHelper.invokePostAuth<DebugValueResponse>(`${this.controller}/v2/${this.systemsPath}/${systemId}/debug-value`, data);
	}
}
