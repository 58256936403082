import { ChangeDetectionStrategy, Component, OnInit, Signal, WritableSignal, computed, signal } from '@angular/core';
import { switchMap, first, tap, filter } from 'rxjs';
import { ProfileQuery } from 'src/app/profile/state/profile.query';
import { ContentPipe } from 'src/app/services/content/content.pipe';
import { EmptyStateContent } from '../table-empty-state/mine-table-empty-state.interface';
import { ContentPipeModule } from 'src/app/services/content/content-pipe.module';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { SafePipe } from '../pipes/safe.pipe';

@Component({
  standalone: true,
  selector: 'mine-no-permissions',
  templateUrl: './mine-no-permissions.component.html',
  styleUrls: ['./mine-no-permissions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, ContentPipeModule, SafePipe, NgOptimizedImage]

})
export class MineNoPermissionsComponent implements OnInit {
  mail: WritableSignal<string> = signal(null);
  content: Signal<EmptyStateContent> = computed(() => this.contentPipe.transform('company-settings.sectionForAdmins', { params: { mail: this.mail() }}));

  constructor (
    private contentPipe: ContentPipe,
    private profileQuery: ProfileQuery,
  ) { }

  ngOnInit(): void {
    this.profileQuery.selectLoading().pipe(
      filter(res => !res),
      switchMap(() => this.profileQuery.selectAdminMail()), 
      first(),
      tap(mail => this.mail.set(mail)),
    ).subscribe();
  }

}
