import { Component, Input, Output, EventEmitter, ElementRef, ViewChild, AfterViewInit, OnChanges, SimpleChanges, ChangeDetectorRef, HostBinding } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AnalyticsEvent } from 'src/app/analytics/analytics-event';
import { AnalyticsService } from 'src/app/analytics/analytics.service';
import { EventActionType } from 'src/app/analytics/event-action.enum';
import { MineSpinnerComponent } from '../mine-spinner/mine-spinner.component';
import { ButtonTheme } from '../models/button-theme.enum';
import { TippyModule } from '../tippy.module';

@Component({
	standalone: true,
	selector: 'mine-button-primary',
	templateUrl: './mine-button-primary.component.html',
	styleUrls: ['./mine-button-primary.component.scss'],
	imports: [CommonModule, TippyModule, MineSpinnerComponent]
})
export class MineButtonPrimaryComponent implements AfterViewInit, OnChanges {

	@Input()
	id: string = 'btn-primary'; 

	@Input()
	text: string;

	@Input()
	disabled: boolean;

	@Input()
	tooltip: string;

	@Input() 
	theme: ButtonTheme = ButtonTheme.Purple;

	@Input()
	customStyle: string = '';

	@Input()
	spinnerSize = 24;

	@Input()
	spinner: boolean;

	@Input()
	spinnerCustomStyle: string = '';
	
	@Input()
	eventCatagory: string;

	@Input()
	eventAction: string = EventActionType.Click;

	@Output()
	onClick = new EventEmitter<Event>();

	@ViewChild('content', { static: true })
	content: ElementRef;

	@HostBinding('class') get hostClass() {
		if (this.disabled) {
			return 'disabled ' + this.theme;
		} else {
			return this.theme;
		}
	}
	
	constructor(
		private cdref: ChangeDetectorRef,
		private analytics: AnalyticsService
	) { }

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.text) {
			this.setContentWidth();
		}
	}

	ngAfterViewInit(): void {
		this.setContentWidth();
	}

	onBtnClick($event: Event) {
		if (!this.disabled) {
			if (this.eventCatagory) {
				const e = {
					eventCategory: this.eventCatagory ?? this.text, 
					eventAction: this.eventAction
				} as AnalyticsEvent;
				this.analytics.track(e);
			}

			this.onClick.emit($event);
		}
	}

	private setContentWidth(): void {
		this.content.nativeElement.style.minWidth = 0;
		this.cdref.detectChanges();

		this.content.nativeElement.style.minWidth = `${this.content.nativeElement.offsetWidth}px`;
	}
}
