import { Injectable } from '@angular/core';
import { DefaultUrlSerializer, UrlSerializer, UrlTree } from '@angular/router';

@Injectable({
    providedIn: "root"
})
export class CustomUrlSerializer implements UrlSerializer {

   private serializer = new DefaultUrlSerializer();

   parse(url: string): UrlTree {
      const decoded = decodeURIComponent(url);
      return this.serializer.parse(decoded);
   }

   serialize(tree: UrlTree): string {
      return this.serializer.serialize(tree);
   }
}