import { DropdownOption } from "src/app/shared/mine-dropdown/mine-dropdown.interface";

const codeToCountry = new Map<string, string>([
	['AF', 'Afghanistan'],
	['AL', 'Albania'],
	['DZ', 'Algeria'],
	['AS', 'American Samoa'],
	['AD', 'Andorra'],
	['AO', 'Angola'],
	['AI', 'Anguilla'],
	['AQ', 'Antarctica'],
	['AG', 'Antigua and Barbuda'],
	['AR', 'Argentina'],
	['AM', 'Armenia'],
	['AW', 'Aruba'],
	['AU', 'Australia'],
	['AT', 'Austria'],
	['AZ', 'Azerbaijan'],
	['BS', 'Bahamas'],
	['BH', 'Bahrain'],
	['BD', 'Bangladesh'],
	['BB', 'Barbados'],
	['BY', 'Belarus'],
	['BE', 'Belgium'],
	['BZ', 'Belize'],
	['BJ', 'Benin'],
	['BM', 'Bermuda'],
	['BT', 'Bhutan'],
	['BO', 'Bolivia'],
	['BA', 'Bosnia and Herzegovina'],
	['BW', 'Botswana'],
	['BR', 'Brazil'],
	['IO', 'British Indian Ocean Territory'],
	['VG', 'British Virgin Islands'],
	['BN', 'Brunei'],
	['BG', 'Bulgaria'],
	['BF', 'Burkina Faso'],
	['MM', 'Myanmar'],
	['BI', 'Burundi'],
	['KH', 'Cambodia'],
	['CM', 'Cameroon'],
	['CA', 'Canada'],
	['CV', 'Cape Verde'],
	['KY', 'Cayman Islands'],
	['CF', 'Central African Republic'],
	['TD', 'Chad'],
	['CL', 'Chile'],
	['CN', 'China'],
	['CX', 'Christmas Island'],
	['CC', 'Cocos Islands'],
	['CO', 'Colombia'],
	['KM', 'Comoros'],
	['CG', 'Republic of the Congo'],
	['CD', 'Democratic Republic of the Congo'],
	['CK', 'Cook Islands'],
	['CR', 'Costa Rica'],
	['HR', 'Croatia'],
	['CU', 'Cuba'],
	['CW', 'Curacao'],
	['CY', 'Cyprus'],
	['CZ', 'Czech Republic'],
	['DK', 'Denmark'],
	['DJ', 'Djibouti'],
	['DM', 'Dominica'],
	['DO', 'Dominican Republic'],
	['TL', 'East Timor'],
	['EC', 'Ecuador'],
	['EG', 'Egypt'],
	['SV', 'El Salvador'],
	['GQ', 'Equatorial Guinea'],
	['ER', 'Eritrea'],
	['EE', 'Estonia'],
	['ET', 'Ethiopia'],
	['FK', 'Falkland Islands'],
	['FO', 'Faroe Islands'],
	['FJ', 'Fiji'],
	['FI', 'Finland'],
	['FR', 'France'],
	['PF', 'French Polynesia'],
	['GA', 'Gabon'],
	['GM', 'Gambia'],
	['GE', 'Georgia'],
	['DE', 'Germany'],
	['GH', 'Ghana'],
	['GI', 'Gibraltar'],
	['GR', 'Greece'],
	['GL', 'Greenland'],
	['GD', 'Grenada'],
	['GU', 'Guam'],
	['GT', 'Guatemala'],
	['GG', 'Guernsey'],
	['GN', 'Guinea'],
	['GW', 'Guinea-Bissau'],
	['GY', 'Guyana'],
	['HT', 'Haiti'],
	['HN', 'Honduras'],
	['HK', 'Hong Kong'],
	['HU', 'Hungary'],
	['IS', 'Iceland'],
	['IN', 'India'],
	['ID', 'Indonesia'],
	['IR', 'Iran'],
	['IQ', 'Iraq'],
	['IE', 'Ireland'],
	['IM', 'Isle of Man'],
	['IL', 'Israel'],
	['IT', 'Italy'],
	['CI', 'Ivory Coast'],
	['JM', 'Jamaica'],
	['JP', 'Japan'],
	['JE', 'Jersey'],
	['JO', 'Jordan'],
	['KZ', 'Kazakhstan'],
	['KE', 'Kenya'],
	['KI', 'Kiribati'],
	['XK', 'Kosovo'],
	['KW', 'Kuwait'],
	['KG', 'Kyrgyzstan'],
	['LA', 'Laos'],
	['LV', 'Latvia'],
	['LB', 'Lebanon'],
	['LS', 'Lesotho'],
	['LR', 'Liberia'],
	['LY', 'Libya'],
	['LI', 'Liechtenstein'],
	['LT', 'Lithuania'],
	['LU', 'Luxembourg'],
	['MO', 'Macau'],
	['MK', 'Macedonia'],
	['MG', 'Madagascar'],
	['MW', 'Malawi'],
	['MY', 'Malaysia'],
	['MV', 'Maldives'],
	['ML', 'Mali'],
	['MT', 'Malta'],
	['MH', 'Marshall Islands'],
	['MR', 'Mauritania'],
	['MU', 'Mauritius'],
	['YT', 'Mayotte'],
	['MX', 'Mexico'],
	['FM', 'Micronesia'],
	['MD', 'Moldova'],
	['MC', 'Monaco'],
	['MN', 'Mongolia'],
	['ME', 'Montenegro'],
	['MS', 'Montserrat'],
	['MA', 'Morocco'],
	['MZ', 'Mozambique'],
	['NA', 'Namibia'],
	['NR', 'Nauru'],
	['NP', 'Nepal'],
	['NL', 'Netherlands'],
	['AN', 'Netherlands Antilles'],
	['NC', 'New Caledonia'],
	['NZ', 'New Zealand'],
	['NI', 'Nicaragua'],
	['NE', 'Niger'],
	['NG', 'Nigeria'],
	['NU', 'Niue'],
	['MP', 'Northern Mariana Islands'],
	['KP', 'North Korea'],
	['NO', 'Norway'],
	['OM', 'Oman'],
	['PK', 'Pakistan'],
	['PW', 'Palau'],
	['PA', 'Panama'],
	['PG', 'Papua New Guinea'],
	['PY', 'Paraguay'],
	['PE', 'Peru'],
	['PH', 'Philippines'],
	['PN', 'Pitcairn'],
	['PL', 'Poland'],
	['PT', 'Portugal'],
	['PR', 'Puerto Rico'],
	['QA', 'Qatar'],
	['RE', 'Reunion'],
	['RO', 'Romania'],
	['RU', 'Russia'],
	['RW', 'Rwanda'],
	['BL', 'Saint Barthelemy'],
	['WS', 'Samoa'],
	['SM', 'San Marino'],
	['ST', 'Sao Tome and Principe'],
	['SA', 'Saudi Arabia'],
	['SN', 'Senegal'],
	['RS', 'Serbia'],
	['SC', 'Seychelles'],
	['SL', 'Sierra Leone'],
	['SG', 'Singapore'],
	['SX', 'Sint Maarten'],
	['SK', 'Slovakia'],
	['SI', 'Slovenia'],
	['SB', 'Solomon Islands'],
	['SO', 'Somalia'],
	['ZA', 'South Africa'],
	['KR', 'South Korea'],
	['SS', 'South Sudan'],
	['ES', 'Spain'],
	['LK', 'Sri Lanka'],
	['SH', 'Saint Helena'],
	['KN', 'Saint Kitts and Nevis'],
	['LC', 'Saint Lucia'],
	['MF', 'Saint Martin'],
	['PM', 'Saint Pierre and Miquelon'],
	['VC', 'Saint Vincent and the Grenadines'],
	['SD', 'Sudan'],
	['SR', 'Suriname'],
	['SJ', 'Svalbard and Jan Mayen'],
	['SZ', 'Swaziland'],
	['SE', 'Sweden'],
	['CH', 'Switzerland'],
	['SY', 'Syria'],
	['TW', 'Taiwan'],
	['TJ', 'Tajikistan'],
	['TZ', 'Tanzania'],
	['TH', 'Thailand'],
	['TG', 'Togo'],
	['TK', 'Tokelau'],
	['TO', 'Tonga'],
	['TT', 'Trinidad and Tobago'],
	['TN', 'Tunisia'],
	['TR', 'Turkey'],
	['TM', 'Turkmenistan'],
	['TC', 'Turks and Caicos Islands'],
	['TV', 'Tuvalu'],
	['AE', 'United Arab Emirates'],
	['UG', 'Uganda'],
	['GB', 'United Kingdom'],
	['UA', 'Ukraine'],
	['UY', 'Uruguay'],
	['US', 'United States'],
	['UZ', 'Uzbekistan'],
	['VU', 'Vanuatu'],
	['VA', 'Vatican'],
	['VE', 'Venezuela'],
	['VN', 'Vietnam'],
	['VI', 'U.S. Virgin Islands'],
	['WF', 'Wallis and Futuna'],
	['EH', 'Western Sahara'],
	['YE', 'Yemen'],
	['ZM', 'Zambia'],
	['ZW', 'Zimbabwe'],
]);

const states = new Map<string, string[]>([
	['Australia', ['New South Wales', 'Queensland', 'South Australia', 'Tasmania', 'Victoria', 'Western Australia', 'Australian Capital Territory', 'Northern Territory']],
	[
		'Brazil',
		[
			'Acre',
			'Alagoas',
			'Amapá',
			'Amazonas',
			'Bahia',
			'Ceará',
			'Distrito Federal',
			'Espírito Santo',
			'Goiás',
			'Maranhão',
			'Mato Grosso',
			'Mato Grosso do Sul',
			'Minas Gerais',
			'Pará',
			'Paraíba',
			'Paraná',
			'Pernambuco',
			'Piauí',
			'Rio de Janeiro',
			'Rio Grande do Norte',
			'Rio Grande do Sul',
			'Rondônia',
			'Roraima',
			'Santa Catarina',
			'São Paulo',
			'Sergipe',
			'Tocantins',
		],
	],
	[
		'Canada',
		[
			'Alberta',
			'British Columbia',
			'Manitoba',
			'New Brunswick',
			'Newfoundland and Labrador',
			'Northwest Territories',
			'Nova Scotia',
			'Nunavut',
			'Ontario',
			'Prince Edward Island',
			'Quebec',
			'Saskatchewan',
			'Yukon',
		]
	],
	['Myanmar', ['Chin', 'Kachin', 'Kayah', 'Kayin', 'Mon', 'Rakhine', 'Shan']],
	[
		'Ethiopia',
		[
			'Addis Ababa',
			'Afar',
			'Amhara',
			'Benshangul-Gumaz',
			'Dire Dawa',
			'Gambela Peoples',
			'Harari People',
			'Oromia',
			'Somali',
			'Southern Nations, Nationalities and Peoples',
			'Tigray',
		],
	],
	[
		'Germany',
		[
			'Baden-Württemberg',
			'Bayern',
			'Berlin',
			'Brandenburg',
			'Bremen',
			'Hamburg',
			'Hessen',
			'Niedersachsen',
			'Mecklenburg-Vorpommern',
			'Nordrhein-Westfalen',
			'Rheinland-Pfalz',
			'Saarland',
			'Sachsen',
			'Sachsen-Anhalt',
			'Schleswig-Holstein',
			'Thüringen',
		],
	],
	[
		'India',
		[
			'Andhra Pradesh',
			'Arunachal Pradesh',
			'Assam',
			'Bihar',
			'Chhattisgarh',
			'Goa',
			'Gujarat',
			'Haryana',
			'Himachal Pradesh',
			'Jharkhand',
			'Karnataka',
			'Kerala',
			'Madhya Pradesh',
			'Maharashtra',
			'Manipur',
			'Meghalaya',
			'Mizoram',
			'Nagaland',
			'Odisha',
			'Punjab',
			'Rajasthan',
			'Sikkim',
			'Tamil Nadu',
			'Telangana',
			'Tripura',
			'Uttar Pradesh',
			'Uttarakhand',
			'West Bengal',
		],
	],
	[
		'Mexico',
		[
			'Aguascalientes',
			'Baja California',
			'Baja California Sur',
			'Campeche',
			'Chiapas',
			'Chihuahua',
			'Coahuila',
			'Colima',
			'Durango',
			'Federal District',
			'Guanajuato',
			'Guerrero',
			'Hidalgo',
			'Jalisco',
			'México',
			'Michoacán',
			'Morelos',
			'Nayarit',
			'Nuevo León',
			'Oaxaca',
			'Puebla',
			'Querétaro',
			'Quintana Roo',
			'San Luis Potosí',
			'Sinaloa',
			'Sonora',
			'Tabasco',
			'Tamaulipas',
			'Tlaxcala',
			'Veracruz',
			'Yucatán',
			'Zacatecas',
		],
	],
	['Micronesia', ['Yap', 'Chuuk', 'Pohnpei', 'Kosrae']],
	[
		'Nigeria',
		[
			'Abia',
			'Adamawa',
			'Akwa Ibom',
			'Anambra',
			'Bauchi',
			'Bayelsa',
			'Benue',
			'Borno',
			'Cross River',
			'Delta',
			'Ebonyi',
			'Edo',
			'Ekiti',
			'Enugu',
			'Gombe',
			'Imo',
			'Jigawa',
			'Kaduna',
			'Kano',
			'Katsina',
			'Kebbi',
			'Kogi',
			'Kwara',
			'Lagos',
			'Nasarawa',
			'Niger',
			'Ogun',
			'Ondo',
			'Osun',
			'Oyo',
			'Plateau',
			'Rivers',
			'Sokoto',
			'Taraba',
			'Yobe',
			'Zamfara',
		],
	],
	[
		'Palau',
		[
			'Aimeliik',
			'Airai',
			'Angaur',
			'Hatohobei',
			'Kayangel',
			'Koror',
			'Melekeok',
			'Ngaraard',
			'Ngarchelong',
			'Ngardmau',
			'Ngatpang',
			'Ngchesar',
			'Ngeremlengui',
			'Ngiwal',
			'Peleliu',
			'Sonsorol',
		],
	],
	[
		'South Sudan',
		[
			'Northern Bahr el Ghazal',
			'Western Bahr el Ghazal',
			'Lakes',
			'Warrap',
			'Western Equatoria',
			'Central Equatoria',
			'Eastern Equatoria',
			'Jonglei',
			'Unity',
			'Upper Nile',
			'Abyei Area',
			'Pibor Area',
			'Ruweng Area',
		],
	],
	[
		'United States',
		[
			'Alabama',
			'Alaska',
			'Arizona',
			'Arkansas',
			'California',
			'Colorado',
			'Connecticut',
			'Delaware',
			'Florida',
			'Georgia',
			'Hawaii',
			'Idaho',
			'Illinois',
			'Indiana',
			'Iowa',
			'Kansas',
			'Kentucky',
			'Louisiana',
			'Maine',
			'Maryland',
			'Massachusetts',
			'Michigan',
			'Minnesota',
			'Mississippi',
			'Missouri',
			'Montana',
			'Nebraska',
			'Nevada',
			'New Hampshire',
			'New Jersey',
			'New Mexico',
			'New York',
			'North Carolina',
			'North Dakota',
			'Ohio',
			'Oklahoma',
			'Oregon',
			'Pennsylvania',
			'Rhode Island',
			'South Carolina',
			'South Dakota',
			'Tennessee',
			'Texas',
			'Utah',
			'Vermont',
			'Virginia',
			'Washington',
			'Washington DC',
			'West Virginia',
			'Wisconsin',
			'Wyoming',
		],
	],
]);

const continents = {
	"Afghanistan": "Asia",
	"Aland Islands": "Europe",
	"Albania": "Europe",
	"Algeria": "Africa",
	"Andorra": "Europe",
	"Angola": "Africa",
	"Anguilla": "North America",
	"Antigua and Barbuda": "North America",
	"Argentina": "South America",
	"Armenia": "Europe",
	"Aruba": "North America",
	"Australia": "Oceania",
	"Austria": "Europe",
	"Azerbaijan": "Europe",
	"Bahamas": "North America",
	"Bahamas, The": "North America",
	"Bahrain": "Asia",
	"Bangladesh": "Asia",
	"Barbados": "North America",
	"Belarus": "Europe",
	"Belgium": "Europe",
	"Belize": "North America",
	"Benin": "Africa",
	"Bermuda": "North America",
	"Bhutan": "Asia",
	"Bolivia": "South America",
	"Bosnia and Herzegovina": "Europe",
	"Botswana": "Africa",
	"Bouvet Island": "South America",
	"Brazil": "South America",
	"British Indian Ocean Territory": "Asia",
	"British Virgin Islands": "North America",
	"Brunei": "Asia",
	"Bulgaria": "Europe",
	"Burkina": "Africa",
	"Burkina Faso": "Africa",
	"Burma": "Asia",
	"Burma (Myanmar)": "Asia",
	"Burundi": "Africa",
	"Cambodia": "Asia",
	"Cameroon": "Africa",
	"Canada": "North America",
	"Cape Verde": "Africa",
	"Cayman Islands": "North America",
	"Central African Republic": "Africa",
	"Chad": "Africa",
	"Chile": "South America",
	"China": "Asia",
	"Christmas Island": "Oceania",
	"Cocos (Keeling) Islands": "Oceania",
	"Colombia": "South America",
	"Comoros": "Africa",
	"Congo": "Africa",
	"Congo, Democratic Republic of": "Africa",
	"Congo, Democratic Republic of the": "Africa",
	"Congo, Republic of the": "Africa",
	"Cook Islands": "Oceania",
	"Costa Rica": "North America",
	"Cote d'Ivoire": "Africa",
	"Croatia": "Europe",
	"Cuba": "North America",
	"Cyprus": "Europe",
	"Czech Republic": "Europe",
	"Denmark": "Europe",
	"Djibouti": "Africa",
	"Dominica": "North America",
	"Dominican Republic": "North America",
	"East Timor": "Asia",
	"Ecuador": "South America",
	"Egypt": "Africa",
	"El Salvador": "North America",
	"Equatorial Guinea": "Africa",
	"Eritrea": "Africa",
	"Estonia": "Europe",
	"Ethiopia": "Africa",
	"Falkland Islands (Islas Malvinas)": "South America",
	"Faroe Islands": "Oceania",
	"Fiji": "Oceania",
	"Finland": "Europe",
	"France": "Europe",
	"French Guiana": "South America",
	"French Polynesia": "Oceania",
	"French Southern and Antarctic Lands": "Oceania",
	"Gabon": "Africa",
	"Gambia": "Africa",
	"Gambia, The": "Africa",
	"Georgia": "Europe",
	"Germany": "Europe",
	"Ghana": "Africa",
	"Gibraltar": "Europe",
	"Greece": "Europe",
	"Greenland": "North America",
	"Grenada": "North America",
	"Guadeloupe": "North America",
	"Guam": "Oceania",
	"Guatemala": "North America",
	"Guernsey": "Europe",
	"Guinea": "Africa",
	"Guinea-Bissau": "Africa",
	"Guyana": "South America",
	"Haiti": "North America",
	"Heard Island and McDonald Islands": "Oceania",
	"Holy See (Vatican City)": "Europe",
	"Honduras": "North America",
	"Hong Kong": "Asia",
	"Hungary": "Europe",
	"Iceland": "Europe",
	"India": "Asia",
	"Indonesia": "Asia",
	"Iran": "Asia",
	"Iraq": "Asia",
	"Ireland": "Europe",
	"Isle of Man": "Europe",
	"Israel": "Asia",
	"Italy": "Europe",
	"Ivory Coast": "Africa",
	"Jamaica": "North America",
	"Japan": "Asia",
	"Jersey": "Europe",
	"Jordan": "Asia",
	"Kazakhstan": "Asia",
	"Kenya": "Africa",
	"Kiribati": "Oceania",
	"Korea, North": "Asia",
	"Korea, South": "Asia",
	"Kuwait": "Asia",
	"Kyrgyzstan": "Asia",
	"Laos": "Asia",
	"Latvia": "Europe",
	"Lebanon": "Asia",
	"Lesotho": "Africa",
	"Liberia": "Africa",
	"Libya": "Africa",
	"Liechtenstein": "Europe",
	"Lithuania": "Europe",
	"Luxembourg": "Europe",
	"Macau": "Asia",
	"Macedonia": "Europe",
	"Madagascar": "Africa",
	"Malawi": "Africa",
	"Malaysia": "Asia",
	"Maldives": "Asia",
	"Mali": "Africa",
	"Malta": "Europe",
	"Marshall Islands": "Oceania",
	"Martinique": "North America",
	"Mauritania": "Africa",
	"Mauritius": "Africa",
	"Mayotte": "Africa",
	"Mexico": "North America",
	"Micronesia": "Oceania",
	"Micronesia, Federated States of": "Oceania",
	"Moldova": "Europe",
	"Monaco": "Europe",
	"Mongolia": "Asia",
	"Montenegro": "Europe",
	"Montserrat": "North America",
	"Morocco": "Africa",
	"Mozambique": "Africa",
	"Namibia": "Africa",
	"Nauru": "Oceania",
	"Nepal": "Asia",
	"Netherlands": "Europe",
	"Netherlands Antilles": "North America",
	"New Caledonia": "Oceania",
	"New Zealand": "Oceania",
	"Nicaragua": "North America",
	"Niger": "Africa",
	"Nigeria": "Africa",
	"Niue": "Oceania",
	"Norfolk Island": "Oceania",
	"Northern Mariana Islands": "Oceania",
	"Norway": "Europe",
	"Oman": "Asia",
	"Pakistan": "Asia",
	"Palau": "Oceania",
	"Panama": "North America",
	"Papua New Guinea": "Oceania",
	"Paraguay": "South America",
	"Peru": "South America",
	"Philippines": "Asia",
	"Pitcairn Islands": "Asia",
	"Poland": "Europe",
	"Portugal": "Europe",
	"Puerto Rico": "North America",
	"Qatar": "Asia",
	"Reunion": "Africa",
	"Romania": "Europe",
	"Russia": "Asia",
	"Russian Federation": "Asia",
	"Rwanda": "Africa",
	"Saint Barthelemy": "North America",
	"Saint Helena": "Africa",
	"Saint Kitts and Nevis": "North America",
	"Saint Lucia": "North America",
	"Saint Martin": "North America",
	"Saint Pierre and Miquelon": "North America",
	"Saint Vincent and the Grenadines": "North America",
	"Samoa": "Oceania",
	"San Marino": "Europe",
	"Sao Tome and Principe": "Africa",
	"Saudi Arabia": "Asia",
	"Senegal": "Africa",
	"Serbia": "Europe",
	"Seychelles": "Africa",
	"Sierra Leone": "Africa",
	"Singapore": "Asia",
	"Slovakia": "Europe",
	"Slovenia": "Europe",
	"Solomon Islands": "Oceania",
	"Somalia": "Africa",
	"South Africa": "Africa",
	"South Georgia South Sandwich Islands": "South America",
	"South Sudan": "Africa",
	"Spain": "Europe",
	"Sri Lanka": "Asia",
	"Sudan": "Africa",
	"Suriname": "South America",
	"Svalbard": "Europe",
	"Swaziland": "Africa",
	"Sweden": "Europe",
	"Switzerland": "Europe",
	"Syria": "Asia",
	"Taiwan": "Asia",
	"Tajikistan": "Asia",
	"Tanzania": "Africa",
	"Thailand": "Asia",
	"Timor-Leste": "Asia",
	"Togo": "Africa",
	"Tokelau": "Oceania",
	"Tonga": "Oceania",
	"Trinidad and Tobago": "North America",
	"Tunisia": "Africa",
	"Turkey": "Asia",
	"Turkmenistan": "Asia",
	"Turks and Caicos Islands": "North America",
	"Tuvalu": "Oceania",
	"Uganda": "Africa",
	"Ukraine": "Europe",
	"United Arab Emirates": "Asia",
	"United Kingdom": "Europe",
	"United States": "North America",
	"Uruguay": "South America",
	"Uzbekistan": "Asia",
	"Vanuatu": "Oceania",
	"Vatican City": "Europe",
	"Venezuela": "South America",
	"Vietnam": "Asia",
	"Virgin Islands": "North America",
	"Wallis and Futuna": "Oceania",
	"Western Sahara": "Africa",
	"Yemen": "Asia",
	"Zambia": "Africa",
	"Zimbabwe": "Africa"
};

export class CountriesHelper {
	static getAllCountriesForDropdown(): DropdownOption[] {
		return Array.from(codeToCountry.keys()).map(key => ({ id: key, value: codeToCountry.get(key) }));
	}

	static getStatesByCountryForDropdown(country: string): DropdownOption[] {
		const statesByCountry = states.get(country) ?? [];
		return statesByCountry?.length > 0 ? statesByCountry.map(state => ({ id: state, value: state })) : [];
	}

	static getCountryNameByCode(code: string) {
		return codeToCountry.get(code);
	}

	static getContinentsMap(): Map<string, string[]> {
		const map = new Map<string, string[]>();
		let uniqueContinents = new Set<string>(Object.values(continents));

		for (let continent of uniqueContinents.keys()) {
			map.set(continent, Object.keys(continents).filter(key => continents[key] === continent));
		}

		return map;
	}

	static getCountriesByContinentDropdown(): DropdownOption[] {
		const options: DropdownOption[] = [];
		for (const [key, value] of this.getContinentsMap()) {
			const continentAny = { id: `${key}Any`, value: `${key} (any)` } as DropdownOption;
			const continent = {
				id: key,
				value: key,
				options: value.map(country => ({ id: country, value: country } as DropdownOption))
			} as DropdownOption;
			continent.options.unshift(continentAny);
			options.push(continent);
		}
		return options;
	}
}