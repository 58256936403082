<div *ngIf="visible" class="snackbar" [ngClass]="backgroundColor" [@toggle]>
	
	<p class="snackbar__text" [innerHTML]="text | safe"></p>

	<button *ngIf="dismissible" class="snackbar__dismiss" (click)="onDismiss()">

			<svg class="snackbar__close-btn">
				<use xlink:href="../../../assets/img/sprite.svg#icon-X"></use>
			</svg>
		
	</button>
	
</div>