import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { ProfileFeatureFlags } from 'src/app/api/models/profile/profile-feature-flags';
import { FeatureFlags, PublicFeatureFlags } from 'src/app/api/models/profile/profile-feature-flags.enum';

export function createInitialState(): ProfileFeatureFlags {
	const featureFlags = Object.assign(FeatureFlags, PublicFeatureFlags);
	return Object.fromEntries(
	    Object.entries(featureFlags).map(([key, value]) => [value, false])
	) as ProfileFeatureFlags;
}

@Injectable({
  	providedIn: 'root'
})
@StoreConfig({
	name: 'featureFlags',
})
export class FeatureFlagsStore extends Store<ProfileFeatureFlags> {

  constructor() {
    super(createInitialState());
  }
}
