export const PII_TYPE_TO_DATA_TYPE_ID_MAP = new Map<string, string>([
    ['ADVERTISING_ID', "Advertising ID"],
    ['AGE', "Age"],
    ["CREDIT_CARD_NUMBER", "Credit card number"],
    ["CREDIT_CARD_TRACK_NUMBER", "Credit Card track number"],
    ["DATE", "Date"],
    ["DATE_OF_BIRTH", "Date of birth"],
    ["DOMAIN_NAME", "Domain"],
    ["EMAIL_ADDRESS", "Email address"],
    ["ETHNIC_GROUP", "Ethnicity"],
    ["FEMALE_NAME", "Name"],
    ["FIRST_NAME", "First name"],
    ["GENDER", "Gender"],
    ["GENERIC_ID", "Generic ID"],
    ["IBAN_CODE", "IBAN code"],
    ["HTTP_COOKIE", "HTTP"],
    ["ICCID_NUMBER", "ICCID number"],
    ["ICD9_CODE", "ICD code"],
    ["ICD10_CODE", "ICD code"],
    ["IMEI_HARDWARE_ID", "Hardware ID"],
    ["IMSI_ID", "Mobile subscription number"],
    ["IP_ADDRESS", "IP address"],
    ["LAST_NAME", "Last name"],
    ["LOCATION", "Location-id"],
    ["LOCATION_COORDINATES", "Location coordinates"],
    ["MAC_ADDRESS", "Mac address"],
    ["MAC_ADDRESS_LOCAL", "Mac address"],
    ["MALE_NAME", "Name"],
    ["MEDICAL_TERM", "Medical term"],
    ["ORGANIZATION_NAME", "Organization name"],
    ["PASSPORT", "Passport number"],
    ["PERSON_NAME", "Name"],
    ["PHONE_NUMBER", "Phone number"],
    ["STREET_ADDRESS", "Street address"],
    ["SWIFT_CODE", "SWIFT code"],
    ["STORAGE_SIGNED_POLICY_DOCUMENT", "Document"],
    ["STORAGE_SIGNED_URL", "URL"],
    ["TIME", "Time"],
    ["URL", "URL"],
    ["VEHICLE_IDENTIFICATION_NUMBER", "Vehicle ID"],
    ["AUTH_TOKEN", "Auth token"],
    ["AWS_CREDENTIALS", "Credentials"],
    ["AZURE_AUTH_TOKEN", "Auth token"],
    ["BASIC_AUTH_HEADER", "HTTP"],
    ["ENCRYPTION_KEY", "Encryption key"],
    ["GCP_API_KEY", "API key"],
    ["GCP_CREDENTIALS", "GCP credentials"],
    ["JSON_WEB_TOKEN", "Token"],
    ["HTTP_COOKIE", "HTTP cookie"],
    ["PASSWORD", "Password"],
    ["WEAK_PASSWORD_HASH", "Password hash"],
    ["XSRF_TOKEN", "Token"],
    ["ARGENTINA_DNI_NUMBER", "National ID"],
    ["AUSTRALIA_DRIVERS_LICENSE_NUMBER", "Driver license ID"],
    ["AUSTRALIA_MEDICARE_NUMBER", "Medical ID"],
    ["AUSTRALIA_PASSPORT", "Passport number"],
    ["AUSTRALIA_TAX_FILE_NUMBER", "Tax ID"],
    ["BELGIUM_NATIONAL_ID_CARD_NUMBER", "National ID"],
    ["BRAZIL_CPF_NUMBER", "Tax ID"],
    ["CANADA_BANK_ACCOUNT", "Bank account number"],
    ["CANADA_BC_PHN","Medical ID"],
    ["CANADA_DRIVERS_LICENSE_NUMBER", "CA drivers license"],
    ["CANADA_OHIP", "Health insurance number"],
    ["CANADA_PASSPORT", "Passport number"],
    ["CANADA_QUEBEC_HIN", "Health insurance number"],
    ["CANADA_SOCIAL_INSURANCE_NUMBER", "Social insurance number"],
    ["CHILE_CDI_NUMBER", "National ID"],
    ["CHINA_RESIDENT_ID_NUMBER", "National ID"],
    ["CHINA_PASSPORT", "Passport number"],
    ["COLOMBIA_CDC_NUMBER", "National ID"],
    ["DENMARK_CPR_NUMBER", "National ID"],
    ["FRANCE_CNI", "National ID"],
    ["FRANCE_NIR", "SSN"],
    ["FRANCE_PASSPORT", "Passport number"],
    ["FRANCE_TAX_IDENTIFICATION_NUMBER", "Tax ID"],
    ["FINLAND_NATIONAL_ID_NUMBER", "National ID"],
    ["GERMANY_DRIVERS_LICENSE_NUMBER", "Driver license ID"],
    ["GERMANY_IDENTITY_CARD_NUMBER", "National ID"],
    ["GERMANY_PASSPORT", "Passport number"],
    ["GERMANY_TAXPAYER_IDENTIFICATION_NUMBER", "Tax ID"],
    ["GERMANY_SCHUFA_ID", "Credit ID"],
    ["HONG_KONG_ID_NUMBER", "National ID"],
    ["INDIA_AADHAAR_INDIVIDUAL", "National ID"],
    ["INDIA_GST_INDIVIDUAL", "Tax ID"],
    ["INDIA_PAN_INDIVIDUAL", "Tax ID"],
    ["INDONESIA_NIK_NUMBER", "National ID"],
    ["IRELAND_DRIVING_LICENSE_NUMBER", "Driver license ID"],
    ["IRELAND_EIRCODE", "Postal code"],
    ["IRELAND_PASSPORT", "Passport number"],
    ["IRELAND_PPSN", "National ID"],
    ["ISRAEL_IDENTITY_CARD_NUMBER", "National ID"],
    ["ITALY_FISCAL_CODE", "Fiscal ID"],
    ["JAPAN_BANK_ACCOUNT", "Bank account number"],
    ["JAPAN_DRIVERS_LICENSE_NUMBER", "JP drivers license"],
    ["JAPAN_INDIVIDUAL_NUMBER", "National ID"],
    ["JAPAN_PASSPORT", "Passport number"],
    ["KOREA_PASSPORT", "Passport number"],
    ["KOREA_RRN", "SSN"],
    ["MEXICO_CURP_NUMBER", "National ID"],
    ["MEXICO_PASSPORT", "Passport number"],
    ["NETHERLANDS_BSN_NUMBER", "National ID"],
    ["NETHERLANDS_PASSPORT", "Passport number"],
    ["NORWAY_NI_NUMBER", "National ID"],
    ["PARAGUAY_CIC_NUMBER", "National ID"],
    ["PERU_DNI_NUMBER", "National ID"],
    ["POLAND_PESEL_NUMBER", "National ID"],
    ["POLAND_NATIONAL_ID_NUMBER", "National ID"],
    ["POLAND_PASSPORT", "Passport number"],
    ["PORTUGAL_CDC_NUMBER", "National ID"],
    ["SINGAPORE_NATIONAL_REGISTRATION_ID_NUMBER", "National ID"],
    ["SINGAPORE_PASSPORT", "Passport number"],
    ["SOUTH_AFRICA_ID_NUMBER", "National ID"],
    ["SPAIN_CIF_NUMBER", "Tax ID"],
    ["SPAIN_DNI_NUMBER", "National ID"],
    ["SPAIN_DRIVERS_LICENSE_NUMBER", "Driver license ID"],
    ["SPAIN_NIE_NUMBER", "Employer ID"],
    ["SPAIN_NIF_NUMBER", "Fiscal ID"],
    ["SPAIN_PASSPORT", "Passport number"],
    ["SPAIN_SOCIAL_SECURITY_NUMBER", "SSN"],
    ["SWEDEN_NATIONAL_ID_NUMBER", "National ID"],
    ["SWEDEN_PASSPORT", "Passport number"],
    ["TAIWAN_PASSPORT", "Passport number"],
    ["THAILAND_NATIONAL_ID_NUMBER", "National ID"],
    ["TURKEY_ID_NUMBER", "National ID"],
    ["SCOTLAND_COMMUNITY_HEALTH_INDEX_NUMBER", "Medical ID"],
    ["UK_DRIVERS_LICENSE_NUMBER", "Driver license ID"],
    ["UK_NATIONAL_HEALTH_SERVICE_NUMBER", "Medical ID"],
    ["UK_NATIONAL_INSURANCE_NUMBER", "Insurance ID"],
    ["UK_PASSPORT", "Passport number"],
    ["UK_TAXPAYER_REFERENCE", "Tax ID"],
    ["AMERICAN_BANKERS_CUSIP_ID", "Bankers ID"],
    ["FDA_CODE", "FDA code"],
    ["US_ADOPTION_TAXPAYER_IDENTIFICATION_NUMBER", "Tax ID"],
    ["US_BANK_ROUTING_MICR", "Bankers ID"],
    ["US_DEA_NUMBER", "DEA code"],
    ["US_DRIVERS_LICENSE_NUMBER", "US drivers license"],
    ["US_EMPLOYER_IDENTIFICATION_NUMBER", "Employer ID"],
    ["US_HEALTHCARE_NPI", "Medical ID"],
    ["US_INDIVIDUAL_TAXPAYER_IDENTIFICATION_NUMBER", "Tax ID"],
    ["US_PASSPORT", "Passport number"],
    ["US_PREPARER_TAXPAYER_IDENTIFICATION_NUMBER", "Tax ID"],
    ["US_SOCIAL_SECURITY_NUMBER", "SSN"],
    ["US_STATE", "State"],
    ["US_TOLLFREE_PHONE_NUMBER", "Phone number"],
    ["US_VEHICLE_IDENTIFICATION_NUMBER", "Vehicle ID"],
    ["URUGUAY_CDI_NUMBER", "National ID"],
    ["VENEZUELA_CDI_NUMBER", "National ID"],
    ["DOCUMENT_TYPE/FINANCE/REGULATORY", "Financial document"],
    ["DOCUMENT_TYPE/FINANCE/SEC_FILING", "Financial document"],
    ["DOCUMENT_TYPE/HR/RESUME", "CV/resume"],
    ["DOCUMENT_TYPE/LEGAL/BLANK_FORM", "Legal document"],
    ["DOCUMENT_TYPE/LEGAL/BRIEF", "Legal document"],
    ["DOCUMENT_TYPE/LEGAL/COURT_ORDER", "Legal document"],
    ["DOCUMENT_TYPE/LEGAL/LAW", "Legal document"],
    ["DOCUMENT_TYPE/LEGAL/PLEADING", "Legal document"],
    ["DOCUMENT_TYPE/R&D/PATENT", "R&D document"],
    ["DOCUMENT_TYPE/R&D/SOURCE_CODE", "R&D document"],
    ["DOCUMENT_TYPE/R&D/SYSTEM_LOG", "R&D document"],
    ["DOCUMENT_TYPE/R&D/DATABASE_BACKUP", "R&D document"],
]);