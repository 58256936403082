import { Pipe, PipeTransform } from '@angular/core';
import { EmployeeDataMapping } from 'src/app/api/models/data-mapping/data-mapping.interface';
import { ContentPipe } from 'src/app/services/content/content.pipe';

@Pipe({
    standalone: true,
    name: 'employeeFullname'
})
export class DataMappingEmployeeNamePipe implements PipeTransform {

    constructor(
        private contentPipe: ContentPipe
    ) { }

    transform(employee: EmployeeDataMapping) {
        if (employee.invitationPending) {
            return this.contentPipe.transform('data-mapping.invitationPending');
        }

        const fullname = employee.firstName?.concat(' ', employee.lastName)?.trim();
        return fullname ?? employee.email ?? '-' ;
    }
}