export enum PlanType {
    Free = 'free',
    Basic = 'basic',
    Pro = 'pro',
    Enterprise = 'enterprise',
    Complete = 'complete',
    BasicDiscovery = 'basicDiscovery',
    FullDiscovery = 'fullDiscovery',
    Consent = 'consent',
    Sandbox = 'sandbox'
}