import { Injectable } from '@angular/core';
import { ActiveState, EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { AutomationLocalized } from 'src/app/api/models/automations/automations.interface';
import { produce } from 'immer';

export interface AutomationState extends EntityState<AutomationLocalized, string>, ActiveState { }

@Injectable({ 
	providedIn: 'root' 
})
@StoreConfig({ 
	name: 'automation',
	producerFn: produce,
	idKey: 'locale'
})

export class AutomationStore extends EntityStore<AutomationState> {

	constructor() {
		super();
	}

}
