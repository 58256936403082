export class DataTypeSmartSampleFormatHelper {
  
    private static readonly ranges = new Map<string, number[]>([
      [`1-10`, [1, 10]],
      [`10-100`, [10, 100]],
      [`100-1K`, [100, 1_000]],
      [`1K-10K`, [1_000, 10_000]],
      [`10K-100K`, [10_000, 100_000]],
      [`100K-500K`, [100_000, 500_000]],
      [`0.5M-1M`, [500_000, 1_000_000]],
      [`1M-10M`, [1_000_000, 10_000_000]],
      [`10M-100M`, [10_000_000, 100_000_000]],
      [`100M+`, [100_000_000, Number.MAX_SAFE_INTEGER]],
    ]);

    static transform(value: number): string {
      const range = Array.from(this.ranges.entries()).find((entry) => value >= entry[1][0] && value <= entry[1][1]);
      return range ? range[0] : '' + value;
    }
}