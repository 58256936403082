import { Injectable, Signal } from "@angular/core";
import { filter, first, map, Observable } from "rxjs";

import { MineOSChatbotStore } from "../state/mineos-chatbot.store";
import { DataUpdateEnum } from "../models/data-update.enum";
import { Message } from "../models/message.interface";
import { PusherService } from "../pusher.service";

@Injectable({
    providedIn: 'root'
})
export class MineOSChatbotService {

    constructor(
        private store: MineOSChatbotStore,
        private pusherService: PusherService,
    ) {}

    open(): void {
        this.store.actions.open();
    }

    close(): void {
        this.store.actions.close();
    }

    toggle(): void {
        this.store.actions.toggle();
    }

    isOpen(): boolean {
        return this.store.get('open');
    }

    showChatbot(): Signal<boolean> {
        return this.store.signal('open');
    }

    getChatMessages(): Signal<Message[]> {
        return this.store.signal('messages');
    }

    addMessage(message: Message): void {
        this.store.set('messages', (state) => [...state.messages, message]);
    }

    getDsReady(): Signal<boolean> {
        return this.store.signal('dsReady');
    }

    selectDsReady(): Observable<boolean> {
        return this.store.select('dsReady');
    }

    setDsReady(ready: boolean): void {
        this.store.set({ 'dsReady' : ready });
    }

    getChannelReady(): Signal<boolean> {
        return this.store.signal('channelReady');
    }

    selectChannelReady(): Observable<boolean> {
        return this.store.select('channelReady');
    }

    setChannelReady(ready: boolean): void {
        this.store.set({ 'channelReady' : ready });
    }

    sendMessage(message: Message): void {
        this.store.actions.sendMessage(message);
    }

    updateData(data: DataUpdateEnum[]): void {
        this.store.actions.updateData(data);
    }

    dsReady(ready: boolean): void {
        this.store.actions.dsReady(ready);
    }

    onOpen(): Observable<void> {
        return this.store.actions.open$;
    }

    onDisconnect(): Observable<void> {
        return this.store.actions.disconnect$;
    }

    subscriber(callback: object): void {
        this.selectChannelReady().pipe(
            filter(ready => ready),
            first(),
            map(() => this.pusherService.subscribe(callback))
        ).subscribe();
    }
}