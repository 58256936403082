import { TicketStateEnum } from 'src/app/api/models/requests/ticket-state.enum';
export class TicketStepsUtils {

  static convertTicketStateToStep(ticketState: TicketStateEnum): number {
    switch (ticketState) {
      case TicketStateEnum.Edit:
        return 0;
      case TicketStateEnum.Processing:
        return 1;
      case TicketStateEnum.Notify:
        return 2;
      case TicketStateEnum.Closed:
      case TicketStateEnum.Redacting:
      case TicketStateEnum.Redacted:
        return 3;
      default:
        return 1;
    }
  }
}