import { Injectable } from '@angular/core';
import { LoggerService } from '../logger/logger.service';
import { combineLatest, EMPTY, iif, Observable, catchError, first, map, switchMap, take, tap } from 'rxjs';

import { ContentMode } from './content/content.enum';
import { ContentStore } from './content/state/content.store';
import { ContentSchemaHelper } from './content/content-schema-helper';
import { ApiClientContentService } from '../api/api-client-content.service';
import { FeatureFlagQuery } from '../feature-flag/state/feature-flag.query';
import { PublicFeatureFlags } from '../api/models/profile/profile-feature-flags.enum';
import { SquidexSchema } from '../api/models/content/squidex.interface';
import { ContentSchema } from './content/content-schema.interface';

@Injectable({
  providedIn: 'root'
})
export class ContentService {
	
	private readonly loggerName: string = 'ContentService';
	
	public contentMode: ContentMode;

	constructor(
		private logger: LoggerService,
		private featureFlagQuery: FeatureFlagQuery,
		private contentApiService: ApiClientContentService,
		private contentStore: ContentStore,
	) {}

	private fetchContent(): Observable<void> {
		return this.featureFlagQuery.selectFlag(PublicFeatureFlags.CmsBackup).pipe(
			switchMap(backup => iif(() => backup, this.getSchemasFromBackup(), this.getSchemasFromSquidex())),
			catchError((error) => {
				this.logger.error(this.loggerName, error);
				return EMPTY;
			}),
			take(1),
			map(() => void 0)
		);
	}

	private getSchemasFromBackup(): Observable<any> {
		const loadSingleSchemasArray: Observable<void>[] = [];
		const schemasNames = Object.keys(ContentSchemaHelper);
		schemasNames.forEach(schemaName => loadSingleSchemasArray.push(
				this.loadSingleSchema(ContentSchemaHelper[schemaName])
			)
		);

		return combineLatest(loadSingleSchemasArray);
	}

	private getSchemasFromSquidex(): Observable<any> {
		return this.contentApiService.getAllSchemas(this.contentMode, 'en').pipe(
			map(res => res.items), tap(schemas => schemas.forEach(schema => this.addSchemaToStore(schema)))
		);
	}

	private addSchemaToStore(schema: SquidexSchema, language?: string): void {
		if (this.validateContent(schema.data)) {
			this.contentStore.add({ 
				id: language? `${language}${schema.schemaName}`: schema.schemaName,
				...schema.data 
			});
		}
		else {
			this.logger.error(this.loggerName, `No content received from CMS for schema: ${schema.schemaName}`);
		}
	}

	private validateContent(content: any): boolean {
		return (content && Object.keys(content).length > 0);
	}

	public init(): Observable<void> {
		const language = 'en';
		this.contentMode = this.convertQueryParamsToContentMode(new URLSearchParams(window.location.search).get('contentMode'));
		this.logger.debug(this.loggerName, `Init content. Content mode=${this.contentMode} Language=${language}`);
		return this.fetchContent().pipe(first());
	}

	private convertQueryParamsToContentMode(mode: string): ContentMode {
		switch(mode) {
			case ContentMode.Debug:
				return ContentMode.Debug;
			case ContentMode.Draft:
				return ContentMode.Draft;
			default:
				return ContentMode.Default;
		}
	}

	private loadSingleSchema(schema: ContentSchema, language?: string): Observable<void> {
		return this.featureFlagQuery.selectFlag(PublicFeatureFlags.CmsBackup).pipe(
			switchMap(backup => this.contentApiService.getSingleSchema(schema, this.contentMode, language, backup)),
			map(schema => this.addSchemaToStore(schema, language))
		);
	}

	getSchemaForDifferentLanguage(schemaName: string, language?: string): Observable<void> {
		const schemasNames = Object.keys(ContentSchemaHelper);
		const schemaNameToLoad = schemasNames.find((item) => ContentSchemaHelper[item].name === schemaName);
		const schema: ContentSchema = ContentSchemaHelper[schemaNameToLoad];
		return this.loadSingleSchema(schema, language);
	}
}
