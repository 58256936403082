import { EmployeesCountFilterOptions } from '../../radar-table/radar-filters/radar-filters.enum';

export class ConnectedEmployeesHelper {

  static convertEmployeesCountToEnum(employeesCount: number): EmployeesCountFilterOptions {
    if (employeesCount === null) {
      return EmployeesCountFilterOptions.Unknown;
    }

    if (employeesCount > 15) {
      return EmployeesCountFilterOptions.Many;
    } else if (employeesCount <= 15 && employeesCount > 8) {
      return EmployeesCountFilterOptions.Several;
    } else if (employeesCount <= 8 && employeesCount > 3) {
      return EmployeesCountFilterOptions.Some;
    } else if (employeesCount <=3 && employeesCount > 0) {
      return EmployeesCountFilterOptions.Few;
    }
    return EmployeesCountFilterOptions.Zero;
  }

}
