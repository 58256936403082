import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { MineSnackbarType } from './mine-snackbar-type';
import { MineSnackbarParams } from './mine-snackbar-params';
import { environment } from 'src/environments/environment';

@Injectable({
	providedIn: 'root'
})
export class MineSnackbarService {
	private readonly replaceDuration = 500;
	private currentBarConfig: MineSnackbarParams;
	private hideTimer: number;

	private visibleSub = new Subject<boolean>();
	visible$ = this.visibleSub.asObservable();

	private params = new Subject<MineSnackbarParams>();
	params$ = this.params.asObservable();

	private show(type: MineSnackbarType, text: string, dismissble = true): void {
		const newBarConfig: MineSnackbarParams = {
			type: type,
			text: text,
			dismissible: dismissble
		};

		if (this.currentBarConfig) {
			this.replace(newBarConfig);
		} else {
			this.params.next(newBarConfig);
			this.visibleSub.next(true);
			this.currentBarConfig = newBarConfig;
		}
	}

	showPermanent(type: MineSnackbarType, text: string, dismissble: boolean = true): void {
		this.show(type, text, dismissble);
	}

	showTimed(type: MineSnackbarType, text: string, dismissble: boolean = true): void {
		const hidingDuration = environment.notifications.snackbarDisplayTime + (this.currentBarConfig ? this.replaceDuration : 0);
		this.show(type, text, dismissble);

		// clear the current timer first
		if (this.hideTimer) {
			clearTimeout(this.hideTimer);
		}
		this.hideTimer = window.setTimeout(() => this.hide(), hidingDuration);
	}

	hide(): void {
		// If a currentBarConfig exists, a timed bar was displayed. Cancel it's timer before closing
		this.currentBarConfig = undefined;
		if (this.hideTimer) {
			clearTimeout(this.hideTimer);
		}
		this.visibleSub.next(false);
	}

	private replace(newBar: MineSnackbarParams): void {
		this.visibleSub.next(false);
		window.setTimeout(() => {
			this.params.next(newBar);
			this.currentBarConfig = newBar;
			this.visibleSub.next(true);
		}, this.replaceDuration);
	}
}
