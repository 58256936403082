import { Pipe, PipeTransform } from '@angular/core';
import { RiskRateEnum } from 'src/app/api/models/processing-activities/processing-activities.enum';

@Pipe({
  standalone: true,
  name: 'riskCalulation'
})
export class RiskCalulationPipe implements PipeTransform {

  transform(severity: RiskRateEnum, likelihood: RiskRateEnum): RiskRateEnum {
    const risks = Object.values(RiskRateEnum);
    const severityCalc = this.getNumberRepresentation(severity);
    const likelihoodCalc = this.getNumberRepresentation(likelihood);

    const calc = severityCalc * likelihoodCalc / 2;
    return (calc > risks.length) ? RiskRateEnum.High : risks[Math.round(calc) - 1];
  }
  
  getNumberRepresentation(rate: RiskRateEnum): number {
    switch (rate) {
      case RiskRateEnum.Low:
        return 1;
      case RiskRateEnum.Medium:
        return 2;
      case RiskRateEnum.High:
        return 3;
      default:
        return 1;
    }
  }
}
