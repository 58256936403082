import { Injectable } from "@angular/core";
import { ContentPipe } from "./content/content.pipe";

@Injectable({
	providedIn: 'root'
})
export class KnowledgeBaseService {

	constructor(private contentPipe: ContentPipe) { }

	public navigate(page?: string, newTab: boolean = true): void {
		const url = !!page ? `${this.contentPipe.transform('knowledge-base-urls.url')}/${page}` : this.contentPipe.transform('knowledge-base-urls.url');
		const target = newTab ? '_blank' : '_self';
		window.open(url, target, 'noopener');
	}
}