<div class="checklist__gauge">

   <ngx-gauge *ngIf="gaugeValue < 100; else checklistDone" 
               #gauge
               [type]="gaugeType"
               [size]="gaugeSize"
               [value]="gaugeValue"
               [cap]="gaugeCap"
               [thick]="gaugeThick"
               [foregroundColor]="gaugeColorForeground"
               [backgroundColor]="gaugeColorBackground"
               class="progress__gauge">

      <ngx-gauge-value class="checklist__gauge-value">
         <span class="checklist__gauge-value--number">{{gaugeValue}}</span>
         <span class="checklist__gauge-value--percent">%</span>
      </ngx-gauge-value>

   </ngx-gauge>

   <ng-template #checklistDone>

      <div class="checklist__finished-outer-circle">
         <div class="checklist__finished-inner-circle">
            <svg class="checklist__v-svg">
               <use xlink:href="../../../assets/img/sprite.svg#icon-success-check"></use>
            </svg> 
         </div>
      </div>

   </ng-template>

   <h3 class="mine-title-xxs checklist__title">{{ title }}</h3>

</div>

<div class="checklist__task" *ngFor="let task of tasksCopy"
      [class.checklist__task--no-pointer]="task.key === 'verifyCompany'"
      (click)="onClickTask(task.key)">
   
   <div *ngIf="tasks[task.key]; else notFinished" class="checklist__task-icon checklist__task-icon--done">
      <svg [@pimple] class="checklist__task-done-svg">
            <use xlink:href="../../../assets/img/sprite.svg#icon-success-check"></use>
      </svg>    
   </div>

   <ng-template #notFinished>
      <div class="checklist__task-icon checklist__task-icon--undone"
            [class.checklist__task-icon--next]="nextTask === task.key">
      </div>
   </ng-template>

   <span [class.checklist__task--done]="tasks[task.key]">{{task.step}}</span>
   
</div>