<mine-x-button (onClick)="closeDialog()"></mine-x-button>

<img class="activate-portal__img" srcset="{{assetsUrl}}/Portal/activate-portal-1x.jpg 1x, {{assetsUrl}}/Portal/activate-portal-2x.jpg 2x">

<h1 class="mine-title-m activate-portal__title">{{dialogConfiguration.title}}</h1>

<p class="activate-portal__subtitle" [innerHtml]="dialogConfiguration.text | safe"></p>

<p class="activate-portal__comment" *ngIf="dialogConfiguration.comment" >{{dialogConfiguration.comment}}</p>

<mine-button-primary class="activate-portal__btn mine-button-primary-lg"
                    [text]="'common.activatePortalBtn' | content"
                    (onClick)="upgradePlan()"></mine-button-primary>

<a class="activate-portal__read-more" (click)="goToCalendar()">{{'common.calendarLabel' | content}}</a>
