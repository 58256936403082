import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	standalone: true,
  	name: 'lowerCaseFirstLetter'
})
export class LowerCaseFirstLetterPipe implements PipeTransform {

	transform(str: string): string {
		return `${str.charAt(0).toLowerCase()}${str.slice(1)}`;
	}
}