import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { RiskRating } from './models/risk-rating.interface';

export interface VendorManagementState extends EntityState<RiskRating, string> {}

@Injectable({
	providedIn: 'root'
})
@StoreConfig({ name: 'vendorManagement', idKey: 'systemId' })
export class VendorManagementStore extends EntityStore<VendorManagementState> {
	constructor() {
		super();
	}
}
