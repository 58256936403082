import { Base64 } from 'js-base64';
import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { map, Observable } from 'rxjs';

import { TicketSource } from 'src/app/api/models/requests/ticket-source.enum';
import { AutoPilotState, TicketStateEnum } from 'src/app/api/models/requests/ticket-state.enum';
import { TicketType } from 'src/app/api/models/requests/ticket-type.enum';
import { FilterCategory } from 'src/app/core/models/filtering.interface';
import { RequestsStatusEnum } from '../models/requests-list-type.enum';
import { RequestsListPayload, RequestsSearchPayload } from '../models/requests.interface';
import { RequestsFilteringGroups } from '../requests-filtering/requests-filtering.enum';
import { RequestItemDeep } from 'src/app/api/models/requests/requests.interface';
import { RequestsState, RequestsStore } from './requests.store';
import { RequestItem } from 'src/app/api/models/requests/requests.interface';
import { FeatureFlagQuery } from 'src/app/feature-flag/state/feature-flag.query';
import { FeatureFlags } from 'src/app/api/models/profile/profile-feature-flags.enum';

@Injectable({
	providedIn: 'root'
})
export class RequestsQuery extends QueryEntity<RequestsState> {

	constructor(
		protected store: RequestsStore,
		private featureFlagQuery: FeatureFlagQuery
	) {
		super(store);
	}
	
	getSearchPayload(search: string, size?: number): RequestsSearchPayload {
		return { size, term: Base64.encode(search) } as RequestsSearchPayload;
	}

	getListPayload(filters: FilterCategory[], status: RequestsStatusEnum, size?: number): RequestsListPayload {
        const statesByStatus = this.featureFlagQuery.getFlag(FeatureFlags.DevTicketsTableViews) ? [] : this.getTicketStatesByStatus(status);
		const states: TicketStateEnum[] = [];
		const ticketType: TicketType[] = [];
		const sources: TicketSource[] = [];
		const countryCode: string[] = [];
		const domain: string[] = [];
		const privacyRightIds: string[] = [];

		filters.find(f => f.id === RequestsFilteringGroups.Type)?.options?.filter(o => o.selected)
			.forEach(o => ticketType.push(o.id as TicketType));

		filters.find(f => f.id === RequestsFilteringGroups.Rights)?.options?.filter(o => o.selected)
		.forEach(o => privacyRightIds.push(o.id));

		filters.find(f => f.id === RequestsFilteringGroups.Country)?.options?.filter(o => o.selected)
			.forEach(o => countryCode.push(o.id));

		filters.find(f => f.id === RequestsFilteringGroups.Domain)?.options?.filter(o => o.selected)
			.forEach(o => domain.push(o.id));

		filters.find(f => f.id === RequestsFilteringGroups.Source)?.options?.filter(o => o.selected)
			.forEach(o => sources.push(o.id as TicketSource));

		filters.find(f => f.id === RequestsFilteringGroups.Status)?.options?.filter(o => o.selected)
			.forEach(o => states.push(o.id as TicketStateEnum));

		const readStatus = filters.find(f => f.id === RequestsFilteringGroups.ReadStatus)?.options?.filter(o => o.selected).length > 0;

		return {
			size,
			sources,
			domain,
			ticketType,
			countryCode,
			privacyRightIds,
			states: states?.length ? states : statesByStatus,
			unread: readStatus,
		} as RequestsListPayload;
	}

	private getTicketStatesByStatus(status: RequestsStatusEnum): TicketStateEnum[] {
		switch (status) {
			case RequestsStatusEnum.Open:
				return [TicketStateEnum.Edit, TicketStateEnum.Processing, TicketStateEnum.Notify];

			case RequestsStatusEnum.Unverified:
				return [TicketStateEnum.Unverified];

			case RequestsStatusEnum.Closed:
				return [TicketStateEnum.Closed, TicketStateEnum.Redacting, TicketStateEnum.Redacted];

			default:
				return [TicketStateEnum.Unknown];
		}
	}

	isOpen(state: TicketStateEnum): boolean {
		return (state === TicketStateEnum.Edit) || (state === TicketStateEnum.Processing) || (state === TicketStateEnum.Notify);
	}

	isClosed(state: TicketStateEnum): boolean {
		return (state === TicketStateEnum.Closed) || (state === TicketStateEnum.Redacted) || (state === TicketStateEnum.Redacting);
	}

	selectRequestByActionId(id: string): Observable<RequestItemDeep> {
		return this.selectAll({
			filterBy: [
				entity => entity.actionId === id,
				entity => entity.ticketSource === TicketSource.MineApp,
				entity => entity.ticketState !== TicketStateEnum.Redacted && entity.ticketState !== TicketStateEnum.Closed,
			],
		}).pipe(map(res => <RequestItemDeep>res[0]));
	}

	selectTicketType(ticketId: string): Observable<TicketType> {
		return this.selectEntity(ticketId, ({ ticketType }) => ticketType);
	}

	isAutoPilotSupported(ticketId: string): boolean {
		const request = this.getEntity(ticketId);
		const requestState = this.getRequestState(request);
		
		if (!requestState) return false;
		if (this.isClosed(requestState)) return false;
		if (requestState === TicketStateEnum.Unverified) return false;
		if (request.autoPilotState === AutoPilotState.Assigned) return false;

		return ((this.featureFlagQuery.getFlag(FeatureFlags.AutopilotDoNotSell) && request.ticketType === TicketType.DoNotSell)
			|| (request.ticketType === TicketType.Delete));
	}

	private getRequestState(request: RequestItemDeep | RequestItem): TicketStateEnum {
		return typeof request.ticketState === 'string' ? request.ticketState : request.ticketState?.state;
	}
}