import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => {
    console.error(err);

    // report error to user.
    // TODO: Make this pretty
    const errorNode = document.createElement('p');
    errorNode.id = 'main-error';
    errorNode.innerText = 'Oops something went wrong. Please share this with our tech support nerds: ' + (err?.message !== undefined ? err.message : err);
    document.body.appendChild(errorNode);
  });
