import { DestroyRef, Injectable } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { catchError, EMPTY, first, forkJoin, map, Observable, switchMap, tap, concat } from "rxjs";
import { HttpErrorResponse } from "@angular/common/http";
import { rxActions } from "@rx-angular/state/actions";
import { RxState } from "@rx-angular/state";

import { PusherService } from "../pusher.service";
import { DataUpdateEnum } from "../models/data-update.enum";
import { LoggerService } from "src/app/logger/logger.service";
import { SystemsService } from "src/app/systems/state/systems.service";
import { UnverifiedSystemsService } from "src/app/radar/state/unverified-systems.service";
import { MineOSChatbotState, MineOSChatbotActions } from '../models/mineos-chatbot.interface';
import { CompanySettingsService } from "src/app/company-settings/state/company-settings.service";
import { ProcessingActivitiesService } from "src/app/processing-activities/state/processing-activities.service";

@Injectable({
    providedIn: 'root'
})
export class MineOSChatbotStore extends RxState<MineOSChatbotState> {

    private readonly loggerName: string = 'MineOSChatbotStore';

    private readonly initState = {
        open: false,
        dsReady: false,
        channelReady: false,
        messages: []
    } as MineOSChatbotState;

    public actions = rxActions<MineOSChatbotActions>();

    constructor(
        private logger: LoggerService,
        private destoryRef: DestroyRef,
        private pusherService: PusherService,
        private systemsService: SystemsService,
        private companySettingsService: CompanySettingsService,
        private unverifiedSystemsService: UnverifiedSystemsService,
        private processingActivitiesService: ProcessingActivitiesService
    ) {
        super();
        this.set(this.initState);

        this.pusherService.channelReady.pipe(
            first(),
            map(ready => this.actions.channelReady(ready)),
            catchError(err => this.handleError(err))
        ).subscribe();

        this.pusherService.channelDisconnected.pipe(
            first(),
            map(() => this.actions.disconnect()),
            catchError(err => this.handleError(err))
        ).subscribe();

        this.actions.sendMessage$.pipe(
            tap(message => this.pusherService.sendMessage(message)),
            tap(message => this.set('messages', (state) => [...state.messages, message])),
            catchError(err => this.handleError(err)),
            takeUntilDestroyed(this.destoryRef)
        ).subscribe();

        this.actions.toggle$.pipe(
            map(() => this.set({ 'open': !this.get('open') })),
            catchError(err => this.handleError(err)),
            takeUntilDestroyed(this.destoryRef)
        ).subscribe();

        this.actions.open$.pipe(
            map(() => this.set({ 'open': true })),
            catchError(err => this.handleError(err)),
            takeUntilDestroyed(this.destoryRef)
        ).subscribe();

        this.actions.close$.pipe(
            map(() => this.set({ 'open': false })),
            catchError(err => this.handleError(err)),
            takeUntilDestroyed(this.destoryRef)
        ).subscribe();

        this.actions.dsReady$.pipe(
            tap(ready => this.logger.debug(this.loggerName, `ds ready state: ${ready}`)),
            map(ready => this.set({ 'dsReady' : ready })),
            catchError(err => this.handleError(err)),
            takeUntilDestroyed(this.destoryRef)
        ).subscribe();

        this.actions.channelReady$.pipe(
            tap(ready => this.logger.debug(this.loggerName, `channel ready state: ${ready}`)),
            map(ready => this.set({ 'channelReady' : ready })),
            catchError(err => this.handleError(err)),
            takeUntilDestroyed(this.destoryRef)
        ).subscribe();

        this.actions.updateData$.pipe(
            tap(dataTypes => this.logger.debug(this.loggerName, `updating the following data types: ${dataTypes}`)),
            switchMap(dataTypes => this.updateDataTypes(dataTypes)),
            catchError(err => this.handleError(err)),
            takeUntilDestroyed(this.destoryRef)
        ).subscribe();
    }

    private updateDataTypes(dataTypes: DataUpdateEnum[]): Observable<void> {
        const streams$: Observable<void>[] = [];
        if (dataTypes.includes(DataUpdateEnum.Radar)) {
            streams$.push(
                this.unverifiedSystemsService.getUnverifiedSystemsFromServer().pipe(
                    map(() => void 0)
                )
            );
        }
        if (dataTypes.includes(DataUpdateEnum.DataSources) && dataTypes.includes(DataUpdateEnum.ProcessingActivities)) {
            streams$.push(
                concat(
                    forkJoin([
                        this.companySettingsService.getCompanySettings(), 
                        this.systemsService.getSystemsFromServer()
                    ]), 
                    this.processingActivitiesService.getProcessingActivities(true)
                ).pipe(
                    map(() => void 0)
                ));
        }
        else if (dataTypes.includes(DataUpdateEnum.DataSources)) {
            streams$.push(
                forkJoin([
                    this.companySettingsService.getCompanySettings(), 
                    this.systemsService.getSystemsFromServer()
                ]).pipe(
                    map(() => void 0)
                ));
        }
        else if (dataTypes.includes(DataUpdateEnum.ProcessingActivities)) {
            streams$.push(
                this.processingActivitiesService.getProcessingActivities(true).pipe(
                    map(() => void 0)
                ));
        }
        if (streams$.length) {
            return forkJoin(streams$).pipe(
                map(() => void 0)
            );
        }
        return EMPTY;
    }

    private handleError(error: HttpErrorResponse): Observable<never> {
        this.logger.error(this.loggerName, error.message);
        console.error(error);
        return EMPTY;
    }
}