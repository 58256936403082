import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

import { ContentPipe } from 'src/app/services/content/content.pipe';
import { DropdownOption } from 'src/app/shared/mine-dropdown/mine-dropdown.interface';

import { AiAssessmentTableStatus } from '../models/ai-assessments.interface';
import { AiAssessmentInstance } from 'src/app/api/models/ai-assessments/ai-assessments.interface';

@Pipe({
    standalone: true,
    name: 'aiAssessmentStatus'
})
export class AiAssessmentStatusPipe implements PipeTransform {

    private readonly statuses = this.contentPipe.transform('ai-assessments.statuses') as DropdownOption[];

    constructor(
        private datePipe: DatePipe,
        private contentPipe: ContentPipe,
    ) {}

    transform(instance: AiAssessmentInstance): AiAssessmentTableStatus {
        return {
            date: this.datePipe.transform(instance.lastModified ?? instance.createdAt, 'MMM y'),
            status: this.statuses.find(status => status.id === instance.state)
        } as AiAssessmentTableStatus;
    }
}