import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';

import { HtmlDecodePipe } from 'src/app/shared/pipes/html-decode.pipe';
import { DataType } from '../models/data-types.interface';

import { produce } from 'immer';

export interface DataTypesState extends EntityState<DataType, string> { }

@Injectable({
	providedIn: 'root'
})
@StoreConfig({
	name: 'dataTypes',
	idKey: 'id',
	producerFn: produce
})
export class DataTypesStore extends EntityStore<DataTypesState> {
	
	constructor(
		private htmlDecodePipe: HtmlDecodePipe
	) {
		super();
	}

	akitaPreAddEntity(dataType: DataType): DataType {
		return this.getDecodedDataType(dataType);
	}

	akitaPreUpdateEntity(currDataType: DataType, newDataType?: DataType): DataType {
		return this.getDecodedDataType(!!newDataType ? newDataType : currDataType);
	}

	private getDecodedDataType(dataType: DataType): DataType {	
		return {
			...dataType,
			name: this.htmlDecodePipe.transform(dataType.name)
		};
	}
}
