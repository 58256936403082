import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { BehaviorSubject, combineLatest, map, Observable, tap } from 'rxjs';

import { LoggerService } from 'src/app/logger/logger.service';
import { ApiClientSystemsDirectoryService } from 'src/app/api/api-client-systems-directory.service';
import { PaginationCursor, PaginationCursorConfig } from 'src/app/shared/mine-pagination/mine-pagination.interface';
import { SystemsDirectoryByIdsResponse, SystemsDirectoryDiscoveryResponse, SystemsDirectoryItem } from 'src/app/api/models/systems-directory/systems-directory.interface';
import { SystemsDirectoryDiscoverPayload, SystemsDirectoryListPayload, SystemsDirectorySearchPayload } from '../models/systems-directory.interface';
import { SystemsDirectoryState, SystemsDirectoryStore } from './systems-directory.store';

@Injectable({
	providedIn: 'root'
})
export class SystemsDirectoryQuery extends QueryEntity<SystemsDirectoryState> {
	private readonly loggerName: string = 'SystemsDirectoryQuery';
	private before$ = new BehaviorSubject<string>(null);
	private after$ = new BehaviorSubject<string>(null);

	constructor(
		private logger: LoggerService,
		protected store: SystemsDirectoryStore,
		private apiClientSystemsDirectory: ApiClientSystemsDirectoryService,
	) {
		super(store);
	}

	selectCursor(): Observable<PaginationCursor> {
		return combineLatest([
			this.before$.asObservable(),
			this.after$.asObservable()
		]).pipe(
			map(([before, after]) => ({before, after} as PaginationCursor))
		)
	}

	getCursor(): PaginationCursor {
		return {
			before: this.before$.getValue(), 
			after: this.after$.getValue()
		} as PaginationCursor;
	}

	setCursor(cursor: PaginationCursor): void {
		this.before$.next(cursor.before ?? null);
		this.after$.next(cursor.after ?? null);
	}

	discover(payload: SystemsDirectoryDiscoverPayload): Observable<SystemsDirectoryDiscoveryResponse> {
		return this.apiClientSystemsDirectory.discover(payload);
	}

	search(payload: SystemsDirectorySearchPayload): Observable<SystemsDirectoryItem[]> {
		return this.apiClientSystemsDirectory.search(payload).pipe(
			tap(response => this.initStore(response)),
			map(response => response.services)
		);
	}

	list(payload: SystemsDirectoryListPayload): Observable<SystemsDirectoryItem[]> {
		return this.apiClientSystemsDirectory.list(payload).pipe(
			tap(response => this.initStore(response)),
			map(response => response.services)
		);
	}
	
	getById(id: string): Observable<SystemsDirectoryItem> {
		return this.getByIds([id]).pipe(
			map(res => {
				if (Object.keys(res?.services).includes(id)) {
					return res.services[id];
				}
				return undefined;
			}),
		);
	}

	getByIds(ids: string[]): Observable<SystemsDirectoryByIdsResponse> {
		return this.apiClientSystemsDirectory.getByIds(ids);
	}

	private initStore(response: PaginationCursorConfig<SystemsDirectoryItem>): void {
		this.logger.debug(this.loggerName, `Init store - ${response.services?.length} requests`);
		this.before$.next(response.cursor?.before ?? null);
		this.after$.next(response.cursor?.after ?? null);
		this.store.set(response.services ?? []);
	}

	resetStore(): void {
		this.setCursor({before: null, after: null} as PaginationCursor);
		this.store.reset();
	}
}