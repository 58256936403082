<div class="error">
	<main class="error__main">
		<div class="error__image">
			<img class="error__img" src="{{assetsUrl}}/Portal/ostrich-on-bicycle.png">
		</div>
		<div class="error__content">
			<h1 class="error__title">{{'authentication.noPermissionTitle' | content}}</h1>
			<p class="error__explanation">{{'authentication.noPermissionExplenation' | content}}</p>
			<p class="error__text">{{'authentication.noPermissionText' | content}}</p>

			<div class = "error__btn-container">
				<mine-button-primary id="get-started-btn"
										class="try-again-btn u-display-block"
										[text]="'authentication.noPermissionFaqBtn' | content"
										(onClick)="back()">
				</mine-button-primary>

				<mine-button-tertiary id="get-started-btn"
									class="try-again-btn u-display-block"
									[label]="'No permissions - back'"
									(onClick)="redirectToFaq()">
									{{'authentication.noPermissionBack' | content}}
				</mine-button-tertiary>
			</div>
		</div>
	</main>
</div>