import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ContentMode } from '../services/content/content.enum';
import { SquidexResponse, SquidexSchema } from './models/content/squidex.interface';
import { ContentSchemaHelper } from '../services/content/content-schema-helper';
import { ContentSchema } from '../services/content/content-schema.interface';
import { environment } from 'src/environments/environment';

@Injectable({
	providedIn: 'root'
})
export class ApiClientContentService {

	private readonly defaultLanguage = 'en';
	private readonly endpointApi = 'https://cloud.squidex.io/api/content/mine-app/';
	private readonly backupApi = environment.cms.backupEndpoint;

	constructor(
		private httpClient: HttpClient
	) { }

	getSingleSchema(schema: ContentSchema, contentMode: ContentMode, language?: string, backup: boolean = false): Observable<SquidexSchema> {
		const endpoint = backup ? `${this.backupApi}/${schema.id}.json` : `${this.endpointApi}${schema.name}/${schema.id}`;
		return this.httpClient.get<SquidexSchema>(endpoint, { 
			headers: this.getHeaders(contentMode, language) 
		});
	}

	getAllSchemas(contentMode: ContentMode, language?: string): Observable<SquidexResponse> {
		const ids = Object.keys(ContentSchemaHelper).map(key => ContentSchemaHelper[key]?.id).join(',');
		return this.httpClient.get<SquidexResponse>(`${this.endpointApi}?ids=${ids}`, { 
			headers: this.getHeaders(contentMode, language) 
		});
	}

	private getHeaders(contentMode: ContentMode, language: string): HttpHeaders {
		let headers = new HttpHeaders();
		headers = headers.append('X-Flatten', 'true');
		if (language){
			headers = headers.append('X-Languages', language);
			headers = headers.append('Cache-Control', 'no-cache');
		} else {
			headers = headers.append('X-Languages', this.defaultLanguage);
		}
		if (contentMode === ContentMode.Draft) {
			headers = headers.append('X-Unpublished', '');
		}

		return headers;
	}
}
