import { inject, InjectionToken } from '@angular/core';
import { PaginatorPlugin } from '@datorama/akita';
import { RequestsQuery } from './requests.query';

export const REQUESTS_PAGINATOR = new InjectionToken('REQUESTS_PAGINATOR', {
  providedIn: 'root',
  factory: () => {
    return new PaginatorPlugin(inject(RequestsQuery))
            .withControls()
            .withRange();
  }
});