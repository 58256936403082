import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { RequestItem } from 'src/app/api/models/requests/requests.interface';
import { RequestItemDeep } from 'src/app/api/models/requests/requests.interface';

import { produce } from 'immer';

export interface RequestsState extends EntityState<RequestItem | RequestItemDeep> { }

@Injectable({
	providedIn: 'root'
})
@StoreConfig({
	name: 'requests',
	idKey: 'ticketId',
	producerFn: produce
})
export class RequestsStore extends EntityStore<RequestsState> {
	constructor() {
		super();
	}
}