import { UrlTree, Router, CanMatchFn, Route, UrlSegment } from '@angular/router';
import { map, Observable, of, switchMap, tap } from 'rxjs';
import { inject } from '@angular/core';

import { DashboardTabsEnum } from './models/dashboard-tabs.enum';
import { RoleEnum } from 'src/app/core/models/permission-role.enum';
import { RoutesManager } from '../shared/models/routes.interfaces';
import { PermissionsService } from '../auth/permissions.service';
import { LoggerService } from '../logger/logger.service';
import { ProfileQuery } from '../profile/state/profile.query';
import { RolePermissionsHelper } from '../auth/role-permmissions-helper';

const loggerName: string = 'DashboardRoleGuard';

const DashboardRoleGuardFn = (route: Route, segments: UrlSegment[]): Observable<boolean | UrlTree> => {
    const router = inject(Router);
    const logger = inject(LoggerService);
    const profileQuery = inject(ProfileQuery);
    const permissionsService = inject(PermissionsService);
    const rolesPermmitted = RolePermissionsHelper.getPerrmitedRoleByRoute(segments?.map(segment => segment.path) ?? ['']);

    return permissionsService.selectIsAllowed(rolesPermmitted).pipe(
        tap(isAllowed => logger.debug(loggerName, `isAllowed: ${isAllowed}`)),
        switchMap(isAllowed => isAllowed ? of(true) : getAlternativeRoute(router, profileQuery)),
    );
}

const getAlternativeRoute = (router: Router, profileQuery: ProfileQuery): Observable<UrlTree> => {
    return profileQuery.selectAgentRole().pipe(
        map(role => role === RoleEnum.DsrManager ? router.parseUrl(`${RoutesManager.dashboard}/${DashboardTabsEnum.RequestHandling}`) : router.parseUrl(`${RoutesManager.pageNotAllowed}`)),
    );
}

export const DashboardRoleGuard: CanMatchFn = DashboardRoleGuardFn;