import { Injectable } from '@angular/core';
import { LoggerService } from '../logger/logger.service';
import { UserProperties } from './user-properties';

declare global {
	interface Window {
        hj: any;
    }
}

@Injectable({
  providedIn: 'root'
})
export class HotjarService {
  private readonly loggerName = 'HotjarService';

  constructor(private logger: LoggerService) { }

  init(): void {
    this.logger.debug(this.loggerName, 'init()');
  }

  event(name: string): void {
    window.hj('event', name);
  }

  updateUserProperties(userProperties: UserProperties): void {
    if(!userProperties.Id) {
      // no PII is allowed in HotJar without a userID, since we cannot comply to data requests
      return;
    }

    // https://help.hotjar.com/hc/en-us/articles/360033640653
    // At present, there is one User Attribute key for email addresses: email. Email addresses should be passed as strings to email.
    // If any other User Attributes of a string type contain an email address, these will be rejected, meaning you should only use `email` to pass email addresses.

    window.hj('identify', userProperties.Id, {
      //email: userProperties.Email,
			'First Name': userProperties.FirstName,
			'Last Name': userProperties.LastName,
			'Company Name': userProperties.CompanyName,
			'Company ID': userProperties.CompanyId,
      'Company Plan': userProperties.PlanName,
      'IsSandbox': userProperties.CompanyName?.indexOf('-sandbox') > -1,
    });
  }
}
