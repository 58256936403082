<ng-container *ngIf="profile$ | async as profile">
	<input type="checkbox" [(ngModel)]="checked" class="app-menu__checkbox" id="menu">
	
	<div class="app-menu__toggle">
		<img ngSrc="{{profile.pictureUrl ? profile.pictureUrl : profilePicture}}" alt="Mine profile img" width="30" height="30" class="app-menu__img">

		<label for="menu" class="app-menu__label">
			<p [attr.data-label]="'profile-menu'" class="app-menu__name">{{profile.firstName | titlecase}} {{profile.lastName | titlecase}}</p>
			<svg [attr.data-label]="'profile-menu'" class="app-menu__arrow">
				<use xlink:href="../../../assets/img/sprite.svg#icon-Arrow-2"></use>
			</svg>
		</label>
	</div>

	<div *ngIf="checked" @fadeInOut class="app-menu__menu">
		<div class="app-menu__user">
			<h2 class="app-menu__user-name">{{profile.firstName | titlecase}} {{profile.lastName | titlecase}}</h2>
			<p class="app-menu__user-mail">{{profile.email}}</p>
			<!-- <a class="app-menu__user-link" routerLink="/{{routesManager.profile}}" (click)="closeProfileMenu()">{{textManager.PROFILE_MENU_my_profile}}</a> -->
		</div>

		<ul class="app-menu__list">
			<!-- <a href="{{environment.routing.faq}}" target="_blank" class="app-menu__list-link" (click)="closeProfileMenu()">{{textManager.NAVIGATION_f_a_q}}</a> -->
			<a href="javascript:void(0)" class="app-menu__list-link" (click)="onFeedbackClick()">{{'menu.feedback' | content}}</a>
		</ul>

		<button id="logoutBtn" class="app-menu__logout" (click)="onLogout()">
			<svg class="app-menu__logout--icon">
				<use xlink:href="../../../assets/img/sprite.svg#icon-Menu-log-out-icon"></use>
			</svg>
			<span class="app-menu__logout--text">{{'menu.logout' | content}}</span>
		</button>
	</div>
</ng-container>