import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';

import { HtmlDecodePipe } from 'src/app/shared/pipes/html-decode.pipe';
import { Framework } from 'src/app/data-types/models/frameworks.interface';

import { produce } from 'immer';

export interface FrameworksState extends EntityState<Framework, string> { }

@Injectable({
	providedIn: 'root'
})
@StoreConfig({
	name: 'frameworks',
	idKey: 'id',
	producerFn: produce
})
export class FrameworksStore extends EntityStore<FrameworksState> {
	
	constructor(
		private htmlDecodePipe: HtmlDecodePipe
	) {
		super();
	}

  	akitaPreAddEntity(framework: Framework): Framework {
		return this.getDecodedFramework(framework);
	}

	akitaPreUpdateEntity(framework: Framework): Framework {
		return this.getDecodedFramework(framework);
	}

	private getDecodedFramework(framework: Framework): Framework {
		return {
			...framework,
			backgroundColor: this.htmlDecodePipe.transform(framework?.backgroundColor),
			displayName: this.htmlDecodePipe.transform(framework?.displayName),
			textColor: this.htmlDecodePipe.transform(framework?.textColor),
		};
	}
}
