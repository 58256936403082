export class AnalyticEventsLabels {
	// Onboarding
	static readonly ONBOARDING_enter_your_portal: string = 'Onboarding - enter your portal';
	static readonly ONBOARDING_talk_with_an_expert: string = 'Onboarding - talk with an expert';
	static readonly ONBOARDING_no_thanks: string = 'Onboarding - no thanks';
	static readonly ONBOARDING_skip: string = 'Onboarding - skip';

	// Sidebar
	static readonly SIDEBAR_progrees_bar: string = 'Sidebar - progress bar';
	static readonly SIDEBAR_home_requests: string = 'Sidebar - home';
	static readonly SIDEBAR_dashboard: string = 'Sidebar - dashboard';
	static readonly SIDEBAR_data_mapping: string = 'Sidebar - data mapping';
	static readonly SIDEBAR_pii_discovery: string = 'Sidebar - pii discovery';
	static readonly SIDEBAR_data_sources: string = 'Sidebar - data sources';
	static readonly SIDEBAR_requests: string = 'Sidebar - requests';
	static readonly SIDEBAR_open_requests: string = 'Sidebar - open requests';
	static readonly SIDEBAR_closed_requests: string = 'Sidebar - closed requests';
	static readonly SIDEBAR_automations: string = 'Sidebar - automations';
	static readonly SIDEBAR_consent_management: string = 'Sidebar - consent management';
	static readonly SIDEBAR_privacy_center: string = 'Sidebar - privacy center';
	static readonly SIDEBAR_integrations: string = 'Sidebar - integrations';
	static readonly SIDEBAR_help_center: string = 'Sidebar - help center';
	static readonly SIDEBAR_add_a_team_member: string = 'Sidebar - add a team member';
	static readonly SIDEBAR_open_menu: string = 'Sidebar - open menu';
	static readonly SIDEBAR_email_forwarding: string = 'Sidebar - email forwarding';
	static readonly SIDEBAR_user_search: string = 'Sidebar - user serch';
	static readonly SIDEBAR_dpo_advisor: string = 'Sidebar - dpo advisor';
	static readonly SIDEBAR_impact_assessment: string = 'Sidebar - impact assessment';
	static readonly SIDEBAR_tasks: string = 'Sidebar - tasks';
	static readonly SIDEBAR_ai_assets: string = 'Sidebar - ai assets';
	static readonly SIDEBAR_ai_assessments: string = 'Sidebar - ai assessments';

	// Integrations
	static readonly INTEGRATIONS_learn_more: string = 'integrations - learn more';
	static readonly INTEGRATIONS_talk_to_us: string = 'integrations - talk to us';
	static readonly INTEGRATIONS_opens_chat: string = 'integration (opens chat)';
	static readonly INTEGRATIONS_downgrade_popup: string = 'integration (downgrade popup)';
	static readonly INTEGRATIONS_add_to_list: string = 'integrations - add to list';
	static readonly INTEGRATIONS_remove_from_list: string = 'integrations - remove from list';
	static readonly INTEGRATIONS_upgrade_your_plan: string = 'integrations - upgrade your plan';
	static readonly INTEGRATIONS_remove_from_list_yes: string = 'integrations - remove from list - yes';
	static readonly INTEGRATIONS_remove_from_list_no: string = 'integrations - remove from list - no';

    // Integrations - webhook
    static readonly INTEGRATIONS_webhook_events: string = 'integrations - Webhook events';
    static readonly INTEGRATIONS_webhook_documentation: string = 'Webhook events - Webhook events documentation';
    static readonly INTEGRATIONS_webhook_testing_your_integration: string = 'Webhook events - testing your integration';
    static readonly INTEGRATIONS_webhook_enable: string = 'Webhook events - enable integration';
    static readonly INTEGRATIONS_webhook_disable: string = 'Webhook events - disable integration';
    static readonly INTEGRATIONS_webhook_copy_key: string = 'Webhook events - copy key';
    static readonly INTEGRATIONS_webhook_save: string = 'Webhook events - save';
    static readonly INTEGRATIONS_custom_webhook_testing_your_integration: string = 'Custom integration - testing your integration';
	static readonly INTEGRATIONS_custom_pii_testing_your_integration: string = 'Custom integration pii - testing your integration';

    // Integrations - api key
    static readonly INTEGRATIONS_api_key_integration: string = 'integrations - API Key integration';
    static readonly INTEGRATIONS_api_key_documentation: string = 'API Key - documentation';
    static readonly INTEGRATIONS_api_key_testing_your_integration: string = 'API Key - testing your integration';
    static readonly INTEGRATIONS_api_key_enable: string = 'API Key - enable integration';
    static readonly INTEGRATIONS_api_key_disable: string = 'API Key - disable integration';
	static readonly INTEGRATIONS_api_key_save: string = 'API Key - save';
	static readonly INTEGRATIONS_api_key_save_and_add_to_list: string = 'API Key - save and add to list';
	static readonly INTEGRATIONS_api_key_add_to_list: string = 'API Key - add to list';
	static readonly INTEGRATIONS_api_key_remove_from_list: string = 'API Key - remove from list';

	// Integrations - oauth
	static readonly INTEGRATIONS_oauth_integration: string = 'integrations - OAuth integration';
	static readonly INTEGRATIONS_oauth_documentation: string = 'OAuth - documentation';
	static readonly INTEGRATIONS_oauth_connect: string = 'OAuth - connect';
	static readonly INTEGRATIONS_oauth_disconnect: string = 'OAuth - disconnect';
	static readonly INTEGRATIONS_oauth_enable: string = 'OAuth - enable integration';
	static readonly INTEGRATIONS_oauth_disable: string = 'OAuth - disable integration';
	static readonly INTEGRATIONS_oauth_save: string = 'OAuth - save';
	static readonly INTEGRATIONS_oauth_save_and_add_to_list: string = 'OAuth - save and add to list';
	static readonly INTEGRATIONS_oauth_add_to_list: string = 'OAuth - add to list';
	static readonly INTEGRATIONS_oauth_remove_from_list: string = 'OAuth - remove from list';

	// Integrations - email
	static readonly INTEGRATIONS_email_save: string = 'Email - save';
	static readonly INTEGRATIONS_email_save_and_add_to_list: string = 'Email - save and add to list';
	static readonly INTEGRATIONS_email_add_to_list: string = 'Email - add to list';
	static readonly INTEGRATIONS_email_remove_from_list: string = 'Email - remove from list';

	// Integrations - internal system
    static readonly INTEGRATIONS_internal_system_testing_your_integration: string = 'Internal System events - testing your integration';
	static readonly INTEGRATIONS_internal_system_save: string = 'Internal System events - save';
	static readonly INTEGRATIONS_internal_system_save_and_add_to_list: string = 'Internal System events - save and add to list';
	static readonly INTEGRATIONS_internal_system_add_to_list: string = 'Internal System events - add to list';
	static readonly INTEGRATIONS_internal_system_remove_from_list: string = 'Internal System events - remove from list';
	
	// Integrations - manual
	static readonly INTEGRATIONS_manual_integration: string = 'integrations - Manual integration';
	static readonly INTEGRATIONS_manual_notify: string = 'Manual - notify me when automation available'
	static readonly INTEGRATIONS_manual_add_to_list: string = 'Manual - add to list';
	static readonly INTEGRATIONS_manual_remove_from_list: string = 'Manual - remove from list';

	// Free trial banner
	static readonly FREE_trial_banner: string = 'Free trial banner';

	// Pricing
	static readonly PRICING_my_billing: string = 'pricing - my billing'

	//Forward emails
	static readonly IMPORT_TICKET_forward_email: string = 'connect forward email - learn more';

	// Homepage
	static readonly ONBOARDING_CHECKLIST_close_request_step: string = 'onboarding checklist - close your first request - step';
	static readonly ONBOARDING_CHECKLIST_close_request_cta: string = 'onboarding checklist - close your first request - cta';
	static readonly ONBOARDING_CHECKLIST_connect_experts_step: string = 'onboarding checklist - connect with experts - step';
	static readonly ONBOARDING_CHECKLIST_connect_experts_cta: string = 'onboarding checklist - connect with experts - cta';
	static readonly ONBOARDING_CHECKLIST_integration_step: string = 'onboarding checklist - setup integration - step';
	static readonly ONBOARDING_CHECKLIST_integration_cta: string = 'onboarding checklist - setup integration - cta';
	static readonly ONBOARDING_CHECKLIST_add_teammate_step: string = 'onboarding checklist - add teammate - step';
	static readonly ONBOARDING_CHECKLIST_add_teammate_cta: string = 'onboarding checklist - add teammate - cta';
	static readonly ONBOARDING_CHECKLIST_privacy_center_step: string = 'onboarding checklist - customize privacy center - step';
	static readonly ONBOARDING_CHECKLIST_privacy_center_cta: string = 'onboarding checklist - customize privacy center - cta';
	static readonly ONBOARDING_CHECKLIST_data_mapping_step: string = 'onboarding checklist - data mapping - step';
	static readonly ONBOARDING_CHECKLIST_data_mapping_cta: string = 'onboarding checklist - data mapping - cta';
	static readonly ONBOARDING_CHECKLIST_connect_to_mine_step: string = 'onboarding checklist - connect with mine - step';
	static readonly ONBOARDING_CHECKLIST_connect_to_mine_cta: string = 'onboarding checklist - connect with mine - cta';
	static readonly HOME_talk_with_an_expert: string = 'home - talk with an expert';
	static readonly HOME_introduction_video: string = 'Introduction video';
	static readonly HOME_channels = 'homepage - connect channels';

	// Requests table
	static readonly REQUESTS_TABLE_get_ticket_page: string = 'Requests table - get ticket page';

	// Data mapping
	static readonly DATA_MAPPING_intro_next = 'data-mapping - Intro next';
	static readonly DATA_MAPPING_add_account_dialog = 'data-mapping - Add account dialog';
	static readonly DATA_MAPPING_copy_link = 'data-mapping - Copy link';
	static readonly DATA_MAPPING_add_your_account = 'data-mapping - Add your account';
	static readonly DATA_MAPPING_add_your_account_callback = 'data-mapping - Add your account callback';
	static readonly DATA_MAPPING_connect_user = 'data-mapping - connect-user';

	// Connect to Mine
	static readonly CONNECT_TO_MINE_table_btn = 'connect-to-mine - connect to mine table button';
	static readonly CONNECT_TO_MINE_privacy_center = 'connect-to-mine - Privacy center';
	static readonly CONNECT_TO_MINE_email_forwarding = 'connect-to-mine - Email forwarding';
	static readonly AUTHORIZATION_DIALOG_confirm_btn = 'authorization-dialog - Confirm button';
	static readonly AUTHORIZATION_DIALOG_resend_mail_btn = 'authorization-dialog - Resend button';
	static readonly CONNECT_TO_MINE_resend_header_tooltip = 'connect-to-mine - header resend button';
	static readonly CONNECT_TO_MINE_header_btn = 'connect-to-mine - connect to mine header button';

	// Schedule Meeting
	static readonly ACTIVATE_PORTAL_integrations_btn = 'talk to an expert & get free access - integrations prompt';
	static readonly ACTIVATE_PORTAL_data_mapping_btn = 'talk to an expert & get free access - data mapping prompt';
	static readonly ACTIVATE_PORTAL_templates_btn = 'talk to an expert & get free access - templates prompt';
	static readonly ACTIVATE_PORTAL_panorays = 'talk to an expert & get free access - panorays plan';
	static readonly ACTIVATE_PORTAL_add_team_member_btn = 'talk to an expert & get free access - add team member prompt';
	static readonly DEVELOPERS_api_keys_btn = 'talk to an expert & get free access - api keys prompt'; 
	static readonly DEVELOPERS_webhook_btn = 'talk to an expert & get free access - webhook integration prompt';
	static readonly DEVELOPERS_notfications_btn = 'talk to an expert & get free access - notifications prompt';

	// Developers
	static readonly DEVELOPERS_label = 'For developers';
	static readonly SLACK_NOTIFICATION_test = 'Slack - test your integration';
	static readonly SLACK_NOTIFICATION_enable = 'Slack - enable notification';

	// Settings
	static readonly SETTINGS_BRANDING_change_logo = 'Branding - Change logo';
	static readonly SETTINGS_BRANDING_add_footer_link = 'Branding - Add footer link';
	static readonly SETTINGS_BRANDING_remove_footer_link = 'Branding - Remove footer link';
	static readonly SETTINGS_BRANDING_save = 'Branding - Save';

	// Employees panel
	static readonly EMPLOYEES_see_all = 'see all employees';

	// Search Evidence
	static readonly SEARCH_EVIDENCE_flag_on = 'ticket search feature flag on';
	static readonly SEARCH_EVIDENCE_flag_off = 'ticket search feature flag off';
	static readonly SEARCH_EVIDENCE_clicked = 'homepage evidence search';
	static readonly SEARCH_EVIDENCE_found = 'found';
	static readonly SEARCH_EVIDENCE_not_found = 'not found';
	static readonly SEARCH_EVIDENCE_process_request = 'homepage - process this request';	
	static readonly SEARCH_EVIDENCE_process_request_connected = 'connected to Mine';
	static readonly SEARCH_EVIDENCE_process_request_not_connected = 'not connected to Mine';

	// Systems Catalog
	static readonly SYSTEMS_CATALOG_open_panel = 'Systems Catalog - Open panel';
	static readonly SYSTEMS_CATALOG_close_panel = 'Systems Catalog - Close panel';
	static readonly SYSTEMS_CATALOG_add_to_inventory = 'Systems Catalog - Add to your inventory';
	static readonly SYSTEMS_CATALOG_unarchived = 'Systems Catalog - Unarchive';

	// Systems Directory
	static readonly SYSTEMS_DIRECTORY_open_panel = 'Systems Directory - Open panel';
	static readonly SYSTEMS_DIRECTORY_close_panel = 'Systems Directory - Close panel';
	static readonly SYSTEMS_DIRECTORY_add_to_inventory = 'Systems Directory - Add to your inventory';
	static readonly SYSTEMS_DIRECTORY_unarchived = 'Systems Directory - Unarchive';

	// processing activities panel
	static readonly PROCESSING_ACTIVITIES_panel = 'Processing Activities - Go to system pa';
	static readonly PROCESSING_ACTIVITIES_suggestions = 'Processing Activities - Suggestions';
}
