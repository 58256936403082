<label [attr.data-label]="label" (click)="toggle()" class="panel__label" 
	[class.panel__label--disabled]="disableToggle">
	<ng-content select="[header]"></ng-content>	
</label>
  
<div *ngIf="!disableContent" 
	[@showHide]="state" 
	(@showHide.done)="onAnimationDone()" 
	(@showHide.start)="onAnimationStart()"
	class="panel__content" #panelContent>
	
	<ng-container *ngIf="state === AnimationStateEnum.Show">
		<ng-content select="[content]"></ng-content>
	</ng-container>
</div>