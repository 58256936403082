import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { SystemIcon } from '../models/systems.interface';

import { produce } from 'immer';

export interface SystemsIconsState extends EntityState<SystemIcon, string> {}

@Injectable({
	providedIn: 'root'
})
@StoreConfig({
	name: 'systemsIcons',
	idKey: 'systemType',
	producerFn: produce,
})
export class SystemsIconsStore extends EntityStore<SystemsIconsState> {
	constructor() {
		super();
	}
}
