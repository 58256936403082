import { Injectable } from '@angular/core';
import { combineLatest, Observable, map, filter, switchMap } from 'rxjs';
import { Channel } from './channel.interface';
import { ChannelId } from './connect-channel-id.enum';
import { ConnectedToMineStatusEnum } from 'src/app/api/models/profile/connected-to-mine-status.enum';
import { PrivacyCenterRequestForm } from 'src/app/api/models/privacy-center/privacy-center.interface';
import { ProfileQuery } from 'src/app/profile/state/profile.query';
import { FeatureFlagQuery } from 'src/app/feature-flag/state/feature-flag.query';
import { FeatureFlags } from 'src/app/api/models/profile/profile-feature-flags.enum';
import { RoutesManager } from 'src/app/shared/models/routes.interfaces';
import { Profile } from 'src/app/profile/models/profile.interface';
import { PrivacyCenterQuery } from 'src/app/privacy-center/state/privacy-center.query';
import { PrivacyCenterService } from 'src/app/privacy-center/state/privacy-center.service';

@Injectable({
    providedIn: 'root'
  })
  export class ChannelService {
  
    readonly svgSprite = '../../../assets/img/sprite.svg#';

    constructor(
        private profileQuery: ProfileQuery,
		private featureFlagsQuery: FeatureFlagQuery,
		private privacyCenterService: PrivacyCenterService,
    ) { }

    getChannels(homePageVersion = false): Observable<Channel[]> {
		const privacyCenter$ = this.privacyCenterService.getLoadedPrivacyCenter();
        
		return combineLatest([privacyCenter$,this.profileQuery.select()])
		.pipe(
				map(([form, profile]) => this.initiateChannels(profile, form, homePageVersion))
			);
    }

    private initiateChannels(profile: Profile, form: PrivacyCenterRequestForm, homePageVersion: boolean): Channel[] {
		const channels = [
			{
				id: ChannelId.Mine,
				connected: profile.connectedToMineStatus === ConnectedToMineStatusEnum.ConnectedToMine,
				iconName: `${this.svgSprite}mine-logo`,
				contentKey: 'connect-channels.connectMIne',
				routerLink: '',
			},
			{
				id: ChannelId.PrivacyCenter,
				connected: !!form.customPath && !!form.enabled,
				iconName: `${this.svgSprite}icon-Privacy`,
				contentKey: !homePageVersion ? 'connect-channels.privacyCenter' : 'connect-channels.privacyCenterHomepage',
				routerLink: RoutesManager.privacyCenter,
			},
			{
				id: ChannelId.CompanyInbox,
				connected: profile.emailForwardingConnected,
				iconName: `${this.svgSprite}icon-Mail`,
				contentKey: !homePageVersion ? 'connect-channels.companyMail' : 'connect-channels.companyMailHomepage',
				routerLink: RoutesManager.emailForwarding,
                tooltip: !profile.emailForwardingConnected ? 'connect-channels.emailTooltip' : '',
			},
		];
		
		if (!homePageVersion && this.featureFlagsQuery.getFlag(FeatureFlags.TollFree)) {
			channels.push(
				{
					id: ChannelId.TollFree,
					connected: false,
					iconName: `${this.svgSprite}icon-phone`,
					contentKey: 'connect-channels.tollFree',
					routerLink: '',
				}
			)
		}

		return channels;
	}
}
