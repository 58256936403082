export enum RequestsListTypeEnum {
	All = 'all',
	Open = 'open',
	Closed = 'closed',
	Unverified = 'unverified',
}

export enum RequestsStatusEnum {
	Open = 'Open',
	Edit = 'Edit',
	InProcessV2 = 'Review',
	Notify = 'Reply',
	Processing = 'Processing',
	Unverified = 'Unverified',
	Closed = 'Closed',
}
