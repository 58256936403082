export enum RiskRatingEnum {
    Bad = "Bad",
    Poor = "Poor",
    Fair = "Fair",
    Good = "Good",
    Excellent = "Excellent",
}

export enum PostureCategoriesEnum {
    It = "it",
    Application = "application",
    Human = "human"
}