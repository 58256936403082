export enum TicketStateEnum {
   Unknown = "Unknown",
   Edit = "Edit",
   Open = "Open",
   Closed = "Closed",
   Notify = "Notify",
   Processing = "Processing",
   Redacted = "Redacted",
   Redacting = "Redacting",
   Unverified = "Unverified",
}

export enum AutoPilotState {
   NotAssigned = 'NotAssigned',
   Assigned = 'Assigned',
   TimeOut = 'TimeOut',
   MaxRetry = 'MaxRetry',
}