<div class="search-evidence__search-section" [class.search-evidence__search-section-separator]="showResultsSection">

    <p class="search-evidence__badge">
        {{ 'home.evidenceBadge' | content }}
    </p>

    <div class="search-evidence__image">
        <svg width="79" height="92" viewBox="0 0 79 92" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="53" cy="3" r="3" fill="#B4EDD2"/>
            <path d="M15.0744 26.1812H3.66287C1.83612 26.1812 0.347656 27.6696 0.347656 29.4964V36.3974C0.347656 38.2242 1.83612 39.7126 3.66287 39.7126H15.0744C16.9012 39.7126 18.3896 38.2242 18.3896 36.3974V29.4964C18.3896 27.6696 16.9012 26.1812 15.0744 26.1812ZM15.0744 27.8726C15.1984 27.8726 15.3225 27.8951 15.4352 27.9177L9.37991 32.9469L3.44862 27.8951C3.51628 27.8839 3.58393 27.8726 3.66287 27.8726H15.0744ZM16.6982 36.3974C16.6982 37.2882 15.9652 38.0212 15.0744 38.0212H3.66287C2.77204 38.0212 2.03909 37.2882 2.03909 36.3974V29.4964C2.03909 29.3159 2.07292 29.1468 2.1293 28.9889L8.8161 34.6947C8.97397 34.83 9.17694 34.8977 9.36864 34.8977C9.56033 34.8977 9.75203 34.83 9.9099 34.706L16.6531 29.113C16.6869 29.237 16.6982 29.361 16.6982 29.4964V36.3974Z" fill="#9897EE"/>
            <path d="M15.0744 52H3.66287C1.83612 52 0.347656 53.4885 0.347656 55.3152V62.2163C0.347656 64.043 1.83612 65.5315 3.66287 65.5315H15.0744C16.9012 65.5315 18.3896 64.043 18.3896 62.2163V55.3152C18.3896 53.4885 16.9012 52 15.0744 52ZM15.0744 53.6914C15.1984 53.6914 15.3225 53.714 15.4352 53.7365L9.37991 58.7657L3.44862 53.714C3.51628 53.7027 3.58393 53.6914 3.66287 53.6914H15.0744ZM16.6982 62.2163C16.6982 63.1071 15.9652 63.84 15.0744 63.84H3.66287C2.77204 63.84 2.03909 63.1071 2.03909 62.2163V55.3152C2.03909 55.1348 2.07292 54.9656 2.1293 54.8078L8.8161 60.5135C8.97397 60.6489 9.17694 60.7165 9.36864 60.7165C9.56033 60.7165 9.75203 60.6489 9.9099 60.5248L16.6531 54.9318C16.6869 55.0559 16.6982 55.1799 16.6982 55.3152V62.2163Z" fill="#9897EE"/>
            <path d="M15.0744 78H3.66287C1.83612 78 0.347656 79.4885 0.347656 81.3152V88.2163C0.347656 90.043 1.83612 91.5315 3.66287 91.5315H15.0744C16.9012 91.5315 18.3896 90.043 18.3896 88.2163V81.3152C18.3896 79.4885 16.9012 78 15.0744 78ZM15.0744 79.6914C15.1984 79.6914 15.3225 79.714 15.4352 79.7365L9.37991 84.7657L3.44862 79.714C3.51628 79.7027 3.58393 79.6914 3.66287 79.6914H15.0744ZM16.6982 88.2163C16.6982 89.1071 15.9652 89.84 15.0744 89.84H3.66287C2.77204 89.84 2.03909 89.1071 2.03909 88.2163V81.3152C2.03909 81.1348 2.07292 80.9656 2.1293 80.8078L8.8161 86.5135C8.97397 86.6489 9.17694 86.7165 9.36864 86.7165C9.56033 86.7165 9.75203 86.6489 9.9099 86.5248L16.6531 80.9318C16.6869 81.0559 16.6982 81.1799 16.6982 81.3152V88.2163Z" fill="#9897EE"/>
            <rect x="29" y="26" width="50" height="4" rx="2" fill="#E9E9EA"/>
            <rect x="29" y="35" width="28" height="4" rx="2" fill="#E9E9EA"/>
            <rect x="29" y="52" width="50" height="4" rx="2" fill="#E9E9EA"/>
            <rect x="29" y="78" width="50" height="4" rx="2" fill="#E9E9EA"/>
            <rect x="29" y="61" width="28" height="4" rx="2" fill="#E9E9EA"/>
            <rect x="29" y="87" width="28" height="4" rx="2" fill="#E9E9EA"/>
            <rect width="45" height="6" rx="3" fill="#D5D4D7"/>
        </svg>        
    </div>
    
    <div class="search-evidence__content">
        
        <p class="search-evidence__title"> 
            {{ 'home.evidenceTitle' | content }} 
            <svg class="search-evidence__title-tooltip-svg" [tp]="tooltipTpl" [tpInteractive]="true">
                <use xlink:href="../../../assets/img/sprite.svg#icon-Info-1"></use>
            </svg>

            <ng-template #tooltipTpl>
                <p [innerHTML]="('home.evidenceTooltip' | content) | safe"></p>
            </ng-template>
        </p>
        
        <p class="search-evidence__subtitle"> {{ 'home.evidenceSubtitle' | content }} </p>
    
        <mine-input [formControl]="ticketControl"
                    class="search-evidence__input"
                    id="search" 
                    [placeholder]="'home.evidencePlaceholder' | content"
                    [error]="ticketControl.errors?.error ?? ''">
        </mine-input>
    
        <mine-button-primary class="search-evidence__search-btn" 
                                [disabled]="ticketControl.invalid" 
                                [text]="'home.evidenceBtn' | content"
                                (onClick)="searchClicked()"></mine-button-primary>
    </div>

</div>

<div *ngIf="showResultsSection" @fadeInOnEnter class="search-evidence__results" [class.search-evidence__results--top]="ticketEvidence && loaderFinished">

    <ng-template #loaderContainer></ng-template> 

    <ng-container *ngIf="ticketEvidence && loaderFinished">

        <div @fadeInOnEnter *ngIf="!ticketEvidence.error; else emptyState" class="search-evidence__ticket-snippet">
            <ticket-info [request]="ticketEvidence.ownedItem"></ticket-info>

            <ticket-evidence class="search-evidence__ticket-evidence grid" [request]="ticketEvidence.ownedItem" [showInsideStep]="true" [showVerified]="true"></ticket-evidence>
        </div>

        <ng-template #emptyState>
            <div @fadeInOnEnter class="search-evidence__empty-state">
                
                <img [src]="assetsUrl + '/Portal/requests-empty-v2.jpg'" class="search-evidence__empty-state-img">
    
                <p class="search-evidence__empty-state-text">{{ 'home.evidenceNoResults' | content }}</p>
    
                <p class="search-evidence__empty-state-chat">
                    {{ 'home.evidenceNoResultsHelp' | content }}
                    <mine-button-secondary class="search-evidence__empty-state-chat-btn" [label]="'common.talkToUs' | content | sentenceCase" (onClick)="openIntercom()">
                    <span>{{'common.talkToUs' | content | sentenceCase}}</span>
                </mine-button-secondary>
                </p>
            </div>
        </ng-template>

    </ng-container>
</div>

<div @fadeInOnEnter *ngIf="ticketEvidence && loaderFinished && !ticketEvidence.error" class="search-evidence__results-bottom">
    <mine-button-primary class="search-evidence__bottom-btn" 
                        [text]="'home.fulfillBtn' | content"
                        (onClick)="navigateToRequest()"></mine-button-primary>
</div>