import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, HostBinding } from '@angular/core';
import { last, map, scan, switchMap, takeWhile, tap, MonoTypeOperatorFunction, timer } from 'rxjs';
import { Router } from '@angular/router';

import { Animations } from 'src/app/animations/animations';
import { ProfileService } from 'src/app/profile/state/profile.service';
import { MineSnackbarType } from '../../shared/mine-snackbar/mine-snackbar-type';
import { MineSnackbarService } from '../../shared/mine-snackbar/mine-snackbar.service';
import { LoggerService } from 'src/app/logger/logger.service';
import { environment } from 'src/environments/environment';
import { ProfileQuery } from 'src/app/profile/state/profile.query';
import { ProfilePlanDetails } from 'src/app/api/models/profile/profile-plan-details';
import { ContentPipe } from 'src/app/services/content/content.pipe';
import { RoutesManager } from 'src/app/shared/models/routes.interfaces';

@Component({
  selector: 'post-checkout',
  templateUrl: './post-checkout.component.html',
  styleUrls: ['./post-checkout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [Animations.postCheckout]
})
export class PostCheckoutComponent implements OnInit {

  @HostBinding('@postCheckout') 
  get postCheckout() { 
    return true;
  }

  private readonly loggerName: string = 'PostCheckoutComponent';

  private readonly pollingInterval = environment.postCheckout.pollingInterval;
  private readonly pollingAttemptsThreshold = environment.postCheckout.pollingAttemptsThreshold; 

  isCheckoutCompleted = false;

  constructor(
    private router: Router,
    private logger: LoggerService,
    private cdref: ChangeDetectorRef,
    private profileQuery: ProfileQuery,
    private profileService: ProfileService,
    private snackbarService: MineSnackbarService,
    private contentPipe: ContentPipe,
  ) { }

	ngOnInit(): void {
    const currentPlanDetails = this.profileQuery.getValue().planDetails; 
    const companyPlanPoll$ = this.profileService.getUserProfile().pipe(
      map(res => res.planDetails),
      this.pollWhile(
        this.pollingInterval,
        this.pollingAttemptsThreshold,
        (planDetails: ProfilePlanDetails) => !this.isPlanModified(currentPlanDetails, planDetails),
        true
      )
    );

    companyPlanPoll$.subscribe(planDetails => {
      this.pollingCompleted(planDetails);
    }, error => {
      this.pollingFailed(error);
    });
	}

  // check if the plan was modified recently (in other words - if the transaction has succeeded)
  private isPlanModified(currentPlan: ProfilePlanDetails, fetchedPlan: ProfilePlanDetails): boolean {
    // option 1: if plan was never changed it won't contain lastModifiedAt field
    return (!currentPlan.lastModifiedAt && !!fetchedPlan.lastModifiedAt) 
      // option 2: compare the quried plan last modification time with the API fetched plan and check if there are diffs
      || (currentPlan.lastModifiedAt !== fetchedPlan.lastModifiedAt)
      // option 3: check if the fetched plan was updated recently
      || (this.isRecentlyChanged(fetchedPlan));
  }

  private pollingCompleted(planDetails: ProfilePlanDetails): void {
    this.logger.debug(this.loggerName, `Plan has changed successfully, new plan is: ${planDetails?.plan}`);
    
    setTimeout(() => {
      this.isCheckoutCompleted = true;
      this.cdref.detectChanges();

      setTimeout(() => {
        window.location.href = environment.postCheckout.successfulUrl;
      }, environment.postCheckout.timeToWaitBeforeRedirect);

    }, environment.postCheckout.timeToWaitBeforeRedirect);
  }

  private pollingFailed(error): void {
    this.logger.error(this.loggerName, `Failed to change plan: ${error}`);
    this.router.navigate([RoutesManager.plans]).then(() => {
      this.snackbarService.showTimed(MineSnackbarType.Error, this.contentPipe.transform('checkout.failureText'), true);
    });
  }

  private pollWhile<T>(pollInterval: number, maxAttempts = Infinity, isPollingActive: (res: T) => boolean, emitOnlyLast = false): MonoTypeOperatorFunction<T> {
    return source$ => {
      const poll$ = timer(0, pollInterval).pipe(
        // increase the attempts counter by 1
        scan(attempts => ++attempts, 0),
        // check that the attempts counter is below threshold
        tap(this.attemptsGuardFactory(maxAttempts)),
        // take the inner observable (this) merge it with the outer (the API call) and keep running the inner observable (this)
        switchMap(() => source$),
        // invoke this inner observable while isPollingActive is true, the second param is also true cause we want the last emittion to be returned
        takeWhile(isPollingActive, true)
      );
  
      // determine if to emit value every interval tick or only on the last one 
      return emitOnlyLast ? poll$.pipe(last()) : poll$;
    };
  }

  private attemptsGuardFactory(maxAttempts: number) {
    return (attemptsCount: number) => {
      if (attemptsCount > maxAttempts) {
        throw new Error("Exceeded maxAttempts");
      }
    };
  }
  
  private isRecentlyChanged(planDetails: ProfilePlanDetails): boolean {
    if (!!planDetails.lastModifiedAt) {
      const lastModificationDate = new Date(planDetails.lastModifiedAt);
      return new Date().getTime() - lastModificationDate.getTime() <= environment.postCheckout.recentModificationTimeFrame;
    }

    return false; 
  }
}
