import { Component, OnInit, ChangeDetectionStrategy, Signal, Injector } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { ButtonColorEnum } from '@mineos/mine-ui';
import { Router } from '@angular/router';
import { Observable, map } from 'rxjs';

import { environment } from 'src/environments/environment';
import { AnalyticsEvent } from 'src/app/analytics/analytics-event';
import { AnalyticEventsLabels } from 'src/app/analytics/analytics-events-labels';
import { AnalyticsService } from 'src/app/analytics/analytics.service';
import { EventActionType } from 'src/app/analytics/event-action.enum';
import { ConnectedToMineStatusEnum } from 'src/app/api/models/profile/connected-to-mine-status.enum';
import { ChatService } from 'src/app/chat/chat.service';
import { ProfileQuery } from 'src/app/profile/state/profile.query';
import { AuthorizationDialogComponent } from 'src/app/requests/requests-table/authorization-dialog/authorization-dialog.component';
import { DialogsManagerService } from 'src/app/services/dialogs-manager.service';
import { FeatureFlagQuery } from 'src/app/feature-flag/state/feature-flag.query';
import { Channel } from './channel.interface';
import { ChannelService } from './channel.service';
import { ChannelId } from './connect-channel-id.enum';
import { Animations } from 'src/app/animations/animations';
import { FeatureFlags } from 'src/app/api/models/profile/profile-feature-flags.enum';

@Component({
	selector: 'connection-channels',
	templateUrl: './connection-channels.component.html',
	styleUrls: ['./connection-channels.component.scss'],
	animations: [Animations.fadeInOnEnter],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConnectionChannelsComponent implements OnInit {
	readonly svgSprite = '../../../assets/img/sprite.svg#';
	readonly buttonColorEnum = ButtonColorEnum;
	 
	assetsUrl = environment.assets.url;
	channels$: Observable<Channel[]>;
	channelIds = ChannelId;

	private hideConnectToMine = false;
	connectToMineStatus: Signal<ConnectedToMineStatusEnum>;
	connectedToMineStatusEnum = ConnectedToMineStatusEnum;

	constructor(
		private router: Router,
		private profileQuery: ProfileQuery,
		private analytics: AnalyticsService,
		private featureFlagQuery: FeatureFlagQuery,
		private dialogService: DialogsManagerService,
		private channelService: ChannelService,
		private chatService: ChatService,
		private injector: Injector,
	) {}

	ngOnInit(): void {
		this.initFeatureFlags();
		this.initConnectToMineStatus();
		this.initChannels();
	}

	private initFeatureFlags(): void {
		this.hideConnectToMine = this.featureFlagQuery.getFlag(FeatureFlags.HideConnectToMine);
	}

	private initConnectToMineStatus(): void {
		if (!this.hideConnectToMine) {
			this.connectToMineStatus = toSignal(this.profileQuery.selectConnectToMineStatus(), { injector: this.injector });
		}
	}

	private initChannels(): void {
		this.channels$ = this.channelService.getChannels().pipe(
			map(channels => channels.filter(channel => this.hideConnectToMine ? channel.id !== ChannelId.Mine : true))
		);
	}

	onRowButtonClick(channel: Channel) {
		if (channel.id === ChannelId.Mine) {
			this.handleConnectToMine(channel);
			return;
		}
		if (channel.id === ChannelId.TollFree) {
			this.chatService.show();
			return;
		}
		this.router.navigate([channel.routerLink]);
	}

	trackByFn(index: number, item: Channel) {
		return item.id;
	}

	private handleConnectToMine(channel: Channel) {
		if (channel.connected) {
			return;
		}
		const status = this.profileQuery.getValue().connectedToMineStatus;
		if (status === ConnectedToMineStatusEnum.NotStarted) {
			this.sendAnalyticsRequest();
			this.dialogService.openDialogCommon(AuthorizationDialogComponent, null, '514px', false);
		}
	}

	private sendAnalyticsRequest(){
		this.analytics.track({
			eventCategory: AnalyticEventsLabels.CONNECT_TO_MINE_header_btn,
			eventAction: EventActionType.Click,
		} as AnalyticsEvent);
	}
}
