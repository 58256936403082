<h2 class="mine-title-xs channels__title"> 
    {{ "connect-channels.title" | content }}
</h2>

<h4 class="channels__subtitle"> 
    {{ "connect-channels.subtitle" | content }}
</h4>

<div @fadeInOnEnter *ngIf="channels$ | async as channels" 
    class="channels__container"> 

    <div *ngFor="let c of channels; trackBy: trackByFn" 
        class="channels__row"
        [class.channels__row--narrow]="!c.connected && c.id !== channelIds.Mine || !c.connected && c.id === channelIds.Mine && connectToMineStatus() !== connectedToMineStatusEnum.EmailSent">
        
        <span class="channels__btn-icon-group">
            <svg class="channels__icon">
                <use [attr.xlink:href]="c.iconName"></use>
            </svg>

            {{ c.contentKey | content}}

            <svg *ngIf="c.tooltip"
                [tp]="(c.tooltip | content)"
                class="channels__tooltip-icon">
                <use xlink:href="../../../assets/img/sprite.svg#icon-Info-1"></use>
            </svg>
        </span>

        <span *ngIf="c.connected" class="channels__connected-lbl" [class.channels-label--span]="c.id === channelIds.Mine && connectToMineStatus() === connectedToMineStatusEnum.ConnectedToMine">
            {{'connect-channels.connectedLabel'|content}}
        </span>

        <p *ngIf="c.id === channelIds.Mine && connectToMineStatus() === connectedToMineStatusEnum.EmailSent" class="channels__waiting-verification">
            <svg class="channels__waiting-verification-svg">
                <use xlink:href="../../../assets/img/sprite.svg#dashed-circle"></use>
            </svg>
            {{'connect-channels.waitingVerification'|content}}
            <mine-info-icon [heliText]="'connect-channels.waitingVerificationTooltip'|content"></mine-info-icon>
        </p>
        
        <button *ngIf="(c.id !== channelIds.Mine) || (c.id === channelIds.Mine && connectToMineStatus() !== connectedToMineStatusEnum.ConnectedToMine)" mine-button class="channels__setup-btn"
            [color]="buttonColorEnum.Secondary"
            [disabled]="c.id === channelIds.Mine && connectToMineStatus() !== connectedToMineStatusEnum.NotStarted"
            (click)="onRowButtonClick(c)">
            {{ 'connect-channels.setupBtn' | content }}
        </button>

    </div>

</div>