<mine-x-button (onClick)="closeDialog()"></mine-x-button>

<ng-container  *ngIf="dpo$ | async as companyDpo; else loading">

	<ng-container  *ngIf="!!companyDpo.dpo; else missingDpo">
	
		<ng-container *ngIf="!mailSent; else sent">

			<p class="auth__title mine-title-m">{{"authorization-dialog.title1" | content}}</p>

			<p class="auth__text">{{"authorization-dialog.text1" | content}} 
				<span class="auth__text--bold"> {{companyDpo.dpo}}</span>?</p>

			<mine-button-primary class="auth__primay-btn" 
				[text]="'authorization-dialog.confirmBtn'| content" 
				[spinner]="confirmSpinner"
				(onClick)="sendMail()"
			>
			</mine-button-primary>

			<p class="auth__subtext auth__subtext--light auth__info">{{"authorization-dialog.subtextPrivacy" | content}}</p>

			<p class="auth__help">
				<span class="auth__subtext auth__subtext--light">{{"authorization-dialog.needHelp" | content}}&nbsp;</span>
				<mine-button-secondary
					[label]="'common.talkToUs '| content"
					(onClick)="showChat()"
					class="auth__help-btn"
				>
					<span>{{"common.talkToUs" | content}}</span>
				</mine-button-secondary>
			</p>

		</ng-container>

		<ng-template #sent>
			<div @fadeInOut>
				<img srcset="{{assetsUrl}}/Portal/flying-envelope.jpg 1x, {{assetsUrl}}/Portal/flying-envelope@2x.jpg 2x" alt="Mine portal sent verification" class="auth__img">
				<p class="auth__title mine-title-m">{{"authorization-dialog.title2" | content}}</p>
				<p class="auth__text">{{"authorization-dialog.emailedTo" | content}}</p>
				<p class="auth__text auth__text--bold">{{companyDpo.dpo}}</p>
				<p class="auth__help auth__resend">
					<span class="auth__subtext">{{"authorization-dialog.didntReciveEmail" | content}}&nbsp;</span>
					<mine-button-secondary
						[label]="'authorization-dialog.resendBtn '| content"
						(onClick)="sendMail(true)"
						class="auth__help-btn"
					> 
						<span>{{"authorization-dialog.resendBtn" | content}}</span>
					</mine-button-secondary>
				</p>
			</div>

		</ng-template>

	</ng-container>

</ng-container>


<ng-template #loading>

    <mine-spinner [width]="70" [height]="70" class="conversation__center"></mine-spinner>

</ng-template>

<ng-template #missingDpo>

 	<img  class="auth__error-img" ngSrc="{{assetsUrl}}/Portal/error-message.jpg" width="93" height="106" alt="unlike error image">
	
    <h2 class="auth__error-title auth__margin-top">{{'onboarding.errorMissingDpoTitle' | content}}</h2>

    <h4 class="auth__text">
      {{'onboarding.errorMissingDpoSubTitle' | content}}
    </h4>

    <h4 class="auth__text auth__margin-top">{{'onboarding.errorText' | content}}</h4>

    <mine-button-secondary class="auth__link" 
      [label]="'onboarding.errorContactSupportLink' | content" (onClick)="showChat()">
  
      <span>{{'onboarding.errorContactSupportLink' | content}}</span>
  
    </mine-button-secondary>

</ng-template>
