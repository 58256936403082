import { NgModule } from '@angular/core';
import { popperVariation, provideTippyConfig, TippyConfig, TippyDirective, tooltipVariation } from '@ngneat/helipopper';

@NgModule({
  imports: [
    TippyDirective,
  ],
  exports: [
    TippyDirective
  ],
  providers: [
    provideTippyConfig({
        defaultVariation: 'tooltip',
        variations: getDefaultConfiguration()
    })
  ]
})
export class TippyModule { }

export function getDefaultConfiguration() {
    const tpProps: TippyConfig = {
        ...this,
        arrow: true,
        animation: false,
        allowHTML: true,
        offset: [0, 17],
        maxWidth: 287,
    };
	return {
        popper: {
            ...popperVariation,
            ...tpProps
        },
        tooltip: {
            ...tooltipVariation,
            ...tpProps
        },
    };
}