import { ChangeDetectionStrategy, Component, DestroyRef, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { DialogRef } from '@ngneat/dialog';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import * as dayjs from 'dayjs';
import { EMPTY, Observable, catchError, first, map, startWith, tap } from 'rxjs';
import { LoggerService } from 'src/app/logger/logger.service';
import { DueDateData } from 'src/app/requests/models/requests.interface';
import { RequestsService } from 'src/app/requests/state/requests.service';
import { ContentPipe } from 'src/app/services/content/content.pipe';
import { KnowledgeBaseService } from 'src/app/services/knowledge-base.service';
import { BoxBgColor } from 'src/app/shared/mine-info-box/mine-info-box.enum';
import { MineSnackbarType } from 'src/app/shared/mine-snackbar/mine-snackbar-type';
import { MineSnackbarService } from 'src/app/shared/mine-snackbar/mine-snackbar.service';
import { CommonModule } from '@angular/common';
import { MineRadioButtonComponent } from 'src/app/shared/mine-radio-button/mine-radio-button.component';
import { MineDateInputComponent } from 'src/app/shared/mine-date-input/mine-date-input.component';
import { MineInfoBoxComponent } from 'src/app/shared/mine-info-box/mine-info-box.component';
import { MineButtonTertiaryComponent } from 'src/app/shared/mine-button-tertiary/mine-button-tertiary.component';
import { MineButtonPrimaryComponent } from 'src/app/shared/mine-button-primary/mine-button-primary.component';
import { ContentPipeModule } from 'src/app/services/content/content-pipe.module';
import { MineButtonSecondaryComponent } from 'src/app/shared/mine-button-secondary/mine-button-secondary.component';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    MineRadioButtonComponent,
    MineDateInputComponent,
    MineInfoBoxComponent,
    MineButtonTertiaryComponent,
    MineButtonPrimaryComponent,
    MineButtonSecondaryComponent,
    ContentPipeModule,
    ReactiveFormsModule,
  ],
  selector: 'edit-due-date-dialog',
  templateUrl: './edit-due-date-dialog.component.html',
  styleUrls: ['./edit-due-date-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditDueDateDialogComponent implements OnInit {  
  private readonly loggerName = 'EditDueDateDialogComponent';

  readonly InfoBoxBgColor = BoxBgColor.Grey;

  readonly extendOptions =[30, 60, 1]

  dueDateCtrl: FormControl<number>;
  otherDueDateCtrl: FormControl<string>;

  currDueDate: string;

  possibleDueDates: string[] = [];
  
  otherOption: string;
	
  formGroup: FormGroup;

  dueDateData: DueDateData;

  constructor(
    private dialogRef: DialogRef,
    private destroyRef: DestroyRef,
    private snackbarService: MineSnackbarService,
    private contentPipe: ContentPipe,
    private loggerService: LoggerService,
    private requestsService: RequestsService,
    private knowledgeBaseService: KnowledgeBaseService
  ) {}
  
  ngOnInit(): void {
    this.currDueDate = this.dialogRef.data.dueDate;
    this.initRadioButtons();
  }

  private initRadioButtons(): void {
    const firstExtendOption = dayjs(this.currDueDate).add(this.extendOptions[0], 'day').toISOString();
    const secondExtendOption = dayjs(this.currDueDate).add(this.extendOptions[1], 'day').toISOString();
    const thirdExtendOption = dayjs(new Date()).add(this.extendOptions[2], 'day').toISOString();
    this.possibleDueDates = [firstExtendOption, secondExtendOption, thirdExtendOption];

    this.formGroup = new FormGroup({
			dueDateCtrl: new FormControl(0),
			otherDueDateCtrl: new FormControl(this.possibleDueDates[2]),
		});

    this.formGroup.get('otherDueDateCtrl').valueChanges.pipe(
      startWith(this.possibleDueDates[2]),
      tap(res => this.possibleDueDates[2] = res),
      map(res => this.requestsService.getDueDateData(res)),
      tap(res => this.dueDateData = res),
      takeUntilDestroyed(this.destroyRef)
    ).subscribe();
  }

  cancel(): void {
    this.dialogRef.close();
  }

  save(): void {
    const date = this.possibleDueDates[this.formGroup.get('dueDateCtrl').value];
    const newDate =  this.transformDateFormat(date)

    this.requestsService.updateTicketDetails(this.dialogRef.data.id, { dueDate: newDate}).pipe(
        first(),
        tap(() => this.snackbarService.showTimed(MineSnackbarType.Confirmation, this.contentPipe.transform('ticket.editRequestSuccess'))),
        tap(() => this.dialogRef.close(newDate)),
        catchError(() => this.updateTicketDetailsFailed()),
      ).subscribe();
  }

	private transformDateFormat(date: string): string {
		const originalDate = new Date(date);
    const year = originalDate.getFullYear();
    const month = String(originalDate.getMonth() + 1).padStart(2, '0');
    const day = String(originalDate.getDate()).padStart(2, '0');
    
    // Create the new formatted date string
    return `${year}-${month}-${day}`;
	}

  private updateTicketDetailsFailed(): Observable<never> {
    this.snackbarService.showTimed(MineSnackbarType.Error, this.contentPipe.transform('ticket.editRequestFailed'));
    this.loggerService.error(this.loggerName, `Failed to update due date for ticket: ${this.dialogRef.data.id}`);
    return EMPTY;
  }

  learnMore(): void {
				this.knowledgeBaseService.navigate(this.contentPipe.transform('knowledge-base-urls.dueDate'));
  }
}
