import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';

import { produce } from 'immer';
import { CustomFieldV2 } from 'src/app/api/models/company-settings/custom-fields.interface';
import { HtmlDecodePipe } from 'src/app/shared/pipes/html-decode.pipe';

export interface CustomFieldsState extends EntityState<CustomFieldV2, string> { }

@Injectable({
	providedIn: 'root'
})
@StoreConfig({
	name: 'customFields',
	idKey: 'id',
	producerFn: produce
})
export class CustomFieldsStore extends EntityStore<CustomFieldsState> {

  constructor(
	private htmlDecodePipe: HtmlDecodePipe
  ) {
    super();
  }

  akitaPreAddEntity(customField: CustomFieldV2): CustomFieldV2 {
	return this.getDecodedCustomValue(customField);
  }

  akitaPreUpdateEntity(oldCustomField: CustomFieldV2, newCustomField: CustomFieldV2): CustomFieldV2 {
	return this.getDecodedCustomValue(newCustomField);
  }

  private getDecodedCustomValue(customField: CustomFieldV2): CustomFieldV2 {
	return {
		...customField,
		name: this.htmlDecodePipe.transform(customField.name),
	}
  }
}
