import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Data, CanActivateFn, Router } from '@angular/router';
import { inject, Injector } from '@angular/core';
import { Observable, map } from 'rxjs';

import { RoutesManager } from '../shared/models/routes.interfaces';
import { FeatureFlags } from '../api/models/profile/profile-feature-flags.enum';
import { FeatureFlagValueStrategy } from './feature-flag-value-strategy';
import { FeatureFlagGuardStrategy } from './feature-flag-guard-strategy';

const RopaAssessmentsMigrationGuardFn = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> => {
  const router = inject(Router);
  const strategy = getStrategy(next.data);
  const featureFlagName = FeatureFlags.DevAssessmentsRopaMigration;
  const canActivate$ = strategy.canActivate(state, next, featureFlagName);

  return canActivate$.pipe(
    map(isEnabled => {
      // Redirect to ai_assessments if the feature flag is enabled
      if (!isEnabled) {
        router.navigate([RoutesManager.ai_assessments]);
        return false; // Block access to the current route
      }
      return true;
    })
  );
}

const getStrategy = (data: Data): FeatureFlagGuardStrategy => {
  const injector = inject(Injector);
  const defaultStrategy = inject(FeatureFlagValueStrategy);

  if (data['featureFlagStrategy']) {
    return injector.get(data['featureFlagStrategy']);
  }

  return defaultStrategy;
}

export const RopaAssessmentsMigrationGuard: CanActivateFn = RopaAssessmentsMigrationGuardFn;