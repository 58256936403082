import { ChipColorName } from "./mine-chip.enum";

export interface ChipConfig {
  isRemovable: boolean;
  chipColor?: ChipColor;
  tooltip?: string;
  id?: string;
  isCustom?: boolean;
}

export interface ChipColor {
  backgroundColor: string;
  textColor: string;
}

export const MineChip: Map<ChipColorName, ChipColor> =
  new Map([
    [ChipColorName.Purple, {
      backgroundColor: 'var(--mine-purple-light-2)',
      textColor: 'var(--mine-purple-dark)'
    }],
    [ChipColorName.Blue, {
      backgroundColor: 'var(--mine-blue-2)',
      textColor: 'var(--mine-blue-3)'
    }],
    [ChipColorName.Grey, {
      backgroundColor: 'var(--mine-grey-light-2)',
      textColor: 'var(--mine-grey-3)'
    }],
    [ChipColorName.Yellow, {
      backgroundColor: 'var(--mine-light-yellow)',
      textColor: 'var(--mine-dark-yellow-3)'
    }],
    [ChipColorName.Red, {
      backgroundColor: 'var(--mine-red-light-4)',
      textColor: 'var(--mine-red-2)'
    }],
    [ChipColorName.Green, {
      backgroundColor: 'var(--mine-green-2)',
      textColor: 'var(--mine-green-3)'
    }],
    [ChipColorName.LightPurple, {
      backgroundColor: 'var(--mine-purple-light-6)',
      textColor: 'var(--mine-purple-dark)'
    }],
    [ChipColorName.LightPink, {
      backgroundColor: 'var(--mine-pink-light)',
      textColor: 'var(--mine-pink-dark)'
    }],
    [ChipColorName.VeryLightBlue, {
      backgroundColor: 'var(--mine-blue-very-light-2)',
      textColor: 'var(--mine-dark-blue-2)'
    }],
    [ChipColorName.Cyan, {
      backgroundColor: 'var(--mine-blue-very-light-3)',
      textColor: 'var(--mine-dark-blue-3)'
    }],
]);