import { Injectable } from '@angular/core';
import { HttpClientHelperService } from './http-client-helper.service';
import { PrivacyCenterConfiguration, PrivacyCenterRequestForm, RequestFormConfiguration } from './models/privacy-center/privacy-center.interface';
import { Observable } from 'rxjs';

@Injectable({
  	providedIn: 'root'
})
export class ApiClientPrivacyCenterService {

	private readonly controller = 'privacycenter';

	constructor(private httpClientHelper: HttpClientHelperService) { }

	getConfiguration(): Observable<PrivacyCenterConfiguration> {
		return this.httpClientHelper.invokeGetAuth<PrivacyCenterConfiguration>(`${this.controller}/Configuration`);
	}

	setConfiguration(data: PrivacyCenterRequestForm): Observable<PrivacyCenterRequestForm> {
		return this.httpClientHelper.invokePostAuth<PrivacyCenterRequestForm>(`${this.controller}/RequestFormConfig`, data);
	}
}
