import { FeedbackRequest, AgentFeedbackState, FeedbackAnswer, ReviewerTaskResponse, ReviewerTasksResponse, FeedbackResponse } from './models/feedback/feedback.interface';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClientHelperService } from './http-client-helper.service';
import { FeedbackStateEnum } from './models/feedback/feedback.enum';

@Injectable({
  	providedIn: 'root'
})
export class ApiClientFeedbackService {

	private readonly controller = 'feedback';

  	constructor(private httpClientHelper: HttpClientHelperService) { }

	getSystemFeedbacks (systemId: string): Observable<FeedbackResponse> {
		return this.httpClientHelper.invokeGetAuth<FeedbackResponse>(`${this.controller}/SystemFeedbacks/${systemId}`);
  	}

  	requestFeedback(body: FeedbackRequest): Observable<FeedbackResponse> {
		return this.httpClientHelper.invokePostAuth<FeedbackResponse>(`${this.controller}/Request`, body);
  	}

  	agentUpdateFeedbackState(feedbackId: string, state: FeedbackStateEnum): Observable<void> {
		const body = {
			newState: state
		} as AgentFeedbackState;

		return this.httpClientHelper.invokePutAuth<void>(`${this.controller}/UpdateState/${feedbackId}`, body);
  	}

  	reviewerRespondFeedback(answer: FeedbackAnswer, feedbackId: string): Observable<ReviewerTaskResponse> {
		return this.httpClientHelper.invokePostAuth<ReviewerTaskResponse>(`${this.controller}/Respond/${feedbackId}`, answer);
  	}

	getTasks(): Observable<ReviewerTasksResponse> {
		return this.httpClientHelper.invokeGetAuth<ReviewerTasksResponse>(`${this.controller}/ReviewerFeedbacks`);
  	}
}
