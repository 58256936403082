export enum IndicationTypeEnum {
    // fetching indications
    FetchingData = "FetchingData",
    FetchingComplete = "FetchingComplete",
    FetchingError = "FetchingError",
    MissingIdentifiers = "MissingIdentifiers",
    // deleting indications
    DeletingData = "DeletingData",
    DeletionError = "DeletionError",
    DeletionSuccess = "DeletionSuccess",
    // copy indications
    CopyData = "CopyData",
    CopySuccess = "CopySuccess",
    CopyError = "CopyError",
}

export enum IntegrationStateResponseEnum {
    Unknown = "Unknown",
    NotStarted = "NotStarted",
    Starting = "Starting",
    InProgress = "InProgress",
    Completed = "Completed",
    Failed = "Failed",
    NewData = "NewData",
    MissingIdentifiers = "MissingIdentifiers",
}