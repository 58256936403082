import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';

import { produce } from 'immer';
import { PiiDataTypeItem } from '../../models/pii-classifier.interface';


export interface PiiDataTypesState extends EntityState<PiiDataTypeItem> { }

@Injectable({ 
	providedIn: 'root' 
})
@StoreConfig({ 
	name: 'piiDataTypes',
	idKey: 'piiDataType',
	producerFn: produce
})
export class PiiDataTypesStore extends EntityStore<PiiDataTypesState> {
	
	constructor() {
		super();
	}
}