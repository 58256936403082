import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClientHelperService } from './http-client-helper.service';
import { ActiveSessionResponse, SessionInfoResponse } from './models/sessions/sessions.interface';

@Injectable({
  providedIn: 'root'
})
export class ApiClientSessionsService {
	private readonly controller = 'sessions';

	constructor(private httpClientHelper: HttpClientHelperService) { }

  	getSessionsInfo(): Observable<SessionInfoResponse> {
		  return this.httpClientHelper.invokeGetAuth<SessionInfoResponse>(`${this.controller}/Get`);
    }

    logout(): Observable<void> {
      return this.httpClientHelper.invokePostAuth<void>(`${this.controller}/Logout`);
    }

    isSessionActive(): Observable<ActiveSessionResponse> {
      return this.httpClientHelper.invokeGetAuth<ActiveSessionResponse>(`${this.controller}/IsActive`);
    }
}
