import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { TrashSystemsColumnKeys } from './trash-systems.enum';
import { TrashSystem } from 'src/app/api/models/systems/systems.interface';
import { ProfileQuery } from 'src/app/profile/state/profile.query';

@Injectable()
export class TrashSystemsTableCsvHelper {

    constructor(
        private datePipe: DatePipe,
        private profileQuery: ProfileQuery,
    ) {}

    getValueByKey(system: TrashSystem, key: TrashSystemsColumnKeys): string {
      const values: Record<TrashSystemsColumnKeys, string> = {
        name: system.name ?? '-',
        reason: system.removalReason ?? '-',
        updatedBy: this.getUpdatedBy(system),
        lastUpdate: system.removeDate ? this.datePipe.transform(system.removeDate, 'MMM dd, yyyy') : '-'
      };
      return values[key];
    }

    getUpdatedBy(system: TrashSystem): string {
        if (system.removedBy) {
            return this.profileQuery.getAgentById(system.removedBy)?.name ?? '-';
        }
        return '-';
    }
}