<button class="chatbot-toggler" 
    [class.chatbot-toggler--closed]="!showChatbot()" 
    (click)="onTogglerClicked()">

    <svg class="chatbot-toggler__icon chatbot-toggler__icon--logo">
        <use xlink:href="../../../assets/img/sprite.svg#icon-sparking"></use>
    </svg>

    <p class="chatbot-toggler__text">{{'chatbot.askCopilot' | content}}</p>

    <svg class="chatbot-toggler__icon chatbot-toggler__icon--close">
        <use xlink:href="../../../assets/img/sprite.svg#icon-x"></use>
    </svg>

    <ng-container *rxIf="unreadMessagesCounter() > 0">
        <mine-chip [@pimple]="unreadMessagesCounter()" 
            class="chatbot-toggler__unread-badge"
            [backgroundColor]="typeBadge.backgroundColor" 
            [color]="typeBadge.textColor">
            {{unreadMessagesCounter()}}
        </mine-chip>
    </ng-container>
</button>

<div class="chatbot">
    <header class="chatbot__header">
        <svg class="chatbot__header-icon">
            <use xlink:href="../../../assets/img/sprite.svg#icon-sparking"></use>
        </svg>

        <h2 class="chatbot__header-title mine-title-xs">{{'chatbot.title' | content}}</h2>
    </header>

    <ul #chatbox class="chatbot__chatbox">
        <ng-container *rxFor="let chatMessage of chatMessages(); let i=index">
            <ng-container [ngSwitch]="chatMessage.type">

                <li *ngSwitchCase="MessageTypeEnum.USER_TEXT" @fadeInOnEnter #message
                    class="chatbot__chatbox-chat chatbot__chatbox-chat--outgoing">

                    <p class="chatbot__chatbox-chat-message" 
                        [innerHTML]="chatMessage.content">
                    </p>
                </li>

                <li *ngSwitchCase="MessageTypeEnum.ASSISTANT_RESPONSE" @fadeInOnEnter #message
                    class="chatbot__chatbox-chat chatbot__chatbox-chat--incoming">

                    <p class="chatbot__chatbox-chat-message" [id]="'copilot-message-'+i"></p>
                </li>

            </ng-container>
        </ng-container>

        <ng-container *rxIf="copilotTypingState()">
            <ng-container *ngTemplateOutlet="copilotTypingTpl;"></ng-container>
        </ng-container>
    </ul>

    <div class="chatbot__chat-input">
        <textarea #chatInput autosize 
            class="chatbot__chatbox-textarea"
            autocomplete="off"
            spellcheck="false"
            [minRows]="1" 
            [maxRows]="7" 
            [id]="'message-input'"
            [formControl]="messageCtrl" 
            [placeholder]="'chatbot.inputPlaceholder' | content" 
            (keydown)="onKeydown($event)">
        </textarea>

        <svg class="chatbot__chat-input-send" 
            [class.chatbot__chat-input-send--valid]="messageCtrl.valid" 
            (click)="sendMessage()">
            <use xlink:href="../../../assets/img/sprite.svg#icon-send"></use>
        </svg>
    </div>
</div>

<ng-template #copilotTypingTpl>

    <li @fadeInOnEnter #message 
        class="chatbot__chatbox-chat chatbot__chatbox-chat--incoming">

        <p class="chatbot__chatbox-chat-message chatbot__chatbox-chat-message--dots">
            <span *rxFor="let i of [].constructor(3); let index = index" 
                class="chatbot__jumping-dot chatbot__jumping-dot--{{index+1}}">
            </span>
        </p>
    </li>

</ng-template>