import { FeatureFlags } from "../api/models/profile/profile-feature-flags.enum";
import { RoutesManager } from "../shared/models/routes.interfaces";

export class FeatureFlagGuardHelper {
  
  // for generic URLs that we couldn't add the feature flag in the route - this function got URL and returning the FF name
  static getFeatureFlagByUrl(url: string): FeatureFlags | string {
    switch(url) {
      case `/${RoutesManager.automations}/${RoutesManager.automated_responses}/${RoutesManager.automations_reject_response}`:
      case `/${RoutesManager.automations}/${RoutesManager.automated_responses}/${RoutesManager.automations_copy_reject_response}`:
			case `/${RoutesManager.automations}/${RoutesManager.automated_responses}/${RoutesManager.automations_edit_reject_response}`:
			case `/${RoutesManager.automations}/${RoutesManager.automated_responses}/${RoutesManager.automations_notmail_reject_response}`:
			case `/${RoutesManager.automations}/${RoutesManager.automated_responses}/${RoutesManager.automations_notsell_reject_response}`:
        return FeatureFlags.RejectedMailAutomation;
      case `/${RoutesManager.automations}/${RoutesManager.automated_responses}/${RoutesManager.automations_cancel_response}`:
      case `/${RoutesManager.automations}/${RoutesManager.automated_responses}/${RoutesManager.automations_copy_cancel_response}`:
			case `/${RoutesManager.automations}/${RoutesManager.automated_responses}/${RoutesManager.automations_edit_cancel_response}`:
			case `/${RoutesManager.automations}/${RoutesManager.automated_responses}/${RoutesManager.automations_notmail_cancel_response}`:
			case `/${RoutesManager.automations}/${RoutesManager.automated_responses}/${RoutesManager.automations_notsell_cancel_response}`:
        return FeatureFlags.CanceledMailAutomation;
      case `/${RoutesManager.automations}/${RoutesManager.automated_responses}/${RoutesManager.automations_complete_response}`:
      case `/${RoutesManager.automations}/${RoutesManager.automated_responses}/${RoutesManager.automations_copy_complete_response}`:
			case `/${RoutesManager.automations}/${RoutesManager.automated_responses}/${RoutesManager.automations_notmail_complete_response}`:
			case `/${RoutesManager.automations}/${RoutesManager.automated_responses}/${RoutesManager.automations_edit_complete_response}`:
			case `/${RoutesManager.automations}/${RoutesManager.automated_responses}/${RoutesManager.automations_notsell_complete_response}`:
        return FeatureFlags.CompletedMailAutomation;
      case `/${RoutesManager.automations}/${RoutesManager.automated_responses}/${RoutesManager.automations_acknowledge_response}`:
      case `/${RoutesManager.automations}/${RoutesManager.automated_responses}/${RoutesManager.automations_copy_acknowledge_response}`:
        return FeatureFlags.AckMailAutomation;
      case `/${RoutesManager.automations}/${RoutesManager.automations_requests}/${RoutesManager.automations_complete_status}`:
        return FeatureFlags.AutoCompleteRules;
      case `/${RoutesManager.automations}/${RoutesManager.automated_responses}/${RoutesManager.automations_copy_attachment_response}`:
        return FeatureFlags.CopyAttachmentMailAutomation;
      case `/${RoutesManager.data_sources}/${RoutesManager.integrations_webhook_events}`:
        return FeatureFlags.WebHookEvents;
      default: {
        //feature flags that controlled from cms
        if (url.includes(`/${RoutesManager.data_sources}/`)) {
          if (url.includes(`/${RoutesManager.oauth_callback}`)) {
            return url.split('state=').pop();
          }
          return url.split('?')[0].split('/').pop();
        }
        return null;
      };
    }
  }
}