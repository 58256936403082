import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';

import { ProcessingActivity, ProcessingActivitySystemInfo } from 'src/app/api/models/processing-activities/processing-activities.interface';
import { SystemsIconsQuery } from 'src/app/systems/state/systems-icons.query';
import { HtmlDecodePipe } from 'src/app/shared/pipes/html-decode.pipe';

import { produce } from 'immer';

export interface ProcessingActivitiesState extends EntityState<ProcessingActivity, string> { }

@Injectable({
	providedIn: 'root'
})
@StoreConfig({
	name: 'processingActivities',
	idKey: 'path',
	producerFn: produce
})
export class ProcessingActivitiesStore extends EntityStore<ProcessingActivitiesState> {
	
	constructor(
		private htmlDecodePipe: HtmlDecodePipe,
		private systemsIconsQuery: SystemsIconsQuery,
	) {
		super();
	}

	akitaPreAddEntity(activity: ProcessingActivity): ProcessingActivity {
		return this.getDecodedActivity(activity);
	}

	akitaPreUpdateEntity(oldActivity: ProcessingActivity, newActivity: ProcessingActivity): ProcessingActivity {
		return this.getDecodedActivity(newActivity);
	}

	private getDecodedActivity(activity: ProcessingActivity): ProcessingActivity {	
		return {
			...activity,
			customActivityFields: activity?.customActivityFields?.map(field => ({...field, value: this.htmlDecodePipe.transform(field.value)})),
			dataRetentionPolicy: this.htmlDecodePipe.transform(activity.dataRetentionPolicy),
			description: this.htmlDecodePipe.transform(activity?.description),
			dpiaInfo: {
				...activity?.dpiaInfo,
				comment: this.htmlDecodePipe.transform(activity?.dpiaInfo?.comment),
				customFields: activity?.dpiaInfo?.customFields?.map(field => ({...field, value: this.htmlDecodePipe.transform(field.value)})),
				items: activity?.dpiaInfo?.items?.map(item => ({...item, comment: this.htmlDecodePipe.transform(item.comment), name: this.htmlDecodePipe.transform(item.name)})),
			},
			name: this.htmlDecodePipe.transform(activity.name),
			partiesWithAccessDescription: this.htmlDecodePipe.transform(activity.partiesWithAccessDescription),
			path: this.htmlDecodePipe.transform(activity.path),
			systems: this.getActivitySystemsWithIcons(activity),
		};
	}

	private getActivitySystemsWithIcons(activity: ProcessingActivity): { [key: string]: ProcessingActivitySystemInfo; } {
		const activitySystems = {} as { [key: string]: ProcessingActivitySystemInfo; };
		Object.values(activity.systems).forEach(system => {
			activitySystems[system.id] = {
				...system,
				iconPath: this.systemsIconsQuery.getIcon(system.id)
			};
		});
		return activitySystems;
	}
}