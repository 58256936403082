import { Injectable } from '@angular/core';
import { Observable, switchMap } from 'rxjs';

import { MenuItemId } from './menu-item.enum';
import { TasksService } from 'src/app/tasks/services/tasks.service';
import { UnverifiedSystemsQuery } from './../../radar/state/unverified-systems.query';

@Injectable({
  providedIn: 'root'
})
export class AppSidenavPimpleService {

  constructor(
    private tasksService: TasksService,
    private unverifiedSystemsQuery: UnverifiedSystemsQuery,
  ) { }

  getPimpleData(menuItemId: string): Observable<number> {
    switch (menuItemId) {
      case MenuItemId.Radar:
        return this.unverifiedSystemsQuery.selectCount();
      case MenuItemId.Tasks:
        return this.tasksService.getTasks().pipe(
          switchMap(() => this.tasksService.openedTasksCount$)
        );
    }
  }
}
