<ng-container *ngIf="isCheckoutCompleted; else checkoutInProgress">

    <mine-check-mark class="post-checkout__check-mark u-display-block"></mine-check-mark>

    <h1 @postCheckoutItem class="mine-title-m post-checkout__title">{{ 'checkout.successText' | content}}</h1>

    <p @postCheckoutItem class="post-checkout__sub-title">{{ 'checkout.successDescription' | content }}</p>

</ng-container>


<ng-template #checkoutInProgress>

    <mine-spinner [width]="90" [height]="90" class="post-checkout__spinner"></mine-spinner>

    <h1 @postCheckoutItem class="mine-title-m post-checkout__title">{{ 'checkout.title' | content }}</h1>
    
    <p @postCheckoutItem class="post-checkout__sub-title">{{ 'checkout.subTitle' | content }}</p>

</ng-template>