export enum AgentDepartments {
    Support = 'Support', 
    Legal = 'Legal',
    RnD = 'R&D', 
    BizDev = 'Business Development', 
    Accounting = 'Accounting', 
    IT = 'IT', 
    Privacy = 'Privacy', 
    Management = 'Management', 
    Product = 'Product', 
    Marketing = 'Marketing', 
    HR = 'HR',
    Security = 'Security',
    Sales = 'Sales',
    ServiceAccount = 'Service Account',
    Unknown = 'Unknown'
}

