import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';

import { AuthService } from './auth.service';
import { SafePipe } from '../shared/pipes/safe.pipe';
import { AuthRoutingModule } from './auth-routing.module';
import { LoginComponent } from './/login/login.component';
import { SignupComponent } from './signup/signup.component';
import { ContentPipeModule } from '../services/content/content-pipe.module';
import { MineDropdownComponent } from '../shared/mine-dropdown/mine-dropdown.component';
import { MineProviderButtonComponent } from '../shared/mine-provider-button/mine-provider-button.component';


export function initModule(authService: AuthService) {
	return authService.initialize();
}

@NgModule({
	declarations: [ 
		LoginComponent,
		SignupComponent
	],
	imports: [
		CommonModule,
		ReactiveFormsModule,
		NgOptimizedImage,
		HttpClientModule,
		ContentPipeModule,
		MineDropdownComponent,
		MineProviderButtonComponent,
		AuthRoutingModule,
		SafePipe,
	],
	providers: []
})
export class AuthModule { }
