<div class="info__item">
    <p class="info__item-title">{{'ticket.infoEmail' | content}}</p>
    <p class="info__item-value">
        {{ (!request.ticketState || request.ticketState?.state !== ticketStates.Redacted) ? 
            !!request.userEmail ? request.userEmail : '-'
            : '************************'}} 
        <svg class="info__email-svg"
            [tp]="'ticket.stepsReviewVerified' | content">
            <use xlink:href="../../../assets/img/sprite.svg#icon-Check-circle"></use>
        </svg>
    </p>    
</div>

<div class="info__item">
    <p class="info__item-title">{{'ticket.infoFullName' | content}}</p>
    <p class="info__item-value">
        {{ (!request.ticketState || request.ticketState?.state !== ticketStates.Redacted) ?
            !!request.name ? request.name : '-'
            : '******* ********'}}
    </p>
</div>

<div class="info__item">
	<p class="info__item-title">
        {{ 'ticket.evidenceReportedCountry' | content }}
        <svg class="info__pencil-svg" [class.info__pencil-svg--disabled]="ticketStep === 0" (click)="editCountry()" 
            [tp]="editCountryTooltip">
            <use xlink:href="../../../assets/img/sprite.svg#icon-pencil"></use>
        </svg>
    </p>
    <span>{{request.countryCode ? (request.countryCode | countryFromCode) : "-"}}</span>
</div>

<div class="info__item">
    <p class="info__item-title">
        {{ 'ticket.infoType' | content }}
        <svg class="info__pencil-svg" [class.info__pencil-svg--disabled]="ticketStep !== 1" (click)="editTicketType()" 
            [tp]="editTicketTypeTooltip">
            <use xlink:href="../../../assets/img/sprite.svg#icon-pencil"></use>
        </svg>
    </p>
    <mine-chip *ngIf="request.ticketType !== ticketTypes.Undetermined; else noTicketType" 
        class="info__item-chip" [backgroundColor]="ticketStateChip?.backgroundColor" [color]="ticketStateChip.textColor">
        <span>{{ticketStateChip.text}}</span>
    </mine-chip>
    <ng-template #noTicketType>
        -
    </ng-template>
</div>

<div class="info__item">
    <p class="info__item-title">{{ 'ticket.infoDate' | content }}</p>
    <p class="info__date">{{request.createdAt | date: getDateFormat(request.createdAt) }}</p>
</div>    
<div class="info__item">
    <p class="info__item-title">{{ 'ticket.dueDate' | content }}
        <svg class="info__pencil-svg" (click)="editDueDate()">
            <use xlink:href="../../../assets/img/sprite.svg#icon-pencil"></use>
        </svg>
    </p>
    <p class="info__date">{{request.dueDate | date: getDateFormat(request.dueDate) }}</p>
    <mine-chip *ngIf="ticketDateChip" class="info__item-chip" 
        [backgroundColor]="ticketDateChip.backgroundColor" [color]="ticketDateChip.textColor">
        <span>{{ticketDateChip.text}}</span>
    </mine-chip>
</div>    

<div class="info__item">
    <ng-container *ngIf="request.ticketSource; noTicketSource">
        <p class="info__item-title">{{'ticket.infoSource' | content}}</p>
        {{request.ticketSource | splitUpperCase }}
    </ng-container>
    <ng-template #noTicketSource>
        -
    </ng-template>
</div>

<div class="info__item" *ngIf="templatesLocalizationFlag">
    <p class="info__item-title">{{'ticket.preferredLanguage' | content}}</p>
    {{preferredLanguage}}
</div>

<div class="info__item">
    <ng-container *ngIf="request.domain; noDomain">
        <p class="info__item-title">{{'ticket.domain' | content}}</p>
        {{request.domain}}
    </ng-container>
    <ng-template #noDomain>
        -
    </ng-template>
</div>

<div class="info__item">
    <ng-container *ngIf="request.targetAddress; noTargetAddress">
        <p class="info__item-title">{{'ticket.infoSentTo' | content}}</p>
        {{request.targetAddress}}
    </ng-container>
    <ng-template #noTargetAddress>
        -
    </ng-template>
</div>

<div class="info__item">
    <ng-container *ngIf="request.actionId; noActionId">
        <p class="info__item-title">{{'ticket.infoId' | content}}</p>
        #{{request.actionId}}
    </ng-container>
    <ng-template #noActionId>
        -
    </ng-template>
</div>
