<label class="input__header" [attr.for]="id">
	<ng-content select="[mine-input-label]"></ng-content>
	<span *ngIf="required" class="input__header--required">&nbsp;*</span>
</label>
<div (click)="picker.open()">
	<input
	#input
	[attr.id]="id"
	type="text"
	class="input__element"
	[ngClass]="{ 'input__element--error': !!error }"
	[max]="maxDate"
    [min]="minDate"
	[placeholder]="placeholder"
	[matDatepicker]="picker"
	[value]="value"
	(dateChange)="onDateChange($event.value)"
	readonly
	/>
	<svg matDatepickerToggleIcon class="input__calendar-icon">
		<use xlink:href="../../../assets/img/sprite.svg#calendar"></use>
	</svg>
</div>
<mat-datepicker #picker disabled="false"></mat-datepicker>
<div class="input__bottom-text">
	<span *ngIf="!!error; else showHint" class="input__error">{{ error }}</span>
	<span class="input__right-text"><ng-content select="[mine-input-right-text]"></ng-content></span>

	<ng-template #showHint>
		<span class="input__hint"><ng-content select="[mine-input-hint]"></ng-content></span>
	</ng-template>
</div>
