import { CommonModule } from '@angular/common';
import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
	standalone: true,
	selector: 'mine-spinner',
	templateUrl: './mine-spinner.component.html',
	styleUrls: ['./mine-spinner.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [CommonModule]
})
export class MineSpinnerComponent implements OnInit {

	@Input()
	width: number;

	@Input()
	height: number;

	@Input()
	revert: boolean;

	stroke: string;

	ngOnInit() {
		this.stroke = this.revert ? 
			getComputedStyle(document.documentElement).getPropertyValue('--mine-white') :
			getComputedStyle(document.documentElement).getPropertyValue('--mine-purple-dark');
	}
}
