import { Component, OnInit, ChangeDetectionStrategy} from '@angular/core';
import { ConnectedToMineStatusEnum } from '../api/models/profile/connected-to-mine-status.enum';
import { AnalyticsEvent } from 'src/app/analytics/analytics-event';
import { AnalyticEventsLabels } from 'src/app/analytics/analytics-events-labels';
import { AnalyticsService } from 'src/app/analytics/analytics.service';
import { EventActionType } from 'src/app/analytics/event-action.enum';
import { FeatureFlags } from 'src/app/api/models/profile/profile-feature-flags.enum';
import { FeatureFlagQuery } from 'src/app/feature-flag/state/feature-flag.query';
import { ProfileQuery } from 'src/app/profile/state/profile.query';
import { ContentPipe } from 'src/app/services/content/content.pipe';
import { environment } from 'src/environments/environment';
import { ExploreMoreCopy } from './explore-more/explore-more.models';
import { Profile } from '../profile/models/profile.interface';

@Component({
  selector: 'home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  host: {'class': 'page-padding u-display-block'},
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HomeComponent implements OnInit {

  assetsUrl = environment.assets.url;

  title: string;

  connectedToMine = false;

  ticketSearchFF = false;

  copy: ExploreMoreCopy;

  constructor(
    private profileQuery: ProfileQuery,
    private contentPipe: ContentPipe,
    private analyticsService: AnalyticsService,
    private featureFlagQuery: FeatureFlagQuery,
  ) { }

  ngOnInit(): void {
    const profile: Profile = this.profileQuery.getValue();
    this.title = `${this.contentPipe.transform('home.titleV2', { params: { name: profile.firstName } })}`;
    this.connectedToMine = profile.connectedToMineStatus === ConnectedToMineStatusEnum.ConnectedToMine;
    this.ticketSearchFF = this.featureFlagQuery.getFlag(FeatureFlags.TicketSearch);
    this.copy = <ExploreMoreCopy>this.contentPipe.transform('home.exploreMore');
  }

  openScheduler(): void {
    this.analyticsService.track({
      eventCategory: AnalyticEventsLabels.HOME_talk_with_an_expert,
      eventAction: EventActionType.Click,
    } as AnalyticsEvent);

    window.open(this.contentPipe.transform('common.calendarLink'), '_blank', 'noopener');
  }
}
