import { Pipe, PipeTransform } from '@angular/core';
import { ContentPipe } from 'src/app/services/content/content.pipe';
import { AiAssessmentTemplateEnum } from '../models/ai-assessments.enum';

@Pipe({
    standalone: true,
    name: 'aiAssessmentTemplateName'
})
export class AiAssessmentTemplateNamePipe implements PipeTransform {

    private readonly types: {[k: string]: string} = this.contentPipe.transform('ai-assessments.types');

    constructor(
        private contentPipe: ContentPipe
    ) { }

    transform(key: string): string {
        const type = AiAssessmentTemplateEnum[key];
        return this.types[type ?? AiAssessmentTemplateEnum.Empty];
    }
}
