import { Component, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { DialogRef } from '@ngneat/dialog';
import { AnalyticsEvent } from 'src/app/analytics/analytics-event';
import { AnalyticsService } from 'src/app/analytics/analytics.service';
import { EventActionType } from 'src/app/analytics/event-action.enum';
import { ContentPipe } from 'src/app/services/content/content.pipe';
import { environment } from 'src/environments/environment';
import { PremiumDialogConfiguration } from './activate-portal-dialog.interface';

@Component({
  selector: 'activate-portal-dialog',
  templateUrl: './activate-portal-dialog.component.html',
  styleUrls: ['./activate-portal-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ActivatePortalDialogComponent implements OnInit {
	assetsUrl = environment.assets.url;

  dialogConfiguration: PremiumDialogConfiguration;

  constructor(
    private dialogRef: DialogRef,
    private contentPipe: ContentPipe,
    private analyticsSerivce: AnalyticsService,
  ) {}

  ngOnInit(): void {
    this.dialogConfiguration = this.dialogRef.data;
  }

  upgradePlan(): void {
    this.dialogRef.close(true);
  }

  closeDialog(): void {
    this.dialogRef.close(false);
  }

  goToCalendar(): void {
    if (this.dialogRef.data.analyticsEventLabel) {
      this.analyticsSerivce.track({
        eventCategory: this.dialogRef.data.analyticsEventLabel,
        eventAction: EventActionType.Click,
      } as AnalyticsEvent);
    }
    window.open(this.contentPipe.transform('common.calendarLink'), '_blank');
  }
}
