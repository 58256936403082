import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LoggerService } from '../logger/logger.service';

@Injectable({
    providedIn: "root"
})
export class HttpLoggerInterceptor  implements HttpInterceptor {
	private readonly loggerName: string = "HttpLoggerInterceptor";

	constructor(private logger: LoggerService) {}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		this.logger.debug(this.loggerName, `${req.method}: ${req.url}`);

		return next.handle(req);
	}
}