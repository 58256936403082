import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { JwtTokenObject } from "./models/auth/jwt-token-object";
import { HttpClientHelperService } from "./http-client-helper.service";

@Injectable({
  providedIn: 'root'
})
export class ApiClientIpaasService {
  private readonly version1 = 'v1';

  constructor(private httpClientHelper: HttpClientHelperService) { }

    generateIpaasToken(): Observable<JwtTokenObject> {
        return this.httpClientHelper.invokeGetAuth<JwtTokenObject>(`${this.version1}/ipaas-authentication/embedded-token`);
    }
}
