import { PoliciesResponse, Policy } from './models/policies/policies.interface';
import { Injectable } from '@angular/core';
import { HttpClientHelperService } from './http-client-helper.service';
import { Observable } from 'rxjs';

@Injectable({
  	providedIn: 'root'
})
export class ApiClientPoliciesService {

	private readonly controller = 'policies';

	constructor(private httpClientHelper: HttpClientHelperService) { }
	getPolicies(): Observable<PoliciesResponse> {
		return this.httpClientHelper.invokeGetAuth<PoliciesResponse>(`${this.controller}/policies`);
	}

	setPolicy(policy: Policy): Observable<void> {
		return this.httpClientHelper.invokePostAuth<void>(`${this.controller}/Policy`, policy);
	}
}
