import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';

import { CompanySettings } from 'src/app/api/models/company-settings/company-settings.interface';
import { HtmlDecodePipe } from 'src/app/shared/pipes/html-decode.pipe';

export function createInitialState(): CompanySettings {
	return {
		customization: undefined,
		branding: undefined,
		inventory: undefined,
		sessions: undefined
	};
}

@Injectable({
  	providedIn: 'root'
})
@StoreConfig({
	name: 'companySettings',
})
export class CompanySettingsStore extends Store<CompanySettings> {

	constructor(
		private htmlDecodePipe: HtmlDecodePipe
	) {
		super(createInitialState());
		this.setLoading(true);
	}

	akitaPreUpdate(prev: CompanySettings, next: CompanySettings): CompanySettings {
		return this.getDecodedCompanySettings(next);
	}

	getDecodedCompanySettings(companySettings: CompanySettings): CompanySettings {
		return {
			...companySettings,
			activities: {
				...companySettings?.activities,
				customMitigations: companySettings?.activities?.customMitigations?.map(mitigation => ({...mitigation, name: this.htmlDecodePipe.transform(mitigation.name)})),
			},
			customization: {
				...companySettings?.customization,
				doNotMailDisplayName: this.htmlDecodePipe.transform(companySettings?.customization?.doNotMailDisplayName),
				doNotMailDescription: this.htmlDecodePipe.transform(companySettings?.customization?.doNotMailDescription),
			},
			dataSubjects: {
				...companySettings?.dataSubjects,
				customDataSubjects: companySettings?.dataSubjects?.customDataSubjects?.map(subject => ({...subject, name: this.htmlDecodePipe.transform(subject.name)})),
			},
			inventory: {
				...companySettings?.inventory,
				customFields: companySettings?.inventory?.customFields?.map(field => ({...field, name: this.htmlDecodePipe.transform(field.name)})),
				inventoryEntities: companySettings?.inventory?.inventoryEntities?.map(entity => ({...entity, name: this.htmlDecodePipe.transform(entity.name)})),
				workflows: companySettings?.inventory?.workflows?.map(workflow => this.htmlDecodePipe.transform(workflow)),
			},
			ropaDetail: {
				...companySettings?.ropaDetail,
				details: companySettings?.ropaDetail?.details?.map(detail => ({...detail, name: this.htmlDecodePipe.transform(detail.name), value: this.htmlDecodePipe.transform(detail.value)})),
			}
		};
	}
}
