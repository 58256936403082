import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

import { HttpClientHelperService } from './http-client-helper.service';
import { ProcessActivityStatusUpdate, ProcessingActivity, ProcessingActivityConfig, ProcessingActivitiesResponse } from './models/processing-activities/processing-activities.interface';

@Injectable({
	providedIn: 'root',
})
export class ApiClientProcessingActivityService {

	private readonly controller = 'ropa';

	constructor(private httpClientHelper: HttpClientHelperService) { }

	getProcessingActivities(): Observable<ProcessingActivitiesResponse> {
		return this.httpClientHelper.invokeGetAuth<ProcessingActivitiesResponse>(`${this.controller}/GetRopa`);
	}

	removeProcessingActivity(systemId: string): Observable<void> {
		return this.httpClientHelper.invokeDeleteAuth<void>(`${this.controller}/DeletePurposeActivity/${systemId}`);
	}

	addProcessingActivity(ropaItemConfig: ProcessingActivityConfig): Observable<ProcessingActivity> {
		return this.httpClientHelper.invokePostAuth<ProcessingActivity>(`${this.controller}/AddPurposeActivity`, ropaItemConfig);
	}
	
	editProcessingActivity(systemId: string, ropaItemConfig: ProcessingActivity): Observable<ProcessingActivity> {
		return this.httpClientHelper.invokePostAuth<ProcessingActivity>(`${this.controller}/EditPurposeActivity/${systemId}`, ropaItemConfig);
	}
	
	updateProcessingActivityStatus(update: ProcessActivityStatusUpdate): Observable<void> {
		return this.httpClientHelper.invokePostAuth<void>(`${this.controller}/UpdateStatus`, update);
	}

	removeEnabledDpiaBanner(paId: string): Observable<void> {
		return this.httpClientHelper.invokePostAuth<void>(`${this.controller}/HideDpiaBanner`, {paId ,hide: true});
	}

	getFunctions(): Observable<string[]> {
		return this.httpClientHelper.invokeGetAuth<any>(`${this.controller}/Functions`).pipe(
			map(res => res.functions)
		);
	}
}
