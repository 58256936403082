<h1 class="mine-title-m home__title">{{title}}</h1>

<section class="home__main-content">

    <search-evidence *ngIf="ticketSearchFF && !connectedToMine" class="home__search-evidence"></search-evidence>

    <h2 *ngIf="!connectedToMine" class="mine-title-xs explore__title">{{copy.title}}</h2>

    <explore-more></explore-more>

    <div class="home__scheduler">

        <img src="{{assetsUrl}}/Portal/onboarding-scheduler.png" class="home__scheduler-img">

        <p class="home__scheduler-title">{{'common.talkWithAnExpertTitle' | content}}</p>

        <p class="home__scheduler-text">{{'common.talkWithAnExpertDescription' | content}}</p>

        <button mine-button color="secondary" 
            class="home__scheduler-btn"
            (click)="openScheduler()">
            {{'common.talkWithAnExpertBtn' | content}}
        </button>

    </div>

</section>

<!-- Conditional class to align the checklist when ticketSearch is turned off -->
<section class="home__side-content" [class.home__side-content--align]="!ticketSearchFF">

    <onboarding-checklist class="home__checklist"></onboarding-checklist>
    
    <home-channels></home-channels>
    
</section>