import { Injectable } from '@angular/core';
import { Observable, of, catchError } from 'rxjs';

import { ApiClientSearchEvidenceService } from 'src/app/api/api-client-evidence-search.service';
import { SearchEvidenceError } from 'src/app/api/models/search-evidence/search-evidence.enum';
import { SearchEvidenceResponse } from 'src/app/api/models/search-evidence/search-evidence.interface';

@Injectable({
	providedIn: 'root',
})
export class SearchEvidenceService {

	constructor(
		private apiClientSearchEvidenceService: ApiClientSearchEvidenceService,
	) {}

	getEvidenceByRequest(actionId: string): Observable<SearchEvidenceResponse> {
		return this.apiClientSearchEvidenceService.getEvidenceByRequest(actionId).pipe(
			catchError(() => of(
				{ error: SearchEvidenceError.DoesntExist } as SearchEvidenceResponse
			))
		);
	}
}