import { Pipe, PipeTransform } from '@angular/core';
import { RoleEnum } from 'src/app/core/models/permission-role.enum';
import { ContentPipe } from 'src/app/services/content/content.pipe';

@Pipe({
  standalone: true,
  name: 'userRoleToTooltip'
})
export class UserRoleToTooltipPipe implements PipeTransform {
  private rolesMap = new Map<RoleEnum, string>(); 

  constructor(private content: ContentPipe) {
    const roles = this.content.transform('profile.agentRolesTooltip');
    
    roles.forEach(role => this.rolesMap.set(role.id, role.value));
  }
  
  transform(role: RoleEnum): string {
    return  this.rolesMap.get(role) ?? '';
  }

}
