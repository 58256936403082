import { Injectable } from '@angular/core';
import { AiAssessmentInstance } from 'src/app/api/models/ai-assessments/ai-assessments.interface';
import { AiAssessmentCustomComponentEnum, AiAssessmentsColumnEnum } from '../models/ai-assessments.enum';
import { AiAssessmentEntityTypeEnum } from 'src/app/api/models/ai-assessments/ai-assessments.enum';
import { AiAssessmentTemplateNamePipe } from '../pipes/ai-assessment-template-name.pipe';
import { AiAssessmentStatusPipe } from '../pipes/ai-assessment-status.pipe';
import { AiAssessmentsFormControl } from '../models/ai-assessments.interface';
import { AiAssessmentsExportHelper } from './ai-assessments-export-helper';

const COLUMN_TO_ENTITY = {
  [AiAssessmentsColumnEnum.DataSources]: AiAssessmentEntityTypeEnum.System,
  [AiAssessmentsColumnEnum.DataSubjects]: AiAssessmentEntityTypeEnum.DataSubject,
  [AiAssessmentsColumnEnum.BusinessUnit]: AiAssessmentEntityTypeEnum.BusinessUnit,
};

@Injectable()
export class AiAssessmentsTableCsvHelper {

  constructor(
    private aiAssessmentsExportHelper: AiAssessmentsExportHelper,
    private aiAssessmentTemplateNamePipe: AiAssessmentTemplateNamePipe,
    private aiAssessmentStatusPipe: AiAssessmentStatusPipe,
  ) { }

  async getValueByKey(instance: AiAssessmentInstance, key: AiAssessmentsColumnEnum): Promise<string> {
    const control = this.parseSchema(key, instance.templateSchema);
    const customFieldData = this.parseCustomFieldData(control, instance.data);
    const entitiesString = await this.buildRelatedEntities(instance, key, control);
    const status = this.aiAssessmentStatusPipe.transform(instance);

    const values = {
      [AiAssessmentsColumnEnum.Type]: this.aiAssessmentTemplateNamePipe.transform(instance.templateType),
      [AiAssessmentsColumnEnum.DataSources]: entitiesString,
      [AiAssessmentsColumnEnum.Status]: `${status?.status?.value ?? ''} - ${status.date ?? ''}`,
      [AiAssessmentsColumnEnum.DataSubjects]: entitiesString,
      [AiAssessmentsColumnEnum.BusinessUnit]: entitiesString,
    };
    return customFieldData ?? values[key] ?? instance[key];
  }

  private async buildRelatedEntities(instance: AiAssessmentInstance, key: AiAssessmentsColumnEnum, control: AiAssessmentsFormControl | null): Promise<string> {
    const relatedEntities = instance.relatedEntities?.filter(({ type, id }) => type === COLUMN_TO_ENTITY[key] || id === control?.customFieldId) ?? [];
    const externalEntities = relatedEntities.map(({ id, type }) => ({ externalRelatedEntityId: id, type }));
    const relatedAssessmentIds = instance.relatedAssessments.map(({ id }) => id);
    const foundEntities = control?.name === AiAssessmentCustomComponentEnum.LinkedAssessment
        ? await this.aiAssessmentsExportHelper.collectRelatedAssessments(relatedAssessmentIds)
        : await this.aiAssessmentsExportHelper.collectExternalEntitiesData(externalEntities);

    return foundEntities.map(({ value }) => value).join(', ');
  }

  private parseCustomFieldData(control: AiAssessmentsFormControl | null, data: string): string {
    if (!control || !control.customFieldId || !data) {
      return;
    }
    if (control.value) {
      return control.value;
    }
    try {
      const parsedData = JSON.parse(data);
      if (!parsedData) {
        return;
      }
      let valueFound: string | undefined;
      Object.values(parsedData).forEach(value => Object.entries(value).forEach(([name, value]) => {
        if (control.name !== name) {
          return;
        }
        valueFound = value?.value ?? value;
      }));
      return valueFound;
    }
    catch (error) {
      console.error(error);
    }
    return;
  }

  private parseSchema(key: string, schema: string): AiAssessmentsFormControl | null {
    try {
      const parsedSchema = JSON.parse(schema);
      if (!(parsedSchema instanceof Array)) {
        return null;
      }
      let controlFound: AiAssessmentsFormControl | null = null;
      parsedSchema.forEach(({ controls }) => controls?.forEach((control) => {
        if (![control.name, control.customFieldId].includes(key)) {
          return;
        }
        controlFound = control;
      }));
      return controlFound;
    }
    catch (error) {
      console.error(error);
    }
  }
}
