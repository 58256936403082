import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { Observable  } from 'rxjs';

import { EmployeesState, EmployeesStore } from './employees.store';
import { EmployeeDataMapping } from 'src/app/api/models/data-mapping/data-mapping.interface';
import { EmployeesTableColumnKeys } from '../../employees/employees-managment/employees-table/employees-table.enum';
import { EmployeeArchivedEnum } from '../../employees/employees-managment/employee-filtering/employee-filtering.interface';
import { FilterCategory } from 'src/app/core/models/filtering.interface';
import { SortDirectionEnum } from 'src/app/shared/sort-direction.enum';
import { MineSort } from 'src/app/shared/mine-sort/mine-sort.interface';

@Injectable({
	providedIn: 'root'
})
export class EmployeesQuery extends QueryEntity<EmployeesState> {

	constructor(protected store: EmployeesStore) {
		super(store);
	}

	selectSorted(sort: MineSort, filters: FilterCategory[] = []): Observable<EmployeeDataMapping[]> {		
		if (!sort.direction || sort.active === null) {
			return this.selectAll({
				filterBy: [
					entity => 
						this.filterByArchived(filters, entity?.isArchived)
				],
			});
		}

		return this.selectAll({
			sortBy: (a, b) => this.sortData(a, b, sort),
			filterBy: [
				entity => 
					this.filterByArchived(filters, entity?.isArchived)
			],
		});
	}

	private filterByArchived(filter: FilterCategory[], isArchived: boolean): boolean {
		return !!filter[0]?.options?.find(o => o.id === EmployeeArchivedEnum.Archived) ? isArchived : !isArchived;
	}
	
	selectSortedNotArchived(sort: MineSort): Observable<EmployeeDataMapping[]> {
		if (!sort.direction || sort.active === null) {
			return this.selectAll({filterBy: ({ isArchived }) => !isArchived});
		}

		return this.selectAll({
			filterBy: ({ isArchived }) => !isArchived,
			sortBy: (a, b) => this.sortData(a, b, sort),
		});
	}

	selectSortedArchived(sort: MineSort): Observable<EmployeeDataMapping[]> {
		if (!sort.direction || sort.active === null) {
			return this.selectAll({filterBy: ({ isArchived }) => isArchived});
		}

		return this.selectAll({
			filterBy: ({ isArchived }) => isArchived,
			sortBy: (a, b) => this.sortData(a, b, sort),
		});
	}

	selectFilteredCount(filters: FilterCategory[]): Observable<number> {
		return this.selectCount(({ isArchived }) => this.filterByArchived(filters, isArchived));
	}

	updateStore(employee: EmployeeDataMapping): void {
		this.store.add(employee);
	}

	private sortData(a: any, b: any, sort: MineSort): number {
		switch (sort.active) {
			case EmployeesTableColumnKeys.CoworkerName:
				return this.sortByCoworkerName(a, b, sort.direction);
			case EmployeesTableColumnKeys.CoworkerEmail:
				return this.sortByCoworkerEmail(a, b, sort.direction);
			case EmployeesTableColumnKeys.CoworkerDataSources:
				return this.sortByCoworkerDataSources(a, b, sort.direction);
			default:
				return this.sortByCoworkerDataSources(a, b, SortDirectionEnum.Desc);
		}
	}

	private sortByCoworkerName(a: EmployeeDataMapping, b: EmployeeDataMapping, direction: SortDirectionEnum): number {
		return direction === SortDirectionEnum.Asc ?
			`${a.firstName} ${a.lastName}`?.toLowerCase().localeCompare(`${b.firstName} ${b.lastName}`?.toLowerCase()) :
			`${b.firstName} ${b.lastName}`?.toLowerCase().localeCompare(`${a.firstName} ${a.lastName}`?.toLowerCase());
	}

	private sortByCoworkerEmail(a: EmployeeDataMapping, b: EmployeeDataMapping, direction: SortDirectionEnum): number {
		return direction === SortDirectionEnum.Asc ?
			a.email?.toLowerCase().localeCompare(b.email?.toLowerCase()) : b.email?.toLowerCase().localeCompare(a.email?.toLowerCase());
	}

	private sortByCoworkerDataSources(a: EmployeeDataMapping, b: EmployeeDataMapping, direction: SortDirectionEnum): number {
		return direction === SortDirectionEnum.Asc ?
			a.dataSourcesCount - b.dataSourcesCount :
			b.dataSourcesCount - a.dataSourcesCount;
	}
}