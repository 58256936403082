import { Inject, Injectable } from '@angular/core';
import { PaginatorPlugin } from '@datorama/akita';
import { delay, first, of, Subscription, switchMap, tap } from 'rxjs';
import { EMPLOYEES_PAGINATOR } from '../state/employees-paginator';
import { EmployeesAsyncActions } from '../../heavy-action-dialog/heavy-action-dialog.enum';
import { LoggerService } from '../../logger/logger.service';
import { UnverifiedSystemsService } from '../../radar/state/unverified-systems.service';
import { SystemsService } from '../../systems/state/systems.service';
import { EmployeesState } from '../state/employees.store';


/**
/* service explanation:
/* there are actions on employees that are ASYNC. these actions will NOT finished when BE return a response.
/* to support these actions and to be aligned with BE data (and until we will have sockets..) we created a service that retrieve the data one minute after. 1 minute is 99.9% enough time to finish the async actions 
*/

@Injectable({
  providedIn: 'root'
})
export class ManageAsyncActionsEmployeesService {
  private readonly refreshWindow = 60000; // refresh data after 1 minute
  private readonly loggerName = 'ManageAsyncActionsEmployeesService';

  private refreshInventorySubscription: Subscription;
  private refreshRadarSubscription: Subscription;
  private refreshEmployeesSubscription: Subscription;

  constructor(
    private systemsService: SystemsService,
    private unverifiedSystemsService: UnverifiedSystemsService,
    @Inject(EMPLOYEES_PAGINATOR) public employeesPaginatorRef: PaginatorPlugin<EmployeesState>,
    private loggerService: LoggerService, 
  ) { }

  setEmployeesAsyncAction(action: EmployeesAsyncActions): void {
    this.refreshEmployeesData();

    switch(action) {
      case EmployeesAsyncActions.FromRadarToInventory:
      case EmployeesAsyncActions.FromTrashToInventory:
        this.refreshInventoryData();
        break;
      
      case EmployeesAsyncActions.FromInventoryToTrash:
      case EmployeesAsyncActions.FromRadarToTrash:
        break;

      case EmployeesAsyncActions.ArchiveEmployee:
      case EmployeesAsyncActions.UnArchiveEmployee:
        this.refreshInventoryData();
        this.refreshRadarData();
        break;

      default:
        this.loggerService.warn(this.loggerName, 'Cant refresh data after action');
        break;
    }
  }

  private refreshInventoryData(): void {
    if (this.refreshInventorySubscription) {
      this.refreshInventorySubscription.unsubscribe();
    }

    this.refreshInventorySubscription = of(true).pipe(
      delay(this.refreshWindow),
      switchMap(() => this.systemsService.getSystemsFromServer()),
      first(),
    ).subscribe();
  }

  private refreshRadarData(): void {
    if (this.refreshRadarSubscription) {
      this.refreshRadarSubscription.unsubscribe();
    }

    this.refreshRadarSubscription = of(true).pipe(
      delay(this.refreshWindow),
      switchMap(() => this.unverifiedSystemsService.getUnverifiedSystemsFromServer()),
      first(),
    ).subscribe();
  }

  private refreshEmployeesData(): void {
    if (this.refreshEmployeesSubscription) {
      this.refreshEmployeesSubscription.unsubscribe();
    }

    this.refreshEmployeesSubscription = of(true).pipe(
      delay(this.refreshWindow),
      tap(() => this.employeesPaginatorRef.clearCache()),
      tap(() => this.employeesPaginatorRef.refreshCurrentPage()),
      first(),
    ).subscribe();
  }
}
