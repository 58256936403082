import { Component, OnInit, ChangeDetectionStrategy, Input, OnChanges, SimpleChanges, EventEmitter, Output } from '@angular/core';
import { DatePipe } from '@angular/common';
import { filter, first, tap } from 'rxjs';

import { TicketStateEnum } from 'src/app/api/models/requests/ticket-state.enum';
import { TicketType } from 'src/app/api/models/requests/ticket-type.enum';
import { DaysAgoPipe } from '../../common/days-ago.pipe';
import { ContentPipe } from 'src/app/services/content/content.pipe';
import { FeatureFlagQuery } from 'src/app/feature-flag/state/feature-flag.query';
import { Badge } from 'src/app/shared/mine-card/mine-card.component.interface';
import { MineChip } from 'src/app/shared/mine-chip/mine-chip.interface';
import { ChipColorName } from 'src/app/shared/mine-chip/mine-chip.enum';
import { LanguageConfiguration } from 'src/app/privacy-center/localization.interface';
import { DateUtils } from 'src/app/shared/models/date-utils';
import { TicketTypeDisplayNamePipe } from '../pipes/ticket-type-display-name.pipe';
import { FeatureFlags } from 'src/app/api/models/profile/profile-feature-flags.enum';
import { TicketStepsUtils } from '../common/ticket-steps.utils';
import { DialogsManagerService } from 'src/app/services/dialogs-manager.service';
import { EditCountryDialogComponent } from '../../../dsr/dsr-ticket/dsr-ticket-info/dsr-ticket-details/dsr-ticket-details-panel/dialogs/edit-country-dialog/edit-country-dialog.component';
import { EditTicketTypeDialogComponent } from '../../../dsr/dsr-ticket/dsr-ticket-info/dsr-ticket-details/dsr-ticket-details-panel/dialogs/edit-ticket-type-dialog/edit-ticket-type-dialog.component';
import { EditDueDateDialogComponent } from '../../../dsr/dsr-ticket/dsr-ticket-info/dsr-ticket-details/dsr-ticket-details-panel/dialogs/edit-due-date-dialog/edit-due-date-dialog.component';
import { RequestsService } from '../../state/requests.service';
import { RequestItemDeep } from 'src/app/api/models/requests/requests.interface';

@Component({
  selector: 'ticket-info',
  templateUrl: './ticket-info.component.html',
  styleUrls: ['./ticket-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TicketInfoComponent implements OnInit, OnChanges {

	private readonly oldRequests = 15;

	@Input() request: RequestItemDeep;

	@Output() openConversationClicked = new EventEmitter<void>();
	@Output() ticketUpdated = new EventEmitter<RequestItemDeep>();

	ticketStep: number;

	ticketStateChip: Badge;

	ticketType: string;

	ticketDateChip: Badge;

	dateText: string;

	dateAgoSuffix: string;

	ticketStates = TicketStateEnum;

	ticketTypes = TicketType;

	mergedRequestTable: boolean;

	templatesLocalizationFlag: boolean;
	
	preferredLanguage: string = '';
	private availableLanguages: Map<string, LanguageConfiguration> = new Map();
	private defaultLanguage: string = '';

	editCountryTooltip: string;
	editTicketTypeTooltip: string;

	constructor(
		private datePipe: DatePipe,
		private daysAgoPipe: DaysAgoPipe,
		private contentPipe: ContentPipe,
		private featureFlagQuery: FeatureFlagQuery,
		private ticketTypeDisplayNamePipe: TicketTypeDisplayNamePipe,
		private dialogsManagerService: DialogsManagerService,
		private requestsService: RequestsService,
	) { }
	
	ngOnChanges(changes: SimpleChanges): void {
		if (changes.request && changes.request.currentValue !== changes.request.previousValue) {
			this.init();
		}
	}
	
	ngOnInit(): void {
		this.initFlags();
		this.initLocalization();
		this.init();
	}

	private initFlags(): void {
		const flags = this.featureFlagQuery.getMultipleFlags([FeatureFlags.TemplatesLocalization]);
		this.templatesLocalizationFlag = flags.templatesLocalization;
	}

	private init(): void {
		this.ticketType = this.ticketTypeDisplayNamePipe.transform(this.request.ticketType);
		if (this.request.ticketState) {
			this.dateAgoSuffix = this.requestsService.isOpenTicket(this.request.ticketState.state) ? `(${this.daysAgoPipe.transform(this.request.createdAt)})` : '';
		}
		this.dateText = `${this.datePipe.transform(this.request.createdAt, 'dd MMM yyyy')}`;
		this.setPreferredLanguage();
		this.initChips();
		this.ticketStep = TicketStepsUtils.convertTicketStateToStep(this.request.ticketState.state);
		this.initTooltips();
	}

	private initLocalization() {
		if(this.templatesLocalizationFlag) {
			this.defaultLanguage = this.contentPipe.transform('templates-localization.defaultEnglish').nameForDisplay;
			const languages = this.contentPipe.transform('templates-localization.languages');
			languages.forEach((lang:LanguageConfiguration) => {
				this.availableLanguages.set(lang.localizationCode, lang);
			});
		}
	}

	private setPreferredLanguage() {
		if(!this.templatesLocalizationFlag){
			return;
		}
		const ticketLanguage = this.request.preferredLocale;
		this.preferredLanguage = this.availableLanguages.get(ticketLanguage)?.nameForDisplay ?? this.defaultLanguage;
	}

	private initChips(): void {
		switch (this.request.ticketType) {
			case TicketType.Delete:
				this.ticketStateChip = {
					backgroundColor: MineChip.get(ChipColorName.Grey).backgroundColor,
					textColor: MineChip.get(ChipColorName.Grey).textColor,
					text: this.ticketType,
				};
				break;
			case TicketType.GetCopy:
				this.ticketStateChip = {
					backgroundColor: MineChip.get(ChipColorName.Yellow).backgroundColor,
					textColor: MineChip.get(ChipColorName.Yellow).textColor,
					text: this.ticketType,
				};
				break;
			case TicketType.DoNotSell:
				this.ticketStateChip = {
					backgroundColor: MineChip.get(ChipColorName.LightPink).backgroundColor,
					textColor: MineChip.get(ChipColorName.LightPink).textColor,
					text: this.ticketType,
				};
				break;
			case TicketType.DoNotMail: 
				this.ticketStateChip = {
					backgroundColor: MineChip.get(ChipColorName.VeryLightBlue).backgroundColor,
					textColor: MineChip.get(ChipColorName.VeryLightBlue).textColor,
					text: this.ticketType
				}
				break;
			case TicketType.RightToEdit: 
				this.ticketStateChip = {
					backgroundColor: MineChip.get(ChipColorName.Cyan).backgroundColor,
					textColor: MineChip.get(ChipColorName.Cyan).textColor,
					text: this.ticketType
				}
				break;
		}

		this.initDateChipV2();
	}

	private initDateChipV2(): void {
		if (this.request.ticketState && this.requestsService.isOpenTicket(this.request.ticketState.state)) {
			const data = this.requestsService.getDueDateData(this.request.dueDate);
			const colorChip = data.isDueDatePassed ? ChipColorName.Red : ChipColorName.Grey;
			this.ticketDateChip = {
				backgroundColor: MineChip.get(colorChip).backgroundColor,
				textColor: MineChip.get(colorChip).textColor,
				text: data.text,
			};
		}
	}

	private initTooltips(): void {
		switch (this.ticketStep) {
			case 0:
				this.editCountryTooltip = this.contentPipe.transform('ticket.editTicketNotAvailable');
				this.editTicketTypeTooltip = this.contentPipe.transform('ticket.editTicketNotAvailable');
				break;
			case 1:
				this.editCountryTooltip = this.contentPipe.transform('ticket.editTicketMetadata');
				this.editTicketTypeTooltip = this.contentPipe.transform('ticket.editTicketMetadata');
				break;
			default:
				this.editCountryTooltip = this.contentPipe.transform('ticket.editTicketMetadata');
				this.editTicketTypeTooltip = this.contentPipe.transform('ticket.editTicketNotAvailableAfterProcessing');
		}
	}

	isOld(time: string): boolean {
		const days = DateUtils.getDaysAgo(time);
		return days >= this.oldRequests;
	}

	openConversation(): void {
		this.openConversationClicked.emit();
	}

	editTicketType(): void {
		if (this.ticketStep === 1) {
			const ref = this.dialogsManagerService.openDialogCommon(EditTicketTypeDialogComponent, { id: this.request.ticketId, ticketType: this.request.ticketType }, '415px');
			ref.afterClosed$.pipe(
				first(),
				filter(res => !!res),
				tap((res: TicketType) => this.ticketUpdated.emit({...this.request, ticketType: res})),
			).subscribe();
		}
	}

	editCountry(): void {
		if (this.ticketStep > 0) {
			const ref = this.dialogsManagerService.openDialogCommon(EditCountryDialogComponent, { id: this.request.ticketId, countryCode: this.request.countryCode }, '403px');
			ref.afterClosed$.pipe(
				first(),
				filter(res => !!res),
				tap((res: string) => this.ticketUpdated.emit({...this.request, countryCode: res})),
			).subscribe();
		}
	}

	editDueDate(): void {
		const ref = this.dialogsManagerService.openDialogCommon(EditDueDateDialogComponent, { id: this.request.ticketId, dueDate: this.request.dueDate }, '396px');
		ref.afterClosed$.pipe(
			first(),
			filter(res => !!res),
			tap((res: string) => this.ticketUpdated.emit({...this.request, dueDate: res})),
		).subscribe();
	}

	getDateFormat(date: string): string {
		return new Date().getFullYear() === new Date(date).getFullYear() ? 'dd MMMM' : 'dd MMMM yyyy';
	}
}
