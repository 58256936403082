import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, ViewContainerRef, ViewChild, ComponentRef } from '@angular/core';
import { AbstractControl, FormControl, ValidatorFn, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { filter, first, tap } from 'rxjs';

import { environment } from 'src/environments/environment';
import { Animations } from 'src/app/animations/animations';
import { ChatService } from 'src/app/chat/chat.service';
import { ContentPipe } from 'src/app/services/content/content.pipe';
import { SearchEvidenceResponse } from 'src/app/api/models/search-evidence/search-evidence.interface';
import { MineEvidenceLoaderComponent } from 'src/app/shared/mine-evidence-loader/mine-evidence-loader.component';
import { SearchEvidenceService } from '../services/search-evidence.service';
import { TicketEvidenceService } from 'src/app/requests/request-ticket-v2/ticket-evidence/ticket-evidence.service';
import { AnalyticsService } from 'src/app/analytics/analytics.service';
import { AnalyticEventsLabels } from 'src/app/analytics/analytics-events-labels';
import { EventActionType } from 'src/app/analytics/event-action.enum';
import { RoutesManager } from 'src/app/shared/models/routes.interfaces';
import { RequestsQuery } from 'src/app/requests/state/requests.query';

@Component({
  selector: 'search-evidence',
  templateUrl: './search-evidence.component.html',
  styleUrls: ['./search-evidence.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [Animations.fadeInOnEnter]
})
export class SearchEvidenceComponent implements OnInit {

  readonly assetsUrl = environment.assets.url;

  ticketControl: FormControl;
  
  ticketEvidence: SearchEvidenceResponse;

  showResultsSection = false;

  loaderFinished = false;

  startFetching = false;

  @ViewChild('loaderContainer', { read: ViewContainerRef }) loaderContainer;

  loaderComponentRef: ComponentRef<MineEvidenceLoaderComponent>;

  constructor(
    private requestsQuery: RequestsQuery,
    private searchEvidenceService: SearchEvidenceService,
    private contentPipe: ContentPipe,
    private cdr: ChangeDetectorRef,
    private router: Router,
    private ticketEvidenceService: TicketEvidenceService,
    private chatService: ChatService, 
    private analyticsService: AnalyticsService,
  ) { }

  ngOnInit(): void {
    this.ticketControl = new FormControl('', [Validators.required, this.requestIdValidator()] );
  }

  searchClicked(): void {
    this.clearLoaderComponent();
    this.showResultsSection = true;
    this.startFetching = true;
    this.loaderFinished = false;
    this.cdr.detectChanges();
    this.searchEvidence();
  }

  private searchEvidence(): void {
    this.searchEvidenceService.getEvidenceByRequest(this.ticketControl.value)
    .pipe(
      first(),
      tap(res => this.initTicketEvidence(res)),
      tap(() => this.sendAnalytics()),
      filter(res => !res.error),
      tap(res => this.ticketEvidenceService.updateFetchedEvidence(res.ownedItem.ticketId)),
    )
    .subscribe();
  }

  private initTicketEvidence(res: SearchEvidenceResponse): void {
    this.ticketEvidence = res;
    if (!this.ticketEvidence.error) {
      this.createLoaderComponent();
    } else {
      this.loaderFinished = true;
      this.cdr.detectChanges();
    }
  }

  private requestIdValidator(): ValidatorFn {
		return (control: AbstractControl): { [key: string]: any } | null => {
			const regex = /^[A-Za-z0-9-]*$/;			
			const validInput = regex.test(control.value);
			return validInput ?  null : { error: this.contentPipe.transform('home.evidenceIdNotValid') };
		};
	}

  afterLoaderFinished(): void {
    this.loaderFinished = true;
    this.clearLoaderComponent();
    this.cdr.detectChanges();
  }

  private createLoaderComponent(): void {
    this.loaderComponentRef = this.loaderContainer.createComponent(MineEvidenceLoaderComponent);
    this.loaderComponentRef.instance.startFetching = this.startFetching;
    this.loaderComponentRef.instance.loaderFinished.subscribe(() => this.afterLoaderFinished());  
    this.loaderComponentRef.changeDetectorRef.detectChanges();
  }

  private clearLoaderComponent(): void {
    this.loaderComponentRef = null;
    this.loaderContainer?.clear();
  }

  navigateToRequest(): void {
    if (!this.requestsQuery.hasEntity(this.ticketEvidence.ownedItem.ticketId)) {
      this.analyticsService.track({
        eventAction: EventActionType.Click,
        eventCategory: AnalyticEventsLabels.SEARCH_EVIDENCE_process_request,
        eventLabel: AnalyticEventsLabels.SEARCH_EVIDENCE_process_request_not_connected,
      });
      this.openIntercom();
    } else {
      this.analyticsService.track({
        eventAction: EventActionType.Click,
        eventCategory: AnalyticEventsLabels.SEARCH_EVIDENCE_process_request,
        eventLabel: AnalyticEventsLabels.SEARCH_EVIDENCE_process_request_connected,
      });
      this.router.navigate([RoutesManager.requests, RoutesManager.requests_ticket_v2, this.ticketEvidence.ownedItem.ticketId]);
    }
  }

  openIntercom(): void {
    this.chatService.show();
  }

  private sendAnalytics(): void {
    this.analyticsService.track({
      eventAction: EventActionType.Click,
      eventCategory: AnalyticEventsLabels.SEARCH_EVIDENCE_clicked,
      eventLabel: !this.ticketEvidence.error ? AnalyticEventsLabels.SEARCH_EVIDENCE_found : AnalyticEventsLabels.SEARCH_EVIDENCE_not_found,
    });
  }
}
