import { Pipe, PipeTransform } from '@angular/core';
import { TicketType } from 'src/app/api/models/requests/ticket-type.enum';
import { CompanySettingsQuery } from 'src/app/company-settings/state/company-settings.query';
import { ContentPipe } from 'src/app/services/content/content.pipe';

@Pipe({
  standalone: true,
	name: 'ticketTypeDisplayName',
})
export class TicketTypeDisplayNamePipe implements PipeTransform {
		
  constructor(
    private companySettingsQuery: CompanySettingsQuery,
    private contentPipe: ContentPipe
  ) { }

	transform(value: TicketType): string {
    if (value === TicketType.DoNotMail) {
      const doNotMailDisplay = this.companySettingsQuery.getCustomization()?.doNotMailDisplayName;
      if (!!doNotMailDisplay) {
        return doNotMailDisplay;
      }
    }
    return this.contentPipe.transform(`common.ticketTypes.${value}`);
	}
}
