import { Injectable } from '@angular/core';
import { LoggerService } from '../logger/logger.service';
import { PlanType } from '../api/models/plans/plan.enum';
import { UserProperties } from '../analytics/user-properties';

declare global {
	interface Window {
        _hsq: any;
    }
}

@Injectable({
  providedIn: 'root'
})
export class HubspotAnalyticsService {

  private readonly loggerName: string = 'HubspotAnalyticsService';

  constructor(private loggerService: LoggerService) { }

  // See: https://developers.hubspot.com/docs/api/conversation/visitor-identification

  updateUserProperties(userProperties: UserProperties) {
    if(userProperties.Email === undefined) {
      this.loggerService.debug(this.loggerName, `updateUserProperties(); Email = undefined`);
      var _hsq = window._hsq = window._hsq || [];
      _hsq.push(["identify", { }]);
    }
    else {
      this.loggerService.debug(this.loggerName, `updateUserProperties(); ID = ${userProperties.Id}`);
      const stage = this.getLifecycleStage(userProperties);
      var _hsq = window._hsq = window._hsq || [];

      _hsq.push(["identify", {
        // builtin properties that are used as unique identifiers
        email: userProperties.Email,
        id: userProperties.Id,

        // more builtin properties
        lifecyclestage: stage,
        firstname: userProperties.FirstName,
        lastname: userProperties.LastName,

        // custom properties that need to be created
        mine_company_id: userProperties.CompanyId,
        mine_company_name: userProperties.CompanyName,

        closed_tickets_in_last_30_days: userProperties.ClosedTicketsInLast30Days

        // mine_plan_name: userProperties.PlanName,
        // mine_plan_last_modified_at: userProperties.PlanLastModifiedAt,

        // mine_is_verified: userProperties.IsVerified,
        // mine_identity_provider: userProperties.IdentityProvider,

        // mine_privacy_center_enabled: userProperties.PrivacyCenterEnabled,
        // mine_webhook_enabled: userProperties.WebhookEnabled,

        // mine_reject_rule_enabled: userProperties.RejectRuleEnabled,
        // mine_reject_response_enabled: userProperties.RejectResponseEnabled,
        // mine_ack_response_enabled: userProperties.AckResponseEnabled,
        // mine_canceled_response_enabled: userProperties.CanceledResponseEnabled,
        // mine_completed_response_enabled: userProperties.CompletedResponseEnabled,

        // mine_requests_total: userProperties.TotalRequests,
        // mine_requests_open: userProperties.OpenRequests,
        // mine_requests_closed: userProperties.ClosedRequests,
        // mine_requests_unverified: userProperties.UnverifiedRequests,
      }]);
    }
  }

  private getLifecycleStage(userProperties: UserProperties): string {
    if((userProperties.PlanName === PlanType.Pro)
      || (userProperties.PlanName === PlanType.Enterprise)
      || (userProperties.PlanName === PlanType.Complete)) {
      return 'customer';
    }
    if(userProperties.IsVerified === true) {
      return 'salesqualifiedlead';
    }
    else {
      return 'marketingqualifiedlead';
    }
  }

  trackPageView(path: string) {
    var _hsq = window._hsq = window._hsq || [];
    _hsq.push(['setPath', path]);
    _hsq.push(['trackPageView']);
  }

  trackEvent(name: string) {
    var _hsq = window._hsq = window._hsq || [];
    _hsq.push(['trackEvent', {id: name}]);
  }
}
