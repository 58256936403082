import { DialogRef } from '@ngneat/dialog';
import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { LanguageConfiguration } from 'src/app/privacy-center/localization.interface';
import { AutomationsLocalizationService } from '../automations-localization.service';

@Component({
	selector: 'add-language-dialog',
	templateUrl: './add-language-dialog.component.html',
	styleUrls: ['./add-language-dialog.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddLanguageDialogComponent implements OnInit {
	selectedLanguage: string;
	locales: Map<string, LanguageConfiguration>;
	showSpinner: boolean = false;

	constructor(private dialogRef: DialogRef,
		private automationLocalizationService: AutomationsLocalizationService) {}

	ngOnInit() {
		this.locales = this.automationLocalizationService.getAvailableLocales();
	}

	chooseLanguage(locale: LanguageConfiguration) {
		if(locale.configured) {
			return;
		}
		this.selectedLanguage = locale.localizationCode;
	}

	cancelDialog() {
		this.dialogRef.close();
	}

	addLanguage() {
		this.showSpinner = true;
		setTimeout(() => {
			this.showSpinner = false;
			this.dialogRef.close(this.selectedLanguage);
		}, 1500);
	}
}
