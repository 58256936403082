import { ErrorHandler, Injectable } from "@angular/core";
import { LoggerService } from '../logger/logger.service';

// global error handler catch chuncks loading issues and log them. other errors should be treated in their origin file 
@Injectable({
    providedIn: "root"
})
export class GlobalErrorHandler implements ErrorHandler {
	private readonly loggerName = 'GlobalErrorHandler';
	private readonly chunkFailedMessage = /Loading chunk [\d]+ failed/;

	constructor(
		private logger: LoggerService
	) { }
	
	handleError(error: Error): void {
    	if(this.chunkFailedMessage.test(error.message)) {
			this.logger.info(this.loggerName, 'Chunk load failed, refreshing to new version');
			window.location.reload();
		}
		console.error(error);
	}
}