import { map, firstValueFrom, Observable } from "rxjs";
import { AiAssessmentInstance, AiAssessmentPageRequest, AiAssessmentPageSection, AssessmentExternalEntity } from "src/app/api/models/ai-assessments/ai-assessments.interface";
import { AiAssessmentEntityTypeEnum } from "src/app/api/models/ai-assessments/ai-assessments.enum";
import { DataFlowEdge } from "src/app/api/models/processing-activities/processing-activities.interface";
import { AiAssessmentsFormControl } from "../models/ai-assessments.interface";
import { AiAssessmentsFormControlTypeEnum, AiAssessmentCustomComponentEnum, AiAssessmentTemplateEnum } from '../models/ai-assessments.enum';
import { AiAssessmentExportTemplateInstance, AiAssessmentExportTemplateSection, DataFlowDataExport, DataFlowEdgeExport, AiAssessmentSectionListItem } from "../models/ai-assessment-export.interface";
import { DataFlowNodeTypeEnum, RiskRateEnum } from "src/app/api/models/processing-activities/processing-activities.enum";
import { ContentPipe } from "src/app/services/content/content.pipe";
import { CustomValuesQuery } from "src/app/company-settings/state/custom-values/custom-values.query";
import { EmployeeDataMapping } from "src/app/api/models/data-mapping/data-mapping.interface";
import { Injectable } from "@angular/core";
import { SpecialSystemType } from "src/app/systems/models/custom-system-type.enum";
import { ProcessingActivitiesQuery } from "src/app/processing-activities/state/processing-activities.query";
import { SystemsQuery } from "src/app/systems/state/systems.query";
import { EmployeesService } from "src/app/employees/state/employees.service";
import { AiAssessmentsStore } from "../state/ai-assessments.store";
import { AiAssessmentStatusPipe } from "../pipes/ai-assessment-status.pipe";
import { CompanySettingsQuery } from "src/app/company-settings/state/company-settings.query";
import { DataType } from "src/app/data-types/models/data-types.interface";
import { DropdownOption } from "src/app/shared/mine-dropdown/mine-dropdown.interface";
import { RiskCalulationPipe } from "src/app/processing-activities/services/risk-calulation.pipe";
import { CustomValueTypeEnum } from "src/app/api/models/company-settings/custom-values.enum";
import { LoggerService } from "src/app/logger/logger.service";

const ENTITY_TYPE_TO_SECTION_TYPE: Partial<Record<AiAssessmentEntityTypeEnum, AiAssessmentCustomComponentEnum>> = {
  [AiAssessmentEntityTypeEnum.BusinessOwner]: AiAssessmentCustomComponentEnum.BusinessOwner,
  [AiAssessmentEntityTypeEnum.ProcessingActivity]: AiAssessmentCustomComponentEnum.BusinessPurpose,
  [AiAssessmentEntityTypeEnum.LinkedAssessment]: AiAssessmentCustomComponentEnum.LinkedAssessment,
  [AiAssessmentEntityTypeEnum.System]: AiAssessmentCustomComponentEnum.DataSources,
  [AiAssessmentEntityTypeEnum.DataSubject]: AiAssessmentCustomComponentEnum.DataSubjects,
  [AiAssessmentEntityTypeEnum.BusinessUnit]: AiAssessmentCustomComponentEnum.BusinessUnit,
  [AiAssessmentEntityTypeEnum.DataRole]: AiAssessmentCustomComponentEnum.DataRole,
  [AiAssessmentEntityTypeEnum.TransferMechanism]: AiAssessmentCustomComponentEnum.TransferMechanism,
  [AiAssessmentEntityTypeEnum.LegalBasis]: AiAssessmentCustomComponentEnum.LegalBasis,
};

const COMPONENTS_IN_MAPPER = Object.values(ENTITY_TYPE_TO_SECTION_TYPE);

const SECTION_TYPES_TO_HIDE: string[] = [AiAssessmentCustomComponentEnum.DataFlowNeeded];


@Injectable()
export class AiAssessmentsExportHelper {

  private readonly loggerName = 'AiAssessmentsExportHelper';

  constructor(
    private store: AiAssessmentsStore,
    private processingActivitiesQuery: ProcessingActivitiesQuery,
    private systemsQuery: SystemsQuery,
    private customValuesQuery: CustomValuesQuery,
    private employeesService: EmployeesService,
    private companySettingsQuery: CompanySettingsQuery,
    private contentPipe: ContentPipe,
    private aiAssessmentStatusPipe: AiAssessmentStatusPipe,
    private riskCalulationPipe: RiskCalulationPipe,
    private loggerService: LoggerService,
  ) { }

  async collectRelatedAssessments(relatedAssessmentIds: string[] = []): Promise<AiAssessmentSectionListItem[]> {
    const listItems: AiAssessmentSectionListItem[] = [];
    const promises = relatedAssessmentIds.map(async asessmentId => {
      const assessment = await this.selectAssessmentById(asessmentId);
      if (!assessment) {
        return;
      }
      const status = this.aiAssessmentStatusPipe.transform(assessment);
      const cmsTypes = this.contentPipe.transform('ai-assessments.types');
      const emptyValue = this.contentPipe.transform('pdf-generator.empty');
      const templateType = cmsTypes[AiAssessmentTemplateEnum[assessment.templateType]];
      return listItems.push({
        value: `${assessment.name} (${templateType})`,
        additional: `${status?.status?.value ?? emptyValue} - ${status.date}`,
      });
    });
    await Promise.all(promises);
    return listItems;
  }

  async collectExternalEntitiesData(
    externalRelatedEntities: AssessmentExternalEntity[] = [],
    hideCustomSystemIds: boolean = false,
    employees: EmployeeDataMapping[] = [],
  ): Promise<AiAssessmentSectionListItem[]> {
    const listItems: AiAssessmentSectionListItem[] = [];
    const imageUrls: string[] = [];
    externalRelatedEntities?.forEach(async ({ type, externalRelatedEntityId }) => {
      switch (type) {
        case AiAssessmentEntityTypeEnum.BusinessOwner:
          const employee = employees.find(({ id }) => id === externalRelatedEntityId);
          if (!employee) {
            return;
          }
          return listItems.push({
            value: `${employee.firstName} ${employee.lastName}`,
          });

        case AiAssessmentEntityTypeEnum.ProcessingActivity:
          const pa = this.processingActivitiesQuery.getEntity(externalRelatedEntityId);
          if (!pa) {
            return;
          }
          return listItems.push({
            value: pa.name,
          });

        case AiAssessmentEntityTypeEnum.System:
          const system = this.systemsQuery.getEntity(externalRelatedEntityId);
          if (!system) {
            return;
          }
          if (system.iconPath) {
            imageUrls.push(system.iconPath);
          }
          const additional =
            hideCustomSystemIds && (Object.values(SpecialSystemType) as string[]).includes(system.systemType)
              ? ''
              : system.systemId;
          return listItems.push({
            value: system.name,
            iconPath: system.iconPath,
            additional,
            serverLocations: system.serverLocations
          });

        default:
          const customValueEntity = this.customValuesQuery.getEntity(externalRelatedEntityId);
          if (!!customValueEntity) {
            return listItems.push({
              value: customValueEntity.name
            });
          }

          return listItems.push({
            value: externalRelatedEntityId,
          });
      }
    });
    return listItems;
  }

  public async buildInstanceForExport(
    page: AiAssessmentPageRequest,
    employees: EmployeeDataMapping[],
    ignoreIncompleteFields: boolean,
  ): Promise<AiAssessmentExportTemplateInstance> {
    const {
      name,
      sections,
      lastApprovedAt,
      type,
      externalRelatedEntities,
      relatedAssessmentIds,
      createdDate,
      reviewDate,
      state
    } = page;

    this.loggerService.info(this.loggerName, `Building instance "${name}" for export...`);

    const controls = await this.buildSectionsForExport(
      sections,
      externalRelatedEntities,
      relatedAssessmentIds,
      employees,
      ignoreIncompleteFields,
    );
    const owner = employees.find(({ id }) => id === page.ownerId);
    const parsedInstance = {
      name,
      ownerName: owner ? `${owner.firstName} ${owner.lastName}` : this.contentPipe.transform('pdf-generator.empty'),
      approvedAt: lastApprovedAt ?? null,
      templateType: type ?? null,
      controls,
      createdDate,
      state,
      reviewDate,
    };

    this.loggerService.info(this.loggerName, `Finished building instance "${name}" built for export.`);

    return parsedInstance;
  }

  private parseSectionData(section: AiAssessmentPageSection): { schema: AiAssessmentsFormControl[], data: any } {
    let data = {};
    let schema = {} as AiAssessmentsFormControl;
    try {
      data = JSON.parse(section.data);
    } catch (error) {
      this.loggerService.error(this.loggerName, `Error parsing section data for export: ${error.message}`);
      console.error(error);
    }
    try {
      schema = JSON.parse(section.schema);
    } catch (error) {
      this.loggerService.error(this.loggerName, `Error parsing section schema for export: ${error.message}`);
      console.error(error);
    }
    return this.selectSchema(schema, data);
  }

  private selectSchema(schema: AiAssessmentsFormControl | AiAssessmentsFormControl[], dataToSelect: any): { schema: AiAssessmentsFormControl[], data: any } {
    const data = dataToSelect;
    if (schema instanceof Array) {
      return { schema, data };
    }
    if (schema.type === AiAssessmentsFormControlTypeEnum.Group) {
      return { schema: schema.controls, data };
    }
    return { schema: [schema], data };
  }

  private async buildSectionsForExport(
    sections: AiAssessmentPageSection[],
    externalRelatedEntities: AssessmentExternalEntity[] = [],
    relatedAssessmentIds: string[] = [],
    employees: EmployeeDataMapping[],
    ignoreIncompleteFields: boolean = false,
  ): Promise<AiAssessmentExportTemplateSection[]> {
    const sectionsPromises = sections.map(async section => {
      const { name, label } = section;
      const { schema, data } = this.parseSectionData(section);
      const controls = await this.iterateControls(
        schema,
        data,
        externalRelatedEntities,
        relatedAssessmentIds,
        employees,
        ignoreIncompleteFields,
      );
      return { type: AiAssessmentsFormControlTypeEnum.Group, name, label, controls };
    });
    const sectionsWithControls = await Promise.all(sectionsPromises);
    if (!ignoreIncompleteFields) {
      return sectionsWithControls;
    }
    return sectionsWithControls.filter(({ controls }) => controls.length > 0);
  }

  private async iterateControls(
    controls: AiAssessmentsFormControl[],
    data: any,
    externalRelatedEntities: AssessmentExternalEntity[],
    relatedAssessmentIds: string[],
    employees: EmployeeDataMapping[],
    ignoreIncompleteFields: boolean,
  ): Promise<AiAssessmentExportTemplateSection[]> {
    if (!controls || controls?.length === 0) {
      return [];
    }
    const controlsToDisplay = controls.filter(({ type, name }) =>
      type !== AiAssessmentsFormControlTypeEnum.CustomComponent ||
      !SECTION_TYPES_TO_HIDE.includes(name)
    );
    const sectionPromises = controlsToDisplay.map(async (control) => {
      this.loggerService.debug(this.loggerName, `Building section "${control.name}"...`);
      if (control.type === AiAssessmentsFormControlTypeEnum.CustomComponent) {
        const section = await this.buildCustomSectionData(
          control,
          data,
          externalRelatedEntities,
          relatedAssessmentIds,
          employees,
        );
        this.loggerService.debug(this.loggerName, `Finished building custom section "${control.name}".`);
        return section;
      } else {
        const section = this.buildControlData(control, data);
        this.loggerService.debug(this.loggerName, `Finished building regular section "${control.name}".`);
        return section;
      }
    });
    const sections = await Promise.all(sectionPromises);
    if (!ignoreIncompleteFields) {
      return sections;
    }
    return sections.filter(section => !this.isSectionEmpty(section));
  }

  private buildControlData(control: AiAssessmentsFormControl, data: any): AiAssessmentExportTemplateSection {
    const { type, name, label, customFieldId } = control;
    if (customFieldId?.length > 0 && control.type === AiAssessmentsFormControlTypeEnum.Dropdown) {
      const values = (control.multiple ? control.value?.split(',')?.join(', ') : data[name]?.value) ?? '';
      return { type, name, label, value: { value: values } };
    }
    if (control.type === AiAssessmentsFormControlTypeEnum.Dropdown && typeof data[name] === 'string') {
      return { type, name, label, value: { value: data[name] } };
    }
    if (!data[name]) {
      return { type, name, label };
    }
    return { type, name, label, value: data[name] };
  }

  private async buildCustomSectionData(
    control: AiAssessmentExportTemplateSection,
    data: any,
    externalRelatedEntities: AssessmentExternalEntity[],
    relatedAssessmentIds: string[],
    employees: EmployeeDataMapping[],
  ): Promise<AiAssessmentExportTemplateSection> {
    const { type, name, label } = control;
    if (name === AiAssessmentCustomComponentEnum.Risks) {
      const riskData = this.buildRiskData(data);
      return { type, name, label, value: riskData };
    }
    if (name === AiAssessmentCustomComponentEnum.DataFlow) {
      const value = await this.mapDataFlowEntities(data[name], externalRelatedEntities);
      return { type, name, label, value };
    }
    if (name === AiAssessmentCustomComponentEnum.DpiaNeeded) {
      const value = [{ value: this.contentPipe.transform(data[name] ? 'ropa.dpiaEnable' : 'ropa.dpiaDisable') }]
      return { type, name, label, value };
    }
    if (name === AiAssessmentCustomComponentEnum.DataTypes) {
      const value = await this.mapDataTypes(data[name], externalRelatedEntities);
      return { type, name, label, value };
    }
    if (name === AiAssessmentCustomComponentEnum.PartiesWithAccess) {
      const value = [];
      if (data[name]?.type) {
        value.push({ value: data[name]?.type });
      }
      if (data[name]?.description) {
        value.push({ value: data[name]?.description });
      }
      return { type, name, label, value };
    }

    if (COMPONENTS_IN_MAPPER.includes(name as AiAssessmentCustomComponentEnum)) {
      const externalEntities = this.filterExternalEntitiesByCustomSection(externalRelatedEntities, name);
      const collectedCustomExternalEntities = this.collectCustomExternalEntitiesByCustomSection(externalRelatedEntities, name);
      const listItems = name === AiAssessmentCustomComponentEnum.LinkedAssessment
        ? await this.collectRelatedAssessments(relatedAssessmentIds)
        : await this.collectExternalEntitiesData([...externalEntities, ...collectedCustomExternalEntities], true, employees);
      return { type, name, label, value: listItems };
    }
    return this.buildControlData(control, data);
  }

  public filterExternalEntitiesByCustomSection(externalRelatedEntities: AssessmentExternalEntity[], customSectionType: string): AssessmentExternalEntity[] {
    return externalRelatedEntities.filter(system => ENTITY_TYPE_TO_SECTION_TYPE[system.type] === customSectionType) ?? [];
  }

  public collectCustomExternalEntitiesByCustomSection(externalRelatedEntities: AssessmentExternalEntity[], customSectionType: string): AssessmentExternalEntity[] {
    const entities = [] as AssessmentExternalEntity[];
    externalRelatedEntities.filter(entity => !entity.isDefault).forEach(entity => {
      if ([AiAssessmentEntityTypeEnum.CustomValue, AiAssessmentEntityTypeEnum.CustomField].includes(entity.type)) {
        const customValueEntity = this.customValuesQuery.getEntity(entity.externalRelatedEntityId);
        if (!!customValueEntity && ENTITY_TYPE_TO_SECTION_TYPE[AiAssessmentEntityTypeEnum[customValueEntity.type]] === customSectionType) {
          entities.push({
            isDefault: false,
            externalRelatedEntityId: entity.externalRelatedEntityId,
            type: AiAssessmentEntityTypeEnum[customValueEntity.type],
          } as AssessmentExternalEntity);
        }
      }
    });
    return entities;
  }

  private async mapDataFlowEntities(
    data: DataFlowEdge[],
    externalRelatedEntities: AssessmentExternalEntity[],
  ): Promise<DataFlowDataExport> {
    if (!data) {
      return {
        nodes: [],
        edges: [],
      };
    }
    const systemEntities = externalRelatedEntities.filter(({ type }) => type === AiAssessmentEntityTypeEnum.System);
    const regularSystems = await this.collectExternalEntitiesData(systemEntities);
    const filteredData = data?.filter(edge => edge.source && edge.destination);
    const customSystems = this.companySettingsQuery.getValue()?.inventory?.inventoryEntities?.map(({ id, name, serverLocations }) => ({
      value: name,
      additional: id,
      serverLocations,
      iconPath: null,
    }))?.filter(
      ({ additional: id }) => filteredData.some(({ destination, source }) => [destination.id, source.id].includes(id))
    );

    const systems = [...regularSystems, ...customSystems];

    const nodes = systems.map(system => ({
      id: system.value,
      label: system.value,
      dimension: {
        width: 80,
        height: 100,
      },
      meta: {
        type: DataFlowNodeTypeEnum.System,
        serverLocations: system?.serverLocations?.length ? system.serverLocations : [this.contentPipe.transform('ropa.noLocationSet')],
        iconPath: system?.iconPath
      }
    }));

    const edges: DataFlowEdgeExport[] = [];
    filteredData.forEach(edge => {
      const source = systems.find(({ additional }) => additional === edge.source.id);
      const target = systems.find(({ additional }) => additional === edge.destination.id);

      if (!source || !target || source.value === target.value) {
        return;
      }

      edges.push({
        id: `${source.value}-${target.value}`,
        source: source.value,
        target: target.value,
      });
    });

    return {
      nodes: Object.values(nodes),
      edges,
    }
  }

  private isSectionEmpty({ value }: AiAssessmentExportTemplateSection) {
    const isEmptyString = value === '' || value?.value === '';
    const isEmtpyArray = value instanceof Array && value.length === 0;
    const isEmptyObject = typeof value === 'object' && Object.keys(value).length === 0;
    const isEmptyDataFlow = value?.nodes && value.nodes.length === 0;

    return !value ||
      isEmptyString ||
      isEmtpyArray ||
      isEmptyObject ||
      isEmptyDataFlow;
  }

  private buildRiskData(data: any) {
    const riskData = typeof data?.risks === 'object' ? data.risks : data;
    if (typeof data !== 'object' || data instanceof Array) {
      return {};
    }

    const validRiskItems: Record<string, any> = {};
    Object.keys(riskData).forEach(key => {
      if (typeof riskData[key] !== 'object') {
        return;
      }
      const calculatedRisk = this.riskCalulationPipe.transform(
        riskData[key].severity?.id,
        riskData[key].likelihood?.id
      );
      const residualRisk = RiskRateEnum.Low;
      const accepted = this.contentPipe.transform(`pdf-generator.${riskData[key] ? 'yes' : 'no'}`)
      validRiskItems[key] = { ...riskData[key], calculatedRisk, residualRisk, accepted };
    });
    return validRiskItems;
  }
  
  private groupEntitiesById(entities: AiAssessmentSectionListItem[]): Record<string, AiAssessmentSectionListItem> {
    return entities.reduce((result, entity) => {
        const { additional } = entity;
        result[additional] = entity;
        return result;
    }, {} as Record<string, AiAssessmentSectionListItem>);
}

  private async mapDataTypes(
    data: { dataType: DataType, dataSources: DropdownOption[] }[], 
    externalRelatedEntities: AssessmentExternalEntity[]
  ): Promise<AiAssessmentSectionListItem[]> {
    if (!data || !data?.length) {
      return [];
    }
    const systemEntities = externalRelatedEntities.filter(({ type }) => type === AiAssessmentEntityTypeEnum.System);
    const systems = await this.collectExternalEntitiesData(systemEntities);
    const groupedSystems = this.groupEntitiesById(systems);
    const allSourcesPlaceholder = this.contentPipe.transform('ai-assessments.dataTypes.allSourcesPlaceholder');

    const customValues$ = this.customValuesQuery.selectCustomValuesGroupedByType().pipe(
      map(customValuesAll => customValuesAll.get(CustomValueTypeEnum.DataType))
    );
    const customValues = await firstValueFrom(customValues$);
    
    return data.map(({ dataType, dataSources }) => {
      const customValue = customValues.find(({ id }) => id === dataType.id);
      const value = customValue?.name ?? dataType.name;
      const systemNames = dataSources.map(ds => groupedSystems[ds.id]?.value);

      let joinedSystemNames = '';
      if(dataSources.length > 0 && systems.length === dataSources.length) {
        joinedSystemNames = allSourcesPlaceholder;
      } else {
        joinedSystemNames = systemNames.filter(sn => !!sn)?.join(', ');
      }

      return { 
        value, 
        additional: joinedSystemNames
      };
    });
  }

  selectAssessments(asArray: boolean = false): Observable<Map<string, AiAssessmentInstance> | AiAssessmentInstance[]> {
    if (asArray) {
      return this.store.select('assessments').pipe(
        map(assessments => Array.from(new Set([...assessments.values()])))
      );
    }
    return this.store.select('assessments');
  }

  selectAssessmentById(id: string): Promise<AiAssessmentInstance> {
    return firstValueFrom(this.selectAssessments().pipe(
      map((assessmentMap: Map<string, AiAssessmentInstance>) => assessmentMap.get(id)),
    ));
  }

  public imageToBase64(url: string): Promise<string> {
    return new Promise((resolve) => {
      const xhr = new XMLHttpRequest();
      xhr.onload = () => {
        const reader = new FileReader();
        reader.onloadend = () => {
          resolve(reader.result.toString());
        }
        reader.onerror = () => {
          this.loggerService.error(this.loggerName, `Error converting image to base64: ${url}.`);
          resolve('');
        }
        reader.readAsDataURL(xhr.response);
      };
      xhr.open('GET', url);
      xhr.responseType = 'blob';
      xhr.send();
    });
  }

  async imagesToBase64ByUrl(urls: string[]): Promise<Record<string, string>> {
    const imagePromises = urls.map(url => this.imageToBase64(url));
    const imagesInBase64 = await Promise.all(imagePromises);
    const imagesByUrl: Record<string, string> = {};
    urls.forEach((url, index) => {
      imagesByUrl[url] = imagesInBase64[index];
    });
    return imagesByUrl;
  }

  extractImagesInBase64(instances: AiAssessmentExportTemplateInstance[]): Promise<Record<string, string>> {
    const imageUrls: Set<string> = new Set();
    instances.forEach(instance => {
      instance.controls.forEach(({ controls }) => {
        controls.forEach(({ value }) => {
          if (value instanceof Array) {
            const filtered = value.filter(({ iconPath }) => !!iconPath).map(({ iconPath }) => iconPath);
            filtered.forEach(url => imageUrls.add(url));
          }
          if (value?.nodes) {
            const filtered = value.nodes.filter(({ meta }) => meta.iconPath).map(({ meta }) => meta.iconPath);
            filtered.forEach(url => imageUrls.add(url));
          }
        });
      });
    });
    return this.imagesToBase64ByUrl(Array.from(imageUrls));
  }

  async getBusinessOwnerData(pages: AiAssessmentPageRequest[], additionalEmployeeIds: string[] = []): Promise<EmployeeDataMapping[]> {
    const employeeIds = pages.reduce((allIds, { externalRelatedEntities }) => {
      const instanceEmployeeIds = externalRelatedEntities
      ?.filter(({ type }) => type === AiAssessmentEntityTypeEnum.BusinessOwner)
      .map(({ externalRelatedEntityId }) => externalRelatedEntityId);
      if (instanceEmployeeIds?.length > 0) {
        instanceEmployeeIds.forEach(id => allIds.add(id));
      }
      return allIds;
    }, new Set<string>(additionalEmployeeIds));
    const uniqueEmployeeIds = Array.from(employeeIds);
    if (uniqueEmployeeIds.length === 0) {
      return [];
    }
    return firstValueFrom(this.employeesService.getEmployeesByIds(uniqueEmployeeIds));
  }
}