import { Injectable } from '@angular/core';
import { ParamMap } from '@angular/router';
import { Observable, of, tap, map } from 'rxjs';

import { ApiClientDataMappingService } from '../api/api-client-data-mapping.service';
import { ApiClientDiscoveryService } from '../api/api-client-discovery-tools.service';
import { CallbackResponse } from '../api/models/auth/callback-response';
import { RoutesManager } from '../shared/models/routes.interfaces';
import { DiscoveryToolsStatus, DiscoveryToolConnection } from './discovery-tools-home/discovery-section.interface';
import { AwsCloudConfig, GcpConfig, OktaSsoConfig, RegionsResponse } from '../api/models/discovery-tools/discovery-tools.intrface';
import { GoogleDataMappingGroupResponse, GoogleDirectoryGroupsRequest } from '../api/models/data-mapping/data-mapping.interface';

@Injectable({
  providedIn: 'root'
})
export class DiscoveryToolsService {
  
  private discoveryToolsStatus: DiscoveryToolsStatus;
  
  constructor(
    private apiClientDiscovery: ApiClientDiscoveryService,
    private apiClientDataMappingService: ApiClientDataMappingService,
  ) { }

  connectSSO(): Observable<string> {
    const redirectUrl = `${window.location.href}?${RoutesManager.gmailCallback}=true`;
    return this.apiClientDiscovery.getSSOUrl(redirectUrl);
  }

  ssoConnectionCallback(queryParams: ParamMap): Observable<CallbackResponse> {
    const redirectURL = `${window.location.protocol}//${window.location.host}${window.location.pathname}?${RoutesManager.gmailCallback}=true`;
    return this.apiClientDiscovery.ssoConnectionCallback(queryParams, redirectURL);
  }

  getDiscoveryToolsStatus(fetchFromServer = false): Observable<DiscoveryToolsStatus> {
		if (this.discoveryToolsStatus && !fetchFromServer) {
      return of(this.discoveryToolsStatus);
		}

		return this.apiClientDiscovery.getDiscoveryToolsStatus().pipe(
      tap((res) => (this.discoveryToolsStatus = res)));
	}

  autoDiscoveryEnabled(): Observable<boolean> {
    const discoveryStatus$ = this.getDiscoveryToolsStatus();

    return discoveryStatus$.pipe(
      map((status) => this.checkDiscoveryEnabled(status)),
    );
	}

  private checkDiscoveryEnabled(status: DiscoveryToolsStatus): boolean {
    return Object.values(status).some(tools => Object.values(tools).some((tool: DiscoveryToolConnection) => tool.connected));
  }

  googleEmailCallback(queryParams: ParamMap): Observable<CallbackResponse> {
    const redirectURL = `${window.location.protocol}//${window.location.host}/${RoutesManager.discovery}/${RoutesManager.discoveryToolsEmail}GoogleCallback/`;
    return this.apiClientDiscovery.googleEmailCallback(queryParams, redirectURL);
  }

  connectGoogleWorkspace(): Observable<string> {
    const redirectUrl = `${window.location.protocol}//${window.location.host}/${RoutesManager.discovery}/${RoutesManager.discoveryToolsEmail}GoogleCallback/`;
    return this.apiClientDiscovery.connectGoogleWorkspace(redirectUrl).pipe(map(res => res.loginLink));
  }

  getMicrosoftAdminInstallResult(code: string): Observable<void> {
    const redirectURL = `${window.location.protocol}//${window.location.host}/${RoutesManager.discovery}/${RoutesManager.discoveryToolsEmail}MicrosoftCallback/`;
		return this.apiClientDataMappingService.getAdminInstallResult(code, redirectURL);
	}

  getAzureAdminConnectResult(code: string): Observable<void> {
    const redirectURL = `${window.location.protocol}//${window.location.host}/${RoutesManager.discovery}/EntraCallback/`;
    return this.apiClientDataMappingService.getAzureConnectResult(code, redirectURL);
  }

  getAwsRegions(): Observable<RegionsResponse> {
    return this.apiClientDiscovery.getAwsRegions();
  }

  getAwsCloudConfiguration(): Observable<AwsCloudConfig> {
    return this.apiClientDiscovery.getAwsCloudConfiguration();
  }

  saveAwsCloudConfiguration(awsConfig: AwsCloudConfig): Observable<void> {
      return this.apiClientDiscovery.saveAwsCloudConfiguration(awsConfig);
  }

  startAwsCloudScan(): Observable<void> {
    return this.apiClientDiscovery.startAwsCloudScan();
  }

  getOktaSsoConfiguration(): Observable<OktaSsoConfig> {
    return this.apiClientDiscovery.getOktaSsoConfiguration();
  }

  saveOktaSsoConfiguration(oktaSso: OktaSsoConfig): Observable<void> {
    return this.apiClientDiscovery.saveOktaSsoConfiguration(oktaSso);
  }

  getGcpLoginUrl(organization: string): Observable<string> {
    const redirectURL = `${window.location.protocol}//${window.location.host}/${RoutesManager.discovery}/${RoutesManager.discoveryToolsCloudHostingGcpCallback}/`;
    return this.apiClientDiscovery.getGcpLoginUrl(redirectURL, false, organization).pipe(map(res => res.loginLink));
  }

  gcpCallback(queryParams: ParamMap): Observable<CallbackResponse> {
    const redirectURL = `${window.location.protocol}//${window.location.host}/${RoutesManager.discovery}/${RoutesManager.discoveryToolsCloudHostingGcpCallback}/`;
    return this.apiClientDiscovery.gcpLoginCallback(queryParams, redirectURL);
  }

  getGcpConfiguration(): Observable<GcpConfig> {
    return this.apiClientDiscovery.getGcpConfiguration();
  }

  getAvailableGroups(query: string): Observable<GoogleDataMappingGroupResponse> {
    return this.apiClientDataMappingService.getAvailableGroups(query);
  }

  getGroupsWhitelist(): Observable<GoogleDataMappingGroupResponse> {
		return this.apiClientDataMappingService.getGroupsWhitelist();
	}

	setGroupsWhitelist(request: GoogleDirectoryGroupsRequest): Observable<GoogleDataMappingGroupResponse> {
		return this.apiClientDataMappingService.setGroupsWhitelist(request);
	}

	updateGroupsWhitelist(request: GoogleDirectoryGroupsRequest): Observable<GoogleDataMappingGroupResponse> {
		return this.apiClientDataMappingService.updateGroupsWhitelist(request);
	}
}
