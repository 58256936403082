export enum PaToggleFilter {
    Enabled = "enabled",
    Disabled = "disabled",
}

export enum PaFilteringGroups {
    DataFlow = 'dataFlow',
    Dpia = 'dpia',
    Roles = 'roles',
    PaStatus = 'paStatus',
    Functions = 'functions',
}

export enum PaFilteringCategoryEnum {
    All = 'all',
    Dpia = 'dpia',
    DataFlow = 'dataFlow',
}

export enum PaStatusEnum {
    Completed = 'Completed',
    NotCompleted = 'NotCompleted',
}