import { Injectable } from '@angular/core';
import { BehaviorSubject, first, from, map, Observable, tap } from "rxjs";
import { JwtTokenObject } from "../../api/models/auth/jwt-token-object";
import { LoggerService } from "../../logger/logger.service";
import { ApiClientIpaasService } from "../../api/api-client-ipaas.service";
import prismatic, {
    closePopover,
    ConfigureInstanceProps,
    getMessageIframe,
    PrismaticMessageEvent
} from "@prismatic-io/embedded";
import { ConfigVars } from "@prismatic-io/embedded/dist/types/configVars";
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class IntegrationIpaasService {
    private readonly loggerName: string = 'IntegrationIpaasService';

    constructor(
        private logger: LoggerService,
        private apiClientIpaasService: ApiClientIpaasService
    ) {
    }

    private tokenObj: JwtTokenObject;

    private listenerConfigurationLoaded: (message: MessageEvent) => void;

    private updateStatus: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    updateStatus$: Observable<string> = this.updateStatus.asObservable();

    init(): void {
        this.logger.debug(this.loggerName, 'init()');
        prismatic.init({
            fontConfiguration: {
                google: {
                    families: ["Lato"],
                },
            },
            prismaticUrl: environment.prismatic.url
        });
    }

    generateIpaasToken(): Observable<JwtTokenObject> {
        return this.apiClientIpaasService.generateIpaasToken().pipe(
            first(),
            tap(res => this.tokenObj = res),
            tap(() => this.logger.debug(this.loggerName, 'Generated IPAAS token'))
        );
    }

    authenticate(): void {
        if (this.tokenObj?.authToken) {
            this.authToPrismatic(this.tokenObj.authToken)
                .then(() => this.logger.debug(this.loggerName, 'Authenticated with Prismatic'))
                .catch(() => {
                    this.logger.error(this.loggerName, 'Error authenticating with Prismatic');
                    this.generateTokenAndAuth();
                });
        } else {
            this.generateTokenAndAuth();
        }
    }

    private generateTokenAndAuth(): void {
        this.generateIpaasToken()
            .pipe(
                first(),
                tap(() => this.authToPrismatic(this.tokenObj.authToken))
            )
            .subscribe();
    }

    private authToPrismatic(token: string): Promise<void> {
        return prismatic.authenticate({token})
    }

    removeJwtToken(): void {
        this.tokenObj = undefined;
    }

    configureInstance(instanceId: string): void {
        const config: ConfigureInstanceProps = {
            instanceId,
            skipRedirectOnRemove: true,
            usePopover: true,
            screenConfiguration: {
                isInPopover: true,
                configurationWizard: {
                    isInModal: true,
                    triggerDetailsConfiguration: "hidden"
                },
                instance: {
                    hideBackToMarketplace: true,
                    hidePauseButton: true,
                    hideTabs: ["Logs", "Test", "Executions", "Monitors"]
                }
            }
        };

        prismatic.configureInstance(config);
    }

    setConfigVars(message: MessageEvent, configVars: ConfigVars): void {
        const iframe = getMessageIframe(message);
        prismatic.setConfigVars({iframe, configVars});
    }

    initializeEventListenerConfigLoaded(instanceIntegrationId: string, mineIntegrationId: string): void {
    this.removeEventListenerConfigLoaded();

        this.listenerConfigurationLoaded = (message: MessageEvent) => {
            const { event, data } = message.data;

            switch (event) {
                case PrismaticMessageEvent.INSTANCE_CONFIGURATION_OPENED:
                    this.setConfigVars(message, {
                        "mine-integration-instance-id": {value: instanceIntegrationId}
                    });
                    break;
                case PrismaticMessageEvent.INSTANCE_DELETED:
                case PrismaticMessageEvent.INSTANCE_CONFIGURATION_CLOSED:
                    this.updateStatus.next(data.instanceName);
                    closePopover();
                    break;
            }
        };

        setTimeout(() => this.addEventListenerConfigLoaded(), 0);
    }

    addEventListenerConfigLoaded(): void {
        window.addEventListener("message", this.listenerConfigurationLoaded);
    }

    removeEventListenerConfigLoaded(): void {
        window.removeEventListener("message", this.listenerConfigurationLoaded);
    }

    getInstanceStatus(instanceId: string): Observable<boolean> {
        return from(prismatic.graphqlRequest({
            query: `query getInstancesStatus {
                        instances {
                            nodes {
                              id
                              enabled
                            }
                        }
                    }
                `,
        })).pipe(
            map(res => res.data.instances?.nodes?.find(node => node.id === instanceId)?.enabled)
        )
    }
}
