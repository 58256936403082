import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { SystemsDirectoryItem } from 'src/app/api/models/systems-directory/systems-directory.interface';

import { produce } from 'immer';

export interface SystemsDirectoryState extends EntityState<SystemsDirectoryItem, string> { }

@Injectable({
	providedIn: 'root',
})
@StoreConfig({
	name: 'systemsDirectory',
	idKey: 'id',
	producerFn: produce
})
export class SystemsDirectoryStore extends EntityStore<SystemsDirectoryState> {
	constructor() {
		super();
	}
}