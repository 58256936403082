import { Injectable } from '@angular/core';
import { BehaviorSubject, EMPTY, Observable, tap, first } from 'rxjs';
import { ApiClientUserSearchService } from '../api/api-client-user-search.service';
import { IntegrationPreviewResponse, IntegrationRecordsResponse } from '../api/models/integrations/integrations.interface';
import { DataSource, DataSourceIndication } from '../requests/request-ticket-v2/ticket-steps/ticket-steps-processing/data-source.inteface';
import { IndicationTypeEnum, IntegrationStateResponseEnum } from '../requests/request-ticket-v2/ticket-steps/ticket-steps-processing/ticket-steps-processing.enums';

@Injectable({
  providedIn: 'root'
})
export class UserSearchService {
  private indicationsMap = new Map<string, BehaviorSubject<DataSourceIndication>>(); // integrationId + subject
  integrationsWithPreview: DataSource[] = [];

  constructor(private userSearchApi: ApiClientUserSearchService) { }

  fetchUserData(email: string, dataSources: DataSource[]): void { 
    dataSources.forEach(dataSource => {
      this.userSearchApi.fetchUserData(dataSource.id, email).pipe(
        first(),
        tap(response => this.updateFetchingIndication(dataSource, response)),
        tap(response => this.handleIntegrationForPreview(response, dataSource)),
      ).subscribe();
    });
  }

  private handleIntegrationForPreview(response: IntegrationRecordsResponse, dataSource: DataSource): void {
    if (response.state === IntegrationStateResponseEnum.Completed && response.recordCount > 0 ) {
      this.integrationsWithPreview.push(dataSource);
    }
  }
ב
  // update indication for fetching
  private updateFetchingIndication(dataSource: DataSource, dataSourceApiRes: IntegrationRecordsResponse): void {
    this.indicationsMap.get(dataSourceApiRes.integrationId).next({...dataSource, ...dataSourceApiRes});
	}

  initIntegrationsSearch(dataSources: DataSource[]): void {
    dataSources.forEach(dataSource => {
      this.indicationsMap.set(dataSource.id, 
        new BehaviorSubject<DataSourceIndication>({...dataSource, indicationType: IndicationTypeEnum.FetchingData, stateOnlyFetch: true }));
			});
	}
  
  getIndicationById(id: string): Observable<DataSourceIndication> {
    return this.indicationsMap.get(id) ? this.indicationsMap.get(id).asObservable() : EMPTY;
  }

  previewUserData(integrationId: string, email: string): Observable<IntegrationPreviewResponse> {
    return this.userSearchApi.previewUserData(integrationId, email);
  }
}
