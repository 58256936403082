import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Policy } from '../../api/models/policies/policies.interface';

export interface PoliciesState extends EntityState<Policy, string> { }

@Injectable({
	providedIn: 'root'
})

@StoreConfig({ name: 'policies', idKey: 'id'})
export class PoliciesStore extends EntityStore<PoliciesState> {
  constructor() {
    super() ;
  }
}