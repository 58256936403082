import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';

import { CustomValuesState, CustomValuesStore } from './custom-values.store';
import { Observable, combineLatest, map } from 'rxjs';
import { CustomValueTypeEnum } from 'src/app/api/models/company-settings/custom-values.enum';
import { CustomValue } from 'src/app/api/models/company-settings/custom-values.interface';
import { FeatureFlagQuery } from 'src/app/feature-flag/state/feature-flag.query';
import { FeatureFlags } from 'src/app/api/models/profile/profile-feature-flags.enum';

@Injectable({ 
	providedIn: 'root' 
})
export class CustomValuesQuery extends QueryEntity<CustomValuesState> {

	constructor(protected store: CustomValuesStore,
				private featureFlagQuery: FeatureFlagQuery) {
		super(store);
	}

	selectCustomValuesByType(type: CustomValueTypeEnum): Observable<CustomValue[]> {
		return this.selectAll({
			filterBy: [
				entity => entity.type === type,
			],
		});
	}

	getCustomValuesByType(type: CustomValueTypeEnum): CustomValue[] {
		return this.getAll({
			filterBy: [
				entity => entity.type === type,
			],
		});
	}

	getServerLocationsByEntityId(id: string): string[] {
		return this.getAll({
			filterBy: [
				entity => entity.type === CustomValueTypeEnum.PaEntity,
			],
		})?.find(i => i.id === id)?.additionalData.serverLocations ?? [];
	}

	isCustomValueNameExists(type: CustomValueTypeEnum, name: string, id?: string): boolean {
		return this.getAll({
			filterBy: [
				entity => id ? entity.type === type && entity.id !== id && entity.name.toLowerCase() == name.toLowerCase() :
							entity.type === type && entity.name.toLowerCase() == name.toLowerCase(),
			],
		}).length > 0;
	}

	selectCustomValuesGroupedByType(): Observable<Map<CustomValueTypeEnum, CustomValue[]>> {
		const valuesV2FF = this.featureFlagQuery.getFlag(FeatureFlags.DevCustomValuesV2);
		let types = [CustomValueTypeEnum.BusinessUnit, CustomValueTypeEnum.LegalBasis, CustomValueTypeEnum.TransferMechanism];

		if (valuesV2FF) {
			types = [...types, CustomValueTypeEnum.Mitigation, CustomValueTypeEnum.DataSubject, CustomValueTypeEnum.DataType, CustomValueTypeEnum.PaEntity];
		}

		return combineLatest(types.map(c => this.selectCustomValuesByType(c)))
			.pipe(
				map(res => this.createCustomValueMap(res, valuesV2FF))
			);
	}

	private createCustomValueMap(values: CustomValue[][], customValuesV2: boolean): Map<CustomValueTypeEnum, CustomValue[]> {
		const customValuesMap = new Map<CustomValueTypeEnum, CustomValue[]>([
			[CustomValueTypeEnum.BusinessUnit, values[0]],
			[CustomValueTypeEnum.LegalBasis, values[1]],
			[CustomValueTypeEnum.TransferMechanism, values[2]],
		]);

		if (customValuesV2) {
			customValuesMap.set(CustomValueTypeEnum.Mitigation, values[3]);
			customValuesMap.set(CustomValueTypeEnum.DataSubject, values[4]);
			customValuesMap.set(CustomValueTypeEnum.DataType, values[5]);
			customValuesMap.set(CustomValueTypeEnum.PaEntity, values[6]);
		}

		return customValuesMap;
	}
	
}
