import { CanMatchFn, Route, Router, UrlSegment, UrlTree } from "@angular/router";
import {  filter, map, Observable, tap } from "rxjs";
import { inject } from "@angular/core";

import { DefaultOnboardingFlow } from "../onboarding/onboarding-manager/onboarding-flows/flows";
import { LocalStorageHelper, RedirectUrl } from "../services/localStorage-helper";
import { RoutesManager } from "../shared/models/routes.interfaces";
import { AuthProviders } from "../api/models/auth/auth-providers";
import { ProfileQuery } from "../profile/state/profile.query";
import { LoggerService } from "../logger/logger.service";
import { AuthService } from "./auth.service";

const loggerName: string = 'AuthGuard';

const AuthGuardFn = (route: Route, segments: UrlSegment[]): boolean | UrlTree => {
    const authService = inject(AuthService);
    return authService.isAuthenticated() ? true : redirectUnauthorizedUsers(segments);
}

const CompanyVerificationGuardFn = (route: Route, segments: UrlSegment[]): Observable<boolean | UrlTree> => {
    const router = inject(Router);
    const logger = inject(LoggerService);
    const profileQuery = inject(ProfileQuery);

    return profileQuery.select('verifiedToCompany').pipe(
        filter(verified => verified !== undefined),
        tap(response => logger.debug(loggerName, `Verified = ${response}`)),
        map(response => response ? true : router.parseUrl(LocalStorageHelper.getOnboardingRedirect()?.url[0] ?? DefaultOnboardingFlow.postSignupStep.path))
    );
}

const redirectUnauthorizedUsers = (segments: UrlSegment[]): UrlTree => {
    const router = inject(Router);
    const logger = inject(LoggerService);
    const onboardingStartedProvider = LocalStorageHelper.getOnboardingStarted();

    if (!LocalStorageHelper.getRedirect()) {
        const redirectUrl: RedirectUrl = { 
            url: segments?.map(segment => segment.path) ?? [''], 
            queryParams: router.getCurrentNavigation().initialUrl.queryParams 
        };
        logger.debug(loggerName, `Stored redirectUrl: ${redirectUrl}`);
        LocalStorageHelper.setRedirect(redirectUrl);
    }
    
    const updatedRoute = !onboardingStartedProvider || onboardingStartedProvider === AuthProviders.auth0 ? RoutesManager.login : RoutesManager.signup;
    return router.parseUrl(updatedRoute);
}

export const AuthGuard: CanMatchFn = AuthGuardFn;
export const CompanyVerificationGuard: CanMatchFn = CompanyVerificationGuardFn;