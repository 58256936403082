<h1 class="mine-title-m dialog__title">
	{{ 'ticket.editRequestTypeDialogTitle' | content }}
</h1>

<mine-dropdown *ngIf="dropdownOptions$ | async as dropdownOptions"
    class="dialog__select-ticket-type"
    [formControl]="ticketTypeCtrl"
    id="ticket-type"
    [height]="'193px'"
    [placeholder]="'ticket.editRequestTypeDialogPlaceholder' | content"
    [options]="dropdownOptions">
    <span mine-dropdown-label>{{ 'ticket.editRequestTypeDialogLabel' | content }}</span>
</mine-dropdown>

<div class="dialog__buttons">
	<mine-button-tertiary class="dialog__btn" (onClick)="cancel()">
		{{ 'common.cancelBtn' | content }}
	</mine-button-tertiary>

	<mine-button-primary class="dialog__btn" [disabled]="!ticketTypeCtrl.value" (onClick)="save()">
		{{ 'ticket.updateBtn' | content }}
	</mine-button-primary>
</div>