import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';

export interface ContentState extends EntityState<any, string> {}

@Injectable({
	providedIn: 'root',
})
@StoreConfig({
	name: 'content',
})
export class ContentStore extends EntityStore<ContentState> {
	
	constructor() {
		super({});
	}
	
}
