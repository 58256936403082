import { ChangeDetectorRef, DestroyRef, Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { RoleEnum } from '../../core/models/permission-role.enum';
import { tap } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { OnInit } from '@angular/core';
import { PermissionsService } from 'src/app/auth/permissions.service';

@Directive({
  standalone: true,
  selector: '[minePermissions]',
})
export class MinePermissionsDirective implements OnInit {  
  private allowedRoles: RoleEnum[] = [];

  private elseTemplateRef: TemplateRef<Element>;

  constructor(
    private destroyRef: DestroyRef,
    private cdref: ChangeDetectorRef,
    private templateRef: TemplateRef<Element>,
    private viewContainer: ViewContainerRef,
    private permissionsService: PermissionsService,
  ) { }

  ngOnInit(): void {
    this.permissionsService.selectIsAllowed(this.allowedRoles).pipe(
      tap(isAllowed => this.updateView(isAllowed)),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe();  
  }

  @Input()
  set minePermissions(roles: RoleEnum[]) {
    this.allowedRoles = roles;
  }

  @Input()
  set minePermissionsElse(templateRef: TemplateRef<Element>) {
    if (templateRef) {
      this.elseTemplateRef = templateRef;
    }
  }

  private updateView(isAllowed: boolean): void {
    if (isAllowed) {
      this.viewContainer.createEmbeddedView(this.templateRef);
      this.cdref.detectChanges();
      return;
    }
    
    if (this.elseTemplateRef) {
      this.viewContainer.createEmbeddedView(this.elseTemplateRef);
    } else {
      this.viewContainer.clear();
    }
    this.cdref.detectChanges();
  }
}