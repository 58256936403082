<a class="sidenav__mine-logo sidenav__mine-logo--clickable" routerLink="/{{routesManager.homePage}}"
    (click)="logAnalyticsEvent(analyticEventsConsts.SIDEBAR_progrees_bar)">
    <img class="sidenav__mine-logo-image" src="{{assetsUrl}}/Portal/mine-os-white-logo.svg">
    <div class="sidenav__mine-logo-image-hidden"></div>
</a>

<div class="sidenav__items-wrapper">

    <section class="sidenav__section" [class.sidenav__section--shrink]="shrink">
        <ng-container *minePermissions="homePermittedrRoles">
            <onboarding-progress-bar *ngIf="!finishedTasks" class="sidenav__progress-bar" 
            (progressFinished)="collapseProcessBar()" [@collapse] [shrink]="shrink" (click)="navigateToHome()">
            </onboarding-progress-bar>
        </ng-container>

        <app-sidenav-content [shrink]="shrink"></app-sidenav-content>
    </section>

</div>

<section class="sidenav__section sidenav__section--sticky">
    <ng-container *minePermissions="whatsNewPermittedrRoles">
        <div *ngIf="beamerNotificationsFF" class="beamerTrigger sidenav__button" [attr.data-label]="'menu.whatsNew' | content">
            <svg [attr.data-label]="'menu.whatsNew' | content" class="sidenav__svg">
                <use xlink:href="../../../assets/img/sprite.svg#icon-bell"></use>
            </svg>
            <span class="sidenav__link-text hide"> {{ 'menu.whatsNew' | content }} </span>
        </div>
    </ng-container>

    <a [attr.data-label]="'menu.help' | content" href="javascript:void(0)" (click)="onHelpClick()" class="sidenav__button sidenav__mt-auto" 
        [tp]="'menu.help' | content" [tpIsEnabled]="shrink" [tpPlacement]="tpProps.placement">
        <svg [attr.data-label]="'menu.help' | content" class="sidenav__svg">
            <use xlink:href="../../../assets/img/sprite.svg#icon-info-tooltip"></use>
        </svg>
        <span class="sidenav__link-text hide"> {{ 'menu.help' | content }} </span>
    </a>

    <a *ngIf="!beamerNotificationsFF" [attr.data-label]="'menu.addMember' | content" (click)="onAddMember()" class="sidenav__button" 
        [tp]="'menu.addMember' | content" [tpIsEnabled]="shrink" [tpPlacement]="tpProps.placement">
        <svg [attr.data-label]="'menu.addMember' | content" class="sidenav__svg sidenav__svg--add-member sidenav__svg--enabled">
            <use xlink:href="../../../assets/img/sprite.svg#icon-Add-member"></use>
        </svg>
        <span class="sidenav__link-text hide"> {{ 'menu.addMember' | content }} </span>
    </a>

</section>

<div class="sidenav__app-menu-container">
    <div class="sidenav__shrink-btn" [class.sidenav__shrink-btn--open]="!alwaysOpen" (click)="toggle()">
        <svg class="sidenav__shrink-btn-svg">
            <use xlink:href="../../../assets/img/sprite.svg#icon-Arrow-right"></use>
        </svg>
    </div>

    <app-menu class="sidenav__app-menu"></app-menu>
    <div class="sidenav__app-menu-hidden"></div>
</div>