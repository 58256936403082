import { Injectable } from '@angular/core';

import { DataLayerCustomDimensionsSet } from '../api/models/analytics/data-layer-custom-dimensions-set';
import { DataLayerCustomDimensions } from '../api/models/analytics/data-layer-custom-dimensions';

@Injectable({
	providedIn: 'root'
})
export class DataLayerService {
	private currentDimension: DataLayerCustomDimensionsSet;

	constructor() { 
		this.generateEmptyDimensionsSet();
	}

	private generateEmptyDimensionsSet(): void {
		this.currentDimension = {
			[DataLayerCustomDimensions.PropertyId]: undefined,
			[DataLayerCustomDimensions.UserID]: undefined
		};
	}

	set dimensions(newDimesions: DataLayerCustomDimensionsSet) {
		Object.keys(DataLayerCustomDimensions).map(key => DataLayerCustomDimensions[key])
			.forEach(key => {
				this.currentDimension[key] = newDimesions[key];
			});
	}

	trigger(): void {
		(<any>window).dataLayer.push(this.currentDimension);
	}
}
