<h1 class="mine-title-m add-lang__title">
	{{ 'templates-localization.addLanguagePopupTitle' | content }}
</h1>
<ul class="add-lang__languages-container">
	<li
		*ngFor="let lang of locales.values()"
		(click)="chooseLanguage(lang)"
		class="add-lang__option"
		[class.add-lang__option--active]="lang.localizationCode === selectedLanguage"
		[class.add-lang__option--disabled]="lang.configured"
		[tp]="'templates-localization.languageAlreadyChosen' | content"
		[tpIsEnabled]="lang.configured"
		[tpOffset]="[0, 10]">
	
		{{ lang.nameForDisplay }}
	</li>
</ul>
<div class="add-lang__btns">
	<button mine-button color="secondary" class="add-lang__btn" (click)="cancelDialog()">
		{{ 'templates-localization.cancelBtn' | content }}
	</button>
	
	<button mine-button color="primary" (click)="addLanguage()" class="add-lang__btn add-lang__main-btn" [disabled]="!selectedLanguage" [spinner]="showSpinner">
		{{ 'templates-localization.mainBtn' | content }}
	</button>
</div>
