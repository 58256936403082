export enum AiAssessmentEntityTypeEnum {
    System = 'System',
    BusinessPurpose = 'BusinessPurpose',
    ProcessingActivity = 'ProcessingActivity',
    BusinessUnit = 'BusinessUnit',
    DataSubject = 'DataSubject',
    DataRole = 'DataRole',
    LegalBasis = 'LegalBasis',
    RiskMitigation = 'RiskMitigation',
    TransferMechanism = 'CrossBorder',
    BusinessOwner = 'BusinessOwner',
    PartiesWithAccess = 'PartiesWithAccess',
    LinkedAssessment = 'LinkedAssessment',
    DataType = 'DataType',
    CustomValue = 'CustomValue',
    CustomField = 'CustomField',
}