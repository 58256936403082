import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { catchError, concatMap, filter, first, switchMap, tap } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';

import { SystemsService } from 'src/app/systems/state/systems.service';
import { IntegrationConfig } from 'src/app/api/models/integrations/integrations.interface';
import { MineSnackbarService } from 'src/app/shared/mine-snackbar/mine-snackbar.service';
import { MineSnackbarType } from 'src/app/shared/mine-snackbar/mine-snackbar-type';
import { LoggerService } from 'src/app/logger/logger.service';
import { ContentPipe } from 'src/app/services/content/content.pipe';
import { SystemsQuery } from 'src/app/systems/state/systems.query';
import { RoutesManager } from 'src/app/shared/models/routes.interfaces';

@Component({
  selector: 'integration-oauth-callback',
  templateUrl: './integration-oauth-callback.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IntegrationOauthCallbackComponent implements OnInit {

  private readonly loggerName: string = 'IntegrationOauthCallbackComponent';

  private state: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private logger: LoggerService,
    private snackbarService: MineSnackbarService,
    private systemsService: SystemsService,
    private systemsQuery: SystemsQuery,
    private contentPipe: ContentPipe
  ) { }

  ngOnInit(): void {
    this.route.queryParamMap.pipe(
      first(),
      tap(p => this.state = p.get('state')),
      switchMap(paramMap => this.systemsService.oauthCallback(paramMap)),
      switchMap((config: IntegrationConfig) => this.handleOauthResponse(config?.authenticated)),
      catchError(error => this.oauthAuthenticationFailure(error)),
    ).subscribe();
  }

  private oauthAuthenticationFailure(error): Observable<boolean> {
    this.logger.error(this.loggerName, `Failed to authenticate ${this.state}: ${error}`);
    return this.handleOauthResponse(false);
  }

  private handleOauthResponse(authenticated: boolean): Observable<boolean> {
    return this.systemsQuery.selectLoading().pipe(
      filter(loading => !loading),
      switchMap(() => this.systemsService.selectSystemInstanceById(this.state.split('~')[1])),
      concatMap(system => this.router.navigate([RoutesManager.systems, system.pathFriendlyId], { state: { integrationId: this.state.split('~')[3], callbackSuccess: true }})),
      first(),
      tap(() => this.showSnackbar(authenticated))
    );
  }

  private showSnackbar(authenticated: boolean) {
    if (authenticated === true) {
      this.snackbarService.showTimed(MineSnackbarType.Confirmation, `${this.contentPipe.transform('integrations-oauth.formSuccess')} ${this.contentPipe.transform('integrations-oauth.formConnected')}`, true);
    }
    else {
      this.snackbarService.showTimed(MineSnackbarType.Error, this.contentPipe.transform('integrations-oauth.failureText'), true);
    }
  }
}