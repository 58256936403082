import { Pipe, PipeTransform } from '@angular/core';
import { TicketStateEnum } from 'src/app/api/models/requests/ticket-state.enum';
import { ContentPipe } from 'src/app/services/content/content.pipe';

@Pipe({
	standalone: true,
	name: 'ticketStateToStatus'
})
export class TicketStateToStatusPipe implements PipeTransform {
	
	constructor(private contentPipe: ContentPipe) {}
	
	transform(state: TicketStateEnum): string {
		const ticketStates = this.contentPipe.transform('common.ticketStates');
		switch (state) {
			case TicketStateEnum.Open:
				return ticketStates.open;
			case TicketStateEnum.Notify:
				return ticketStates.notify;	
			case TicketStateEnum.Processing:
				return ticketStates.processing;
			case TicketStateEnum.Unverified:
				return ticketStates.unverified;
			case TicketStateEnum.Closed:
				return ticketStates.closed;
			default:
				return state;
		}
	}
}