import { Pipe, PipeTransform } from '@angular/core';
import { CustomValuesQuery } from 'src/app/company-settings/state/custom-values/custom-values.query';

// common pipe for transforming id to name to activity fields that supports custom values [we are showing and working with names but server contract are Ids]
@Pipe({
  standalone: true,
  name: 'systemFieldIdToName'
})
export class SystemFieldIdToNamePipe implements PipeTransform {

  constructor(
    private customValuesQuery: CustomValuesQuery,
  ) {}

  transform(id: string): string {
      // try to get it from custom values
      const customValue = this.customValuesQuery.getEntity(id?.trim());
      if (customValue) {
        return customValue.name.trim();
      }

      return id;
  }

}
