import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { AutosizeModule } from 'ngx-autosize';

// RxAngular Template Operators
import { RxIf } from '@rx-angular/template/if';
import { RxFor } from '@rx-angular/template/for';
import { RxLet } from '@rx-angular/template/let';

import { MineosChatbotComponent } from './mineos-chatbot.component';
import { ContentPipeModule } from '../services/content/content-pipe.module';
import { MineChipComponent } from '../shared/mine-chip/mine-chip.component';

@NgModule({
  declarations: [
    MineosChatbotComponent
  ],
  imports: [
    RxIf,
    RxFor,
    RxLet,
    CommonModule,
    AutosizeModule,
    ContentPipeModule,
    ReactiveFormsModule,
    MineChipComponent
  ],
  exports: [
    MineosChatbotComponent
  ],
})
export class MineosChatbotModule {}
