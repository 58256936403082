export enum SystemsColumnKeys {
  Counter = 'counter',
  Category = 'category',
  BusinessOwner = 'businessOwner',
  StakeHolders = 'stakeHolders',
  InstanceType = 'instanceType',
  System = 'system',
  Ropa = 'ropa',
  Review = 'review',
  Frameworks = 'frameworks',
  DataTypes = 'dataTypes',
  Locations = 'locations',
  DataSourceOrigin = 'dataSourceOrigin',
  Feedback = 'feedback',
  RiskCalc = 'riskCalc',
  Mitigations = 'mitigations',
  CyberRating = 'cyberRating',
  HighPotentialRisk = 'highPotentialRisk',
  Functions = 'functions',
  ReviewDate = 'reviewDate',
}