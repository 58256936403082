import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { PrivacyCenterRequestForm } from 'src/app/api/models/privacy-center/privacy-center.interface';
import { PrivacyCenterStore } from './privacy-center.store';

@Injectable({ 
	providedIn: 'root' 
})
export class PrivacyCenterQuery extends Query<PrivacyCenterRequestForm> {

	constructor(protected store: PrivacyCenterStore) {
		super(store);
	}

	getPrivacyCenterSupportedLanguages(): string[] {
		return this.getValue().localization.forms.filter(l => l.supported).map(l => l.locale);
	}

}
