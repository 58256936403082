import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { HttpClientHelperService } from "./http-client-helper.service";
import { RegionsResponse } from './models/regions.interface';

@Injectable({
  providedIn: 'root'
})
export class ApiClientRegionsService {
  private readonly controller = 'regions';

  constructor(private httpClientHelper: HttpClientHelperService) { }

    getRegions(): Observable<RegionsResponse> {
        return this.httpClientHelper.invokeGetAuth<RegionsResponse>(`${this.controller}/by-continents`);
    }
}
