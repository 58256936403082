import { Injectable } from "@angular/core";
import { PreloadingStrategy, Route } from "@angular/router";
import { concatMap, filter, map, take, combineLatest, Observable, of, tap } from "rxjs";

import { AuthService } from "../auth/auth.service";
import { RoleEnum } from "../core/models/permission-role.enum";
import { ProfileQuery } from "../profile/state/profile.query";
import { PermissionsService } from "../auth/permissions.service";
import { RoutesManager } from "../shared/models/routes.interfaces";

@Injectable({
    providedIn: 'root'
})
export class CustomPreloadingStrategy implements PreloadingStrategy {

    constructor(
        private authService: AuthService,
        private profileQuery: ProfileQuery,
        private permissionsService: PermissionsService,
    ) { }

    preload(route: Route, load: () => Observable<any>): Observable<any> {
        const isRoleAllowed: Observable<boolean> = route?.path === RoutesManager.companySettings ? 
            of(true) : 
            this.permissionsService.selectIsAllowed([
                RoleEnum.Agent, 
                RoleEnum.Admin, 
                RoleEnum.DsrManager, 
                RoleEnum.InventoryManager
            ]);
        
        return combineLatest([
            isRoleAllowed,
            this.authService.isLoggedIn$,
            this.profileQuery.select('verifiedToCompany'),
        ]).pipe(
            map(res => res.every(r => r === true)),
            filter(res => res === true && route.data && route.data['preload']),
            take(1),
            concatMap(() => load())
        );
    }
}