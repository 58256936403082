<p class="channels__header">
    {{ 'home.channelsTitle' | content }}
</p>

<div @fadeInOnEnter class="channels__content" *ngIf="channels$ | async as channels">

    <div class="channels__item" *ngFor="let channel of channels">
        
        <div class="channels__icon-wrapper">
            <svg class="channels__icon">
                <use [attr.xlink:href]="channel.iconName"></use>
            </svg>
        </div>

        <p class="channels__name">
            {{ channel.contentKey | content }}
            <svg *ngIf="channel.tooltip" class="channels__tooltip-icon"
                [tp]="(channel.tooltip | content)">
				<use xlink:href="../../../assets/img/sprite.svg#icon-Info-1"></use>
			</svg>
        </p>

        <div class="channels__status">
            
            <div class="channels__status-circle" [class.channels__status-circle--on]="channel.connected"></div>
            
            {{ (channel.connected ? 'home.channelsOn' : 'home.channelsOff') | content }}
        </div>
    
    </div>

    <mine-button-tertiary class="channels__btn" (onClick)="navigateToChannels()">
        {{ 'home.channelsManageBtn' | content }}
    </mine-button-tertiary>

</div>