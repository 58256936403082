import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { HttpClientHelperService } from './http-client-helper.service';

@Injectable({
  providedIn: 'root'
})
export class ApiClientTicketViewService {
  private readonly controller = 'ticketView';

  constructor(
    private httpClientHelper: HttpClientHelperService
  ) {
  }

  updateEvidenceViewed(ticketId: string): Observable<void> {
    return this.httpClientHelper.invokePostAuth<void>(`${this.controller}/UpdateEvidenceViewed/${ticketId}`);
  }
}
