import { Component, OnInit, ViewChild, ElementRef, OnDestroy, ChangeDetectionStrategy, DestroyRef} from '@angular/core';
import { Subject } from 'rxjs';

import * as confetti from 'canvas-confetti';
import { ConfettiService } from 'src/app/services/confetti.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-confetti',
  templateUrl: './app-confetti.component.html',
  styleUrls: ['./app-confetti.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppConfettiComponent implements OnInit, OnDestroy {

  @ViewChild('confetticanvas', { static: true })
  confettiCanvas: ElementRef;
  
  private destroy = new Subject<void>();
	destroy$ = this.destroy.asObservable();

  constructor(private confettiService: ConfettiService, private destroyRef: DestroyRef) {}

  ngOnDestroy(): void {
    this.destroy.next();
  }

	ngOnInit(): void {
		this.confettiService.celebrate$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => this.celebrate());
	}

  celebrate() {
    const myConfetti = confetti.create(this.confettiCanvas.nativeElement, {
			resize: true,
			useWorker: true
    });
    
		myConfetti({
			particleCount: 100,
			spread: 160
			// any other options from the global
			// confetti function
		});
	}
}
